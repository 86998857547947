import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { AppSettings } from 'app/app.settings';
import { Settings } from 'app/app.settings.model';
import { UserService } from 'app/shared/services/common/user.service';
import { PasswordService } from '../password.service';
import { SecurityService } from 'app/shared/services';
import { Constant } from 'app/shared/constant/constant';
import { takeUntil, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  Constant = Constant;
  public form: FormGroup;
  public settings: Settings;
  data: any = {};
  token: string;
  isLoading: boolean = false;
  isTokenValidate: boolean = false;
  isSend: boolean = false;
  minLength: number = 8;
  passNotMatch: boolean = false;

  constructor(
    public appSettings: AppSettings,
    public fb: FormBuilder,
    public router: Router,
    public passwordService: PasswordService,
    public route: ActivatedRoute,
    public securityService: SecurityService
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.settings.loadingSpinner = false;
    this.initForm();
    const tokenObj = this.route.params['_value'];
    this.isLoading = true;
    this.passwordService.checkToken(tokenObj.token)
      .pipe( finalize(() => this.isLoading = false) )
      .subscribe((res) => {
        this.isTokenValidate = true;
        this.token = tokenObj.token;
      });
  }

  initForm() {
    this.form = this.fb.group({
      password: [this.data.password, Validators.compose([Validators.required, Validators.minLength(this.minLength)])],
      confirmPassword: [this.data.confirmPassword,
      Validators.compose([
        Validators.required])]
    }, { validator: this.mustMatch('password', 'confirmPassword') });
  }

  // confirmPasswordValidator(control: AbstractControl): { [key: string]: boolean } | null {
  //   const passwordControl: AbstractControl | null = this.getFormControl('password');
  //   if (control.value && passwordControl
  //     && control.value.length >= this.minLength
  //     && control.value !== passwordControl.value) {
  //     return { 'passNotMatch': true };
  //   }
  //   return null;
  // }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  markAsTouched(group: FormGroup | FormArray) {
    group.markAsTouched({ onlySelf: true });
    Object.keys(group.controls).map((field) => {
      const control = group.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAsTouched(control);
      }
    });
  }

  getFormControl(name: string) {
    if (!this.form) {
      return null;
    }
    return this.form.get(name);
  }

  goToLogin() {
    this.router.navigate(['login']);
  }

  changePassword() {
    this.markAsTouched(this.form);
    if (!this.form.valid) {
      return;
    }
    this.data = this.form.value;
    this.isLoading = true;
    return this.passwordService.resetPasswordAdmin(this.data.password, this.token)
      .subscribe((res: any) => {
        this.isLoading = false;
        this.isSend = true;
      });
  }

}
