<div class="title-header-v3" >
  XUẤT BÁO CÁO EXCEL
</div>
<div mat-dialog-content >
  <div>Bảng tính sẽ được xuất bao gồm các bản ghi theo bộ lọc.</div>
  <div>Bạn có chắc chắn muốn xuất báo cáo này?</div>
</div>
<div mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%;" fxLayoutGap="16px">
    <button class="button-reject" mat-ripple (click)="close()">Hủy</button>
    <button class="button-accept" mat-ripple (click)="exportExcel()">Xuất báo cáo</button>
  </div>
</div>