import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
export interface PopupDealCheckerData {
  isSuccess: boolean;
  isHasNotes: boolean;
  notes: string;
}

@Component({
  selector: 'app-popup-deal-checker',
  styleUrls: ['./popup-deal-checker.scss'],
  templateUrl: './popup-deal-checker.html',
})
export class PopupDealChecker implements OnInit  {

  notes: string;
  constructor(
    public dialogRef: MatDialogRef<PopupDealChecker>,
    @Inject(MAT_DIALOG_DATA) public data: PopupDealCheckerData,
    ) {}

  ngOnInit() {
  }

  execute(): void {
    let data = {isSuccess: true, data: this.data.isSuccess, notes: this.notes};
    this.dialogRef.close(data);
  }

  onNoClick(): void {
    let data = {isSuccess: false};
    this.dialogRef.close(data);
  }
}
