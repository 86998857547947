<ag-grid-angular id="grid-note" class="ag-theme-balham" [rowData]="pagination" [columnDefs]="columnDefs"
  [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
</ag-grid-angular>

<ng-template #createdDateRow let-row>
    <span fxLayout="row" fxLayoutAlign="center">
      {{row.createdDate | date: "dd/MM/yyyy hh:mm:ss"}}
    </span>
</ng-template>
<ng-template #actionRow let-row>
  <a href="javascript:;" (click)="openSurveyDetail(row.survey)">
    <span fxLayout="row" fxLayoutAlign="center" *ngIf="row.survey ? true : false">
      {{row.survey.code}}
    </span>
  </a>
</ng-template>
<ng-template #agDescription let-row>
    <div class="m-cell error-info">
        <span matTooltip="{{isNoteAdmin? getInfoNoteAdmins(row): getInfoNote(row)}}"
                                                [matTooltipClass]="checkTextHasWhiteSpace(isNoteAdmin? getInfoNoteAdmins(row): getInfoNote(row)) ? 'my-tooltip-has-white-space' : 'my-tooltip-no-white-space'">{{createShortText(isNoteAdmin? row.content: row.note)}}</span>
    </div>
  </ng-template>
