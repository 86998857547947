export class PostStatus {
    public static WAITING_FOR_APPROVAL = "WAITING_FOR_APPROVAL"; // Chờ admin duyệt
    public static REJECT = "REJECT"; // Admin từ chối duyệt
    public static OPEN = "OPEN"; // Admin đã duyệt / bài đăng tạo từ danh sách sản phẩm
    public static DEAL = "DEAL"; // Bài đăng đã chốt thỏa thuận
    public static SOLD = "SOLD"; // Bài đăng đã bán
    public static CANCEL = "CANCEL"; // Bài đăng đã hủy

    public static getStatus(status) {
        switch (status) {
            case "WAITING_FOR_APPROVAL": {
                return "Chờ duyệt";
            }
            case "REJECT": {
                return "Từ chối";
            }
            case "OPEN": {
                return "Đã duyệt";
            }
            case "DEAL": {
                return "Đã chốt thỏa thuận";
            }
            case "SOLD": {
                return "Đã bán";
            }
            case "CANCEL": {
                return "Đã hủy";
            }
            default:
                break;
        }
    }
}

export const POST_STATUS = [
    { key: "all", value: "Tất cả" },
    { key: "WAITING_FOR_APPROVAL", value: "Chờ duyệt" },
    { key: "OPEN", value: "Đã duyệt" },
    { key: "REJECT", value: "Từ chối" },
    { key: "DEAL", value: "Đã chốt thỏa thuận" },
    { key: "SOLD", value: "Đã bán" },
];

export const POST_TYPE = [
    { key: "all", value: "Tất cả" },
    { key: "LAND_SALE", value: "Mua bán bất động sản" },
    { key: "LAND_RENT", value: "Cho thuê bất động sản" },
    { key: "CAR_SALE", value: "Mua bán xe hơi" },
    { key: "FINANCIAL_NEWS", value: "Tin tài chính" },
];

export const POST_DEAL_STATUS = [
    { key: "all", value: "Tất cả" },
    { key: "OPEN", value: "Chờ phản hồi" },
    { key: "REJECT", value: "Từ chối thỏa thuận" },
    { key: "WAITING_FOR_EXTEND", value: "Chờ gia hạn" },
    { key: "EXTEND", value: "Đồng ý gia hạn" },
    { key: "ACCEPT", value: "Chấp nhận thỏa thuận" },
    { key: "EXPIRE", value: "Thỏa thuận hết hạn" },
    { key: "COMPLETED", value: "Thỏa thuận đã đồng ý" },
    { key: "CANCEL", value: "Huỷ bỏ thoả thuận" },
    { key: "CANCEL_BY_POST", value: "Hủy bỏ bài đăng" },
];

export enum DealStatus {
    OPEN = "OPEN",
    ACCEPT = "ACCEPT",
    REJECT = "REJECT",
    EXPIRE = "EXPIRE",
    EXTEND = "EXTEND",
    WAITING_FOR_EXTEND = "WAITING_FOR_EXTEND",
    CANCEL = "CANCEL", // Người mua hủy bỏ thỏa thuận
    CANCEL_BY_POST = "CANCEL_BY_POST", // Hệ thống hủy bỏ thỏa thuận do bài đăng đã bị hủy bỏ
    COMPLETED = "COMPLETED",
}

export enum EPostDeal {
    OPEN = "Chờ phản hồi",
    ACCEPT = "Chấp nhận thỏa thuận",
    REJECT = "Từ chối thỏa thuận",
    EXPIRE = "Thỏa thuận hết hạn",
    EXTEND = "Đồng ý gia hạn",
    WAITING_FOR_EXTEND = "Chờ gia hạn",
    CANCEL = "Huỷ bỏ thoả thuận", // Người mua hủy bỏ thỏa thuận
    CANCEL_BY_POST = "Hủy bỏ bài đăng", // Hệ thống hủy bỏ thỏa thuận do bài đăng đã bị hủy bỏ
    COMPLETED = "Thỏa thuận đã đồng ý",
}

export enum PostUnitTypeEnum {
    MAIN = "MAIN", // Sản phẩm hệ thống
    SOCIAL = "SOCIAL", // Sản phẩm cộng đồng
}

export enum PostUnitType {
    MAIN = "Giao dịch có sẵn", // Sản phẩm hệ thống
    SOCIAL = "Sản phẩm tự do", // Sản phẩm cộng đồng
}

export enum PostType {
    LAND_SALE = "Mua bán bất động sản",
    LAND_RENT = "Cho thuê bất động sản",
    CAR_SALE = "Mua bán xe hơi",
    FINANCIAL_NEWS = "Tin tài chính",
}

export enum PostTypeKey {
    LAND_SALE = "LAND_SALE",
    LAND_RENT = "LAND_RENT",
    CAR_SALE = "CAR_SALE",
    FINANCIAL_NEWS = "FINANCIAL_NEWS",
}

export enum ServiceType {
    RENT_CAR = 'RENT_CAR',
    SALE_CAR = 'SALE_CAR',
}

export enum RentType {
    SELF_DRIVE = "SELF_DRIVE",
    RENT_DRIVE = "RENT_DRIVE",
}


export enum CommentType {
    MESSAGE = "MESSAGE",
    DEAL = "DEAL",
}

export enum PostDisplayStatus {
    ON = "Đang hiển thị",
    OFF = "Đã ẩn",
}

export const POST_UNIT_TYPE = [
    { key: "all", value: "Tất cả" },
    { key: "MAIN", value: PostUnitType.MAIN },
    { key: "SOCIAL", value: PostUnitType.SOCIAL },
];

export const POST_DISPLAY_STATUS = [
    { key: "all", value: "Tất cả" },
    { key: true, value: PostDisplayStatus.ON },
    { key: false, value: PostDisplayStatus.OFF },
];

export const POST_DIRECTION = [
    {
        key: "BAC",
        value: "BẮC",
    },
    {
        key: "DONG_BAC",
        value: "ĐÔNG BẮC",
    },
    {
        key: "TAY_BAC",
        value: "TÂY BẮC",
    },
    {
        key: "DONG",
        value: "ĐÔNG",
    },
    {
        key: "DONG_NAM",
        value: "ĐÔNG NAM",
    },
    {
        key: "NAM",
        value: "NAM",
    },
    {
        key: "TAY_NAM",
        value: "TÂY NAM",
    },
    {
        key: "TAY",
        value: "TÂY",
    },
];

export const POST_UNIT_CATEGORY = [
    {
        key: "CHUNG_CU",
        value: "Chung cư",
    },
    {
        key: "DAT_NEN",
        value: "Đất nền",
    },
    {
        key: "CAN_HO",
        value: "Căn hộ",
    },
    {
        key: "BIET_THU",
        value: "Biệt thự",
    },
    {
        key: "KHU_NGHI_DUONG",
        value: "Khu nghỉ dưỡng/Khu Phức hợp",
    },
    {
        key: "SHOPHOUSE",
        value: "Shophouse/Nhà ở TM",
    },
    {
        key: "KHU_DO_THI_THUONG_MAI_GIAI_TRI",
        value: "Khu đô thị thương mại giải trí",
    },
];

export enum SocialUnitType {
    CHUNG_CU = 'CHUNG_CU',
    DAT_NEN = 'DAT_NEN',
    CAN_HO = 'CAN_HO',
    BIET_THU = 'BIET_THU',
    KHU_NGHI_DUONG = 'KHU_NGHI_DUONG',
    SHOPHOUSE = 'SHOPHOUSE',
    KHU_DO_THI_THUONG_MAI_GIAI_TRI = 'KHU_DO_THI_THUONG_MAI_GIAI_TRI',
}

export enum SocialUnitTypeName {
    CHUNG_CU = 'Chung cư',
    DAT_NEN = 'Đất nền',
    CAN_HO = 'Căn hộ',
    BIET_THU = 'Biệt thự',
    KHU_NGHI_DUONG = 'Khu nghỉ dưỡng/Khu Phức hợp',
    SHOPHOUSE = 'Shophouse/Nhà ở TM',
    KHU_DO_THI_THUONG_MAI_GIAI_TRI = 'Khu đô thị thương mại giải trí',
}