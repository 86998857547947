import { GroupApprovalType, GroupShowType, GroupStatus, GroupType } from 'app/consts/group.const';
import { PostStatus, PostUnitType } from 'app/shared/constant/social.constant';
import { IPriorityPostData } from 'app/shared/interfaces/social.interface';
import { DateToFormat } from 'app/shared/parse/date-to-format';
import { NumberToMoneyVN } from 'app/shared/parse/number-to-money-vn';
import moment = require('moment');

export class LandingPageGroupModel {
    totalPosts: number;
    totalComments: number;
    type: string;
    showType: string;
    description: string;
    name: string;
    coverImage: string;
    id: string;
    modifiedDate: string;
    shareUrl: string;
    totalMembers: number;
    isAdmin: boolean;
    isMember: boolean;
    isSendRequest: boolean;

    constructor(params?: any) {
        this.coverImage = params ? params.coverImage || null : null;
        this.description = params ? params.description || null : null;
        this.id = params ? params.id || null : null;
        this.name = params ? params.name || null : null;
        this.totalComments = params ? params.totalComments || 0 : 0;
        this.totalMembers = params ? params.totalMembers || 0 : 0;
        this.totalPosts = params ? params.totalPosts || 0 : 0;
        this.showType = params ? params.showType || GroupShowType.SHOW : GroupShowType.SHOW;
        this.shareUrl = params ? params.shareUrl || null : null;
        this.type = params ? params.type || GroupType.PRIVATE : GroupType.PRIVATE;
        this.modifiedDate = params && params.modifiedDate ? DateToFormat.parseDate(params.modifiedDate, 'dd/MM/yyyy - hh:mm:ss') : '';
        this.isAdmin = params ? params.isAdmin : false;
        this.isMember = params ? params.isMember : false;
        this.isSendRequest = params ? params.isSendRequest : false;
    }
}

export class LandingPageEventModel {
    listAdmin: any[];
    id: string;
    createdDate: Date; 
    modifiedDate: Date; 
    isHot: boolean; 
    title: string; 
    type: { value: string; text: string }; 
    status: string; 
    organizationalForm: string; 
    limit: number; 
    entranceFees: number; 
    imageUrl: string; 
    startDateTime: Date; 
    endDateTime: Date; 
    address: string; 
    geolocation: object; 
    description: string; 
    shareUrl: string; 
    eventStatus: string; 
    totalRegistered: number; 
    totalPaticipated: number; 
    lastReaction: Date; 
    isMapVisible: boolean; 
    isAdmin: boolean; 
    isShowRegisterButton: boolean; 
    isCurrentUserRegistered: boolean; 
    isCurrentUserParticipated: boolean; 
    isShowUnRegisterButton: boolean; 
    currentUserEventTicket: Date;
    liveStream: any;

    constructor(params?: any) {
        this.listAdmin = params ? params.listAdmin || [] : [];
        this.id = params ? params.id || null : null;
        this.isHot = params ? params.isHot : true;
        this.title = params ? params.title || null : null;
        this.type = params ? params.type || {} : {};
        this.status = params ? params.status || 0 : 0;
        this.organizationalForm = params ? params.organizationalForm || null : null;
        this.limit = params ? params.limit || 0 : 0;
        this.entranceFees = params ? params.entranceFees || 0 : 0;
        this.imageUrl = params ? params.imageUrl || null : null;
        this.startDateTime = params ? params.startDateTime || null : null;
        this.address = params ? params.address || null : null;
        this.geolocation = params ? params.geolocation || {} : {};
        this.liveStream = params ? params.liveStream || {} : {};
        this.description = params ? params.description || null : null;
        this.shareUrl = params ? params.shareUrl || null : null;
        this.eventStatus = params ? params.eventStatus || null : null;
        this.totalRegistered = params ? params.totalRegistered || null : null;
        this.totalPaticipated = params ? params.totalPaticipated || null : null;
        this.isMapVisible = params ? params.isMapVisible : false;
        this.isShowRegisterButton = params ? params.isShowRegisterButton : false;
        this.isAdmin = params ? params.isAdmin : true;
        this.isShowUnRegisterButton = params ? params.isShowUnRegisterButton : true;
        this.isCurrentUserRegistered = params ? params.isCurrentUserRegistered : true;
        this.isCurrentUserParticipated = params ? params.isCurrentUserParticipated : true;
        this.currentUserEventTicket = params ? params.currentUserEventTicket : true;
        this.createdDate = params && params.createdDate ? DateToFormat.parseDate(params.createdDate, 'dd/MM/yyyy') : '';
        this.modifiedDate = params && params.modifiedDate ? DateToFormat.parseDate(params.modifiedDate, 'dd/MM/yyyy') : '';
        this.startDateTime = params && params.startDateTime ? DateToFormat.parseDate(params.startDateTime, 'dd/MM/yyyy - hh:mm') : '';
        this.endDateTime = params && params.endDateTime ? DateToFormat.parseDate(params.endDateTime, 'dd/MM/yyyy - hh:mm') : '';
        this.lastReaction = params && params.lastReaction ? DateToFormat.parseDate(params.lastReaction, 'dd/MM/yyyy') : '';
    }
}
export class LandingPageEsalekitModel {
    id: string;
    isActive: boolean;
    createdDate: Date; 
    modifiedDate: Date; 
    bannerUrl: string;
    folders: any;
    folderImages: any;
    folderVideos:any;
    project: any;
    shareUrl: string;
    

    constructor(params?: any) {
        this.id = params ? params.id || null : null;
        this.bannerUrl = params ? params.bannerUrl || null : null;
        this.folders = params ? params.folders || {} : {};
        this.folderImages = params ? params.folderImages || {} : {};
        this.folderVideos = params ? params.folderVideos || {} : {};
        this.project = params ? params.project || {} : {};
        this.shareUrl = params ? params.shareUrl || null : null;
        this.createdDate = params && params.createdDate ? DateToFormat.parseDate(params.createdDate, 'dd/MM/yyyy') : '';
        this.modifiedDate = params && params.modifiedDate ? DateToFormat.parseDate(params.modifiedDate, 'dd/MM/yyyy') : '';
    }
}
export class LandingPagePostGroupModel {
    id: string;
    description: string;
    user: {name: string, avatar: string };
    group: {name: string};
    medias: any[];
    totalFavorites: number;
    totalComments: number;
    totalShares: number;
    createdDate: Date;
    shareUrl: string;
    constructor(params?: any) {
        this.id = params ? params.id || null : null;
        this.description = params ? params.description || null : null;
        this.user = params ? params.user || {} : {};
        this.group = params ? params.group || {} : {};
        this.medias = params ? params.medias || [] : [];
        this.totalFavorites = params ? params.totalFavorites || 0 : 0;
        this.totalComments = params ? params.totalComments || 0 : 0;
        this.totalShares = params ? params.totalShares || 0 : 0;
        this.createdDate = params && params.createdDate ? DateToFormat.parseDate(params.createdDate, 'dd/MM/yyyy - hh:mm') : '';
        this.shareUrl = params ? params.shareUrl || '' : '';
    }
}

export class LandingSocialPostModel {
    stt: number;
    id: string;
    status: string;
    createdDate: string;
    modifiedDate: string;
    title: string;
    price: string;
    priceVat: string;
    propertyUnitImageUrls: any[];
    dealNumber: number;
    favoriteNumber: number;
    commissionType: string;
    commissionPercent: number;
    commissionAmount: string;
    expiredType: string;
    expiredDate: string;
    expiredDay: number;
    escrowFee: string;
    isEscrowFeeIncluded: boolean;
    escrowFeeIncludedList: any[];
    isUsingGuaranteeMoney: boolean;
    guaranteeMoneyAmount: string;
    isUsingPriorityPost: boolean;
    priorityPostAmount: string;
    priorityPostData: IPriorityPostData;
    priorityPostExpiredDate: string;
    description: string;
    interactDate: Date;
    currentUserIsFavorite: boolean;
    ownerId: string;
    ownerName: string;
    ownerPhone: string;
    unitType: string;
    propertyUnitId: string;
    propertyUnitView1: string;
    propertyUnitCategory: string;
    propertyUnitProjectName: string;
    propertyUnitArea: number;
    propertyUnitDirection: string;
    propertyUnitDesc: string;
    propertyUnitBlock: string;
    propertyUnitFloor: string;
    associateDeal: any;
    commentChannels: any;
    commentChannelsNumber: any;
    chatNumber: any;
    shareNumber: number;
    dealerNumber: number;
    userCommentNumber: number;
    commentUsers: any;
    auction: any;
    isAuction: boolean;
    shareUrl: string;
    constructor(params?) {
      params = params || {};
  
      this.setValue(params);
    }
    setValue(params?) {
      this.id = params.id || '';
      this.status = params.status ? PostStatus.getStatus(params.status) : '';
      this.createdDate = params.createdDate ? moment(params.createdDate).format("DD-MM-YYYY") : '';
      this.modifiedDate = params.modifiedDate ? moment(params.modifiedDate).format("DD-MM-YYYY") : '';
      this.title = params.title || '';
      this.price = NumberToMoneyVN.parseToMoney(params.price) || '';
      this.priceVat = NumberToMoneyVN.parseToMoney(params.priceVat) || '';
      this.propertyUnitImageUrls = params.propertyUnitImageUrls || '';
      this.dealNumber = params.dealNumber?  params.dealNumber : 0;
      this.favoriteNumber = params.favoriteNumber || 0;
      this.commissionType = params.commissionType || '';
      this.commissionPercent = params.commissionPercent || 0;
      this.commissionAmount = NumberToMoneyVN.parseToMoney(params.commissionAmount) || '';
      this.expiredType = params.expiredType || '';
      this.expiredDate = moment(params.expiredDate).format("DD-MM-YYYY") || '';
      this.expiredDay = params.expiredDay || '';
      this.escrowFee = NumberToMoneyVN.parseToMoney(params.escrowFee) || 0;
      this.isEscrowFeeIncluded = params.isEscrowFeeIncluded || '';
      this.escrowFeeIncludedList = params.escrowFeeIncludedList || '';
      this.isUsingGuaranteeMoney = params.isUsingGuaranteeMoney || '';
      this.guaranteeMoneyAmount = NumberToMoneyVN.parseToMoney(params.guaranteeMoneyAmount) || '';
      this.isUsingPriorityPost = params.isUsingPriorityPost || '';
      this.priorityPostAmount = NumberToMoneyVN.parseToMoney(params.priorityPostAmount) || '';
      this.priorityPostData = params.priorityPostData || '';
      this.priorityPostExpiredDate = params.priorityPostExpiredDate ? moment(params.priorityPostExpiredDate).format("DD-MM-YYYY") : '';
      this.description = params.description || '';
      this.interactDate = params.interactDate || '';
      this.currentUserIsFavorite = params.currentUserIsFavorite || '';
      this.ownerId = params.ownerId || '';
      this.ownerName = params.ownerName || '';
      this.ownerPhone = params.ownerPhone || '';
      this.unitType = params.unitType ? PostUnitType[params.unitType] : '';
      this.propertyUnitId = params.propertyUnitId || '';
      this.propertyUnitView1 = params.propertyUnitView1 || '';
      this.propertyUnitCategory = params.propertyUnitCategory || '';
      this.propertyUnitProjectName = params.propertyUnitProjectName || '';
      this.propertyUnitArea = params.propertyUnitArea || '';
      this.propertyUnitDirection = this.translateDirectionn(params.propertyUnitDirection) || '';
      this.propertyUnitDesc = params.propertyUnitDesc || '';
      this.propertyUnitBlock = params.propertyUnitBlock || '';
      this.propertyUnitFloor = params.propertyUnitFloor || '';
      this.associateDeal = params.associateDeal || '';
      this.commentChannels = params.commentChannels || null;
      this.commentChannelsNumber = params.commentChannels ? params.commentChannels.length : 0 ;
      this.chatNumber = 0;
      this.dealerNumber = 0;
      this.shareNumber = params.shareNumber || 0 ;
      this.shareUrl = params.shareUrl || '' ;
      this.auction = params.auction || {};
      this.isAuction = params.isAuction || false;
      if(this.expiredType === 'DAY_NUMBER'){
        this.expiredDate = moment(params.createdDate).add(this.expiredDay,'day').format("DD-MM-YYYY");
      }
  
      if (params.dealList && params.dealList.length) {
        const dealerList = params.dealList.filter((item, index, dealListCur) => dealListCur.findIndex(itemCur  => (itemCur.buyer.id === item.buyer.id)) === index);
        this.dealerNumber = dealerList.length;
      }
    }
    translateDirectionn(direction) {
      switch (direction) {
        case 'BAC':
          return 'BẮC';
        case 'DONG':
          return 'ĐÔNG';
        case 'TAY':
          return 'TÂY';
        case 'DONG_BAC':
          return 'ĐÔNG BẮC';
        case 'TAY_BAC':
          return 'TÂY BẮC';
        case 'DONG_NAM':
          return 'ĐÔNG NAM';
        case 'TAY_NAM':
          return 'TÂY NAM';
        default:
          return direction;
      }
    }
}

export class LandingPageVideoModel {
    stt: number;
    id: string;
    createdDate: Date;
    createdBy: string;
    modifiedDate: Date;
    modifiedBy: string;
    active: string;
    owner: string;
    hashtag: string;
    title: string;
    prominent: string;
    status: string;
    heartCount: number;
    viewCount: number;
    createdByUser: any;
    bannerImage: any;
    description: string;
    shareUrl: string;
    media:any;
    commentCount: number;


    constructor(params?: any) {
        this.id = params ? params.id : '';
        this.owner= params ? params.owner : '';
        this.title= params ? params.title : '';
        this.prominent= params ? params.prominent : '';
        this.status= params ? params.status : '';
        this.createdDate =  params && params.createdDate ?  DateToFormat.parseDate(params.createdDate, 'hh:mm - dd/MM/yyyy') : '';
        this.modifiedDate =  params && params.modifiedDate ?DateToFormat.parseDate(params.modifiedDate, 'hh:mm - dd/MM/yyyy') : '';
        this.heartCount= params ? params.heartCount : 0;
        this.viewCount= params ? params.viewCount : 0;
        this.createdByUser= params ? params.createdByUser : '';
        this.bannerImage= params ? params.bannerImage : '';
        this.description= params ? params.description : '';
        this.shareUrl = params ? params.shareUrl || '' : '';
        this.media = params ? params.media || '' : '';
        this.commentCount= params ? params.commentCount : 0;
    }
}