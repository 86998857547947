import { Pipe, PipeTransform } from '@angular/core';
import { FloorRoundFloatNumber } from '../parse/floor-round-float-number';
@Pipe({name: 'money'})
export class TransformMoneyPipe implements PipeTransform {

  static transform(value: number, type) {
    let result = undefined;
    switch (type) {
      case 'summary':
        result = this.summary(value);
      break;
      case 'decimal1':
        result = this.parseMoney(value, -1);
      break;
      case 'decimal2':
        result = this.parseMoney(value, -2);
      break;
      default:
        result = this.parseMoney(value);
      break;
    }
    return result;
  }
  static parseMoney(number, numberDecimal?) {
    !numberDecimal && (numberDecimal = -3);
    let removeDecimal = true;
    if (number) {
      number = this.decimalAdjust('round', number , numberDecimal);
      number+='';
      let negative = false;
      if (number[0] === '-') {
         negative = true;
         number = number.substring(1, number.length);
      }
      const array = number.split('.');
      number= array[0]; let index = 0, str = '';
      let decimal = array[1];
      //remove Decimal
      if (decimal && removeDecimal) {
        const decimalValue= '0.' + decimal;
        if (parseFloat(decimalValue) > 0.5) {
          number = parseInt(number) + 1;
        } else {
          number = parseInt(number);
        }
        number += '';
        decimal = '';
      }
      // end
      for (let i= number.length - 1; i >= 0; i--) {
        index++;
        str= number.charAt(i) + str;
        if (index % 3 === 0) {
          str = ',' + str;
          index = 0;
        }
      }
      if (str.charAt(0) === ',') {
        str = str.substring(1, str.length);
      }
      negative && (str = '-' + str)
      return decimal ? str + '.' + decimal : str;
    }
    else if (number === 0 || number === '0') {
      return '0';
    }
    else {
      return '_';
    }
  }
  static decimalAdjust(type, value, exp) {
    if (exp < 0) {
      exp = exp * -1;
    }
    let valueMul = 1;
    for (let i=1; i <= exp; i++) {
      valueMul= valueMul * 10;
    }
    return Math[type](value * valueMul) / valueMul;
  }
  static summary(price) {
    let text = '';
    if (price >= 1000*1000*1000) {
      price = price/(1000*1000*1000);
      price = FloorRoundFloatNumber.round(price, 2);
      text = price + ' Tỷ'
    } else if (price >= 1000*1000) {
      price = price/(1000*1000);
      price = FloorRoundFloatNumber.round(price, 2);
      text = price + ' Triệu'
    } else if (price >= 1000) {
      price = price/(1000);
      price = FloorRoundFloatNumber.round(price, 2);
      text = price + ' Nghìn'
    }
    return text;
  }

  transform(value: number, type?) {
    let result = undefined;
    switch (type) {
      case 'summary':
        result = TransformMoneyPipe.summary(value);
      break;
      case 'decimal1':
        result = TransformMoneyPipe.parseMoney(value, -1);
      break;
      case 'decimal2':
        result = TransformMoneyPipe.parseMoney(value, -2);
      break;
      default:
        result = TransformMoneyPipe.parseMoney(value);
      break;
    }
    return result;
  }
}
