<mat-sidenav-container>

    <div fxLayout="row" fxLayoutAlign="center center" class="h-100">

        <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" fxFlex="80" fxFlex.gt-sm="30" fxFlex.sm="60">

            <mat-card class="p-0 mat-elevation-z24 box">
                <div fxLayout="column" fxLayoutAlign="center center" class="box-header">
                    <!-- <button mat-fab color="accent" class="mat-elevation-z12" (click)="onSubmit(form.value);">
                        <mat-icon>exit_to_app</mat-icon>
                    </button> -->
                    <div class="login-title" fxLayout="row" fxLayoutAlign="center center">
                        <img src="/assets/img/logo/logo-forgot-password.svg" class="rounded-circle user_img mr-10">
                    </div>
                    <!-- <a mat-button routerLink="/register">Don't have an account? Sign up now!</a> -->
                </div>
                <mat-card-content fxLayout="column" fxLayoutAlign="end center" class="box-content" *ngIf="!isSend">
                    <mat-card fxLayout="column" fxLayoutAlign="center center" class="mat-elevation-z12 box-content-inner">
                        <span class="box-content-header">Email đăng nhập</span>
                        <mat-form-field class="w-100">
                            <input matInput placeholder="Email" [(ngModel)]="data.email" formControlName="email">
                            <mat-error *ngIf="form.controls.email.errors?.required">Email is required</mat-error>
                            <mat-error *ngIf="form.controls.email.hasError('invalidEmail')">Invalid email address</mat-error>
                        </mat-form-field>
                    </mat-card>
                    <button mat-raised-button color="primary" class="mat-elevation-z12 box-button" type="submit">Quên mật khẩu</button>
                </mat-card-content>
                <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%; height: 100px;" *ngIf="isSend">
                    <h2>Vui lòng check email để biết thêm chi tiết</h2>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" style="margin-bottom: 20px;">
                    <a [routerLink]="['/login']">Đăng nhập</a>
                </div>
            </mat-card>

        </form>

    </div>

</mat-sidenav-container>