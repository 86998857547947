import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-landing-footer',
  templateUrl: './landing-footer.component.html',
  styleUrls: ['./landing-footer.component.scss']
})
export class LandingFooterComponent implements OnInit {
  @Input() shareUrl: string = '';
  constructor(
  ) {
  }

  ngOnInit() {
  }
}
