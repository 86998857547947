import { environment } from '../../../environments/environment';
import { createNumberMask } from 'text-mask-addons/dist/textMaskAddons';
import {
  BonusTypeEnum,
  CampaignDestiontionEnum,
  CampaignStatusEnum,
  CampaignTypeEnum,
  WalletTransactionType,
} from 'app/shared/models/campaign.model';
import { CafeStatusEnum } from 'app/pages/cafe/cafe.model';
import { NotiCategoryEnum } from 'app/shared/models/noti-category.model';
import { HomeMenuStatusEnum } from './home-menu.enum';
import {
  MissionTypeEnum,
  MissionMissionTypeEnum,
} from '../models/mission.model';
import { NotificationGlobalDestiontionEnum } from 'app/pages/notification-global/notification-global.enum';
import {
  mlmActionBonusEnum,
  mlmActionTextEnum,
} from 'app/shared/enum/mlm.enum';
import { FlashSaleEnum } from 'app/pages/project/project.model';
import { CompareConstant } from './compare.constant';

export enum StatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum CategoryEnum {
  'DAT_NEN' = 'fbbec112-df7f-4cf2-8e72-c5c546be9b85',
  'CAN_HO' = 'baf92168-1529-462e-8687-d20d6acde1bc',
}

export enum DateSelectTypeEnum {
  'DATE' = 'DATE',
  'DAY_NUMBER' = 'DAY_NUMBER',
}

export enum StatementHistoryType {
  RECEIPTS = 'RECEIPTS',
  PAYMENT = 'PAYMENT',

  DEPOSIT = 'DEPOSIT', // + Nạp tiền
  WITHDRAW = 'WITHDRAW', // - Rút tiền
  PAY = 'PAY', // - Thanh toán
  REFUND = 'REFUND', // + Hoàn tiền
  BONUS = 'BONUS', // + Thưởng
  INVESTOR_BONUS = 'INVESTOR_BONUS', // + Thưởng từ nhà đầu tư
  COMMISSION = 'COMMISSION', // + Hoa hồng
  MISSION_PRIZE = 'MISSION_PRIZE', // + Thưởng nhiệm vụ
}

export enum StatementStatus {
  INIT = 'INIT', // chờ duyệt : chưa thanh toán
  SUCCESS = 'SUCCESS', // đã duyệt: đã thanh toán
  UNSUCCESS = 'UNSUCCESS', // Đã từ chối
}

export enum NewsStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ApproveStatus {
  APPROVED = 'APPROVED',
  WAITING = 'WAITING',
}

export enum EventOrganizationalFormEnum { // Hình thức tổ chức
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  CHARITY = 'CHARITY',
}

export enum TypeStudyEnum { // Loại khóa học
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}
export class Constant {
  //
  public static UPLOAD_FILE_TYPE = {
    RECEIPT: 'receipt',
    CONTRACT: 'contract',
    CONTRACT_LIQUIDATE: 'contract-liquidate',
    PROPERTY_TICKET: 'property-ticket',
    PROJECT: 'project',
    COURSE: 'course',
    ESALEKIT: 'esalekit',
  };

  public static ProjectType = {
    CanHo: 'Căn hộ',
  };

  //Header config
  public static site_key_recaptcha = environment.capcha;

  //TODO Icon s3
  public static icon_url_images = environment.asset + '/assets/images/';

  // Voice Call
  public static voice_call_available = 'AVAILABLE';
  public static voice_call_invisible = 'INVISIBLE';
  public static voice_call_prefix_customer = 'cus_';

  //Key localStorage
  public static key_local_token = 'token';
  public static key_local_version = 'version';
  public static key_local_language = 'language';
  public static key_local_status = 'status';

  //Error code
  public static error_force_logout = 401;
  public static error_not_connect_server = 0;
  public static error_server_error = 500;
  public static error_server_maintenance = 503;
  public static error_code = 400;

  // Error Message
  public static ERROR_REQUIRED = 'Dữ liệu không để trống';
  public static ERROR_REQUIRED_IMPORTANT = 'Thông tin bắt buộc';
  public static ERROR_INVALID = 'Dữ liệu không hợp lệ';
  public static ERROR_VIDEO_INVALID = 'Sai định dạng';
  public static ERROR_MAXLENGTH = 'Dữ liệu quá dài';
  public static ERROR_POPUP =
    'Chỉ có 1 chương trình trong cùng khoảng thời gian được cài đặt hiển thị popup ';
  public static ERROR_INVALID_DATE_MATCHING =
    'Thời gian kết thúc phải lớn hơn thời gian bắt đầu';
  public static ERROR_INVALID_DATE_MATCHING_EQUAL =
    'Thời gian kết thúc phải lớn hơn hoặc bằng thời gian bắt đầu';
  public static ERROR_INVALID_DATE_MATCHING_NOW =
    'Thời gian kết thúc phải lớn hơn thời gian hiện tại';
  public static ERROR_MAX_NUMBER = 'Số phải nhỏ hơn 100';
  public static ERROR_MIN_NUMBER = 'Số phải là số dương lớn hơn 0';
  public static ERROR_NUMBER_PERCENT = 'Số phải là số từ 0-100';
  public static ERROR_INVALID_POINT_RANGE = 'Vui lòng nhập khoảng điểm hợp lệ';
  public static ERROR_MAX_LENGTH_120 = 'Vui lòng nhập tối đa 120 ký tự';
  public static ERROR_REQUIRED_2 = 'Đây là trường bắt buộc';
  public static ERROR_INVALID_2 =
    'Sai định dạng. Vui lòng kiểm tra lại thông tin';
  public static ERROR_MONEY_MIN = 'Số tiền phải nhập > 0';
  public static ERROR_POINT_MIN = 'Số điểm phải nhập > 0';
  public static ERROR_INVALID_DATE_BEFORE_NOW =
    'Thời gian bắt đầu phải lớn hơn thời gian hiện tại';
  // Dropdown Search
  public static PLACEHOLDER_SEARCH = 'Tìm kiếm ...';
  public static NO_ENTRIES_FOUND = 'Không tồn tại kết quả tìm kiếm';
  public static NO_ENTRIES_FOUND_AG_GRID = '<span> Không có dữ liệu </span>';
  public static NO_ENTRIES_FOUND_AG_GRID_PAY_WITHDRAW =
    '<span> Không có phiếu chi </span>';
  public static NO_ENTRIES_FOUND_AG_GRID_PAY_DEPOSIT =
    '<span> Không có phiếu thu </span>';
  public static NO_ENTRIES_FOUND_AG_GRID_DEFAULT = '<span></span>';

  //Success code
  public static success_code = 200;
  public static ERROR_CODE_RENEW_PASSWORD = 40027;

  public static const_timeout_dowload_file = 30 * 60 * 1000;
  //API

  // Regex
  public static REGEX_EMAIL =
    /^[a-z][a-z0-9_\.]{1,32}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/gm;
  public static REGEX_ALL_EMAIL =
    /^(([^<>()[\]\\.,;:@\']+(\.[^<>()[\]\\.,;:@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9\s]+\.)+[a-zA-Z\s]{2,}))$/;
  public static REGEX_YOUTUBE_URL =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  public static REGEX_INTERNAL_URL = /^https?:\/\/(.+\/)+.+(\.(mp4))$/;

  public static REGEX_TEXT = /^ *[^0-9 ]+.*$/;
  public static REGEX_ONLY_TEXT_VIETNAMESE =
    /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
  public static REGEX_ANSWER_SURVEY =
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g;

  public static REGEX_NUMBER = /^[0-9]*$/;
  public static REGEX_NUMBER_ELDER_ZERO = /^[1-9][0-9]{0,9}$/;
  public static REGEX_VN_PHONE =
    /((09|03|07|08|05|849|843|847|848|845)+([0-9]{8})\b)/;
  public static REGEX_PASSWORD = /^[A-Za-z\d!@#$%^&*]/;
  public static REGEX_VALIDATE_FLOAT = '^\\d*(\\.\\d+)?$';
  public static REGEX_NOTE_ADMIN = /[\r\n\x0B\x0C\u0085\u2028\u2029]+/g; // Xóa xuống dòng, xóa khoảng trắng thừa
  public static REGEX_VN_TEXT =
    /^[aAàÀảẢãÃáÁạẠăĂằẰẳẲẵẴắẮặẶâÂầẦẩẨẫẪấẤậẬbBcCdDđĐeEèÈẻẺẽẼéÉẹẸêÊềỀểỂễỄếẾệỆfFgGhHiIìÌỉỈĩĨíÍịỊjJkKlLmMnNoOòÒỏỎõÕóÓọỌôÔồỒổỔỗỖốỐộỘơƠờỜởỞỡỠớỚợỢpPqQrRsStTuUùÙủỦũŨúÚụỤưƯừỪửỬữỮứỨựỰvVwWxXyYỳỲỷỶỹỸýÝỵỴzZ0-9 ]*$/;
  public static REGEX_CODE = /^[a-zA-Z0-9 -]*$/;

  public static ITEMS_PER_PAGE = 10;
  public static LENGTH_20 = 20;
  public static LENGTH_30 = 30;
  public static LENGTH_50 = 50;
  public static LENGTH_100 = 100;
  public static LENGTH_200 = 200;
  public static LENGTH_255 = 255;
  public static LENGTH_500 = 500;
  public static LENGTH_1000 = 1000;

  public static SOCIAL_FACEBOOK = 'Facebook';
  public static SOCIAL_LINKEDIN = 'LinkedIn';
  public static SOCIAL_VIBER = 'Viber';
  public static SOCIAL_ZALO = 'Zalo';
  public static SOCIAL_INSTAGRAM = 'Instagram';
  public static SOCIAL_TIKTOK = 'Tiktok';
  public static SOCIAL_WEBSITE = 'Website';

  public static KEY_VIETNAMESE = [
    'aàảãáạăằẳẵắặâầẩẫấậ',
    'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
    'dđ',
    'DĐ',
    'eèẻẽéẹêềểễếệ',
    'EÈẺẼÉẸÊỀỂỄẾỆ',
    'iìỉĩíị',
    'IÌỈĨÍỊ',
    'oòỏõóọôồổỗốộơờởỡớợ',
    'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
    'uùủũúụưừửữứự',
    'UÙỦŨÚỤƯỪỬỮỨỰ',
    'yỳỷỹýỵ',
    'YỲỶỸÝỴ',
  ];

  public static MAX_SIZE_IMAGE = 5 * 1024 * 1024; // size 5MB
  public static MAX_SIZE_VIDEO = 50 * 1024 * 1024; // size 50MB
  public static MAX_WIDTH_IMAGE = 40;
  public static MAX_HEIGHT_IMAGE = 40;
  public static MAX_ITEMS_UPLOAD = 10;
  public static TYPE_IMAGES_LIST = [
    {
      key: 'icon',
      value: 'Icon',
    },
    {
      key: 'thumnail',
      value: 'Thumnail',
    },
  ];
  public static TYPE_IMAGES = {
    icon: 'Icon',
    thumnail: 'Thumnail',
  };
  // Regex
  public static CONTROL_TYPES = [
    { id: 1, code: 'dropdown', name: 'Drop-down list' },
    { id: 2, code: 'radio', name: 'Radio button list' },
    { id: 3, code: 'checkbox', name: 'Checkboxes' },
    { id: 4, code: 'text', name: 'Textbox' },
    { id: 5, code: 'textare', name: 'Multiline textbox' },
    { id: 6, code: 'slider', name: 'Slider' },
    { id: 7, code: 'number', name: 'Number' },
    // {id: 1, code: "datepicker", name: "Date picker"},
    // {id: 1, code: "file", name: "File upload"},
    // {id: 1, code: "color", name: "Color squares"}
  ];
  public static CONTROL_TYPES_OBJECT = {
    dropdown: 'dropdown',
    radio: 'radio',
    checkbox: 'checkbox',
    text: 'text',
    textare: 'textare',
    slider: 'slider',
    number: 'number',
    // "file": "file",
    // "color": "color"
  };
  public static ORDERS = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];
  // public static MASTER_DATA.ID_CARDS = [{
  //   key: 'CMND',
  //   value: 'CMND',
  // },{
  //   key: 'Passport',
  //   value: 'Hộ chiếu',
  // }];
  // public static DIRECTIONS = [{
  //   key: 'BAC',
  //   value: 'Bắc',
  // },{
  //   key: 'DONG_BAC',
  //   value: 'Đông Bắc',
  // },{
  //   key: 'TAY_BAC',
  //   value: 'Tây Bắc',
  // },{
  //   key: 'DONG',
  //   value: 'Đông',
  // },{
  //   key: 'DONG_NAM',
  //   value: 'Đông Nam',
  // },{
  //   key: 'NAM',
  //   value: 'Nam',
  // },{
  //   key: 'TAY_NAM',
  //   value: 'Tây Nam',
  // },{
  //   key: 'TAY',
  //   value: 'Tây',
  // }];
  public static LEAD_SOURCE = {
    all: 'Tất cả',
    SHARE_HOLDER: 'Cổ đông',
    OLD_CUS: 'Khách hàng đã giao dịch',
    KTN: 'Kho Tài Nguyên',
    PKT: 'Phòng Khai Thác',
    MKT: 'Marketing DXS',
    MKT_DXG: 'Marketing DXG',
  };
  public static TYPES_TRANSACTION = [
    {
      key: 'BUY',
      value: 'Mua',
    },
    {
      key: 'RENT',
      value: 'Thuê',
    },
    {
      key: 'SELL',
      value: 'Bán',
    },
    {
      key: 'LEASE',
      value: 'Cho thuê',
    },
  ];

  public static LIST_PERIOD_COMM = [
    { id: '202001', name: 'Tháng 01/2020' },
    { id: '202002', name: 'Tháng 02/2020' },
    { id: '202003', name: 'Tháng 03/2020' },
    { id: '202004', name: 'Tháng 04/2020' },
    { id: '202005', name: 'Tháng 05/2020' },
    { id: '202006', name: 'Tháng 06/2020' },
    { id: '202007', name: 'Tháng 07/2020' },
    { id: '202008', name: 'Tháng 08/2020' },
    { id: '202009', name: 'Tháng 09/2020' },
    { id: '202010', name: 'Tháng 10/2020' },
    { id: '202011', name: 'Tháng 11/2020' },
    { id: '202012', name: 'Tháng 12/2020' },
  ];

  public static LIST_WEEK_DAY = [
    'Thứ 2',
    'Thứ 3',
    'Thứ 4',
    'Thứ 5',
    'Thứ 6',
    'Thứ 7',
    'Chủ nhật',
  ];

  public static LIST_COMM_STATUS = [
    { key: '', value: 'Tất cả' },
    { key: '1', value: 'Đã công bố' },
    { key: '0', value: 'Chưa công bố' },
  ];

  public static NOT_MINING_TRANSACTION = 'NOT_MINING';
  public static BUY_TRANSACTION = 'BUY';
  public static RENT_TRANSACTION = 'RENT';
  public static SELL_TRANSACTION = 'SELL';
  public static LEASE_TRANSACTION = 'LEASE';
  public static SELL_BROKER_FEE_PRECENT_MAX = 100;
  public static LEASE_BROKER_FEE_PRECENT_MIN = 50;
  public static LEASE_BROKER_FEE_MIN = 10000000;
  public static ROSPID = '58cac11a-8334-49ee-ac52-dfcbc248dfee';
  //Config number input
  public static decimal1Number = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    allowLeadingZeroes: true,
    decimalLimit: 1,
    integerLimit: 12,
  });
  public static decimal2Number = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    allowLeadingZeroes: true,
    decimalLimit: 2,
    integerLimit: 12,
  });
  public static percent = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalLimit: 2,
    integerLimit: 2,
  });
  public static percent3 = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalLimit: 2,
    integerLimit: 3,
  });
  public static phoneMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    allowLeadingZeroes: true,
    includeThousandsSeparator: false,
    integerLimit: 20,
  });
  public static numberBankMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    allowLeadingZeroes: true,
    includeThousandsSeparator: false,
    integerLimit: 20,
  });
  public static numberMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalLimit: 2,
    integerLimit: 12,
  });
  public static numberMaskNoLimit = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalLimit: 2,
  });
  public static numberFeeMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalLimit: 1,
    allowLeadingZeroes: true,
    integerLimit: 12,
  });
  public static geolocation = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalLimit: 100,
    allowLeadingZeroes: true,
    integerLimit: 3,
  });
  public static numberIntMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    integerLimit: 12,
    allowLeadingZeroes: true,
  });
  public static number2IntMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    integerLimit: 2,
    allowLeadingZeroes: true,
  });
  public static number4IntMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    integerLimit: 4,
    includeThousandsSeparator: false,
    allowLeadingZeroes: true,
  });

  public static extension_img =
    'jpg|JPG|jpeg|JPEG|tiff|TIFF|png|PNG|bmp|BMP|bgp|BGP|PPM|ppm|PGM|pgm|pbm|PBM|ico|ICO|PGF|pgf|IMG|img|HEIC|heic';
  public static extension_file_doc =
    'pdf|PDF|xls|XLS|xlsx|XLSX|doc|DOC|docx|DOCX|zip|ZIP|rar|RAR';
  public static checkMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4),
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor);
    return window.screen.width < 768 ? check : false;
  }

  public static checkTablet() {
    let screen = window.screen.width;
    return screen >= 768 && screen <= 1024;
  }

  public static checkDateIssue(date) {
    let d = parseInt(date);
    let dayNow = new Date().getDate();
    let monthNow = new Date().getMonth() + 1;
    let yearNow = new Date().getFullYear();
    let day = new Date(d).getDate();
    let month = new Date(d).getMonth() + 1;
    let year = new Date(d).getFullYear();

    if (yearNow === year) {
      if (monthNow === month) {
        return dayNow <= day;
      } else return monthNow <= month;
    } else {
      return yearNow <= year;
    }
  }

  // Dao: sua loi disable khi quy da qua ngay dao han
  public static checkMaturityIssue(date) {
    let d = parseInt(date);
    let dayNow = new Date().getDate();
    let monthNow = new Date().getMonth() + 1;
    let yearNow = new Date().getFullYear();
    let dayNext = new Date(d).getDate();
    let monthNext = new Date(d).getMonth() + 1;
    let yearNext = new Date(d).getFullYear();

    if (yearNow === yearNext) {
      if (monthNow === monthNext) {
        return dayNow >= dayNext;
      } else return monthNow >= monthNext;
    } else {
      return yearNow >= yearNext;
    }
  }

  public static checkAndroid() {
    let userAgent = navigator.userAgent || navigator.vendor;
    return /android/i.test(userAgent);
  }

  public static checkIOS() {
    let userAgent = navigator.userAgent || navigator.vendor;
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }
  }

  public static getMatchingEventName(): string[] {
    return [
      'dealingUpdated',
      'dealingRequested',
      'dealingAccepted',
      'dealingDeleted',
      'dealingApproved',
      'dealingRejected',
      'dealingReserved',
    ];
  }

  public static projectTypeList(): string[] {
    return [
      'Đất nền',
      'Căn hộ',
      'Biệt thự',
      'Khu nghỉ dưỡng/Khu Phức hợp',
      'Shophouse/Nhà ở TM',
      'Khu đô thị thương mại giải trí',
      'Nhà phố xây sẵn',
    ];
  }

  public static videoOwner() {
    return [
      { key: 'GROUP', value: 'Nhóm' },
      { key: 'LIVESTREAM', value: 'Livestream' },
      { key: 'OTHER', value: 'Khác' },
    ];
  }

  public static videoStatus() {
    return [
      {
        key: CompareConstant.StatusVideoEnum.APPROVED,
        value: 'Đã duyệt',
      },
      {
        key: CompareConstant.StatusVideoEnum.WAITING,
        value: 'Chờ duyệt',
      },
      {
        key: CompareConstant.StatusVideoEnum.REJECTED,
        value: 'Đã từ chối',
      },
    ];
  }

  public static PRIORITY_ID = {
    UT1: '1',
    UT2: '2',
    UT3: '3',
    EMPTY: '0',
    LOADING: '4',
  };

  public static listStatusCampaign = [
    { id: CampaignStatusEnum.ACTIVE, value: 'Kích hoạt' },
    { id: CampaignStatusEnum.INACTIVE, value: 'Vô hiệu' },
  ];

  public static listWallet = [
    { id: WalletTransactionType.PROMOTION, value: 'Ví khuyến mãi' },
    { id: WalletTransactionType.MAIN, value: 'Ví chính' },
    { id: WalletTransactionType.EXTERNAL_PAY, value: 'Không trả vào ví' },
    { id: WalletTransactionType.GIFT, value: 'Tặng quà' },
  ];

  public static listHomeMenuEnum = [
    { id: HomeMenuStatusEnum.ACTIVE, value: 'Kích hoạt' },
    { id: HomeMenuStatusEnum.INACTIVE, value: 'Vô hiệu' },
  ];

  public static listNotiCategoryStatus = [
    { id: NotiCategoryEnum.CHECK_ON, value: 'Hoạt Động' },
    { id: NotiCategoryEnum.CHECK_OFF, value: 'Vô hiệu' },
  ];
  public static listCampaignType = [
    { id: CampaignTypeEnum.INTRODUCER, value: 'Giới thiệu' },
    { id: CampaignTypeEnum.BANNER, value: 'Banner' },
    { id: CampaignTypeEnum.PROMOTIONS, value: 'Ưu đãi' }, // Thêm value ưu đãi cho loại campaign
  ];
  public static listBonusType = [
    { id: BonusTypeEnum.CASH, value: 'Tiền mặt' },
    { id: BonusTypeEnum.PERCENT, value: '%' },
  ];
  public static listDateType = [
    { id: DateSelectTypeEnum.DAY_NUMBER, value: 'Ngày' },
    { id: DateSelectTypeEnum.DATE, value: 'Lịch' },
  ];
  public static listCafeStatus = [
    { id: CafeStatusEnum.WAITING_FOR_CONFIRMATION, value: 'Chờ xác nhận' },
    { id: CafeStatusEnum.WAITING_FOR_COMPARISON, value: 'Chờ Đối chiếu' },
    { id: CafeStatusEnum.COMPLETED, value: 'Hoàn thành' },
    { id: CafeStatusEnum.CANCELLED, value: 'Đã hủy' },
    { id: CafeStatusEnum.REFUSED, value: 'Đã từ chối' },
    {},
  ];

  public static historyFilter = 'historyFilter';
  public static historyProjectView = 'historyProjectView';

  public static historyInstructorView = 'historyInstructorView';
  // List trang đích của Banner
  public static listDestinationType = [
    { id: '', value: '--Không chọn--' },
    { id: CampaignDestiontionEnum.EVENT, value: 'Sự kiện' },
    { id: CampaignDestiontionEnum.NEWS, value: 'Tin tức' },
    { id: CampaignDestiontionEnum.LINK, value: 'Link' },
    { id: CampaignDestiontionEnum.MISSION, value: 'Nhiệm vụ' },
    {
      id: CampaignDestiontionEnum.CAMPAIGN_INTRODUCER,
      value: 'Campaign Giới thiệu',
    },
    { id: CampaignDestiontionEnum.GROUP, value: 'Nhóm' },
    { id: NotificationGlobalDestiontionEnum.PROPSKILL, value: 'Propskill' },
    { id: CampaignDestiontionEnum.SURVEY, value: 'Khảo sát' },
    { id: CampaignDestiontionEnum.LUCKY_MONEY, value: 'Popup' },
  ];

  // List trang đích của Banner
  public static listFlashSaleType = [
    { id: '', value: '--Không chọn--' },
    { id: FlashSaleEnum.COMMISSION, value: 'Hoa hồng' },
    { id: FlashSaleEnum.BONUS, value: 'Thưởng từ Propcom' },
    { id: FlashSaleEnum.INVESTOR_BONUS, value: 'Thưởng từ nhà đầu tư' },
  ];

  // List trang đích của Notification tự do
  public static listNotificationDestinationType = [
    { id: '', value: '--Không chọn--' },
    { id: NotificationGlobalDestiontionEnum.EVENT, value: 'Sự kiện' },
    { id: NotificationGlobalDestiontionEnum.NEWS, value: 'Tin tức' },
    { id: NotificationGlobalDestiontionEnum.LINK, value: 'Link' },
    { id: NotificationGlobalDestiontionEnum.MISSION, value: 'Nhiệm vụ' },
    {
      id: NotificationGlobalDestiontionEnum.CAMPAIGN_INTRODUCER,
      value: 'Campaign Giới thiệu',
    },
    { id: NotificationGlobalDestiontionEnum.GROUP, value: 'Nhóm' },
    { id: NotificationGlobalDestiontionEnum.SURVEY, value: 'Khảo sát' },
    { id: NotificationGlobalDestiontionEnum.FORTE, value: 'Sở trường của tôi' },
    { id: NotificationGlobalDestiontionEnum.PROPSKILL, value: 'Propskill' },
  ];

  // List trang đích của Ưu đãi
  public static promotionListDestinationType = [
    { id: '', value: '--Không chọn--' },
    {
      id: CampaignDestiontionEnum.CAMPAIGN_PROMOTION,
      value: 'Campaign Giới thiệu',
    },
  ];

  public static PROJECT_FAV_STATUS = {
    NOT_ADVISED: 'NOT_ADVISED',
    ADVISED: 'ADVISED',
  };

  public static filterProjectFavStatuses = [
    { key: 'all', value: 'Tất cả' },
    { key: 'NOT_ADVISED', value: 'Chưa tư vấn' },
    { key: 'ADVISED', value: 'Đã tư vấn' },
  ];

  public static filterInstructorFavStatuses = [
    { key: 'all', value: 'Tất cả' },
    { key: 'NOT_ADVISED', value: 'Chưa tư vấn' },
    { key: 'ADVISED', value: 'Đã tư vấn' },
  ];

  public static filterFavStatuses = [
    { key: 'all', value: 'Tất cả' },
    { key: 'NOT_ADVISED', value: 'Chưa tư vấn' },
    { key: 'ADVISED', value: 'Đã tư vấn' },
  ];

  public static USER_LESSON_STATUS = {
    NOT_ADVISED: 'NOT_ADVISED', // Chưa tư vấn
    ADVISED: 'ADVISED', // Đã tư vấn
    REGISTERED: 'REGISTERED', // Đã đăng ký
    CANCEL_REGISTER: 'CANCEL_REGISTER', // Hủy đăng ký
    IN_PROGRESS: 'IN_PROGRESS', // Đang học
    RESERVE: 'RESERVE', // Bảo lưu
    COMPLETED: 'COMPLETED', // Hoàn thành
  };

  public static PAYMENT_STATUS = {
    UNPAID: 'UNPAID',
    PAID: 'PAID',
  };

  public static filterUserLessonStatuses = [
    { key: 'all', value: 'Tất cả' },
    { key: 'NOT_ADVISED', value: 'Chưa tư vấn' },
    { key: 'ADVISED', value: 'Đã tư vấn' },
    { key: 'REGISTERED', value: 'Đã đăng ký' },
    { key: 'CANCEL_REGISTER', value: 'Hủy đăng ký' },
    { key: 'IN_PROGRESS', value: 'Đang học' },
    { key: 'RESERVE', value: 'Bảo lưu' },
    { key: 'COMPLETED', value: 'Hoàn thành' },
  ];
  public static filterEventStatus = [
    { value: 'all', text: 'Tất cả' },
    { value: NewsStatus.ACTIVE, text: 'Kích hoạt' },
    { value: NewsStatus.INACTIVE, text: 'Vô hiệu' },
  ];

  public static filterEventOrganizationalForm = [
    { value: 'all', text: 'Tất cả' },
    { value: EventOrganizationalFormEnum.ONLINE, text: 'Online' },
    { value: EventOrganizationalFormEnum.OFFLINE, text: 'Offline' },
    { value: EventOrganizationalFormEnum.CHARITY, text: 'Thiện nguyện' },
  ];

  public static filterEventActiveLiveStream = [
    { value: 'all', text: 'Tất cả' },
    { value: NewsStatus.ACTIVE, text: 'Có livestream' },
    { value: NewsStatus.INACTIVE, text: 'Không có livestream' },
  ];

  public static TYPE_FOLDER_ESALEKIT = {
    MAIN: 'main',
    IMAGE: 'image',
    VIDEO: 'video',
    NAMEPROJECT: 'nameProject',
  };

  public static BANK_CODE_PROPCOM = 'BS0000000001';

  public static TYPE_FIELD_IMAGE_UPLOAD = {
    LOGO: 'LOGO',
    BANNER: 'BANNER',
  };

  public static InvestorTypeConstant = [
    { key: 'INVESTOR', value: 'Chủ đầu tư' },
    { key: 'AGENCY', value: 'Đại lý' },
  ];

  public static listType = [
    { id: MissionTypeEnum.INTRODUCER, value: 'Giới thiệu' },
    { id: MissionTypeEnum.POST_PROJECT, value: 'Đăng tin dự án' },
    { id: MissionTypeEnum.VERIFY, value: 'Xác thực, nhập sở trường' },
    { id: MissionTypeEnum.SURVEY, value: 'Nhập sở trường' },
    { id: MissionTypeEnum.EVENT, value: 'Sự kiện' },
    { id: MissionTypeEnum.PROJECT_FAV, value: 'Dự án yêu thích' },
    { id: MissionTypeEnum.SOCIAL, value: 'Cộng đồng' },
    { id: MissionTypeEnum.SURVEY_LEAD, value: 'Khảo sát lead' },
    { id: MissionTypeEnum.CHECK_IN, value: 'Checkin' },
    { id: MissionTypeEnum.TRANSACTION, value: 'Giao dịch' },
    { id: MissionTypeEnum.INTERACT, value: 'Tương tác' },
  ];
  public static listMissionType = [
    { id: MissionMissionTypeEnum.DEFAULT, value: 'Mặc định' },
    { id: MissionMissionTypeEnum.DAILY, value: 'Hàng ngày' },
    { id: MissionMissionTypeEnum.WEEKLY, value: 'Hàng tuần' },
    { id: MissionMissionTypeEnum.MONTHLY, value: 'Hàng tháng' },
  ];

  public static filterStatementHistoryType = [
    { key: 'all', value: 'Tất cả' },
    { key: StatementHistoryType.RECEIPTS, value: 'Phiếu thu' },
    { key: StatementHistoryType.PAYMENT, value: 'Phiếu chi' },
  ];

  public static filterStatementHistoryTypeDropdown = [
    { key: 'all', value: 'Tất cả' },
    { key: StatementHistoryType.DEPOSIT, value: 'Nạp tiền' },
    { key: StatementHistoryType.WITHDRAW, value: 'Rút tiền' },
    { key: StatementHistoryType.PAY, value: 'Thanh toán' },
    { key: StatementHistoryType.REFUND, value: 'Hoàn tiền' },
    { key: StatementHistoryType.BONUS, value: 'Thưởng từ PROPCOM' },
    {
      key: StatementHistoryType.INVESTOR_BONUS,
      value: 'Thưởng từ nhà đầu tư',
    },
    { key: StatementHistoryType.COMMISSION, value: 'Hoa hồng' },
    { key: StatementHistoryType.MISSION_PRIZE, value: 'Thưởng nhiệm vụ' },
  ];

  public static filterStatementStatusDropdown = [
    { key: 'all', value: 'Tất cả' },
    { key: StatementStatus.INIT, value: 'Chờ duyệt' },
    { key: StatementStatus.SUCCESS, value: 'Đã duyệt' },
    { key: StatementStatus.UNSUCCESS, value: 'Đã từ chối' },
  ];

  public static listWalletMission = [
    { id: WalletTransactionType.CASH_PROMOTION, value: 'Ví khuyến mãi' },
    { id: WalletTransactionType.CASH, value: 'Ví chính' },
    { id: WalletTransactionType.EXTERNAL_PAY, value: 'Không thêm vào ví' },
  ];

  public static LANGUAGE_ENUM = {
    ENGLISH: 'en',
    VIETNAMESE: 'vi-VN',
  };

  public static filterFollowUserStatusDropdown = [
    { key: 'all', value: 'Tất cả' },
    { key: 'FOLLOW', value: 'Following' },
    { key: 'UNFOLLOW', value: 'UnFollow' },
    { key: 'VIEW_PROFILE', value: 'Xem profile' },
  ];

  public static listMlmActionBonus = [
    {
      id: mlmActionBonusEnum.REGISTERED,
      value: mlmActionTextEnum.REGISTERED,
    },
    {
      id: mlmActionBonusEnum.IDENTIFIED,
      value: mlmActionTextEnum.IDENTIFIED,
    },
    {
      id: mlmActionBonusEnum.SIGNED_CONTRACT,
      value: mlmActionTextEnum.SIGNED_CONTRACT,
    },
    {
      id: mlmActionBonusEnum.FIRST_TRANSACTION_DONE,
      value: mlmActionTextEnum.FIRST_TRANSACTION_DONE,
    },
    {
      id: mlmActionBonusEnum.MISSION_DONE,
      value: mlmActionTextEnum.MISSION_DONE,
    },
    {
      id: mlmActionBonusEnum.START_PROBATION,
      value: mlmActionTextEnum.START_PROBATION,
    },
    {
      id: mlmActionBonusEnum.TRANSACTION_COMPLETE,
      value: mlmActionTextEnum.TRANSACTION_COMPLETE,
    },
  ];

  public static filterTypeMLMDropdown = [
    { key: 'ALL', value: 'Tất cả' },
    { key: 'HAS_CHILD', value: 'Có Fx' },
    { key: 'NO_CHILD', value: 'Không có F1' },
  ];

  public static listWalletStatement = [
    { id: WalletTransactionType.MAIN, value: 'Ví chính' },
    { id: WalletTransactionType.PROMOTION, value: 'Ví thưởng' },
  ];

  public static METHOD_CALL = [
    { key: 'VINA', value: 'VINA' },
    { key: 'MOBI', value: 'MOBI' },
    { key: 'VIETTEL', value: 'VIETTEL' },
  ];

  public static FILTER_STATEMENT_WALLET_TYPE = [
    { key: 'all', value: 'Tất cả' },
    { key: WalletTransactionType.MAIN, value: 'Ví chính' },
    { key: WalletTransactionType.PROMOTION, value: 'Ví thưởng' },
  ];

  public static payBonusWalletTypes = [
    { key: 'MAIN', value: 'Ví chính' },
    { key: 'PROMOTION', value: 'Ví thưởng' },
    { key: 'NONE', value: 'Không trả vào ví' },
  ];

  public static listStatus = [
    { id: StatusEnum.ACTIVE, value: 'Kích hoạt' },
    { id: StatusEnum.INACTIVE, value: 'Vô hiệu' },
  ];

  public static filterLoanPackageStatus = [
    { value: 'all', text: 'Tất cả' },
    { value: StatusEnum.ACTIVE, text: 'Kích hoạt' },
    { value: StatusEnum.INACTIVE, text: 'Vô hiệu' },
  ];
  // label uploadfile video store
  public static LABEL_UPLOAD_FILE = 'Không có file nào được chọn';
}

export enum ProjectFavoriteStatusMapping {
  NOT_ADVISED = 'Chưa tư vấn',
  ADVISED = 'Đã tư vấn',
}

export enum InstructorFavoriteStatusMapping {
  NOT_ADVISED = 'Chưa tư vấn',
  ADVISED = 'Đã tư vấn',
}

export enum FavoriteStatusMapping {
  NOT_ADVISED = 'Chưa tư vấn',
  ADVISED = 'Đã tư vấn',
}

export enum QueryTypeEnum {
  CODE = 'code',
  PHONE = 'phone',
  IDENTITY = 'identity',
}

export enum PosEnum {
  INPOS = 'INPOS',
  ASSIGN = 'ASSIGN',
  SELECTEDPOS = 'SELECTEDPOS',
  POS = 'POS',
}

export enum PosStatusEnum {
  INIT = 'init', // Khởi tạo
  WAITING_WORKING = 'waiting.working', // Chờ duyệt
  WORKING = 'working',
  WAITING_STOP = 'waiting.stop', // chờ duyệt ngưng hoạt động
  STOP = 'stop', // ngưng hoạt động
}

export const CommissionType = [
  { key: 'PRICE', value: 'Giá bán' },
  { key: 'REVENUE', value: 'Doanh thu' },
];

export enum PayBonusWalletTypeEnum {
  MAIN = 'MAIN',
  PROMOTION = 'PROMOTION',
  NONE = 'NONE',
}

export enum PolicyRateTypeEnum {
  COMMISSION = 'COMMISSION',
  PRODUCT = 'PRODUCT',
}

export enum ParticipatingUnitType {
  ORG_CHART = 'ORG_CHART',
  GROUP_LEAD = 'GROUP_LEAD',
}

export enum MethodCallEnum {
  VINA = 'VINA',
  MOBI = 'MOBI',
  VIETTEL = 'VIETTEL',
}

export enum UserLessonStatusMapping {
  NOT_ADVISED = 'Chưa tư vấn',
  ADVISED = 'Đã tư vấn',
  REGISTERED = 'Đã đăng ký',
  CANCEL_REGISTER = 'Hủy đăng ký',
  IN_PROGRESS = 'Đang học',
  RESERVE = 'Bảo lưu',
  COMPLETED = 'Hoàn thành',
}

export enum StatusPaymentMapping {
  UNPAID = 'Chưa thanh toán',
  PAID = 'Đã thanh toán',
}

export enum RegisterCoursePaymentMapping {
  PAID = 'Đã thanh toán',
  UNPAID = 'Chưa thanh toán',
  ORG_CHART = 'ORG_CHART',
  GROUP_LEAD = 'GROUP_LEAD',
}
