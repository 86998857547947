import { Injectable } from "@angular/core";
import { CPagination } from "app/api-models";
import { BaseService } from "app/shared/components/base.service";
import { ConstantUrl } from "app/shared/constant/url";
import { GroupPostModel } from "app/pages/group-post/group-post.models";
import { DataService } from "app/shared/services";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { SurveyModel,SurveyQuestionModel, SurveyTVVReportModel } from "../../shared/models/survey.model";
import { HttpService } from "app/shared/services/http.service";



@Injectable()
export class SurveyService extends BaseService{



    constructor(
        public service: DataService,
        private httpService: HttpService,
    ){
        super(service);
        this.domainUrl = ConstantUrl.url_employee_domain + 'admin/survey';
        this.queryUrl = ConstantUrl.url_employee_query + 'admin/survey';
    }
    getQuestionById(id:string, params){

        const Url = `${this.queryUrl}/${id}/questions`
        return this.httpService.get(Url,params).pipe(
            map((res: any) => {
                const pagination = new CPagination<SurveyQuestionModel>().decode(res);
                pagination.items = pagination.items.map((item: any) => new SurveyQuestionModel(item));

                return pagination;
            })
        )
    }
    getOne(id: string,params) {
        const url = `${this.queryUrl}/${id}`
       return this.service.get(url,params)
    }

    get(params?: any): Observable<CPagination<SurveyModel>> {
        return this.service.get(this.queryUrl, params || {}).pipe(
           map(res => {
            const pagination = new CPagination<SurveyModel>().decode(res);
            pagination.items = pagination.items.map((item, index) => {
              const event = new SurveyModel(item);
              event.stt = index + 1 + (params ? params.pageSize * (params.page - 1) : 0);
              return event;
           });
           return pagination;
        })
        )
    }
    getData(){
        return this.service.get(this.queryUrl).pipe()
    }
    updateSurvey(id, params){
        const url = this.domainUrl
        return this.service.put(url,params)
    }
    insertSurvey(params){
        const url = this.domainUrl
        return this.service.post(url,params)
    }

    getReport(id: string, params?: any): Observable<CPagination<SurveyTVVReportModel>> {
        const url = `${ConstantUrl.url_employee}admin/surveyReport/report/${id}`;
        return this.service.get(url, params || {}).pipe(
           map(res => {
            const pagination = new CPagination<SurveyTVVReportModel>().decode(res);
            pagination.items = pagination.items.map((item, index) => {
              const event = new SurveyTVVReportModel(item);
              event.stt = index + 1 + (params ? params.pageSize * (params.page - 1) : 0);
              return event;
           });
           return pagination;
        })
        )
    }

    getTotalReport(id: string,params) {
        const url = `${ConstantUrl.url_employee}admin/surveyReport/totalReport/${id}`;
       return this.service.get(url,params)
    }
}
