import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, Injector } from '@angular/core';
import { AppSettings, Settings } from '../../..';
import {
    ConfigurationService,
    SecurityService,
    StorageService
} from '../../../shared/services';
import {
    CommonService,

    ToastrService,
    StompService,
} from '../../../shared/services/common';
import { EmployeeService } from '..';
import { Employee } from '../employee.model';
import { MyErrorStateMatcher } from '../../fixError';
import { WebsocketService } from '../../../shared/components/websocket.service';
import { BaseComponent } from 'app/shared/components/base.component';
import { PagingComponent } from 'app/shared/components/paging';

const COMMA = 188;

@Component({
    selector: 'app-employee-pos',
    templateUrl: './employee-pos.component.html',
    styleUrls: ['./employee-pos.component.scss'],
    providers: [EmployeeService]
})

export class EmployeePOSComponent extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild('paging') paging: PagingComponent;
    // messaging pattern
    public websocketService: WebsocketService;
    public pos: string = '';
    public list: any = [];
    currentPage: number = 1;
    currentPageSize: number = 10;
    constructor(
        public storageService: StorageService,
        injector: Injector,
        public appSettings: AppSettings,
        public service: EmployeeService,
        public configurationService: ConfigurationService,
        public securityService: SecurityService,
        public commonService: CommonService,
        public stompService: StompService,

        public toastr: ToastrService
    ) {
        super(injector.get(EmployeeService), Employee, injector);
        this.settings = this.appSettings.settings;
        this.infoMessagingPattern = this.messagingPattern + 'spx-adsg.info.employee';
        this.errorMessagingPattern = this.messagingPattern + 'spx-adsg.error.employee';
    }
    matcher = new MyErrorStateMatcher();

    ngOnInit() {
        this.eventSubscribe = this.eventChangeService.emitChangeSource.subscribe((data) => {
            switch (data.broadcast) {
                case 'employeeCreated':
                    // show toast
                    this.toastrService.success('Successfully!', 'Tạo nhân viên');
                    break;
                case 'employeeUpdated':
                    // show toast
                    this.toastrService.success('Successfully!', 'Sửa nhân viên');
                    break;
                case 'employeeDeleted':
                    // show toast
                    // this.toastrService.success('Successfully!', title);
                    break;
            }
        });
        // this.paging && this.paging.setDefault();
        return this.setDefault()
            .then((result) => {
                this.tableProperty.chooseValueShow(['name', 'email', 'phone']);
                this.tableProperty.setTitles(['Tên', 'Email', 'Điện thoại']);
                this.tableProperty.filters = [
                    {
                        type: 'text',
                        placeholder: 'Phone ...',
                        value: null,
                        property: 'phone'
                    }
                ];
                // this.paging && this.paging.setDefault(result);
            });

    }
    setDefault(params?) {
        let p = params ? params : {};
        p.page = this.currentPage;
        p.pageSize = this.currentPageSize;
        this.route.snapshot.data.action == 'history' && ( this.action = 'history' );
        // check for consignment status : done or process
        this.tableProperty.isLoading = true;
        if (params && params.status === 'process') {
            params.status = this.action === 'history' ? 'done' : params.status;
        }
        return new Promise((resolve, reject) => {
            this.tableProperty.methods = {
                edit: this.edit.bind(this),
                review: this.review.bind(this),
                delete: this.delete.bind(this),
            };
            this.tableProperty.data = [];
            const pos = this.storageService.retrieve('pos');
            if (!pos) {
                this.tableProperty.isLoading = false;
                return;
            }
            this.pos = pos.id;
            return this.service.getEmployeePOS(pos.id, p)
            .subscribe((res) => {
                let total = res.total ? res.total : 0;
                let totalPages = res.totalPages ? res.totalPages : 0;
                res = res.rows ? res.rows : res;
                // this.paging.setDefault([]);
                let resdata = res.staffs ? res.staffs : res;
                this.tableProperty.data = resdata.map((item) => {
                    return new this.Entity(item);
                });
                this.list = this.tableProperty.data || [];
                this.paging.setDefaultPaging(total, totalPages);
                // this.changePage(1);
                this.action === 'history' && (this.historyData());
                this.emitTable.subscribe((data) => {
                    this.refreshDataTable(data);
                });
                this.tableProperty.isLoading = false;
                resolve(res);
            });
        });
    }
    ngOnDestroy() {
        this.eventSubscribe && this.eventSubscribe.unsubscribe();
    }
    searchEmployee(value, list) {
        // let items = super.searchAll(value, list);
        // this.paging.setDefault(items);
        // this.paging.changePage(0);
    }
    changePage(event: number) {
      this.currentPage = event || 1;
      this.setDefault();
    }
    changeItemPerPage(event: number) {
      this.currentPageSize = event || 10;
      this.currentPage = 1;
      this.setDefault();
    }
    goToDetail(id) {
        this.router.navigate(['employee/edit/' + id]);
    }
    goToCreateEmployee() {
        this.router.navigate(['employee/create']);
    }
    employeeCompetence(user): string {
        let result = '';
        if(user && user.managerAt) {
            result = "Quản lý";
        } else if(user.workingAt) {
            result = "Nhân viên";
        }
        return result;
    }
}

