import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SecurityService } from '..';
import { environment } from 'environments/environment';
import { ErpService } from '../common/erp.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private securityService: SecurityService,
                private erpService: ErpService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const headers = { timezoneclient: 'Asia/Ho_Chi_Minh' };
        if (request.url.includes(environment.urlErp)) {
            if (this.erpService.checkTokenErp()) {
                headers['Authorization'] = `Bearer ${this.erpService.getToken()}`;
            }else {
                return;
            }
        } else {
            headers['Authorization'] = `Bearer ${this.securityService.GetToken()}`;
        }

        if (request.url.includes(environment.voiceCall.uriAPI)) {
            headers['X-STRINGEE-AUTH'] = `${JSON.parse(sessionStorage.getItem(`voice_call_authen_token`))}`;
        }

        request = request.clone({
            setHeaders: headers
        });


        return next.handle(request);
    }
}
