import { BaseModel } from 'app/shared/models/base.model';
import { BaseModelInterface } from 'app/shared/models/base.model.interface';
import { ValidateIf, IsNotEmpty, Length } from 'class-validator';
// Models
import { GeoLocation } from 'app/pages/property/location.model';
import { Images } from 'app/shared/components/upload-images/image.model';
import { FileModel } from 'app/shared/components/file-uploader';
import _ = require('lodash');
import {ProjectSetting} from './project-setting.model';
const DEFAULT_UNIT = '(triệu/m²)';

export class Project extends BaseModel implements BaseModelInterface {

  @ValidateIf(o => o.otherProperty === '')
  @IsNotEmpty()
  @Length(10, 20)
  id: string;
  active: boolean;
  location: GeoLocation;
  locationAddress: string;
  type: string;
  name: string;
  status: string;
  propertyTotal: number;
  unitPrice: string;
  priceFrom: number;
  priceTo: number;
  bankName: string;
  bankNumber: number;
  banks: any;
  investor: string;
  refUrl: string;
  url3D: string;
  url360: string;
  floorMap: string;
  address: string;
  hotline: string;
  apartmentsTotal: number;
  constructionArea: number;
  projectArea: number;
  parkArea: number;
  constructionFloorArea: number;
  trafficArea: number;
  greenAreaOnBuilding: number;
  constructionPercent: number;
  greenPercent: number;
  blocksTotal: number;
  floorsTotal: number;
  situation: string;
  imageUrl: string;
  projectGroundImageUrl: string;
  utilitiesImageUrl: string;
  images: Images;
  province: string;
  district: string;
  transactionType: string;
  block: string;
  zone: string;
  floor: string;
  plot: string;
  street: string;
  ownership: string;
  landTotal: number;
  country: string;
  ward: string;
  city: string;
  files: FileModel[];
  day: string;
  liveUrl: string;
  chatGroupId: string;
  code: string;
  currentStatus: string;
  designUnit: string;
  constructionUnit: string;
  guideVideoUrl: string;
  projectVideoUrl: string;
  documentUrl: string;
  hasUnitExcelTemplate: boolean;
  guideImages: FileModel[];
  groundImages: FileModel[];
  employeeSale: any;
  villasTotal: number;
  projectLeader: IEmployeeModel;
  employeeSales: IEmployeeModel[];
  salePackages?: ISalePackage[];
  adviceType: number;
  isRetailCustomer: boolean;
  blocks: any;
  accountants: any;
  customerServices: any;
  unitManagers: any;
  salesAdmins: any;
  marketingEmps: any;
  emailTransaction: any;
  setting: ProjectSetting;
  isPin: boolean;
  projectWeb: string;
  projectDetailLink: string;
  stt: number;

  esalekit: boolean;
  allowBooking: boolean;
  isEnableTotalCompleted: boolean;
  isSyncTicket: boolean;
  isSyncReceipt: boolean;
  surveys: any;
  demandCategories: INameValue[];
  createdDate: Date;
  modifiedDate: Date;
  outstandingPolicy: string;
  commissionInterpretation: string;
  bonusInterpretation: string;

  constructor(param?: any) {
    super();
    this.setValue(param);
  }

  setValue(params: any) {
    if (params) {
      this.id = params.id;
      this.active = params.active;
      this.location = new GeoLocation(params.location) || new GeoLocation();
      this.locationAddress = params.locationAddress;
      this.type = params.type;
      this.name = params.name;
      this.status = params.status;
      this.propertyTotal = params.propertyTotal ? Number(params.propertyTotal) : null;
      this.unitPrice = params.unitPrice || DEFAULT_UNIT;
      this.priceFrom = params.priceFrom ? Number(params.priceFrom) : null;
      this.priceTo = params.priceTo ? Number(params.priceTo) : null;
      this.investor = params.investor;
      this.refUrl = params.refUrl;
      this.floorMap = params.floorMap;
      this.url3D = params.url3D;
      this.url360 = params.url360;
      this.address = params.address;
      this.hotline = params.hotline;
      this.apartmentsTotal = params.apartmentsTotal ? Number(params.apartmentsTotal) : null;
      this.constructionArea = params.constructionArea ? Number(params.constructionArea) : null;
      this.projectArea = params.projectArea ? Number(params.projectArea) : null;
      this.parkArea = params.parkArea ? Number(params.parkArea) : null;
      this.constructionFloorArea = params.constructionFloorArea ? Number(params.constructionFloorArea) : null;
      this.trafficArea = params.trafficArea ? Number(params.trafficArea) : null;
      this.greenAreaOnBuilding = params.greenAreaOnBuilding ? Number(params.greenAreaOnBuilding) : null;
      this.constructionPercent = params.constructionPercent ? Number(params.constructionPercent) : null;
      this.greenPercent = params.greenPercent ? Number(params.greenPercent) : null;
      this.blocksTotal = params.blocksTotal ? Number(params.blocksTotal) : null;
      this.floorsTotal = params.floorsTotal ? Number(params.floorsTotal) : null;
      this.situation = params.situation;
      this.imageUrl = params.imageUrl;
      this.projectGroundImageUrl = params.projectGroundImageUrl;
      this.utilitiesImageUrl = params.utilitiesImageUrl;
      this.images = params.images ? new Images(params.images) : new Images();
      this.province = params.province;
      this.district = params.district;
      this.transactionType = params.transactionType;
      this.block = params.block;
      this.zone = params.zone;
      this.floor = params.floor;
      this.plot = params.plot;
      this.street = params.street;
      this.ownership = params.ownership;
      this.landTotal = params.landTotal;
      this.country = params.country;
      this.ward = params.ward;
      this.city = params.city;
      this.files = params.files || [];
      this.day = params.day;
      this.employeeSale = params.employeeSale;
      this.villasTotal = params.villasTotal;
      this.projectLeader = params.projectLeader;
      this.employeeSales = params.employeeSales || [];
      this.salePackages = params.salePackages || [];
      this.adviceType = params.setting ? params.setting.adviceType : null;
      this.isRetailCustomer = params.setting ? params.setting.isRetailCustomer : null;
      this.chatGroupId = params.chatGroupId ? params.chatGroupId : null;
      this.liveUrl = params.liveUrl ? params.liveUrl : null;
      this.code = params.code ? params.code : null;
      this.currentStatus = params.currentStatus ? params.currentStatus : null;
      this.designUnit = params.designUnit ? params.designUnit : null;
      this.constructionUnit = params.constructionUnit ? params.constructionUnit : null;
      this.guideVideoUrl = params.guideVideoUrl ? params.guideVideoUrl : null;
      this.projectVideoUrl = params.projectVideoUrl ? params.projectVideoUrl : null;
      this.documentUrl = params.documentUrl ? params.documentUrl : null;
      this.projectWeb = params.projectWeb ? params.projectWeb : '';
      this.projectDetailLink = params.projectDetailLink ? params.projectDetailLink : '';
      this.guideImages = params.guideImages || [];
      this.groundImages = params.groundImages || [];
      this.blocks = params.blocks || [];
      this.banks = params.banks || [];
      this.demandCategories = params.demandCategories || [];
      this.accountants = params.accountants || [];
      this.customerServices = params.customerServices || [];
      this.unitManagers = params.unitManagers || [];
      this.salesAdmins = params.salesAdmins || [];
      this.marketingEmps = params.marketingEmps || [];
      this.setting = new ProjectSetting(params.setting);
      this.isPin = params.isPin || false;
      this.esalekit = params.esalekit || false;
      this.allowBooking = params.allowBooking || false;
      this.isEnableTotalCompleted = params.isEnableTotalCompleted || false;
      this.surveys = params.surveys || [];
      this.emailTransaction = params.emailTransaction || [];
      this.createdDate = params.createdDate || '';
      this.modifiedDate = params.modifiedDate || '';
      this.outstandingPolicy = params.outstandingPolicy || '';
      this.commissionInterpretation = params.commissionInterpretation || '';
      this.bonusInterpretation = params.bonusInterpretation|| '';
    } else {
      this.setAllNull();
    }
    super.setValue(params);
  }

  setAllNull() {
    this.id = '';
    this.active = false;
    this.location = new GeoLocation();
    this.locationAddress = null;
    this.type = null;
    this.name = '';
    this.status = null;
    this.propertyTotal = null;
    this.unitPrice = DEFAULT_UNIT;
    this.priceFrom = null;
    this.priceTo = null;
    this.investor = null;
    this.refUrl = null;
    this.floorMap = null;
    this.url3D = null;
    this.url360 = null;
    this.address = null;
    this.hotline = null;
    this.apartmentsTotal = null;
    this.constructionArea = null;
    this.projectArea = null;
    this.parkArea = null;
    this.constructionFloorArea = null;
    this.trafficArea = null;
    this.greenAreaOnBuilding = null;
    this.constructionPercent = null;
    this.greenPercent = null;
    this.blocksTotal = null;
    this.floorsTotal = null;
    this.situation = null;
    this.imageUrl = null;
    this.projectGroundImageUrl = null;
    this.utilitiesImageUrl = null;
    this.images = new Images();
    this.province = null;
    this.district = null;
    this.transactionType = null;
    this.block = null;
    this.zone = null;
    this.floor = null;
    this.plot = null;
    this.street = null;
    this.ownership = null;
    this.landTotal = null;
    this.country = null;
    this.ward = null;
    this.city = null;
    this.files = [];
    this.day = null;
    this.employeeSale = null;
    this.villasTotal = null;
    this.projectLeader = null;
    this.liveUrl = null;
    this.chatGroupId = null;
    this.employeeSales = [];
    this.salePackages = [];
    this.code = null;
    this.currentStatus = null;
    this.designUnit = null;
    this.constructionUnit = null;
    this.guideVideoUrl = null;
    this.projectVideoUrl = null;
    this.documentUrl = null;
    this.projectWeb = '';
    this.projectDetailLink = '';
    this.guideImages = [];
    this.groundImages = [];
    this.blocks = [];
    this.banks = [];
    this.demandCategories = [];
    this.accountants = [];
    this.customerServices = [];
    this.unitManagers = [];
    this.salesAdmins = [];
    this.marketingEmps = [];
    this.setting = new ProjectSetting();
    this.isPin = false;
    this.surveys = [];
    this.emailTransaction = [];
    this.createdDate = null;
    this.modifiedDate = null;
    this.outstandingPolicy = null;
    this.commissionInterpretation = null;
    this.bonusInterpretation = null;
  }

  createObjectRequestAPI() {
    let object: any = {};
    this.id && (object.id = this.id || null);
    object.location = this.location.createObjectRequestAPI() || null;
    object.locationAddress = this.locationAddress;
    object.type = this.type;
    object.name = this.name;
    object.status = this.status;
    object.unitPrice = this.unitPrice;
    object.priceFrom = this.priceFrom;
    object.priceTo = this.priceTo;
    object.banks = this.banks;
    object.demandCategories = this.demandCategories;
    object.investor = this.investor;
    object.refUrl = this.refUrl;
    object.floorMap = this.floorMap;
    object.url3D = this.url3D;
    object.url360 = this.url360;
    object.address = this.address;
    object.hotline = this.hotline;
    object.apartmentsTotal = this.apartmentsTotal;
    object.constructionArea = this.constructionArea;
    object.projectArea = this.projectArea;
    object.parkArea = this.parkArea;
    object.constructionFloorArea = this.constructionFloorArea;
    object.trafficArea = this.trafficArea;
    object.greenAreaOnBuilding = this.greenAreaOnBuilding;
    object.constructionPercent = this.constructionPercent;
    object.greenPercent = this.greenPercent;
    object.blocksTotal = this.blocksTotal;
    object.floorsTotal = this.floorsTotal;
    object.hasUnitExcelTemplate = this.hasUnitExcelTemplate;
    object.situation = this.situation;
    object.imageUrl = this.imageUrl;
    object.projectGroundImageUrl = this.projectGroundImageUrl;
    object.utilitiesImageUrl = this.utilitiesImageUrl;
    object.images = this.images.createObjectRequestAPI();
    object.province = this.province;
    object.district = this.district;
    object.transactionType = this.transactionType;
    object.block = this.block;
    object.zone = this.zone;
    object.floor = this.floor;
    object.plot = this.plot;
    object.street = this.street;
    object.ownership = this.ownership;
    object.landTotal = this.landTotal;
    object.country = this.country;
    object.ward = this.ward;
    object.city = this.city;
    object.files = this.files;
    object.day = this.day;
    object.employeeSale = this.employeeSale;
    object.villasTotal = this.villasTotal;
    object.projectLeader = this.projectLeader;
    object.employeeSales = this.employeeSales;
    object.salePackages = this.salePackages;
    object.liveUrl = this.liveUrl;
    object.chatGroupId = this.chatGroupId;
    object.code = this.code;
    object.currentStatus = this.currentStatus;
    object.designUnit = this.designUnit;
    object.constructionUnit = this.constructionUnit;
    object.guideVideoUrl = this.guideVideoUrl;
    object.projectVideoUrl = this.projectVideoUrl;
    object.documentUrl = this.documentUrl;
    object.projectWeb = this.projectWeb;
    object.projectDetailLink = this.projectDetailLink;
    object.guideImages = this.guideImages;
    object.groundImages = this.groundImages;
    object.accountants = this.accountants;
    object.customerServices = this.customerServices;
    object.unitManagers = this.unitManagers;
    object.salesAdmins = this.salesAdmins;
    object.marketingEmps = this.marketingEmps;
    object.setting = this.setting;
    object.isPin = this.isPin;
    object.esalekit = this.esalekit;
    object.allowBooking = this.allowBooking;
    object.isEnableTotalCompleted = this.isEnableTotalCompleted;
    object.surveys = this.surveys;
    object.emailTransaction = this.emailTransaction;
    object.createdDate = this.createdDate;
    object.modifiedDate = this.modifiedDate;
    object.outstandingPolicy = this.outstandingPolicy;
    object.commissionInterpretation = this.commissionInterpretation;
    object.bonusInterpretation = this.bonusInterpretation;
    return object;
  }
  createObjectReference() {
    let object: any = {};
    this.id && (object.id = this.id || null);
    object.name = this.name;
    return object;
  }

  public isShowAdviceProduct(): boolean {
    // 1 - Tư vấn thiện chí, 2 - Tư vấn có mã sản phẩm
    return this.adviceType === 2;
  }
}
interface IEmployeeModel {
  id: string;
  name: string;
  code: string;
  phone: string;
  email: string;
}
class _EmployeeModel implements IEmployeeModel {
  id: string;
  name: string;
  code: string;
  phone: string;
  email: string;
}
export class SalePackageModel implements ISalePackage {
  id: string;
  commission: number;
  commissionStatus: string;
  bonus: number;
  bonusStatus: string;
  investorBonus: number;
  investorBonusStatus: string;
  advance: number;
  escrow: number;
  startDate: Date;
  endDate: Date;
  adminFee: number;
  flashSaleCommission: number;
  flashSaleBonus: number;
  flashSaleInvestorBonus: number;
  flashSaleStartDate: Date;
  flashSaleEndDate: Date;
  isFlashSale: boolean;
  flashSaleType: FlashSaleEnum;
  bonusWalletType: string;
  subjectsApplication: string;

  constructor(params?: any) {
    params = params || {};

    this.id = params.id || '';
    this.commission = params.commission || 0;
    this.commissionStatus = params.commissionStatus || '';
    this.subjectsApplication = params.subjectsApplication || '';
    this.bonus = params.bonus || 0;
    this.bonusStatus = params.bonusStatus || '';
    this.investorBonus = params.investorBonus || 0;
    this.investorBonusStatus = params.investorBonusStatus || '';
    this.advance = params.advance || 0;
    this.escrow = params.escrow || 0;
    this.startDate = params.startDate || '';
    this.endDate = params.endDate || '';
    this.flashSaleStartDate = params.flashSaleStartDate || '';
    this.flashSaleEndDate = params.flashSaleEndDate || '';
    this.adminFee = params.adminFee || 0;
    this.flashSaleCommission = params.flashSaleCommission || 0;
    this.flashSaleBonus = params.flashSaleBonus || 0;
    this.flashSaleInvestorBonus = params.flashSaleInvestorBonus || 0;
    this.isFlashSale = params.isFlashSale ? params.isFlashSale : false;
    this.flashSaleType = params.flashSaleType ? params.flashSaleType : '';
    this.bonusWalletType = params.bonusWalletType || '';
  }
  createDataForList() {
    let object: any = Object.assign({}, this);
    return object;
  }
}
export interface ISalePackage {
  id: string;
  commission: number;                 // Hoa hồng
  commissionStatus: string;           // Trạng thái hoa hồng
  bonus: number;                      // Thưởng từ Propcom
  bonusStatus: string;                // Trạng thái thưởng
  investorBonus: number;              // Thưởng từ chủ đầu tư
  investorBonusStatus: string;
  advance: number;                    // Tạm ứng
  escrow: number;                     // Ký quỹ
  startDate: Date;                    // Thời gian bắt đầu
  endDate: Date;                      // Thời gian kết thúc
  flashSaleStartDate: Date;           // Thời gian bắt đầu
  flashSaleEndDate: Date;             // Thời gian kết thúc
  adminFee: number;                   // Admin fee
  bonusWalletType: string;            // Ví trả Thường từ Propcom
  flashSaleCommission: number;        // Admin fee
  flashSaleBonus: number;             // Admin fee
  flashSaleInvestorBonus: number;     // Admin fee
  isFlashSale: boolean;               // Admin fee
  subjectsApplication: string;        // Đối tượng áp dụng
}
export interface INameValue {
  name: string;
  value: string;
}

export class ProjectFavorite {
  stt: number;
  id: string;
  createdDate: Date;
  createdBy: string;
  modifiedDate: Date;
  modifiedBy: string;
  owner: any;
  favoriteTime: Date;
  project: any;
  status: string;
  notes: any[];

  constructor(params?: any) {
    params = params || {};

    this.id = params.id || '';
    this.createdDate = params.createdDate || '';
    this.createdBy = params.createdBy || '';
    this.modifiedDate = params.modifiedDate || '';
    this.modifiedBy = params.modifiedBy || '',
    this.owner = params.owner || null;
    this.favoriteTime = params.favoriteTime || '';
    this.project = params.project || null;
    this.status = params.status || '';
    this.notes = params.notes || [];
  }
}

export enum FlashSaleEnum {
  COMMISSION = 'COMMISSION', // Hoa hồng
  BONUS = 'BONUS', // Thưởng từ propcom
  INVESTOR_BONUS = 'INVESTOR_BONUS', // Thưởng từ nhà đầu tư
}
