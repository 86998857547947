<div class="toast"
   [@fadeAnimation]="{value: animationState, params:
      { fadeIn: toastConfig.animation.fadeIn, fadeOut: toastConfig.animation.fadeOut }}"
   (@fadeAnimation.done)="onFadeFinished($event)">
   <div class="toast-logo">
         <img class="logo-image" src='./assets/img/logo/logo-dx-noti.png' alt="logo">
   </div>
   <div class="toast-body">
      <a href="/message/{{data.conversationId}}" target="_blank" style="color: black;">
         <div>
            <p style="padding-bottom: 3px;"><b>{{ data.title }}</b></p>
            <p class="data-text" *ngIf="!productUrl">{{ data.text.length < 100 ? data.text : data.text.substr(0, 100) }}</p>
            <p class="data-text" *ngIf="productUrl">
               <b>Link:</b> 
               <a href="{{ productUrl }}">
                  {{ productUrl }}
               </a>
            </p>
         </div>
      </a>
   </div>
   <div class="toast-avatar">
      <img class="profile-image" src='./assets/img/users/icon_avatar.svg' alt="profile">
   </div>
   <!-- <mat-icon (click)="close()" style="cursor: pointer;">close</mat-icon> -->
</div>
