export class EmployeeReferralReport {
    stt: number;
    id: string;
    code: string;
    name: string;
    phone: string;
    identityCode: string;
    referralPersonF1: any;
    referralPersonF2: any;

    constructor(
        param?: any
    ) {
      this.setValue(param);
    }

    setValue(params: any) {
        this.stt = params ? params.stt || 0 : 0;
        this.id = params ? params.id || null : null;
        this.code = params ? params.code || null : null;
        this.name = params ? params.name || null : null;
        this.phone = params ? params.phone || null : null;
        this.identityCode = params ? params.identityCode || null : null;
        this.referralPersonF1 = params ? params.referralPersonF1 || {} : {};
        this.referralPersonF2 = params ? params.referralPersonF2 || {} : {};
    }
}
