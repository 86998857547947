import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm-pay-waiting',
  templateUrl: './confirm-pay-waiting.component.html',
  styleUrls: ['./confirm-pay-waiting.component.scss']
})
export class ConfirmPayWaitingComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmPayWaitingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  approve() {
    this.dialogRef.close({ isApprove: true });
  }
  reject() {
    this.dialogRef.close({ isApprove: false });
  }
}
