import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputMaxLengthDirective } from './input-max-length.directive';
import { ScrollDirective } from './scroll.directive';
import { CounterDirective } from './counter.directive';

@NgModule({
  declarations: [
    InputMaxLengthDirective,
    ScrollDirective,
    CounterDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    InputMaxLengthDirective,
    ScrollDirective,
    CounterDirective
  ]
})
export class DirectivesModule { }
