import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Constant } from 'app/shared/constant/constant';
import { AngularEditorConfig } from '@kolkov/angular-editor';

export interface EditorData {
    title: string;
    message: string;
    textCancel: string;
    textOk: string;
    body: any;
    execute: boolean;
}

@Component({
    selector: 'editor-popup',
    templateUrl: 'editor-popup.html',
})
export class EditorPopup implements OnInit {
    htmlContent = '';
    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '15rem',
        minHeight: '5rem',
        placeholder: 'Nhập ...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Arial',
        toolbarHiddenButtons: [
            ['bold']
        ],
        customClasses: [
            {
                name: "quote",
                class: "quote",
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: "titleText",
                class: "titleText",
                tag: "h1",
            },
        ]
    };
    constructor(
        public dialogRef: MatDialogRef<EditorPopup>,
        @Inject(MAT_DIALOG_DATA) public data: EditorData) { }

    ngOnInit() {
        this.htmlContent = this.data.body;
    }
    onNoClick(): void {
        this.data.execute = false;
        this.dialogRef.close(this.data);
    }
    execute() {
        this.data.execute = true;
        this.data.body = this.htmlContent;
        this.dialogRef.close(this.data);
    }
}
