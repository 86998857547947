<mat-table #table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container *ngFor="let column of gridData.columns" [cdkColumnDef]="column.columnDef">
    <ng-container *ngIf="column.header === 'checkbox'; else elseTemplate">
      <mat-header-cell *cdkHeaderCellDef>
        <mat-checkbox [(ngModel)]="isCheckAll"
        (change)="onChangeHeader($event)">
        </mat-checkbox> 
      </mat-header-cell>
      <mat-cell *cdkCellDef="let item">
        <mat-checkbox [(ngModel)]="item.checkbox" (change)="onChangeItem(item)">
        </mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-template #elseTemplate>
      <mat-header-cell *cdkHeaderCellDef>{{ column.header }}</mat-header-cell>
      <mat-cell *cdkCellDef="let row">{{ column.cell(row) }}</mat-cell>
    </ng-template>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
</mat-table>
<div >
  <button mat-raised-button color="primary" (click)="onClickEvent()" style="margin-top: 15px;">{{buttonName}}</button>
  <button mat-raised-button color="primary" (click)="closeDialog()" style="margin-top: 15px; margin-left: 20px;">Đóng</button>
</div>
