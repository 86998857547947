<div class="landing_page-footer">
    <div class="footer-overplay">
        <div class="footer-overplay-content-wrapper" fxLayout="row">
            <div fxLayout="column" fxFlex="50" class="footer-overplay-content">
                <h1>Bạn muốn tham gia?</h1>
                <p>Tải ứng dụng ngay để trải nghiệm tính năng của Propcom</p>
                <ng-container *ngIf="shareUrl && shareUrl.length > 0; else elseQRCode">
                    <div class="qrcode">
                        <qrcode class="qrcode__qrcode-wrapper" [qrdata]="shareUrl" [size]="140" [level]="'H'"></qrcode>
                    </div>
                </ng-container>
                <ng-template #elseQRCode>
                    <img src="../../../../assets/img/landing/QRcode.svg" alt="qrCode">
                </ng-template>
                <span>Quét mã QR code để tải ứng dụng propcom</span>
                <div class="btn-app">
                    <a href="https://apps.apple.com/vn/app/propcom-c%E1%BB%99ng-%C4%91%E1%BB%93ng-m%C3%B4i-gi%E1%BB%9Bi/id1551955496?l=vi"
                        target="_blank" rel="noopener noreferrer">
                        <img src="../../../../assets/img/landing/Appstore@2x.png" alt="appstore">
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=vn.propcom.app" target="_blank"
                        rel="noopener noreferrer">
                        <img src="../../../../assets/img/landing/Googleplay@2x.png" alt="ggplay">
                    </a>
                </div>
            </div>
            <div fxLayout="column" fxFlex="50" class="footer-overplay-img">
                <img src="../../../../assets/img/landing/intro.svg" alt="bgFooter">
            </div>
        </div>
    </div>
    <div class="footer-content">
        <div class="footer-content-wrapper">
            <div class="row h-100" fxLayout="row" fxLayoutAlign="center">
                <div fxLayout="column" fxFlex="50" fxLayoutAlign="center" class="col-5 mt-75 footer-content-app">
                    <div class="wrapper" fxLayout="row">
                        <div fxLayout="column" fxFlex="40" class="footer-content-app-img">
                            <img src="../../../../assets/img/landing/footerPhone.png" alt="appstore">
                        </div>
                        <div fxLayout="column" fxFlex="60" fxLayoutAlign="center center"
                            class="footer-content-app-body">
                            <p>Tải ứng dụng Propcom</p>
                            <div class="btn-app">
                                <a href="https://apps.apple.com/vn/app/propcom-c%E1%BB%99ng-%C4%91%E1%BB%93ng-m%C3%B4i-gi%E1%BB%9Bi/id1551955496?l=vi"
                                    target="_blank" rel="noopener noreferrer">
                                    <img src="../../../../assets/img/landing/Appstore@2x.png" alt="appstore">
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=vn.propcom.app"
                                    target="_blank" rel="noopener noreferrer">
                                    <img src="../../../../assets/img/landing/Googleplay@2x.png" alt="ggplay">
                                </a>
                            </div>
                            <span>Nhanh chóng - Thuận tiện - Hoa hồng hấp dẫn</span>
                            <div class="call">
                                <img src="../../../../assets/img/landing/call.svg" alt="call">
                                <p>Hotline: 0937 055 368</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="50" fxLayoutAlign="center" class="col-5 mt-75 footer-content-intro">
                    <img src="../../../../assets/img/landing/Logo.svg" alt="logo">
                    <h1>CÔNG TY CỔ PHẦN CÔNG NGHỆ PROPCOM</h1>
                    <p>19/6C Lương Định Của, Phường Bình Khánh, Quận 2, Thành phố Hồ Chí Minh</p>
                    <div class="social">
                        <p>KẾT NỐI VỚI CHÚNG TÔI</p>
                        <div class="social-list">
                            <a href="#"><img src="../../../../assets/img/landing/Icon awesome-facebook.svg"
                                    alt="social"></a>
                            <a href="#"><img src="../../../../assets/img/landing/Icon ionic-logo-instagram.svg"
                                    alt="social"></a>
                            <a href="#"><img src="../../../../assets/img/landing/Icon ionic-logo-twitter.svg"
                                    alt="social"></a>
                            <a href="#"><img src="../../../../assets/img/landing/Icon ionic-logo-youtube.svg"
                                    alt="social"></a>
                            <a href="#"><img src="../../../../assets/img/landing/Icon ionic-logo-pinterest.svg"
                                    alt="social"></a>
                            <a href="#"><img src="../../../../assets/img/landing/Icon awesome-linkedin-in.svg"
                                    alt="social"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p>Copyright © Propcom. All rights reserved</p>
    </div>
</div>