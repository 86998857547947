export class Page {
        public queryCount: number;
        public itemsPerPage: any;
        public selectedPage: number;
        public showedPage: number;
        public previousPage: number;
        loadingPage: number;
    constructor(param?: any) {
        if (param) {
            this.queryCount = this.queryCount ? this.queryCount : 100;
            this.itemsPerPage = this.itemsPerPage ? this.itemsPerPage : 10;
            this.selectedPage = this.selectedPage ? this.selectedPage : 0;
            this.loadingPage = this.loadingPage ? this.loadingPage : 1;
            this.previousPage = this.previousPage ? this.previousPage : 1;
            this.showedPage = 0;
        } else {
            this.queryCount = 0;
            this.itemsPerPage = 10;
            this.selectedPage = 0;
            this.loadingPage = 1;
            this.previousPage = 1;
            this.showedPage = 0;
        }
        
    }
}
