import { DateToFormat } from "app/shared/parse/date-to-format";

export enum NotificationGlobalStatusEnum {
    CHECK_ON = 'CHECK_ON',
    CHECK_OFF = 'CHECK_OFF',
}

export enum NotificationGlobalDestiontionEnum {
    NEWS = 'NEWS', // Tin tức
    EVENT = 'EVENT', // Sự kiện
    LINK = 'LINK', // LINK
    MISSION = 'MISSION', // nhiệm vụ
    CAMPAIGN_PROMOTION = 'CAMPAIGN_PROMOTION', // Campaign ưu đãi
    CAMPAIGN_INTRODUCER = 'CAMPAIGN_INTRODUCER', // Campaign giới thiệu
    GROUP = 'GROUP', // Nhóm
    SURVEY = 'SURVEY', // Khảo sát
    FORTE = 'FORTE', // sở trường của tôi
    PROPSKILL = 'PROPSKILL', // Propskill
}
