import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  public transform(value, keys: string, term: string, isGroup?: boolean, userIds?: any, stringeeUserId?: string, conversationPerPage?: number) {
    value = value || [];
    if (!term) term = '';
    if (typeof isGroup === 'undefined' || isGroup === null) {
      // NVKD
      value = value.filter(item => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key]) && item[key] && item[key] !== ''));
      if (userIds.length > 0) {
        value = value.filter(item => userIds.indexOf(item['email'].replace('@', '.')) === -1);
      }
    } else {
      // Stringee conversation
      value = value.filter(item => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(this.getConversationName(item, stringeeUserId))));
      if (!isGroup) value = value.filter(item => keys.split(',').some(key => item.isGroup === isGroup));
      if (userIds.length > 0) {
        value = value.filter(item => userIds.indexOf(this.getConversationName(item, stringeeUserId)) === -1);
      }
      if (term === '' && conversationPerPage) value = value.slice(0, conversationPerPage);
    }
    return value;
  }

  getConversationId(conversation, currentUserId) {
    if (conversation.isGroup) {
      return conversation.name;
    } else {
      return (conversation.participants[0].userId != currentUserId ? this.parsePhone(conversation.participants[0].userId) : this.parsePhone(conversation.participants[1].userId));
    }
  }

  getConversationName(conversation, currentUserId) {
    if (conversation.isGroup) {
      return conversation.name;
    } else {
      return conversation.participants[0].userId !== currentUserId ? (conversation.participants[0].name ? conversation.participants[0].name : this.parsePhone(this.replaceStringeeCustomerName(conversation.participants[0].userId))) : (conversation.participants[1].name ? conversation.participants[1].name : this.parsePhone(this.replaceStringeeCustomerName(conversation.participants[1].userId)));
    }
  }

  replaceStringeeCustomerName(name: string) {
    return name.replace('cus_', '');
  }

  parsePhone(value) {
    let result = '';
    if (parseInt(value) > -1) {
      for (let i = 0; i < value.length; i++) {
        if (i > 4 && parseInt(value[i]) > -1) {
          result += '*';
        } else {
          result += value[i];
        }
      }
    } else {
      result = value;
    }
    return result;
  }
}
