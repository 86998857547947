import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivate
} from '@angular/router';
import { SecurityService } from '../security.service';
import { map, take, concatMap } from 'rxjs/operators';

export interface CanComponentDeactivate {
  confirmChangeRouter: (url?) => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class UserRouteAccessDeactivate implements CanActivate {
  constructor(
    private securityService: SecurityService,
    private router: Router
  ) {

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean> | Promise<boolean> | boolean {
    return this.securityService.IsAuthenticated.pipe(
      take(1), map(x => {
        if (x) {
          return true;
        } else {
          this.router.navigate(['/login']);
        }
        return false;
      }));
  }
}
