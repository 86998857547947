import { Injectable } from '@angular/core';
@Injectable()
export class AuthorizeService {
    private authorities: any;
    private isFullPermission: boolean;
    observable: any;
    ENTITIES: any = [];
    constructor(
    ) {
    }
    resetAuthorities() {
        this.authorities = null;
    }
    setAuthorities(authorities) {
        if (!authorities) {
            authorities = {
                dashboard: {}
            }
        }
        this.authorities = authorities;
    }
    setIsFullPermission(value) {
        this.isFullPermission = value ? true : false;
    }
    checkAuthorities() {
        if (this.authorities || this.isFullPermission) {
            return true;
        }
        return false;
    }
    checkUrl(route) {
        let isAuthor: boolean = false;
        // for (let property in ROUTES) {

        // }
    }
    getFirstUrl() {
        let url = ''
        // for (let property in this.authorities) {
        //     if (url && this.authorities[property] && this.authorities[property].isAuthor) {
        //         url = ROUTES[property]; 
        //     }
        // }
        return url;
    }
    hasAuthority(authorities: string[]): Promise<boolean> {
        let result = this.getAuthority(authorities);
        if (result) return Promise.resolve(true);
        else return Promise.resolve(false);
    }
    checkHasAuthority(authorities: any): Promise<boolean> {
        let isHasRole = false;
        authorities.forEach((auth) => {
            let result = this.getAuthority(auth);
            if (result) return isHasRole = result;
        })
        return Promise.resolve(isHasRole);
    }
    hasAnyAuthority(): Promise<boolean> {
        let result = null;
        if (this.authorities) {
            this.ENTITIES.forEach((entity) => {
                if (!result) {
                    result = this.getAuthority([entity]);
                }
            })
        }
        if (result) return Promise.resolve(true);
        else return Promise.resolve(false);
    }
    getAuthority(authorities: any) {
        try {
            if (this.isFullPermission) {
                return true;
            }
            let result: any = this.authorities ? JSON.parse(JSON.stringify(this.authorities)) : null;
            authorities.forEach((authority) => {
                if (result) {
                    result = result[authority.toLowerCase()];
                }
            })
            if (result && result.length === 0) {
                result = null;
            }
            return result && result.isAuthor ? result.isAuthor : result;
        } catch (error) {
        }

    }
    checkAuthority() {
        return this.authorities.length > 1;
    }
}
