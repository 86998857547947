export enum mlmStatusEnum {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}

export enum mlmActionBonusEnum {
    REGISTERED = "REGISTERED", // Đăng ký tài khoản thành công
    IDENTIFIED = "IDENTIFIED", // xác thực thành công
    SIGNED_CONTRACT = "SIGNED_CONTRACT", // ký hợp đồng thành công
    FIRST_TRANSACTION_DONE = "FIRST_TRANSACTION_DONE", // giao dịch lần đầu thành công
    MISSION_DONE = "MISSION_DONE", // Đã hoàn thành nhiệm vụ
    START_PROBATION = "START_PROBATION", //  Đã bắt đầu thử việc
    TRANSACTION_COMPLETE = "TRANSACTION_COMPLETE" //  Đã hoàn thành giao dịch (đối chiếu thành công)
}

export enum mlmActionTextEnum {
    REGISTERED = 'Người được giới thiệu đăng ký tài khoản thành công',
    IDENTIFIED = 'Người được giới thiệu xác thực thành công',
    SIGNED_CONTRACT = 'Người được giới thiệu ký HĐLĐ thành công',
    FIRST_TRANSACTION_DONE = 'Người được giới thiệu giao dịch thành công (chuyển cọc thành công)',
    MISSION_DONE = 'Người được giới thiệu thực hiện nhiệm vụ',
    START_PROBATION = "Người được giới thiêu bắt đầu thử việc",
    TRANSACTION_COMPLETE = "Người được giới thiệu hoàn thành giao dịch (đối chiếu thành công)"
}

export enum statusTVVEnum {
    INIT = 'INIT', // chưa xác thực
    WAITING = 'WAITING', // chờ xác thực
    IDENTIFIED = 'IDENTIFIED', // đã xác thực
    REJECTED = 'REJECTED', // từ chối
    SIGNED_CONTRACT = 'SIGNED_CONTRACT', // đã ký hợp đồng
}

export enum statusTextTVVEnum {
    INIT = 'Chưa xác thực', // chưa xác thực
    WAITING = 'Chờ xác thực', // chờ xác thực
    IDENTIFIED = 'Đã xác thực', // đã xác thực
    REJECTED = 'Bị từ chối', // từ chối
    SIGNED_CONTRACT = 'Đã ký hợp đồng', // đã ký hợp đồng
}

export enum WalletTransactionTypeEnum {
    MAIN = 'MAIN', // Ví chính
    PROMOTION = 'PROMOTION', // Ví thưởng
    EXTERNAL_PAY = 'EXTERNAL_PAY', // Không trả vào ví
    GIFT = 'GIFT', // Tặng quà
}

export enum WalletTransactionTypeTextEnum {
    MAIN = 'Ví chính',
    PROMOTION = 'Ví thưởng',
    EXTERNAL_PAY = 'Không trả vào ví',
    GIFT = 'Tặng quà',
}

