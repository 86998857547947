<div class="landing_page">
    <app-landing-header (openDialog)="openDialog()"></app-landing-header>
    <div class="landing_page-body" [ngSwitch]="checkDataGroupPost">
        <img src="../../../../assets/img/landing/abstract.svg" alt="landingAbstract">
        <div class="landing_page-wrapper" *ngSwitchCase="true">
            <div class="landing_page-content">
                <div fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="12px"
                >
                    <img [src]= "model.user.avatar" onError="this.src = './assets/img/landing/test.png'" alt="info" class="img-avatar">
                    <div
                        fxLayout="column"
                        fxLayoutAlign="start"
                    >
                        <div
                            fxLayout="row"
                            fxLayoutGap="12px"
                            fxLayoutAlign="start center"
                        >
                            <span class="fz-20 fw-b">{{model.user.name ? model.user.name : ''}}</span>
                            <div class="icon icon-polygon">
                                <img src="../../../../assets/img/landing/polygon.svg" alt="info">
                            </div>
                            <span class="fz-20 fw-b">{{model.group.name ? model.group.name : ''}}</span>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start">
                            <span class="fz-20 text-time">{{model.createdDate ? model.createdDate : ''}}</span>
                        </div>
                    </div>
                </div>               
                <p>{{model.description ? model.description : ''}}</p>
                <div *ngIf="model.medias">
                    <div class="d-flex flex-1 img-block" *ngIf="count >= 3">
                        <div [ngSwitch]="model.medias[0]?.type" class="img-block-big">
                            <div  *ngSwitchCase="'IMAGE'" class="img-block-small-child">
                                <img [src]="model.medias[0]?.url" alt="" class="img-cover">
                            </div>
                            <div  *ngSwitchCase="'VIDEO'" class="img-block-small-child">
                                <video  controls>
                                    <source [src]="model.medias[0]?.url" type="video/mp4" class="w-100 h-100">
                                </video>
                            </div>
                            <div  *ngSwitchCase="'YOUTUBE'" class="img-block-small-child">
                                <iframe
                                class="w-100 h-100"
                                [src]="model.medias[0]?.url" title="YouTube video player" frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  
                                allowfullscreen></iframe>
                            </div>
                        </div>
                        <div class="img-block-small">
                            <div [ngSwitch]="model.medias[1]?.type" class="block-switch">
                                <!-- anh 2 -->
                                <div class="img-block-small-child" *ngSwitchCase="'IMAGE'">
                                    <img [src]="model.medias[1]?.url" alt="" class="img-cover">
                                </div>
                                <div class="img-block-small-child" *ngSwitchCase="'VIDEO'" >
                                    <video  controls>
                                        <source [src]="model?.medias[1].url" type="video/mp4" class="img-cover">
                                    </video>
                                </div>
                                <div class="img-block-small-child" *ngSwitchCase="'YOUTUBE'" >
                                    <iframe [src]="model.medias[1].url" title="YouTube video player" frameborder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  class="w-100 h-100"
                                    allowfullscreen></iframe>
                                </div>
                            </div>
                            <div [ngSwitch]="model.medias[2]?.type" class="block-switch">
                                <!-- anh 3 -->
                                <div class="img-block-small-child" *ngSwitchCase="'IMAGE'" >
                                    <img [src]="model.medias[2]?.url" alt="" class="img-cover">
                                </div>
                                <div class="img-block-small-child" *ngSwitchCase="'VIDEO'" >
                                    <video  controls>
                                        <source [src]="model?.medias[2].url" type="video/mp4" >
                                    </video>
                                </div>
                                <div class="img-block-small-child" *ngSwitchCase="'YOUTUBE'" >
                                    
                                    <iframe  [src]="model.medias[2].url" title="YouTube video player" 
                                    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowfullscreen class="h-100 w-100"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex img-block" *ngIf="count === 1" >
                        <div [ngSwitch]="model.medias[0]?.type" class="img-block-big-1">
                            <div class="img-block-small-child" *ngSwitchCase="'IMAGE'" >
                                <img [src]="model.medias[0].url" alt="" class="img-cover">
                            </div>
                            <div class="img-block-small-child" *ngSwitchCase="'VIDEO'" >
                                <video  controls>
                                    <source [src]="model?.medias[0].url" type="video/mp4" >
                                </video>
                            </div>
                            <div class="img-block-small-child" *ngSwitchCase="'YOUTUBE'" >
                                <iframe src="model.medias[0].url" title="YouTube video player" frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  class="h-100 w-100"
                                allowfullscreen></iframe>
                            </div>
                        </div>                    
                    </div>

                    <div class="d-flex img-block" *ngIf="count === 0" >
                        <div class="img-block-big-1">
                            <div class="img-block-small-child" >
                                <img src="../../../../assets/img/landing/Image.png" alt="" class="img-cover">
                            </div>
                        </div>                    
                    </div>
    
                    <div class="d-flex img-block" *ngIf="count === 2">
                        <div [ngSwitch]="model.medias[1]?.type" class="img-block-big-2">
                            <div class="img-block-small-child" *ngSwitchCase="'IMAGE'" >
                                <img [src]="model.medias[0].url" alt="" class="img-cover">
                            </div>
                            <div class="img-block-small-child" *ngSwitchCase="'VIDEO'" >
                                <video  controls>
                                    <source [src]="model?.medias[0].url" type="video/mp4" class="w-100 h-100">
                                </video>
                            </div>
                            <div class="img-block-small-child" *ngSwitchCase="'YOUTUBE'" >
                                <iframe src="model.medias[0].url" title="YouTube video player" frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  class="w-100 h-100"
                                allowfullscreen></iframe>
                            </div>
                        </div>
                        <div [ngSwitch]="model.medias[1]?.type" class="img-block-big-2">
                            <!-- anh 2 -->
                            <div class="img-block-small-child" *ngSwitchCase="'IMAGE'" >
                                <img [src]="model.medias[1]?.url" alt="" class="img-cover">
                            </div>
                            <div class="img-block-small-child" *ngSwitchCase="'VIDEO'" >
                                <video  controls>
                                    <source [src]="model?.medias[1].url" type="video/mp4" class="w-100 h-100">
                                </video>
                            </div>
                            <div class="img-block-small-child" *ngSwitchCase="'YOUTUBE'" >
                                <iframe [src]="model.medias[1].url" title="YouTube video player" frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  class="w-100 h-100"
                                allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="infoGroup">
                    <div fxLayoutAlign="start center" fxLayoutGap="5px">
                        <img src="../../../../assets/img/landing/Like.svg" alt="info">
                        <span class="text-icon">{{model.totalFavorites}} thích</span>
                    </div>
                    <div fxLayoutAlign="start center" fxLayoutGap="5px">
                        <img src="../../../../assets/img/landing/comment.svg" alt="info">
                        <span class="text-icon">{{model.totalComments}} bình luận</span>
                    </div>
                    <div fxLayoutAlign="start center" fxLayoutGap="5px">
                        <img src="../../../../assets/img/landing/Share.svg" alt="info">
                        <span class="text-icon">{{model.totalShares}} chia sẻ</span>
                    </div>                    
                </div>
                <div class="btn-wrap btn-landing">
                    <button mat-raised-button>
                        <img class="img-btn" src="../../../../assets/img/landing/login.svg" alt="action">
                        <span class="txt-btn" (click)="openDialog()">Tham gia nhóm</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="landing_page-wrapper-15" *ngSwitchCase="false" >
            <div class="landing_page-content">
                <div fxLayout="column"
                    fxLayoutAlign="start center"
                    fxLayoutGap="12px"
                >                
                    <img src="../../../../assets/img/landing/Group404.svg" alt="landingAbstract"> 
                    <div class="infoGroup" fxLayoutAlign="center center">
                        <div fxLayoutAlign="center center" fxLayoutGap="5px">
                            <span class="text-er">Xin lỗi! Bạn không thể xem được bài đăng này</span>
                        </div>                 
                    </div>   
            </div>
        </div>
    </div>
    <div class="landing_page-intro-wrapper">
        <div class="landing_page-intro" fxLayout="row" fxLayoutGap="60px">
            <div fxLayout="column" fxFlex="50" class="landing_page-intro-img">
                <img src="../../../../assets/img/landing/Mockup.png" alt="Mockup">
            </div>
            <div fxLayout="column" fxFlex="50" class="landing_page-intro-content">
                <div class="intro-title">
                    <h1>LÀM NHỮNG ĐIỀU BẠN THÍCH</h1>
                    <h1 class="text-primary">VỚI TÍNH NĂNG TẠO BÀI VIẾT</h1>
                </div>
                <div class="intro-content">
                    <p>Dễ dàng trao đổi và chia sẻ thông tin về thế giới bất động sản với tính năng tạo bài viết vào các nhóm bạn tham gia</p>
                    <p>Thu thập thông tin thông qua tính năng khảo sát nhóm.</p>
                    <p>Tạo ấn tượng bài viết bằng cách ghim nổi bật</p>
                    <p>Có thể xem chi tiết các bài viết, tham gia bình luận, tương tác để trao đổi ý kiến, kinh nghiệm, xây dựng cộng đồng vui nhộn, tích cực</p>
                </div>
                <div class="intro-button btn-landing">
                    <button mat-raised-button (click)="openDialog()" class="w-100">Tải ứng dụng</button>
                </div>
            </div>
        </div>
        <img src="../../../../assets/img/landing/abstract.png" alt="abstractFooter">
    </div>
    <app-landing-footer [shareUrl]="model.shareUrl"></app-landing-footer>
</div>