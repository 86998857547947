import {
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output,
    Input

} from "@angular/core";

import { SurveyService } from "../survey.service";

import moment = require('moment');
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { data } from "jquery";
import { FormBuilder, FormControl, FormGroup, FormArray,ReactiveFormsModule } from "@angular/forms";
import { Subscription } from "rxjs-compat";
import { group } from "@angular/animations";
import { SurveyQuestionTypeEnum } from "app/shared/enum/survey.enum";
import { SurveyModel } from "app/shared/models/survey.model";


@Component({
    selector: "app-survey-note-detail",
    templateUrl: "./survey-note-detail.component.html",
    styleUrls: ["./survey-note-detail.component.scss"],
})
export class SurveyNoteDetailComponent implements OnInit {
    survey: any;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<SurveyNoteDetailComponent>
    ) {
        if(data){
            this.survey = data;
        }
     }

    ngOnInit() { 

    }
    closeDialog() {
        this.dialogRef.close();
    }
    getValueAnswer(question) {
        if (question.value) {
            return question.value;
        }
        if (question.answers && question.answers.length > 0) {
            const answer = question.answers.find((item) => item.value);
            if (answer) {
                return answer.value;
            }
            return '';
        }
        return '';
    }
}