import { Component, OnInit, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, FormControl } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectComponent), multi: true },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() items = [];
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() placeholder: string;
  @Input() clearable: boolean = true;
  @Input() isDisabled: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() multiple: boolean = false;
  @Input() appendTo: string;
  @Input() notFoundText: string = 'No items found';
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() search: EventEmitter<any> = new EventEmitter();
  @Output() scrollToEnd: EventEmitter<any> = new EventEmitter();

  private _changeCb: any;
  private _blurCb: any;
  public ngModelValue: any;

  constructor() {
  }

  ngOnInit() {
  }
  
  onScrollToEnd() {
    this.scrollToEnd.emit();
  }

  onChangeSelection(event: any) {
    this.change.emit(event);
  }

  onSearch(event: any) {
    this.search.emit(event);
  }

  onChangeNgModel(event) {
    this.ngModelValue = event;
    this.callChangeCb();
  }

  writeValue(value: any): void {
    this.ngModelValue = value;
  }

  registerOnChange(fn: any) {
    this._changeCb = fn;
  }

  registerOnTouched(fn: any) {
    this._blurCb = fn;
  }

  callChangeCb() {
    if(this._changeCb) {
      this._changeCb(this.ngModelValue);
    }
  }

  callBlur() {
    if(this._blurCb) {
      this._blurCb();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  validate(c: FormControl) {
    return c.valid ? null : {
      type: {
        valid: false,
        actual: c.value
      }
    };
  }
}
