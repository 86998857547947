import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PropertyPrimaryService } from '../property-primary.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProjectService } from '../../project.service';
import moment = require('moment');
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { markAsTouched } from 'app/shared/utility/form-utility';
import { ToastrService } from 'app/shared/services/common';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SurveyQuestionTypeEnum } from 'app/shared/enum/survey.enum';
import { Constant } from 'app/shared/constant/constant';

@Component({
  selector: 'app-popup-note',
  templateUrl: './popup-note.component.html',
  styleUrls: ['./popup-note.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('expandCollapseState', style({ height: '*' })),
    ])
  ]
})
export class PopupNoteComponent implements OnInit {
  public note: string = "";
  public maxLength: number = 0;
  public isShowHistory: boolean = false;
  public histories: any[] = [];
  public survey: any = {};
  public isShowSurvey: boolean = false;
  public title: string = "Ghi chú từ admin";
  public isLoanPackage: boolean = true;
  mainForm: FormGroup = null;
  group: any = {};
  Constant =  Constant;
  questions: any = [];
  questionEnum = SurveyQuestionTypeEnum;
  openCloseHistory: boolean = true;
  openCloseSurvey: boolean = true;
  isAnswer: boolean = false;
  isShowTextBox: boolean = false;
  constructor(public dialogRef: MatDialogRef<PopupNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: FormBuilder,
    private toastr: ToastrService,
    ) {
      this.note = data.note;
      this.maxLength = data.maxLength;
      if (data.isShowHistory) {
        this.isShowHistory = data.isShowHistory;
      }
      if (data.histories) {
        this.histories = data.histories;
      }
      if (data.title) {
        this.title = data.title;
      }
      if (data.isShowSurvey){
        this.isShowSurvey = data.isShowSurvey
      }
      if(data.survey){
        this.survey = data.survey
      }
    }

  ngOnInit() {
    let group = {}
    if(Object.values(this.survey).length != 0){
      this.survey.questions.map((item,i) => {
        group[`${item.id}`] = '';
        if(item.isRequired) {
            group[`${item.id}`] = ['', Validators.required];
        }
        if(item.type == this.questionEnum.DROPDOWN ){
            group[`textbox_${item.id}`] = '';
        }
      })
    }
    this.checkAnswer();
    group['note'] = [this.note , Validators.required];
    this.mainForm = this.formBuilder.group(
      group
    );

  }
  getFormControl(name: string) {
    return this.mainForm.get(name);
  }

  onSubmit() {
    markAsTouched(this.mainForm);    
    const formValue = this.mainForm.getRawValue()
    const data = Object.entries(formValue)
    let existSurvey = false;
    if( this.survey &&  this.survey.questions){
      for (const [key, value] of data) {
        this.survey.questions.map( (item: any) => {
          if (item.id === key) {
            if (item.type != SurveyQuestionTypeEnum.DROPDOWN) {
              if (value) {
                existSurvey = true;
                return item.value = value;
              }
            }
            if (value) {
              existSurvey = true;
              let index = item.answers.findIndex(element => element.code === value);
              if (!item.answers[index].isShowTextBox) {
                item.answers[index].value = item.answers[index].name;
              } else {
                const element = <HTMLInputElement>document.getElementById(item.id);
                item.answers[index].value = element.value;
              }
            }
          }
          return item;
        })
      }
    }

    const note = this.getFormControl("note").value;
    const survey = {
      id: this.survey.id,
      code: this.survey.code,
      questions: this.survey.questions
    }
    
    if (!this.mainForm.valid || note.trim().length == 0)  {
      this.toastr.error("Vui lòng nhập ghi chú và các thông tin cần thiết", "Thông báo!");
      return;
    }
    const response: any = {
      isSuccess: true,
      note: note
    };
    if (existSurvey) {
      response.survey = survey;
    }
    this.dialogRef.close(response);
  }

  closeDialog() {
    this.dialogRef.close();
  }
  openHistoriesFilter(): void {
    this.openCloseHistory = !this.openCloseHistory;
    setTimeout(() => {
      document.getElementById('endPage').scrollIntoView();
    }, 100);
  }
  openSurveyFilter(): void {
    this.openCloseSurvey = !this.openCloseSurvey;
    setTimeout(() => {
      document.getElementById('endSurvey').scrollIntoView();
    }, 100);
  }

  // hàm phục vụ cho việc tách component
  checkAnswer(value = '') {
    if(Object.values(this.survey).length != 0){
      this.survey.questions.map((item,i) => {
        if (value) {
          if(item.type == this.questionEnum.DROPDOWN ){
            item.answers.map((answer) => {
              if (answer.id === value && answer.isShowTextBox) {
                this.mainForm.get(`${item.id}`).clearValidators();
                this.mainForm.controls[`${item.id}`].updateValueAndValidity();
                this.isAnswer = true;
              } else {
                this.isAnswer = false;
              }
            }) 
            if(item.isRequired && this.isAnswer ) {
                this.mainForm.get(`textbox_${item.id}`).setValidators([Validators.required]);
                this.mainForm.controls[`textbox_${item.id}`].updateValueAndValidity();
            } else {
                this.mainForm.get(`textbox_${item.id}`).setValue("");
                this.mainForm.controls[`textbox_${item.id}`].updateValueAndValidity();
            }
          }
        }
      })
    }
  }

  setShowTextBox(event, question){
    this.isShowTextBox = event.isShowTextBox;
    let element = (<HTMLInputElement>document.getElementById(question.id));
    if(event.isShowTextBox === true){
        element.style.display = 'block';
    } else {
        element.style.display = 'none';
        element.value = ""
    }
    if (event.id) {
      if(question.type == this.questionEnum.DROPDOWN ){
        question.answers.map((answer) => {
          if (answer.id === event.id && answer.isShowTextBox) {
            this.mainForm.get(`${question.id}`).clearValidators();
            this.isAnswer = true;
          } else {
            this.isAnswer = false;
          }
        }) 
        if(question.isRequired && this.isAnswer ) {
            this.mainForm.get(`textbox_${question.id}`).setValidators([Validators.required]);
            this.mainForm.controls[`textbox_${question.id}`].updateValueAndValidity();
        } else {
            this.mainForm.get(`textbox_${question.id}`).setValue("");
            this.mainForm.get(`textbox_${question.id}`).clearValidators();
            this.mainForm.controls[`textbox_${question.id}`].updateValueAndValidity();
        }
      }
    }
  }
}

