import { Injectable } from '@angular/core';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import { DataService } from '../../shared/services/data.service';
import { StorageService } from '../../shared/services/storage.service';

import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import { ConstantUrl } from '../../shared/constant/url';

@Injectable()
export class WebsocketService {
    private listChannel: any = {};
    private listSub: any = {};
    public messageSubscribe: any;
    ConstantUrl = ConstantUrl;
    CAN_CHANGE = 'CAN_CHANGE';
    SINGLE = 'SINGLE';
    constructor(
    ) {

    }
    setChannel(chanel, sub, type?, force?) {
        !type && (type = this.CAN_CHANGE)
        if (!this.listChannel[chanel] || force) {
            this.listChannel[chanel] = force ? null : type;
            this.listSub[chanel] = sub;
        }
    }
    getChannel(chanel) {
        return this.listChannel[chanel];
    }
    removeListCanChange() {
        let list = [];
        for (let property in this.listChannel) {
            if (this.listChannel[property] === this.CAN_CHANGE) {
                this.listChannel[property] = null;
                list.push(this.listSub[property])
            }
        }
        list.forEach((sub) => {
            if (sub && typeof sub.unsubscribe == 'function') {
                sub.unsubscribe();
            }
        })
    }
    getSub(chanel) {
        return this.listSub[chanel];
    }
}
