import { BaseModel } from "../../shared/models/base.model";
import { BaseModelInterface } from "../../shared/models/base.model.interface";
import { ValidateIf, IsNotEmpty, Length } from "class-validator";
import { Ticket } from "../ticket/ticket.model";
import { Constant } from "../../shared/constant/constant";
import { Category } from "../category/category.model";
import { Customer } from "../customer/customer.model";
import { Property } from "../property/property.model";
import { POS } from '../orgchart-pos/model/pos.model';
import { Employee } from '../employee/employee.model';

export class Consignment extends BaseModel implements BaseModelInterface {
    @ValidateIf(o => o.otherProperty === "")
    @IsNotEmpty()
    @Length(10, 20)
    id: string;

    @Length(10, 100)
    name: string;

    customer: Customer;

    nameCustomer: string;

    type: string;

    ticket: Ticket;

    pos: POS;

    posId: string;

    employee: Employee;

    employeeTakeCare: Employee;

    property: Property;

    images: any = {};

    source: string;
    code: string;

    constructor(
        param?: any
    ) {
        super();
        this.setValue(param);
    }

    static getType() {
        return 'consignment';
    }

    setValue(params?: any) {
        if (params) {
            this.id = params.id || '';
            this.name = params.name || '';
            this.type = params.type || Constant.NOT_MINING_TRANSACTION;
            this.ticket = params.ticket ? new Ticket(params.ticket) : new Ticket();
            this.pos = params.pos ? new POS(params.pos) : new POS();
            this.employee = params.employee ? new Employee(params.employee) : new Employee();
            this.employeeTakeCare = params.employeeTakeCare ? new Employee(params.employeeTakeCare) : new Employee();
            const property = params.property || params.propertyUnit;
            this.property = property ? new Property(property) : new Property();
            this.source = params.source || params.resource || 'webbroker';
            this.code = params.code || null;
            const customer = params.customer || params.propertyCustomer;
            this.customer = customer ? new Customer(customer) : new Customer();
            this.nameCustomer = `${this.customer.personalInfo.lastName ? this.customer.personalInfo.lastName + ' ' : ''}${this.customer.personalInfo.name || ''}`;
        } else {
            this.setAllNull();
        }
        super.setValue(params);
    }

    setAllNull() {
        this.id = '';
        this.name = '';
        this.type = Constant.SELL_TRANSACTION;
        this.ticket = new Ticket();
        this.pos = new POS();
        this.employee = new Employee();
        this.employeeTakeCare = new Employee();
        this.property = new Property();
        this.customer = new Customer();
        this.nameCustomer = '';
        this.source = 'webbroker';
    }
    createObjectRequestAPI() {
        let object: any = {};
        this.id && (object.id = this.id || null);
        object.name = this.name;
        object.type = this.property.transactionType;
        object.ticket = this.ticket.createObjectReference();
        object.customer = this.customer.createObjectRequestAPI();
        object.pos = this.pos.createObjectReference();
        object.employee = this.employee.createObjectReference();
        // object.employeeTakeCare = this.employeeTakeCare.createObjectReference();
        object.property = this.property.createObjectRequestAPI();
        return object;
    }
    createObjectReference() {
        let object: any = {};
        this.id && (object.id = this.id || null);
        object.name = this.name;
        return object;
    }
}
