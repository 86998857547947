import { DateToFormat } from '../../../shared/parse/date-to-format';
import { TransactionStatusEnum } from 'app/shared/enum/transaction.enum';
import { TransactionStatusMapping, TXN_STATUS } from 'app/shared/constant/transaction.constant';
import { isNullOrUndefined } from 'util';
import { PrimaryTransactionStatus } from 'app/shared/enum/primary-transaction.enum';

export class TicketSale {
  id: string;
  isDisable: boolean;
  stt: number;
  customer: any;
  customerName: string;
  customerAddress: any;
  residentAddress: string;
  identity: string;
  identityIssueDate: string;
  identityIssueLocation: string;
  email: string;
  phone: string;
  project: string;
  propertyCode: string;
  createdAt: string;
  timestamp: number;
  projectId: string;
  projectCode: string;
  description: string;
  code: string;
  posName: string;
  sanName: string;
  posId: string;
  employee: any;
  employeeTakeCareName: string;
  employeeTakeCareCode: string;
  employeeTakeCareEmail: string;
  employeeTakeCarePhone: string;
  amount?: number;
  recieptCode: string;
  recieptStatus: string;
  recieptCodeExcel: string;
  recieptStatusExcel: string;
  priority: number;
  codeName: string;
  statusString: string;
  salesUnit: any;
  pos: any;
  isChecked: boolean;
  priceCompare: number;
  commissionTotal: number;
  commissionManager: number;
  commissionInviter: number;
  commissionTVV: number;
  commissionPropcom: number;
  commissionOutPut: any;
  commissionPercents: any;
  commissionName: string;
  sumCommission: number;
  files: any;
  fee: number;
  commissionPolicyId: string;
  paymentPhasePercent: number;
  paymentPhase: number;
  totalPaymentPhase: number;
  dividendRate: number;

  // New endpoint
  bookingTicketCode: string;
  escrowTicketCode: string;
  status: string;
  campaignErp: string;
  systemnoErp: string;
  ticketType: string;
  demandCategory: string;
  demandCategoryName: string;
  birthdayString: string;
  txtStatus: string;
  projectSetting: any;
  propertyUnit: any;
  ticketCode: string;
  ticketCodeRecipt: string;
  reciept: any;
  masked: boolean;
  codeCustomerErp: string;
  surveys: any;
  customer2: any;
  address: any;
  contactAddress: any;
  wardAddress: string;
  districtAddress: string;
  provinceAddress: string;
  ageYear: any;
  note: string;
  contractCode: string;
  contractId: string;
  // booking priority
  expiredTime: string;

  coffeeAmount: number;
  supporter: any;
  bookingAmount: number;
  noteAdmins: any;
  createdDate: string;
  noteFromApp: any;

  constructor(
    param?: any
  ) {
    this.setValue(param);
  }

  setValue(params: any) {
    if (params) {
      this.stt = params.stt || '';
      this.id = params.id;
      this.code = params.code || '';
      this.customer = params.customer || null;
      this.birthdayString = params.customer ? this.getCustomerBODString(params.customer) : '';
      this.customerName = params.customer && params.customer.personalInfo ? params.customer.personalInfo.name : '';
      this.customerAddress = params.customer && params.customer.info ? params.customer.info.rootAddress : null;
      this.residentAddress = this.customerAddress ? this.customerAddress.fullAddress : '';
      this.address = params.customer && params.customer.info ? params.customer.info.address : null;
      this.contactAddress = this.address ? this.address.fullAddress : '';
      this.identity = params.customer && params.customer.identities && params.customer.identities.length > 0 ? params.customer.identities[0].value : '';
      this.identityIssueDate = params.customer && params.customer.identities && params.customer.identities.length > 0 ? DateToFormat.parseDate(params.customer.identities[0].date, 'dd/MM/yyyy') : '';
      this.identityIssueLocation = params.customer && params.customer.identities && params.customer.identities.length > 0 ? params.customer.identities[0].place : '';
      this.email = params.customer && params.customer.personalInfo.email || '';
      this.phone = params.customer && params.customer.personalInfo.phone || '';
      this.project = params.project.name || '';
      this.projectSetting = params.project && params.project.setting ? params.project.setting : null;
      this.propertyCode = params.propertyUnit ? params.propertyUnit.code : '';
      this.createdAt = params.createdDate ? DateToFormat.parseDate(params.createdDate, 'dd/MM/yyyy hh:mm') : '';
      this.createdDate = params.createdDate || '';
      this.timestamp = (new Date(params.createdDate)).getTime();
      this.projectId = params.project.id;
      this.projectCode = params.project.code;
      this.description = `Cần mua sản phẩm ${params.propertyCode} dự án ${params.projectName}`;
      this.posName = params.employee.pos && params.employee.pos.name ? params.employee.pos.name : '';
      this.sanName = params.pos && params.pos.name ? params.pos.name : '';
      this.posId = params.employee.pos && params.employee.pos.id ? params.employee.pos.id : '';
      this.employee = params.employee || null;
      this.employeeTakeCareName = params.employee && params.employee.name ? params.employee.name : '';
      this.employeeTakeCareEmail = params.employee && params.employee.email ? params.employee.email : '';
      this.employeeTakeCarePhone = params.employee && params.employee.phone ? params.employee.phone : '';
      this.amount = params.amount || 0;
      this.recieptCode = '';
      this.recieptStatus = '';
      this.recieptCodeExcel = '';
      this.recieptStatusExcel = '';
      this.priority = params.priority || '';
      this.commissionOutPut = params.commissionOutPut || null;
      this.sumCommission = params.sumCommission || 0;
      this.codeName = this.propertyCode ? `${params.bookingTicketCode} (${this.propertyCode} - UT ${params.priority})` : params.bookingTicketCode;
      this.coffeeAmount = params.coffeeAmount ? params.coffeeAmount : 0
      this.supporter = params.supporter ? params.supporter : {}

      this.statusString = params.status || '';
      this.bookingAmount = params.bookingAmount || 0;
      if (params.noteAdmins && Array.isArray(params.noteAdmins)){
        params.noteAdmins.sort((a,b) => (a.createdDate < b.createdDate) ? 1 : ((b.createdDate < a.createdDate) ? -1 : 0))
    }
      this.noteAdmins = params.noteAdmins || [];
      if (this.statusString) {
        this.txtStatus = TXN_STATUS[this.statusString];
      } else {
        this.txtStatus = '';
      }
      this.bookingTicketCode = params.bookingTicketCode || '';
      this.escrowTicketCode = params.escrowTicketCode || '';
      this.status = params.status || '';
      // this.status = PrimaryTransactionStatus.CLOSE;
      this.salesUnit = params.project && params.project.setting ? params.project.setting.salesUnit : null;
      this.campaignErp = params.project && params.project.setting ? params.project.setting.campaignErp : '';
      this.systemnoErp = params.systemnoErp || '';
      this.pos = params.pos || null;
      this.ticketType = params.ticketType || '';
      this.demandCategory = params.demandCategory || null;
      this.demandCategoryName = params.demandCategoryName || null;
      this.propertyUnit = params.propertyUnit || null;
      this.reciept = params.reciept || [];
      this.ticketCode = params.ticketType ? this.getTicketCode(params.ticketType) : '';
      this.ticketCodeRecipt = this.ticketCode + this.getRecieptString();
      this.masked = params.masked;
      this.codeCustomerErp = params.codeCustomerErp || '';
      this.surveys = params.surveys || [];

      this.setRecieptStatus();
      this.customer2 = params.customer2 ? new Customer(params.customer2) : new Customer();
      this.wardAddress = this.address ? this.address.ward : '';
      this.districtAddress = this.address ? this.address.district : '';
      this.provinceAddress = this.address ? this.address.province : '';
      this.ageYear = this.getAgeYear(params.customer);
      this.note = params.note;
      this.contractCode = params.contract && params.contract.code || '';
      this.contractId = params.contract && params.contract.id || '';
      this.expiredTime = params.expiredTime && DateToFormat.parseDate(params.expiredTime, 'dd/MM/yyyy hh:mm') || '';
      this.files = params.files;
      this.isChecked = params.isChecked || false;
    } else {
      this.setAllNull();
    }
  }
  getAgeYear(customer) {
    if (!customer || !customer.info) {
      return '';
    }

    if (customer.info.onlyYear) {
      return new Date().getFullYear() - parseInt(customer.info.birthdayYear);
    }

    if (customer.info.birthday) {
      return new Date().getFullYear() - new Date(customer.info.birthday).getFullYear();
    }
    return '';
  }
  getRecieptString() {
    let result = '';
    if (this.reciept && this.reciept.length > 0) {
      this.reciept.forEach(item => {
        result += ' ' + item.code + ' - ' + TransactionStatusMapping[item.status];
      });
    }
    if (result) {
      result = '(' + result.trim() + ')';
    }
    return result;
  }
  getTicketCode(ticketType) {
    if (ticketType === 'YCDCH') {
      return this.bookingTicketCode;
    }
    return this.escrowTicketCode;
  }
  getCustomerBODString(customer: any) {
    if (!customer || !customer.info) {
      return '';
    }

    if (customer.info.onlyYear) {
      return customer.info.birthdayYear;
    }

    if (customer.info.birthday) {
      return DateToFormat.parseDate(customer.info.birthday, 'dd/MM/yyyy');
    }
    return '';
  }
  setAllNull() {
    // set header
    this.id = '';
    this.stt = 1;
    this.isDisable = false;
    this.customerName = '';
    this.customer = null;
    this.birthdayString = '';
    this.customerAddress = null;
    this.residentAddress = '';
    this.identity = '';
    this.email = '';
    this.phone = '';
    this.project = '';
    this.propertyCode = '';
    this.createdAt = '';
    this.timestamp = 0;
    this.timestamp = 0;
    this.projectId = '';
    this.projectCode = '';
    this.description = '';
    this.code = '';
    this.posName = '';
    this.sanName = '';
    this.posId = '';
    this.employee = '';
    this.employeeTakeCareName = '';
    this.employeeTakeCareEmail = '';
    this.employeeTakeCarePhone = '';
    this.amount = 0;
    this.recieptCode = '';
    this.recieptStatus = '';
    this.codeName = '';
    this.coffeeAmount = 0;
    this.supporter = {};
    this.priority = null;
    this.statusString = '';

    this.bookingTicketCode = '';
    this.escrowTicketCode = '';
    this.status = '';
    this.salesUnit = null;
    this.campaignErp = '';
    this.pos = null;
    this.systemnoErp = '';
    this.ticketType = '';
    this.demandCategory = null;
    this.demandCategoryName = null;
    this.txtStatus = '';
    this.projectSetting = null;
    this.propertyUnit = null;
    this.ticketCode = '';
    this.ticketCodeRecipt = '';
    this.reciept = [];
    this.masked = false;
    this.codeCustomerErp = '';
    this.surveys = [];
    this.customer2 = null;
    this.address = null;
    this.contactAddress = '';
    this.ageYear = '';
    this.note = '';
    this.files = null;
    this.isChecked = false;
    this.createdDate = '';
  }

  getHeaders() {
    return [
      { header: 'STT', key: 'stt', width: 10 },
      { header: 'Dự án', key: 'project', width: 15 },
      { header: 'Mã YCTV', key: 'ticketCode', width: 40 },
      { header: 'Mã ERP', key: 'systemnoErp', width: 40 },
      { header: 'Mã KH ERP', key: 'codeCustomerErp', width: 40 },
      { header: 'Trạng thái', key: 'txtStatus', width: 40 },
      { header: 'Mã sản phẩm', key: 'propertyCode', width: 15 },
      { header: 'Mã phiếu thu', key: 'recieptCodeExcel', width: 15 },
      { header: 'Trạng thái phiếu thu', key: 'recieptStatusExcel', width: 15 },
      { header: 'Số tiền đã thu', key: 'amount', width: 15 },
      { header: 'Họ và tên', key: 'customerName', width: 30 },
      { header: 'Ngày sinh', key: 'birthdayString', width: 30 },
      { header: 'Số CMND/Thẻ căn cước', key: 'identity', width: 30 },
      { header: 'Ngày cấp', key: 'identityIssueDate', width: 10 },
      { header: 'Nơi cấp', key: 'identityIssueLocation', width: 10 },
      { header: 'Email', key: 'email', width: 50 },
      { header: 'Số điện thoại', key: 'phone', width: 10 },
      { header: 'Địa chỉ thường trú', key: 'residentAddress', width: 30 },
      { header: 'Địa chỉ liên lạc', key: 'contactAddress', width: 30 },
      { header: 'Phường/Xã', key: 'wardAddress', width: 30 },
      { header: 'Quận/Huyện', key: 'districtAddress', width: 30 },
      { header: 'Tỉnh/TP', key: 'provinceAddress', width: 30 },
      { header: 'Thời gian đăng ký', key: 'createdAt', width: 30 },
      { header: 'Sàn', key: 'sanName', width: 30 },
      { header: 'Đơn vị bán hàng', key: 'posName', width: 30 },
      { header: 'Tư vấn viên', key: 'employeeTakeCareName', width: 15 },
      { header: 'Tư vấn viên Email', key: 'employeeTakeCareEmail', width: 15 },
      { header: 'Tư vấn viên Phone', key: 'employeeTakeCarePhone', width: 15 },
      { header: 'Tuổi', key: 'ageYear', width: 15 },
      { header: 'Khảo sát', key: 'surveyString', width: 30 }
    ];
  }
  getHideRows() {
    return [{
      stt: 'stt',
      customerName: 'name',
      identity: 'identity',
      email: 'email',
      phone: 'phone',
      project: 'projectName',
      propertyCode: 'propertyCode',
      createdAt: 'createdAt',
      timestamp: 'createdDate',
      projectId: 'projectId',
      description: 'description',
      code: 'code',
      sanName: 'sanName',
      posName: 'posName',
      employeeTakeCareName: 'employeeTakeCareName',
    }];
  }
  getStatusTicket(refTicketCO: String, reciept: any[]) {
    if (!isNullOrUndefined(refTicketCO)) {
      return 'Đã cọc';
    }
    if (!isNullOrUndefined(reciept)) {
      const f = reciept.find(item => item.type === "REFUND" && item.status === TransactionStatusEnum.transfered)
      if (!isNullOrUndefined(f)) {
        return 'Đã hoàn';
      }
    }
    return '';
  }
  setRecieptStatus() {
    let recieptCode = '';
    let recieptStatus = '';
    let recieptCodeExcel = '';
    let recieptStatusExcel = '';
    if (this.reciept && this.reciept.length > 0) {
      for (let i = 0; i < this.reciept.length; i++) {
        const e = this.reciept[i];
        if (i !== 0) {
          recieptCode += '<br/>';
          recieptStatus += '<br/>';
          recieptCodeExcel += '\n';
          recieptStatusExcel += '\n';
        }
        recieptCode += e.code;
        recieptStatus += TransactionStatusMapping[e.status];
        recieptCodeExcel += e.code;
        recieptStatusExcel += TransactionStatusMapping[e.status];
      }
      this.recieptCode = recieptCode;
      this.recieptStatus = recieptStatus;
      this.recieptCodeExcel = recieptCodeExcel;
      this.recieptStatusExcel = recieptStatusExcel;
    }
  }
}

export class Customer {
  gender: string;
  name: string;
  birthday: string;
  onlyYear: boolean;
  birthdayYear: any;
  phone: string;
  email: string;
  identityNumber: string;
  identityIssueDate: any;
  identityIssueLocation: string;
  address: any;
  rootAddress: any;

  constructor(
    param?: any
  ) {
    this.setValue(param);
  }

  setValue(params: any) {
    if (params) {
      this.gender = params.info ? params.info.gender : '';
      this.name = params.personalInfo && params.personalInfo.name ? params.personalInfo.name.toUpperCase() : null;
      this.birthday = params.info && params.info.birthday ? params.info.birthday : null;
      this.onlyYear = params.info && params.info.onlyYear ? params.info.onlyYear : false;
      this.birthdayYear = params.info && params.info.birthdayYear ? params.info.birthdayYear : null;
      this.phone = params.personalInfo ? params.personalInfo.phone : null;
      this.email = params.personalInfo ? params.personalInfo.email : null;
      this.identityNumber = params.identities && params.identities.length > 0 ? params.identities[0].value : null;
      this.identityIssueDate = params.identities && params.identities.length > 0 ? params.identities[0].date : null;
      this.identityIssueLocation = params.identities && params.identities.length > 0 ? params.identities[0].place : null;
      this.address = params.info && params.info.address ? params.info.address : null;
      this.rootAddress = params.info && params.info.rootAddress ? params.info.rootAddress : null;
    } else {
      this.setAllNull();
    }

  }
  setAllNull() {
    this.gender = '';
    this.name = null;
    this.birthday = null;
    this.onlyYear = false;
    this.birthdayYear = null;
    this.phone = null;
    this.email = null;
    this.identityNumber = null;
    this.identityIssueDate = null;
    this.identityIssueLocation = null;
    this.address = null;
    this.rootAddress = null;
  }

}
