import { BaseModel } from "../../shared/models/base.model";
import { BaseModelInterface } from "../../shared/models/base.model.interface";
import { ValidateIf, IsNotEmpty, Length } from "class-validator";
import { Ticket } from "../ticket/ticket.model";
import { Constant } from "../../shared/constant/constant";
import { POS } from "../orgchart-pos/model/pos.model";
import { Category } from "../category/category.model";
import { Customer } from "../customer/customer.model";
import { Property } from "../property/property.model";
import { Consignment } from "../consignment/consignment.model";
import { DateNoHourTime } from "../../shared/parse/date-no-hour-time";
import { Images } from 'app/shared/components/upload-images/image.model';

export class Contract extends BaseModel implements BaseModelInterface {
  @ValidateIf(o => o.otherProperty === "")
  @IsNotEmpty()
  @Length(10, 20)
  id: string;

  @Length(10, 100)
  name: string;
  customers: Customer[];
  type: string;
  ticket: Ticket;
  pos: POS;
  posId: string;
  property: Property;
  source: string;
  version: string;
  startDate: Date;// Ngay tao
  expiredDate: Date;// Ngay het han
  expiredDateObject: any;
  printDate: Date;// Ngay in
  employeeTakeCare: object;
  employeeSales: object;
  brokerFee: number;// Phi môi giới
  brokerFeePercent: number;// phần trăm phí môi giới
  divideFee: number; // Phí ăn chia
  commissionTotal: number;
  sourceCommissionPercent: number; // Phan tram nguon
  salesCommissionPercent: number; // Phan tram sale
  sourceCommission: number; // Phí nguon
  salesCommission: number; // Phí sale
  commissionPercent: number; // phi hoa hong cua nv
  attachmentFiles: any;
  approvedBy: any;
  approvedDate: Date;
  paid: number;
  paidPercent: number;
  consignment: Consignment;
  consignmentId: string;
  amendments: any = [];
  expiredMonths: number;
  signatures: any = [];
  canApprove: boolean = false;
  isAppendix: boolean = false;
  reason: string;
  status: string;
  state: string;
  contractId: string;
  amendment: {};
  draftAmendment: any = {};
  draftAmendments: any = [];
  sendApproveBy: any = [];
  lifecycleStatus;
  dealts: any = [];
  active: boolean = true;
  originPrice: number;
  liquidateDocuments: any = []; // file thanh lý
  liquidateType: string;
  otherLiquidateReason: string;
  constructor(
    param?: any
  ) {
    super();
    this.setValue(param);
  }

  static getType() {
    return 'contract';
  }

  setValue(params: any) {
    if (params) {
      this.id = params.id || '';
      this.contractId = params.contractId || '';
      this.name = params.name || '';
      this.version = params.version || '';
      this.state = params.state || '';
      this.type = params.type || Constant.SELL_TRANSACTION;
      this.ticket = params.ticket ? new Ticket(params.ticket) : new Ticket();
      this.pos = params.pos ? new POS(params.pos) : new POS();
      this.brokerFeePercent = params.brokerFeePercent ? params.brokerFeePercent : 0;
      this.source = params.source || params.resource || 'Web Broker';
      this.approvedBy = params.approvedBy ? params.approvedBy : {};
      this.approvedDate = params.approvedDate ? new Date(params.approvedDate) : new Date();
      this.startDate = params.startDate ? new Date(params.startDate) : new Date();
      this.printDate = params.printDate;
      this.employeeTakeCare = params.employeeTakeCare || {};
      this.consignment = params.consignment ? new Consignment(params.consignment) : new Consignment();
      this.property = params.property || params.propertyUnit;
      this.property = this.property ? new Property(this.property) : new Property();
      this.customers = params.customers ? params.customers : params.propertyCustomer ? params.propertyCustomer : [params.customer];
      this.customers = this.customers && this.customers.length > 0 ? this.customers.map((customer) => {
        return new Customer(customer);
      }) : [new Customer()]
      this.consignmentId = params.consignmentId || '';
      this.amendments = params.amendments || [];
      this.attachmentFiles = params.attachmentFiles && params.attachmentFiles.zones ? new Images(params.attachmentFiles)  : new Images();
      if ((this.approvedBy && this.approvedBy.id) || (this.amendments && this.amendments.length > 0) || params.status === 'WAITING') {
        this.isAppendix = true;
      }
      this.signatures = params.signatures || [];
      this.canApprove = params.canApprove || false;
      this.originPrice = this.property.price;
      this.commissionPercent = params.commissionPercent ? params.commissionPercent : 40;
      this.sourceCommissionPercent = params.sourceCommissionPercent ? params.sourceCommissionPercent : 50;
      this.salesCommissionPercent = params.salesCommissionPercent ? params.salesCommissionPercent : 50;
      this.brokerFee = params.brokerFee ? params.brokerFee : 0;
      if (!this.brokerFee) {
        this.brokerFee = Math.floor(this.brokerFeePercent / 100 * this.property.price);
      }
      this.divideFee = Math.floor(this.brokerFee * this.commissionPercent / 100);
      this.sourceCommission = Math.floor(this.divideFee * this.sourceCommissionPercent / 100);
      this.salesCommission = Math.floor(this.divideFee * this.salesCommissionPercent / 100);
      this.expiredDate = params.expiredDate ? new Date(params.expiredDate) : new Date();
      this.expiredDate = DateNoHourTime.parse(this.expiredDate);
      this.expiredMonths = params.expiredMonths || 6;
      this.reason = params.reason || '';
      this.dealts = params.dealts || [];
      this.sendApproveBy = params.sendApproveBy || [];
      if (params.expiredDate) {
        let date = new Date(this.expiredDate);
        this.expiredDateObject = {
          day: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear()
        };
      } else {
        this.expiredDateObject = {
          day: '01',
          month: '01',
          year: '1990'
        };
      }
      this.draftAmendment = params.draftAmendment;
      this.draftAmendments = params.draftAmendments || [];
      this.active = params.active || true;
      this.lifecycleStatus = params.lifecycleStatus;
      this.liquidateDocuments = params.liquidateDocuments;
      this.liquidateType = params.liquidateType;
      this.otherLiquidateReason = params.otherLiquidateReason;
    } else {
      this.setAllNull();
    }
    super.setValue(params);
  }

  parseLifecycleStatus(lifecycleStatus) {
    // should parse from enum, not switch case text
    switch (lifecycleStatus) {
      case 'SURVEY':
        return 'Khảo sát';
      case 'TRADING':
        return 'Đang giao dịch';
      case 'DEALING':
        return 'Đã đặt chổ';
      case 'TRADED':
        return 'Đã giao dịch';
      default:
        return lifecycleStatus;
    }
  }
  setAllNull() {
    this.id = '';
    this.name = '';
    this.version = '';
    this.type = Constant.SELL_TRANSACTION;
    this.ticket = new Ticket();
    this.pos = new POS();
    this.property = new Property();
    this.source = 'Web Broker';
    this.consignmentId = '';
    this.reason = '';
    this.brokerFee = 0;
    this.brokerFeePercent = 0;
    this.approvedBy = {};
    this.approvedDate = new Date();
    this.consignment = new Consignment();
    this.amendments = [];
    this.signatures = [];
    this.expiredMonths = 6;
    this.employeeTakeCare = {};
    this.sendApproveBy = [];
    this.attachmentFiles = new Images();
    this.active = true;
  }
  createObjectRequestAPI(isAmendment?) {
    let object: any = {};
    this.id && (object.id = this.id || null);
    object.name = this.name;
    object.type = this.property.transactionType;
    object.ticket = this.ticket.createObjectReference();
    object.customers = this.customers.map((customer) => {
      return customer.createObjectRequestAPI();
    })
    object.pos = this.pos.createObjectReference();
    object.propertyUnit = this.property.createObjectRequestAPI();
    object.consignment = this.consignment.createObjectReference();
    object.consignmentId = this.consignment.id || this.consignmentId;
    object.expiredDate = this.expiredDate;
    object.startDate = this.startDate;
    object.sourceCommissionPercent = this.sourceCommissionPercent;
    object.salesCommissionPercent = this.salesCommissionPercent;
    object.brokerFeePercent = this.brokerFeePercent;
    object.brokerFee = this.brokerFee;
    object.amendments = this.amendments;
    object.version = this.version;
    object.signatures = this.signatures;
    object.expiredMonths = this.expiredMonths;
    object.employeeTakeCare = this.employeeTakeCare;
    object.status = this.status;
    object.state = this.state;
    object.contractId = this.contractId;
    object.amendment = this.amendment;
    object.draftAmendment = this.draftAmendment;
    object.active = this.active;
    object.liquidateType = this.liquidateType;


    return object;
  }
  static updateFollowAmendments(contract) {
    contract.amendments.forEach((amendment) => {
      contract = this.getLasted(contract, amendment);
    });
    return contract;
  }
  static updateAmendment(orginal, amendment) {
    amendment = this.getLasted(orginal, amendment);
    return amendment;
  }
  static getLasted(newObject, lastedObject) {
    if (!lastedObject) {
      return newObject;
    }
    if (!newObject) {
      newObject = lastedObject.length >= 0 ? [] : {};
    }

    for (let property in lastedObject) {
      if (newObject[property] instanceof Date) {
        newObject[property] = newObject[property].toString();
      }
      if (property != 'errors' && property != 'texts') {
        if (typeof newObject[property] === 'object') {
          newObject[property] = this.getLasted(newObject[property], lastedObject[property])
        } else if (newObject[property] != lastedObject[property]) {
          newObject[property] = lastedObject[property];
        }
      }
    }
    return newObject;
  }

  createObjectAmendment(amendment, root) {
    let object: any = this.compare(amendment, root, {});
    return object;
  }
  compare(newObject, oldObject, resultObject) {
    if (!oldObject) {
      return resultObject;
    }
    if (!newObject) {
      return resultObject;
    }
    let object = {}, isFind = false;
    if (oldObject instanceof Array) {
      object = [];
    }
    for (let property in oldObject) {
      if (property != 'errors' && property != 'texts') {
        if (typeof oldObject[property] === 'object') {
          let result = this.compare(newObject[property], oldObject[property], object[property]);
          if (result) {
            isFind = true;
            object[property] = result;
          }
        } else if (newObject[property] != oldObject[property] && property != 'index' && property != 'active') {
          isFind = true;
          object[property] = newObject[property];
        }
      }
    }
    if (isFind) {
      resultObject = object;
    }
    return resultObject;
  }

  static compare(newObject, oldObject) {
    if (!oldObject) {
      return newObject;
    }
    if (!newObject) {
      newObject = oldObject;
      return newObject;
    }
    for (let property in oldObject) {
      if (oldObject[property] instanceof Date) {
        oldObject[property] = oldObject[property].toString();
      }
      if (property != 'errors' && property != 'texts') {
        if (typeof oldObject[property] === 'object') {
          newObject[property] = this.compare(newObject[property], oldObject[property]);
        } else if (newObject[property] != oldObject[property]) {
          if (!newObject[property]) {
            newObject[property] = oldObject[property];
          } else {
            newObject['new' + property] = newObject[property];
            oldObject['old' + property] = oldObject[property];
          }
        }
      }
    }
    return newObject;
  }
}
