<div class="landing_page">
    <app-landing-header (openDialog)="openDialog()"></app-landing-header>
    <div class="landing_page-body" [ngSwitch]="checkDataEvent">
        <img src="../../../../assets/img/landing/abstract.svg" alt="landingAbstract">
        <div class="landing_page-wrapper" *ngSwitchCase="true">
            <div class="landing_page-content">
                <div class="header">
                    <img src="{{model.createdByUser.avatarUrl}}" width="45px" alt="info" class="vertical-bot">
                    <h1>{{model.createdByUser.name}}</h1>
                    <!-- <span>. {{}}</span> -->
                </div>

                <div class="infoGroup">
                    <div class="title">
                        <span>{{model.title}}</span>
                    </div>
                    <div class="info">
                        <span>{{model.description}}</span>
                    </div>
                </div>
                <!-- Image-event -->
                <img [src]="model.media.thumbnail" onError="this.src = './assets/img/landing/Image-event.png'" alt="content" class="img-content-cover">
                <div class="iconGroup">
                    <img src="../../../../assets/img/landing/Show.png">
                    <span>{{model.viewCount}}</span>
                    <img src="../../../../assets/img/landing/Heart1.png">
                    <span>{{model.heartCount}}</span>
                    <img src="../../../../assets/img/landing/Comment.png">
                    <span>{{model.commentCount}}</span>
                    <img src="../../../../assets/img/landing/Share.png" class="img-share">
                    <a href="{{model.media.url}}"><img src="../../../../assets/img/landing/Download.png"></a>
                </div>
                <div class="iconWatch" (click)="openDialog()">
                    <img src="../../../../assets/img/landing/Polygon 2.png">
                </div>
                <div class="btn-wrap btn-landing">
                    <button mat-raised-button (click)="openDialog()">
                        <img class="img-btn" src="../../../../assets/img/landing/login.svg" alt="action">
                        <span class="txt-btn">Đăng ký</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="landing_page-wrapper-empty" *ngSwitchCase="false" >
            <div class="landing_page-content">
                <div fxLayout="column"
                    fxLayoutAlign="start center"
                    fxLayoutGap="12px"
                >                
                    <img src="../../../../assets/img/landing/Group404.svg" alt="landingAbstract"> 
                    <div class="infoGroup" fxLayoutAlign="center center">
                        <div fxLayoutAlign="center center" fxLayoutGap="5px">
                            <span class="text-er">Xin lỗi! Bạn không thể xem được sự kiện này</span>
                        </div>                 
                    </div>   
            </div>
        </div>
    </div>
    <div class="landing_page-intro-wrapper">
        <div class="landing_page-intro" fxLayout="row" fxLayoutGap="60px">
        </div>

    </div>
    <app-landing-footer [shareUrl]="model.shareUrl"></app-landing-footer>
</div>