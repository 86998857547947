declare let $: any;
export class DateNoHourTime {
  static parse(date) {
    let dateNoTime = new Date(date);
    dateNoTime.setHours(0);
    dateNoTime.setTime(0);
    return date;
  }

  static parseDateToString(date){
    if(!date) return;
    date.replaceAll('/','-');
    let year = date.slice(6,10);
    let month = date.slice(3,5);
    let day = date.slice(0,2);
    return year + '-' + month + "-" + day;
  }
}