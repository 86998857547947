<mat-card class="custom-card">
  <div fxLayout="row" fxLayoutAlign="start center" class="title-area">
    <h1 class="title-confirm">{{ data.title }}</h1>
  </div>
  <div mat-dialog-content class="content-area">
    <div fxLayout="column" fxLayoutAlign="space-around">
      <div fxLayout="row" class="table-area">
        <div fxFlex="100" class="flex-p" class="m-table m-elevation-z8">
          <div class="m-header-row">
            <div class="m-header-cell" *ngFor="let item of data.headers">{{ item }}</div>
            <div class="m-header-cell  m-025"></div>
          </div>
          <div *ngFor="let item of data.items">
            <div class="m-row">
              <div class="m-cell" *ngFor="let value of item.values">
                  <span fxLayout="row" fxLayoutAlign="center">
                      {{ value }}
                  </span>
              </div>
              <div class="m-cell m-025" fxLayoutAlign="end center">
                <mat-radio-group aria-label="Choose" [(ngModel)]="data.chooseValue">
                  <mat-radio-button value="{{ item.id }}" ></mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <div class="action-area">
      <button class="m-bg m-bg-primary action-btn" mat-button (click)="execute()">{{ data.action }}</button>
      <button mat-button class="m-black action-btn cancel" (click)="onNoClick()">Hủy bỏ</button>
    </div>
  </div>
</mat-card>
