import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * declare Jquery
 * */
declare let $: any;

@Injectable()
export class EventChangeService {
  public emitChangeSource = new Subject<any>();
  public emitTable = new Subject<any>();
  public filterMessage: any = [];
  constructor(
  ) {

  }
  emitChange(data: any) {
    this.emitChangeSource.next(data);
  }
  emitChangeTable(data: any) {
    this.emitTable.next(data);
  }
}
