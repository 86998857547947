import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Settings } from 'app/app.settings.model';
import { AppSettings } from 'app/app.settings';
import { UserService } from 'app/shared/services/common/user.service';
import { PasswordService } from '../password.service';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: [
    'forgot-password.component.scss'
  ]
})
export class ForgotPasswordComponent {
  public form:FormGroup;
  public settings: Settings;
  data: any;
  isSend: boolean = false;
  constructor(
    public appSettings:AppSettings, 
    public fb: FormBuilder, 
    public router:Router,
    public userService: UserService,
    public passwordService: PasswordService
  ){
    this.data = {};
    this.settings = this.appSettings.settings; 
    this.form = this.fb.group({
      'email': [null, Validators.compose([Validators.required, Validators.minLength(3)])]
    });
  }

  public onSubmit(values:Object) {
    if (!this.form.valid) {
      return;
    }
    let body = {
      email: this.data.email && this.data.email.toLowerCase(),
    };
    if (this.data.email && !this.data.email.includes('@')) {
      body.email = body.email + '@gmail.com';
    }
    return this.passwordService.forgotPassword(body)
    .subscribe((res: any) => {
      this.isSend = true;
    })
  }
}