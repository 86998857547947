import {validate, Contains, IsInt, IsNumber, Length, IsEmail, IsFQDN, IsDate, Min, Max, IsBoolean, ValidateIf, IsNotEmpty} from "class-validator";
import { BaseModel } from "../../shared/models/base.model";
import { BaseModelInterface } from "../../shared/models/base.model.interface";
import { IsLongerThan } from "../../shared/decorators/IsLongerThan.decorator";
import { DefineAttribute } from "../define-attributes/define-attributes.model";
import { GeoLocation } from "./location.model";
import { MoneyToNumber } from "../../shared/parse/money-to-number";
export class InfoTrading {
    

    fakeAddress: string;

    customerOwnerId: string;

    employeeTakeCareId: string;

    employeeSalesId: string;

    posId: string;

    transactionType: string;

    brokerFee: string;

    brokerPercent: string;

    investment: string;

    salePrice: number;

    description: string;
    
    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    static getType() {
        return 'property';
    }

    setValue(params: any) {
        if (params) {
            this.fakeAddress = params.fakeAddress || '';
            this.customerOwnerId = params.customerOwner || params.customerOwnerId || '';
            this.employeeTakeCareId = params.employeeTakeCare || params.employeeTakeCareId || '';
            this.employeeSalesId = params.employeeSales || params.employeeSalesId || '';
            this.posId = params.pos || params.posId || '';
            this.transactionType = params.transactionType || '';
            this.brokerFee = params.brokerFee || '';
            this.brokerPercent = params.brokerPercent || '';
            this.investment = params.investment || '';
            this.salePrice = params.salePrice || 0;
            this.description = params.description || '';
        } else {
            this.setAllNull();
        }
    } 

    setAllNull() {
        this.fakeAddress = '';
        this.customerOwnerId = '';
        this.employeeTakeCareId = '';
        this.employeeSalesId = '';
        this.posId = '';
        this.transactionType = '';
        this.brokerFee = '';
        this.brokerPercent = '';
        this.investment = '';
        this.salePrice = 0;
        this.description =  '';
    }

    createObjectRequestAPI() {
        let object: any = {};
        object.fakeAddress = this.fakeAddress || '';
        object.customerOwnerId = this.customerOwnerId || '';
        object.employeeTakeCareId = this.employeeTakeCareId || '';
        object.employeeSalesId = this.employeeSalesId || '';
        object.pos = this.posId || '';
        object.transactionType = this.transactionType || '';
        object.brokerFee = this.brokerFee || '';
        object.brokerPercent = this.brokerPercent || '';
        object.investment = this.investment || '';
        object.salePrice = MoneyToNumber.parse(this.salePrice);
        object.description = this.description;
        return object;
    }
}
