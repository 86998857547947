import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-layout-public-footer',
  templateUrl: './layout-public-footer.component.html',
  styleUrls: ['./layout-public-footer.component.scss'],
})
export class LayoutPublicFooterComponent implements OnInit, OnDestroy {
  public destroyUnsubscribe: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    public router: Router,
  ) { }

  async ngOnInit() {
  }

  ngOnDestroy() {
    this.destroyUnsubscribe.next(null);
  }
}