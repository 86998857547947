<div fxLayout="row" fxLayoutAlign="end center" class="deal-checker-title">
  <div (click)="onNoClick()" class="button-close" >
    <mat-icon>close</mat-icon>
  </div>
</div>
<mat-card class="deal-checker">
  <div class="text-center text-header">{{ data.isSuccess ? 'XÁC NHẬN GIAO DỊCH THÀNH CÔNG' : 'GIAO DỊCH KHÔNG THÀNH CÔNG'}}</div>
  <div mat-dialog-content class="deal-checker-content">
    <p class="text-center">{{ data.isSuccess ? 'Bạn xác nhận giao dịch này đã thực hiện thành công' : 'Bạn xác nhận giao dịch này không được thực hiện'}}</p>
  </div>
  <div class="box-input">
    <input matInput placeholder="Ghi chú" *ngIf="data.notes" [(ngModel)]="notes" class="app-input">
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
    <button class="m-bg action-btn" mat-button (click)="execute()">Xác nhận</button>
  </div>
</mat-card>
