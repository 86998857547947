<mat-sidenav-container>
    <mat-sidenav #sidenav class="sidenav mat-elevation-z6" [class.small-sidenav]="smallSidenavMode"
        *ngIf="settings.menu == 'vertical'" [opened]="settings.sidenavIsOpened"
        [mode]="(settings.sidenavIsPinned) ? 'side' : 'over'">
        <app-sidenav [small]="smallSidenavMode">
            <button style="width: auto;" *ngIf="settings.menu == 'vertical'" mat-icon-button (click)="toggleSidenav()">
                <mat-icon>menu</mat-icon>
            </button>
        </app-sidenav>
    </mat-sidenav>

    <mat-sidenav-content id="main" perfectScrollbar [disabled]="settings.fixedHeader" (psScrollY)="onPsScrollY($event)">
        <mat-toolbar color="primary" class="flex-p-x">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
                <div fxLayoutAlign="center center">
                    <button *ngIf="isMenuToggleMobile" mat-icon-button (click)="toggleSidenav()">
                        <mat-icon>menu</mat-icon>
                    </button>

                    <app-breadcrumb [hidden]="router.url.includes('/message')"></app-breadcrumb>
                </div>

                <div fxLayoutAlign="end center">
                    <app-messages class="mr-10" fxShow="false" fxShow.gt-xs></app-messages>
                    <app-user-menu></app-user-menu>
                </div>
            </div>
        </mat-toolbar>

        <mat-toolbar color="primary" *ngIf="settings.menu == 'horizontal'" class="horizontal-menu flex-p-x transition-2"
            [class.sticky]="isStickyMenu" [class.fixed-top]="!settings.fixedHeader">
            <div fxLayout="row" fxLayoutAlign="center center" class="w-100">
                <app-horizontal-menu [menuParentId]="0"></app-horizontal-menu>
            </div>
        </mat-toolbar>

        <div id="main-content" class="inner-sidenav-content transition-2" perfectScrollbar
            [disabled]="!settings.fixedHeader" (psScrollY)="onPsScrollY($event)" [class.expanded]="smallSidenavMode"
            [class.horizontal-menu-hidden]="isStickyMenu">
            <!-- <app-breadcrumb [hidden]="router.url.includes('/message')"></app-breadcrumb> -->
            <router-outlet></router-outlet>

            <video id="remoteVideo" playsinline autoplay style="width: 100px" hidden="true"></video>
            <audio preload="auto" id="ringtonePlayer" playsinline="" style="width: 1px"
                src="https://dxs-o2o-static.s3-ap-southeast-1.amazonaws.com/ring-tone-ole-phone.mp3" loop=""></audio>

            <audio preload="auto" id="callWaitingPlayer" playsinline="" style="width: 1px"
                src="https://dxs-o2o-static.s3-ap-southeast-1.amazonaws.com/tones/waiting_tone.mp3" loop=""></audio>
        </div>

    </mat-sidenav-content>

    <!-- <mat-sidenav #options position="end" class="options">

        <div fxLayout="row" fxLayoutAlign="center center" class="control mat-elevation-z1">
            <h2>Settings</h2>
        </div>

        <div perfectScrollbar>

            <div fxLayout="column" class="control">
                <h4>Layout</h4>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span>Fixed header</span>
                    <mat-slide-toggle [checked]="settings.fixedHeader"
                        (change)="settings.fixedHeader = !settings.fixedHeader" labelPosition="before">
                    </mat-slide-toggle>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span>RTL</span>
                    <mat-slide-toggle [checked]="settings.rtl" (change)="settings.rtl = !settings.rtl"
                        labelPosition="before"></mat-slide-toggle>
                </div>
            </div>

            <div fxLayout="column" class="control">
                <h4>Choose menu</h4>
                <mat-radio-group [(ngModel)]="menuOption" (change)="chooseMenu()">
                    <mat-radio-button *ngFor="let menu of menus" [value]="menu">{{menu}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <div fxLayout="column" class="control">
                <h4>Choose menu type</h4>
                <mat-radio-group [(ngModel)]="menuTypeOption" (change)="chooseMenuType()">
                    <mat-radio-button *ngFor="let menuType of menuTypes" [value]="menuType">{{menuType}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div fxLayout="column" class="control">
                <h4>Choose theme skin</h4>
                <div fxLayout="row" fxLayoutAlign="space-around center">
                    <div class="skin-primary indigo-light" (click)="changeTheme('indigo-light')">
                        <div class="skin-secondary"></div>
                    </div>
                    <div class="skin-primary teal-light" (click)="changeTheme('teal-light')">
                        <div class="skin-secondary"></div>
                    </div>
                    <div class="skin-primary red-light" (click)="changeTheme('red-light')">
                        <div class="skin-secondary"></div>
                    </div>
                    <div class="skin-primary blue-dark" (click)="changeTheme('blue-dark')">
                        <div class="skin-secondary"></div>
                    </div>
                    <div class="skin-primary green-dark" (click)="changeTheme('green-dark')">
                        <div class="skin-secondary"></div>
                    </div>
                    <div class="skin-primary pink-dark" (click)="changeTheme('pink-dark')">
                        <div class="skin-secondary"></div>
                    </div>
                </div>
            </div>

            <div fxLayout="column" class="control">
                <h4>Sidenav options</h4>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span>Opened sidenav</span>
                    <mat-slide-toggle [checked]="settings.sidenavIsOpened"
                        (change)="settings.sidenavIsOpened = !settings.sidenavIsOpened" labelPosition="before">
                    </mat-slide-toggle>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span>Pinned sidenav</span>
                    <mat-slide-toggle [checked]="settings.sidenavIsPinned"
                        (change)="settings.sidenavIsPinned = !settings.sidenavIsPinned" labelPosition="before">
                    </mat-slide-toggle>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <span>Sidenav user info</span>
                    <mat-slide-toggle [checked]="settings.sidenavUserBlock"
                        (change)="settings.sidenavUserBlock = !settings.sidenavUserBlock" labelPosition="before">
                    </mat-slide-toggle>
                </div>
            </div>

        </div>

    </mat-sidenav> -->

</mat-sidenav-container>

<div class="call">
    <section id="incomming-call" class="incomming-call">
        <div class="incomming-call-info">
            <div class="wrap-info-text">
                <section class="wrap-status position-relative display-flex">
                    <div class="wrap-status-text">
                        <span class="status-text">Cuộc gọi đến</span>
                    </div>
                </section>
                <section class="wrap-info">
                    <div class="info-name"><span id="incomming-number"></span></div>
                </section>
            </div>
        </div>
        <div class="incomming-call-action" id="incomming-call-action-ring">
            <div class="wrap-btn">
                <button class="btn-action-incomming btn-round border-none" (click)="answerCall()">
                    <img src="assets/img/voice-call/answer.svg" class="icon">
                </button>

                <div class='wrap-circle' id="incomming-call-action-circle">
                    <div class='circle'></div>
                    <div class='circle'></div>
                    <div class='circle'></div>
                    <div class='circle'></div>
                    <div class='circle last'></div>
                </div>
                <button class="btn-action-incomming btn-round border-none" (click)="rejectCall()">
                    <img src="assets/img/voice-call/reject.svg" class="icon">
                </button>
            </div>
        </div>

        <div class="incomming-call-action" id="incomming-call-action-answer">
            <section class="wrap-time">
                <div class="count-time">00:00</div>
            </section>
            <button class="btn-action-incomming btn-round border-none" (click)="hangupCall()">
                <img src="assets/img/voice-call/end-call.svg" class="icon">
            </button>
        </div>
    </section>

    <section id="call-out" class="call-out">
        <div class="call-out-info">
            <div class="wrap-info-text">
                <section class="wrap-status display-flex">
                    <div class="wrap-status-text">
                        <span class="status-text">Cuộc gọi đi</span>
                    </div>
                </section>
                <section class="wrap-info">
                    <div class="info-name"><span id="call-out-number"></span></div>
                </section>
            </div>
        </div>
        <div class="call-out-action" id='call-out-action'>
            <section class="wrap-time">
                <div class="count-time">00:00</div>
            </section>
            <div class='wrap-circle' id="call-out-action-circle">
                <div class='circle'></div>
                <div class='circle'></div>
                <div class='circle'></div>
                <div class='circle'></div>
                <div class='circle last'></div>
            </div>
            <button id="call-out-action-hangup"
                class="call-out-action-hangup btn-action-incomming btn-round border-none" (click)="hangupCall()">
                <img src="assets/img/voice-call/reject.svg" class="icon" id="btn-call-out-ring">
                <img src="assets/img/voice-call/end-call.svg" class="icon" id="btn-call-out-answer">
            </button>
        </div>
    </section>
</div>