<mat-dialog-content class="h-100">
  <div fxLayout="column" fxFlex="100" fxLayoutGap="20px" fxLayoutAlign="center center">
    <span class="title">{{message}}</span>
    <div fxLayout="row" fxLayoutGap="8px">
      <button matRipple (click)="confirm()" class="app-btn app-btn-lv1 app-btn-cta-orange">
        {{btnTextConfirm}}
      </button>
      <button matRipple (click)="cancel()" class="app-btn app-btn-lv1 app-btn-grey">
        {{btnTextCancel}}
      </button>
    </div>
  </div>
</mat-dialog-content>
