import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

export interface DialogData {
  type: string;
  title: string;
  action: string;
  note: boolean;
  reason: string;
  execute: boolean;
  item: any;
}

@Component({
  selector: 'note-dialog',
  styleUrls: ['./note-dialog.scss'],
  templateUrl: 'note-dialog.html',
})
export class NoteDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NoteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(this.data);
  }

  execute() {
    this.data.note = true;
    this.data.execute = true;
    this.dialogRef.close(this.data);
  }

}
