<div [formGroup]="mainForm" fxLayout="row" fxFlex="100" fxLayoutGap="16px">
  <!-- Thông tin hợp đồng -->
  <div fxFlex="100" class="app-card">
    <div class="app-card__header">Thông tin hợp đồng</div>
    <div class="app-card__body">
      <div fxLayout="row" fxLayoutGap="20px">
        <div fxFlex="50" class="mat-elevation-z1 contract-card">
          <table class="form-table">
            <tr>
              <td class="label-cell"><span class="app-text-bold">% Phí dịch vụ</span></td>
              <td class="label-cell"><span class="app-text-bold">Phí dịch vụ</span></td>
              <td class="label-cell"><span class="app-text-bold">Thời hạn hợp đồng</span></td>
            </tr>
            <tr>
              <td>
                <div class="app-form-group">
                  <div class="app-input-unit">
                    <input class="app-input" formControlName="brokerFeePercent"  autofocus 
                      [textMask]="{mask: Constant.numberMask}" (change)="onChangeBrokerFeePercent()"/>
                    <span class="input-unit"> % </span>
                  </div>
                  <ng-container *ngIf="getFormControl('brokerFeePercent').invalid
                    && (getFormControl('brokerFeePercent').dirty || getFormControl('brokerFeePercent').touched)">
                    <span *ngIf=" getFormControl('brokerFeePercent').errors.required" class="app-error">
                      {{Constant.ERROR_REQUIRED}}
                    </span>
                    <span *ngIf="getFormControl('brokerFeePercent').errors.customMax" class="app-error">
                      Tỉ lệ phí dịch vụ không được quá 100%
                    </span>
                    <span *ngIf="getFormControl('brokerFeePercent').errors.customMin" class="app-error">
                      Phí môi giới tối thiểu là 50% giá trị cho thuê
                    </span>
                  </ng-container>
                </div>
              </td>
              <td>
                <div class="app-form-group">
                  <div class="app-input-unit">
                    <input class="app-input" formControlName="brokerFee" [textMask]="{mask: Constant.numberMask}" (change)="onChangeBrokerFee()"/>
                    <span class="input-unit"> VNĐ </span>
                  </div>
                  <ng-container *ngIf="getFormControl('brokerFee').invalid
                    && (getFormControl('brokerFee').dirty || getFormControl('brokerFee').touched)">
                    <span *ngIf="getFormControl('brokerFee').errors.required" class="app-error">
                      {{Constant.ERROR_REQUIRED}}
                    </span>
                    <span *ngIf="getFormControl('brokerFee').errors.customMin" class="app-error">
                      Phí môi giới tối thiểu là 10 triệu đồng
                    </span>
                  </ng-container>
                </div>
              </td>
              <td>
                <div class="app-form-group">
                  <div class="app-input-unit">
                    <input class="app-input" formControlName="expiredMonths" customMin="1"
                      [textMask]="{mask: Constant.numberIntMask}" />
                    <span class="input-unit"> Tháng </span>
                  </div>
                  <ng-container *ngIf="getFormControl('expiredMonths').invalid
                    && (getFormControl('expiredMonths').dirty || getFormControl('expiredMonths').touched)">
                    <span *ngIf="getFormControl('expiredMonths').errors.required" class="app-error">
                      {{Constant.ERROR_REQUIRED}}
                    </span>
                    <span *ngIf="getFormControl('expiredMonths').errors.customMin" class="app-error">
                      Vui lòng nhập giá trị lớn hơn 0
                    </span>
                  </ng-container>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div fxFlex="50" class="mat-elevation-z1 contract-card">
          <div fxLayout="column" fxFlex="100" fxLayoutAlign="space-between start">
            <span>
              <span class="app-text-bold">Số hợp đồng: </span>
              {{data.name}}
            </span>
            <span>
              <span class="app-text-bold">Ngày ký hợp đồng: </span>
              {{data.printDate ? (data.printDate | date : 'dd/MM/yyyy') : 'Chưa ký'}}
            </span>
            <span>
              <span class="app-text-bold">
                {{typeSell ? 'Giá trị sản phẩm:' : 'Giá thuê/Tháng:' }}
                 </span>
              {{data.property.price > 0 ? (data.property.price | money) + ' VNĐ' : data.property.price }}
            </span>
          </div>
        </div>
      </div>
      <span class="contract-title">Tỉ lệ chia phí môi giới ( Hoa hồng được hưởng: {{data.commissionPercent}}% ~
        {{data.divideFee | money}} VNĐ)</span>
      <div fxLayout="row">
        <table class="input-table">
          <tr>
            <td><span>Bên giới thiệu</span></td>
            <td><span>Bên môi giới</span></td>
          </tr>
          <tr>
            <td>
              <div fxLayout="row" fxLayoutGap="14px" fxLayoutAlign="start baseline" class="text-price">
                <div fxLayout="column" class="app-form-group">
                  <div class="app-input-unit">
                    <input class="app-input contract-input-rate" formControlName="sourceCommissionPercent"
                      customMax="100" [textMask]="{mask: Constant.percent}" />
                    <span class="input-unit"> % </span>
                  </div>
                  <ng-container
                    *ngIf="getFormControl('sourceCommissionPercent').invalid
                    && (getFormControl('sourceCommissionPercent').dirty || getFormControl('sourceCommissionPercent').touched)">
                    <span *ngIf="getFormControl('sourceCommissionPercent').errors.required" class="app-error">
                      {{Constant.ERROR_REQUIRED}}
                    </span>
                    <span *ngIf="getFormControl('sourceCommissionPercent').errors.customMax" class="app-error">
                      Tỉ lệ phí dịch vụ không được quá 100%
                    </span>
                  </ng-container>
                </div>
                <span>{{data.sourceCommission ? (data.sourceCommission | money) + ' VNĐ' : ''}}</span>
              </div>
            </td>
            <td>
              <div fxLayout="row" fxLayoutGap="14px" fxLayoutAlign="start baseline" class="text-price">
                <div fxLayout="column" class="app-form-group">
                  <div class="app-input-unit">
                    <input class="app-input contract-input-rate" formControlName="salesCommissionPercent" />
                    <span class="input-unit"> % </span>
                  </div>
                </div>
                <span>{{data.salesCommission ? (data.salesCommission | money) + ' VNĐ' : ''}}</span>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>