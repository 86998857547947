import { Constant } from "../../constant/constant";

export class Images {

    avatar: string;

    list: any = [];

    zones: ZoneImage[];

    videos: any = [];

    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    static getType() {
        return 'image';
    }

    setValue(params: any) {
        if (params) {
            this.avatar = params.avatar || '';
            this.list = params.list ?  params.list.map((item) => {
                return typeof item != 'string' ? new Image(item) : new Image( { url: item});
            }) : [];
            this.zones = params.zones ?  params.zones.map((zone) => {
                return new ZoneImage(zone);
            }) : [];
            this.videos = params.videos || [{
                url: ''
            }];
        } else {
            this.setAllNull();
        }
    } 
    setAllNull() {
        this.list = [];
        this.zones = [];
        this.videos = [{
            url: ''
        }];
    }
    
    validate() {
        let errors: any = {};
        if (this.list.length == 0 && !this.zones.some(zone => zone.list.length > 0)) {
            errors.isExist = true;
            errors.text = 'Hỉnh ảnh phải được nhập';
        }
        return errors;
    }

    createObjectRequestAPI() {
        let object: any = {};
        if (this.avatar) {
            object.avatar = this.avatar;
        }
        if (this.list) {
            object.list = this.list.map((item) => {
                return item.createObjectRequestAPI();
            });
        }
        if (this.zones) {
            object.zones = this.zones.map((zone) => {
                return zone.createObjectRequestAPI();
            });;
        }
        object.videos = this.videos || [];
        return object;
      }
}
export class Image {

    name: string;

    nameChange: string;

    url: string;

    text: string;

    textChange: string;

    isDisplay: boolean = false;

    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    static getType() {
        return 'image';
    }

    setValue(params: any) {
        if (params) {
            this.name = this.nameChange = params.name || '';
            this.url = params.url || '';
            this.text = this.textChange = params.text || '';
            this.isDisplay = params.isDisplay || false;
        } else {
            this.setAllNull();
        }
    } 
    setAllNull() {
        this.name = '';
        this.url = '';
        this.text = '';
    }
    createObjectRequestAPI() {
        let object: any = {};
        object.name  = this.name;
        object.url = this.url;
        object.isDisplay = this.isDisplay;
        return object;
    }
}

export class ZoneImage {

    name: string;

    nameChange: string;

    list: Image[];

    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    static getType() {
        return 'image';
    }

    setValue(params: any) {
        if (params) {
            this.name = this.nameChange = params.name || Constant.TYPE_IMAGES.icon;
            this.list = params.list ? params.list.map((item) => {
                return new Image(item);
            })
            : [];
        } else {
            this.setAllNull();
        }
    } 
    setAllNull() {
        this.name = Constant.TYPE_IMAGES.icon;
        this.list = [];
    }
    createObjectRequestAPI() {
        let object: any = {};
        object.name  = this.name;
        if (this.list) {
            object.list = this.list.map((item) => {
                return item.createObjectRequestAPI();
            });
        }
        return object;
    }
}
