<div class="landing_page">
    <app-landing-header (openDialog)="openDialog()"></app-landing-header>
    <div class="landing_page-body" [ngSwitch]="checkDataGroup">
        <img src="../../../../assets/img/landing/abstract.svg" alt="landingAbstract">
        <div class="landing_page-wrapper" *ngSwitchCase="true">
            <div class="landing_page-content">
                <h1>{{model.name}}</h1>
                <p>{{model.description ? model.description : ''}}</p>
                <img [src]="model.coverImage" onError="this.src = './assets/img/landing/Image.png'" alt="content" class="img-content-cover" ngxViewer [viewerOptions]="viewerOptions">
                <div class="infoGroup">
                    <div class="info">
                        <img src="../../../../assets/img/landing/group.svg" alt="info">
                        <span>{{model.totalMembers}}</span>
                    </div>
                    <div class="info">
                        <img src="../../../../assets/img/landing/post.svg" alt="info">
                        <span>{{model.totalPosts}}</span>
                    </div>
                    <div class="info">
                        <img src="../../../../assets/img/landing/comment1.svg" alt="info">
                        <span>{{model.totalComments}}</span>
                    </div>                    
                </div>
                <div class="btn-wrap btn-landing">
                    <button mat-raised-button (click)="openDialog()">
                        <img class="img-btn" src="../../../../assets/img/landing/login.svg" alt="action">
                        <span class="txt-btn">Tham gia nhóm</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="landing_page-wrapper-empty" *ngSwitchCase="false" >
            <div class="landing_page-content">
                <div fxLayout="column"
                    fxLayoutAlign="start center"
                    fxLayoutGap="12px"
                >                
                    <img src="../../../../assets/img/landing/Group404.svg" alt="landingAbstract"> 
                    <div class="infoGroup" fxLayoutAlign="center center">
                        <div fxLayoutAlign="center center" fxLayoutGap="5px">
                            <span class="text-er">Xin lỗi! Bạn không thể xem được nhóm này</span>
                        </div>                 
                    </div>   
            </div>
        </div>
    </div>
    <div class="landing_page-intro-wrapper">
        <div class="landing_page-intro" fxLayout="row" fxLayoutGap="60px">
            <div fxLayout="column" fxFlex="50" class="landing_page-intro-img">
                <img src="../../../../assets/img/landing/Mockup.png" alt="Mockup">
            </div>
            <div fxLayout="column" fxFlex="50" class="landing_page-intro-content">
                <div class="intro-title">
                    <h1>Với tính năng NHÓM</h1>
                    <h1 class="text-primary">chúng ta có thể</h1>
                </div>
                <div class="intro-content">
                    <p>Tăng số lượng thành viên từ những người có cùng sở thích và có thể hỗ trợ bạn duy trì một cộng đồng sôi nổi.</p>
                    <p>Nâng cao mức độ nhận biết về thị trường môi giới bất động sản bằng cách tham gia và tạo các bài viết, bình luận hoặc tương tác. Hoạt động tương tác và chuyên môn của bạn sẽ góp phần tạo ra sự tương tác tích cực.</p>
                    <p>Thông tin chi tiết về nhóm cung cấp số liệu về nhóm, chẳng hạn như lượt tương tác và số lượng thành viên.</p>
                    <p>Có thể tham gia các nhóm riêng tư hoặc công khai</p>
                </div>
                <div class="intro-button btn-landing">
                    <button mat-raised-button (click)="openDialog()">Tải ứng dụng</button>
                </div>
            </div>
        </div>
        <img src="../../../../assets/img/landing/abstract.png" alt="abstractFooter">
    </div>    
    <app-landing-footer [shareUrl]="model.shareUrl"></app-landing-footer>
</div>