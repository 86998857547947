export class ParseToNumber {
    static Parse(value: any): number {
        let result = value;
        while (isNaN(result) && result && result.includes(',')) {
            result = result.replace(',', '');
        }
        return parseFloat(result);
    }
    static RoundFloatNumber(value: any): number {
        if(isNaN(value)) return 0;
        return parseFloat(Number(value).toFixed(2));
    }
}