<div fxLayout="row" fxLayoutAlign="center">
  <h1>{{title}}</h1>
</div>
<mat-dialog-content class="h-100">
  <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
    <div>
      <img height="100" [src]="imageUrl">
    </div>
    <span class="title">{{message}}</span>
  </div>
</mat-dialog-content>