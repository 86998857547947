<mat-toolbar color="primary" [fxLayoutAlign]="(settings.menuType != 'mini') ? 'space-between center' : 'center center'"
    class="sidenav-header">
    <a *ngIf="!small" [routerLink]="routerLinkDefault" (click)="closeSubMenus()" class="logo-img">
        <img src="/assets/img/logo/logo-white.svg" class="rounded-circle user_img" >
    </a>
    <ng-content></ng-content>

    <!-- <a *ngIf="settings.menuType == 'default'" class="logo" routerLink="/" (click)="closeSubMenus()">
    </a> -->

    <!-- <svg *ngIf="settings.menuType != 'mini'" class="pin" (click)="settings.sidenavIsPinned = !settings.sidenavIsPinned">
        <path *ngIf="!settings.sidenavIsPinned" d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z" />
        <path *ngIf="settings.sidenavIsPinned"
            d="M2,5.27L3.28,4L20,20.72L18.73,22L12.8,16.07V22H11.2V16H6V14L8,12V11.27L2,5.27M16,12L18,14V16H17.82L8,6.18V4H7V2H17V4H16V12Z" />
    </svg> -->
</mat-toolbar>

<!-- <div fxLayout="column" fxLayoutAlign="center center" class="user-block transition-2"
    [class.show]="settings.sidenavUserBlock">
    <div [fxLayout]="(settings.menuType != 'default') ? 'column' : 'row'"
        [fxLayoutAlign]="(settings.menuType != 'default') ? 'center center' : 'space-around center'"
        class="user-info-wrapper">
        <img [src]="userImage" alt="user-image">
        <div class="user-info">
            <p class="name">{{user?.name || user?.email}}</p>
            <p class="name">{{roleName}}</p>
            <p class="name">{{posName | summary: '30'}}</p>
        </div>
    </div>
    <div *ngIf="settings.menuType != 'mini'" fxLayout="row" fxLayoutAlign="space-around center"
        class="w-100 muted-text">
        <button mat-icon-button routerLink="/profile">
            <mat-icon>person_outline</mat-icon>
        </button>
        <a mat-icon-button routerLink="/notification">
            <mat-icon *ngIf="notificationCount > 0" [matBadge]="notificationCount" matBadgeColor="warn" >notifications</mat-icon>
            <mat-icon *ngIf="!notificationCount" matBadgeColor="warn" >notifications</mat-icon>
        </a>
        <a mat-icon-button (click)="logout()">
            <mat-icon>power_settings_new</mat-icon>
        </a>
    </div>
</div> -->

<perfect-scrollbar class="sidenav-menu-outer" [class.user-block-show]="settings.sidenavUserBlock">
    <div id="vertical-menu">
        <app-vertical-menu [small]="small" [menuItems]="menuItems" [menuParentId]="0"></app-vertical-menu>
    </div>
</perfect-scrollbar>