<div fxLayout="row" fxLayoutAlign="center" style="margin-bottom: 50px;">
    <h1>{{data.title}}</h1>
</div>
<div mat-dialog-content style="margin-bottom: 30px;">
  <div fxLayout="row" fxLayoutAlign="space-around center">
    <p fxFlex="20">Lý do</p>
    <div fxFlex="80">
      <mat-form-field fxFlex="90" style="border: 1px solid #dcdcdc; padding: 10px;">
        <textarea matInput [(ngModel)]="data.reason" rows="4"></textarea>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions fxLayoutAlign="center" style="margin-bottom: 0px;">
  <div fxLayout="row">
    <button mat-raised-button mat-warn color="default" (click)="onNoClick()">Hủy bỏ</button>
    <button mat-raised-button color="warn" (click)="execute()" [disabled]="!data.reason">Thực hiện</button>
  </div>
</div>