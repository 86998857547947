import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

export interface ErrorData {
  title: string;
  text: string;
  reason: string;
  execute: boolean;
  errors?: any[];
}

@Component({
  selector: 'error-popup',
  templateUrl: 'error-popup.html',
})
export class ErrorPopup implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ErrorPopup>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorData) {}

  ngOnInit() {
    
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  execute() {
    this.data.execute = true;
    this.dialogRef.close(this.data);
  }
}