import moment = require('moment');

export class ProjectSetting {
  id: string;
  openingTimeSale: any;
  endTimeSale: any;
  bannerStartTime: any;
  bannerEndTime: any;
  startTime: string;
  endTime: string;
  startDate: string;
  endDate: string;
  startTimeBanner: string;
  endTimeBanner: string;
  startDateBanner: string;
  endDateBanner: string;
  timeRegister: number;
  amountRegistration: number;
  recipientsMaximum: number;
  subscriptionsMaximum: number;
  sortOrder: number;
  isRetailCustomer: boolean;
  isHot: boolean;
  salesUnit: any;
  rootPos: any;
  adviceType: number;
  paymentTypes: any;
  salesProgram: any;
  livestreamUrl: string;
  liveChatUrl: string;
  guideVideoUrl: string;
  smallBannerUrl: string;
  bigBannerUrl: string;
  spBannerUrl: string;
  eventName: string;
  eventCode: string;
  vendorCode: string;
  templateFiles: any;
  templateFileYCDCO: any;
  refundTemplateUrl: any;
  receiptTemplateUrl: any;
  unitTableTemplateUrl: any;
  confirmTime: number;
  campaignErp: string;
  prefixReceipt: string;
  templateFileHasStatus: any;
  templateFileHasType: any;
  group: any ;
  enterCustomerInfoEnable: boolean;
  enterCustomerInfoDisable: boolean;
  syncProjectRA: string;
  constructor(param?: any) {
    this.setValue(param);
  }

  setValue(params: any) {
    if (params) {
      this.id = params.id || '';
      this.openingTimeSale = params.openingTimeSale || '';
      this.endTimeSale = params.endTimeSale || '';
      this.bannerStartTime = params.bannerStartTime || '';
      this.bannerEndTime = params.bannerEndTime || '';
      this.startTime = params.startTime || '';
      this.endTime = params.endTime || '';
      this.startDate = params.startDate || '';
      this.endDate = params.endDate || '';
      this.startTimeBanner = params.startTimeBanner || '';
      this.endTimeBanner = params.endTimeBanner || '';
      this.startDateBanner = params.startDateBanner || '';
      this.endDateBanner = params.endDateBanner || '';
      this.timeRegister = params.timeRegister;
      this.amountRegistration = params.amountRegistration;
      this.recipientsMaximum = params.recipientsMaximum;
      this.subscriptionsMaximum = params.subscriptionsMaximum;
      this.sortOrder = params.sortOrder;
      this.isRetailCustomer = params.isRetailCustomer || false;
      this.isHot = params.isHot || false;
      this.salesUnit = params.salesUnit || [];
      this.rootPos = params.rootPos || null;
      this.adviceType = params.adviceType || 0;
      this.paymentTypes = params.paymentTypes || [];
      this.salesProgram = params.salesProgram || [];
      this.livestreamUrl = params.livestreamUrl || '';
      this.liveChatUrl = params.liveChatUrl || '';
      this.guideVideoUrl = params.guideVideoUrl || '';
      this.smallBannerUrl = params.smallBannerUrl || '';
      this.bigBannerUrl = params.bigBannerUrl || '';
      this.spBannerUrl = params.spBannerUrl || '';
      this.eventName = params.eventName || '';
      this.eventCode = params.eventCode || '';
      this.templateFiles = params.templateFiles || [];
      this.templateFileYCDCO = params.templateFileYCDCO || [];
      this.refundTemplateUrl = params.refundTemplateUrl || [];
      this.receiptTemplateUrl = params.receiptTemplateUrl || [];
      this.unitTableTemplateUrl = params.unitTableTemplateUrl || [];
      this.confirmTime = params.confirmTime || '';
      this.vendorCode = params.vendorCode || '';
      this.campaignErp = params.campaignErp || '';
      this.prefixReceipt = params.prefixReceipt || '';      
      this.templateFileHasStatus = params.templateFileHasStatus || [];
      this.templateFileHasType = params.templateFileHasType || [];
      this.group = params.group || {};
      this.enterCustomerInfoEnable = params.enterCustomerInfoEnable;
      this.enterCustomerInfoDisable = params.enterCustomerInfoDisable;
      this.syncProjectRA = params.syncProjectRA || '';
    } else {
      this.setAllNull();
    }
  }

  setAllNull() {
    this.id = null;
    this.openingTimeSale = null;
    this.endTimeSale = null;
    this.bannerStartTime = null;
    this.bannerEndTime = null;
    this.startTime = null;
    this.endTime = null;
    this.startDate = null;
    this.endDate = null;
    this.startTimeBanner = null;
    this.endTimeBanner = null;
    this.startDateBanner = null;
    this.endDateBanner = null;
    this.timeRegister = null;
    this.amountRegistration = null;
    this.recipientsMaximum = null;
    this.subscriptionsMaximum =  null;
    this.sortOrder =  null;
    this.isRetailCustomer = false;
    this.isHot = false;
    this.salesUnit = [];
    this.rootPos = null;
    this.adviceType = null;
    this.paymentTypes = [];
    this.salesProgram = [];
    this.livestreamUrl = null;
    this.liveChatUrl = null;
    this.guideVideoUrl = null;
    this.smallBannerUrl =  null;
    this.bigBannerUrl = null;
    this.spBannerUrl = null;
    this.eventName = null;
    this.eventCode = null;
    this.templateFiles = [];
    this.templateFileYCDCO = [];
    this.refundTemplateUrl = [];
    this.receiptTemplateUrl = [];
    this.unitTableTemplateUrl = [];
    this.confirmTime = null;
    this.vendorCode = '';
    this.campaignErp = '';
    this.prefixReceipt = '';
    this.templateFileHasStatus = [];
    this.templateFileHasType = [];
    this.group = {};
    this.syncProjectRA = '';
  }

  createObjectRequestAPI() {
    let object: any = {
      id: this.id,
      setting: {}
    };
    object.setting.openingTimeSale = (this.startDate && this.startTime) ? moment(this.startDate + ' ' + this.startTime + ':00', 'YYYY-MM-DD HH:mm:ss').valueOf() : '';
    object.setting.endTimeSale = (this.endDate && this.endTime) ? moment(this.endDate + ' ' + this.endTime + ':00', 'YYYY-MM-DD HH:mm:ss').valueOf() : '';
    object.setting.bannerStartTime = (this.startDateBanner && this.startTimeBanner) ? moment(this.startDateBanner + ' ' + this.startTimeBanner + ':00', 'YYYY-MM-DD HH:mm:ss').valueOf() : '';
    object.setting.bannerEndTime = (this.endDateBanner && this.endTimeBanner) ? moment(this.endDateBanner + ' ' + this.endTimeBanner + ':00', 'YYYY-MM-DD HH:mm:ss').valueOf() : '';
    object.setting.timeRegister = this.timeRegister ? parseInt(this.timeRegister.toString().split(',').join('')) : '';
    object.setting.amountRegistration = this.amountRegistration ? parseInt(this.amountRegistration.toString().split(',').join('')) : '';
    object.setting.recipientsMaximum = this.recipientsMaximum ? parseInt(this.recipientsMaximum.toString().split(',').join('')) : '';
    object.setting.subscriptionsMaximum = this.subscriptionsMaximum ? parseInt(this.subscriptionsMaximum.toString().split(',').join('')) : '';
    object.setting.sortOrder = this.sortOrder ? parseInt(this.sortOrder.toString().split(',').join('')) : '';
    object.setting.isRetailCustomer = this.isRetailCustomer;
    object.setting.isHot = this.isHot;
    object.setting.rootPos = this.rootPos;
    object.setting.salesUnit = this.salesUnit;
    // object.setting.salesUnit = []; // temp hide
    object.setting.livestreamUrl = this.livestreamUrl;
    object.setting.liveChatUrl = this.liveChatUrl;
    object.setting.guideVideoUrl = this.guideVideoUrl;
    object.setting.smallBannerUrl = this.smallBannerUrl;
    object.setting.bigBannerUrl = this.bigBannerUrl;
    object.setting.spBannerUrl = this.spBannerUrl;
    object.setting.eventName = this.eventName;
    object.setting.eventCode = this.eventCode;
    object.setting.adviceType = this.isRetailCustomer ? this.adviceType : null;
    object.setting.paymentTypes = this.paymentTypes;
    object.setting.templateFiles = this.templateFiles;
    object.setting.templateFileYCDCO = this.templateFileYCDCO;
    object.setting.refundTemplateUrl = this.refundTemplateUrl;
    object.setting.receiptTemplateUrl = this.receiptTemplateUrl;
    object.setting.unitTableTemplateUrl = this.unitTableTemplateUrl;
    object.setting.confirmTime = this.confirmTime;
    object.setting.vendorCode = this.vendorCode;
    object.setting.campaignErp = this.campaignErp;
    object.setting.prefixReceipt = this.prefixReceipt;
    object.setting.templateFileHasStatus = this.templateFileHasStatus;
    object.setting.templateFileHasType = this.templateFileHasType;
    object.setting.salesProgram = this.salesProgram;
    object.setting.group = this.group;
    object.setting.enterCustomerInfoEnable = this.enterCustomerInfoEnable;
    object.setting.enterCustomerInfoDisable = this.enterCustomerInfoDisable;
    object.setting.syncProjectRA = this.syncProjectRA;
    return object;
  }
}
