import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';
import { StorageService, SecurityService } from '../../../shared/services';
import { EventChangeService } from 'app/shared/services/event.change.service';
import { NotificationService } from 'app/shared/services/notification.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { RouterLinkDefault } from '../menu/menu';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // providers: [ MenuService ]
})
export class SidenavComponent implements OnInit, OnDestroy {

  public routerLinkDefault = RouterLinkDefault;
  public userImage = '../assets/img/users/icon_avatar.svg';
  public menuItems: Array<any>;
  public settings: Settings;
  user: any;
  posName: string;
  roleName: string;
  notificationCount: number;
  eventSubscribe: any;
  selectedIds: string[] = [];
  checkClass$ = new Subject();

  @Input() small: boolean = false;

  constructor(
    public appSettings:AppSettings, 
    public menuService:MenuService, 
    public storageService: StorageService,
    public securityService: SecurityService,
    public eventChangeService: EventChangeService,
    private notificationService: NotificationService,
    private router: Router){
      this.settings = this.appSettings.settings;
  }

  ngOnInit() {


    this.notificationService.notifications$.subscribe(
      (notifications) => {
        let count = 0;
        notifications.forEach((item) => {
          !item.isRead && count++;
        })
        this.notificationCount = count;
      }
    );
    this.menuItems = this.menuService.getVerticalMenuItems();
    this.user = {};
    this.user.email = this.storageService.retrieve('email') ? this.storageService.retrieve('email').split('@')[0] : 'N/A';
    let userInfo = this.storageService.retrieve('user-profile'); 
    if (userInfo) {
        this.user = {
            name:  userInfo.name || '',
            phone: userInfo.phone || '',
            avatar: userInfo.avatar || '',
            email:  userInfo.email,
            images: userInfo.images
      }
      let roleName = '';
      if (userInfo.pos) {
          if (userInfo.pos.type === 'POS') {
              if (userInfo.managerAt !== null) {
                roleName = 'Quản lý';
              } else {
                roleName = 'Nhân Viên';
              }
          } else if (userInfo.pos.type === 'SAN' || userInfo.pos.type === 'ORG')  {
              if (userInfo.managerAt !== null) {
                roleName = 'Giám đốc';
              } else {
                roleName = 'Nhân Viên';
              }
          }
      }
      this.roleName = roleName;
      this.posName = userInfo.pos ? userInfo.pos.name : '';
    }
    this.eventChangeService.emitChange({
        broadcast: 'update-profile',
    });
    this.eventSubscribe = this.eventChangeService.emitChangeSource.subscribe((data) => {
      switch (data.broadcast) {
          case 'update-profile':
            try {
              const userProfile = this.storageService.retrieve('user-profile');
              if (userProfile) {
                  this.user = {
                      name:  userProfile.name || '',
                      phone: userProfile.phone || '',
                      avatar: userProfile.avatar || '',
                      email:  userProfile.email ? userProfile.email.split('@')[0]  :  '',
                      images: userProfile.images
                  }
              }
            } catch (error) {
              console.log('SidenavComponent', error);
            }
          break;
      }
    });
    this.checkClass$
    .debounceTime(1000)
    .subscribe(res => this.checkClass());
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkClass$.next(true);
      }
    });
    this.checkClass$.next(true);
  }
  checkClass() {
    this.selectedIds.forEach((id) => {
      $('#menu-item-' + id).removeClass('parent-menu-active');
    })
    this.selectedIds = [];
    $('.sub-menu').each((index, item) => {
      let id = item.id.split('sub-menu-')[1];
      let childNodes = item.childNodes, isTagA = false, isActiveLink = false;
      childNodes[0].childNodes.forEach((node: any) => {
        node.childNodes.forEach((childNode) => {
          if (childNode.localName === 'div' ) {
            childNode.childNodes.forEach((childchild) => {
              if (childchild.localName === 'a' && !isTagA) {
                isTagA = true;
              }
              if (childchild.classList) {
                let find = false;
                childchild.classList.forEach((item) => {
                  find = item === 'active-link';
                });
                isActiveLink = isActiveLink || find;
              }
            })
          }
        })
      });
      if (!isTagA) {
        $('#menu-' + id).addClass('hidden');
      } 
      if (isActiveLink) {
        this.selectedIds.push(id);
        $('#menu-item-' + id).addClass('parent-menu-active');
      }
    });
  }
  ngOnDestroy() {
    this.eventSubscribe.unsubscribe();
  }
  public closeSubMenus(){
    let menu = document.getElementById("vertical-menu");
    if(menu){
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if(child){
          if(child.children[0].classList.contains('expanded')){
              child.children[0].classList.remove('expanded');
              child.children[1].classList.remove('show');
          }
        }
      }
    }
  }
  logout() {
    this.securityService.logout();
  }


}
