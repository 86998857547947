import { Injectable } from "@angular/core";
import { BaseService } from "../../shared/components/base.service";
// import { ConstantUrl } from "app/shared/constant/url";
// import { GroupPostModel } from "app/pages/group-post/group-post.models";
import { DataService } from "../../shared/services";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { ConstantUrl } from "../../shared/constant/url";
// import { GroupModel, GroupReportModel } from "./landing-page.model";

@Injectable()
export class LandingPageService extends BaseService {

  constructor(
    public service: DataService,
  ) {
    super(service);
    this.domainUrl = ConstantUrl.url_social_domain + 'admin/group';
    this.queryUrl = ConstantUrl.url_social_query + 'public/group';
  }

  getGroupById(id: string) {
    const url = `${this.queryUrl}/${id}`;
    return this.service.get(url);
  }

  getEventById(id: string) {
    const url = `${ConstantUrl.url_news_query}mobile-public/event/getDetail/${id}`;
    return this.service.get(url);
  }
  getEsalekitById(id: string) {
    const url = `${ConstantUrl.url_property}public/esalekit/${id}`;
    return this.service.get(url);
  }
  getGroupPost(id: string) {
    const url = `${ConstantUrl.url_social_query}public/groupPost/${id}`;
    return this.service.get(url);
  }

  getShareLink(phone: string) {
    const url = ConstantUrl.url_employee_query + 'employee/pub/getShareLink/' + phone;
    return this.service.get(url);
  }

  getSocialPostById(id: string) {
    const url = `${ConstantUrl.url_social_query}public/post/${id}`;
    return this.service.get(url);
  }
  getVideo(id: string) {
    const url = `${ConstantUrl.url_news}public/video-store/${id}`;
    return this.service.get(url);
  }
}
