import { Params } from "@angular/router";
import { DateToFormat } from "app/shared/parse/date-to-format";

export enum StatusEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum CampaignStatusEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum WalletTransactionType {
    MAIN = 'MAIN',
    PROMOTION = 'PROMOTION',
    EXTERNAL_PAY = 'EXTERNAL_PAY',
    GIFT = 'GIFT',
    CASH_PROMOTION = 'CASH_PROMOTION',
    CASH = 'CASH',
}

export enum CampaignTypeEnum {
    INTRODUCER = 'INTRODUCER',
    BANNER = 'BANNER',
    PROMOTIONS = 'PROMOTIONS', // Thêm value ưu đãi cho loại campaign
}

export enum CampaignTypeTextEnum {
    INTRODUCER = 'Giới thiệu',
    BANNER = 'Banner',
    PROMOTIONS = 'Ưu đãi',
}

export enum BonusTypeEnum {
    PERCENT = 'PERCENT',
    CASH = 'CASH',
}

export class CampaignModel {
    stt: number;
    id: string;
    name: string;
    code?: string;
    campaignType?: string;
    introducerBonus?: number;
    bonus?: number;
    bonusType?: string;
    limit?: number;
    fromDate?: string;
    toDate?: string;
    status: string;
    showPopup: string;
    receivedDate?: string
    destinationType: string;
    campaign: any;
    startTime: string;
    endTime: string;
    constructor(param?: any) {
        this.setValue(param);
    }


    setValue(params: any) {
        if (params) {
            this.id = params.id || '';
            this.name = params.name || '';
            this.code = params.code || '';
            this.campaignType = params.campaignType || '';
            this.introducerBonus = params.introducerBonus || '';
            this.bonus = params.bonus || null;
            this.bonusType = params.bonusType || '';
            this.limit = params.limit;
            this.fromDate = params.fromDate ? params.fromDate : '';
            this.toDate = params.toDate ? params.toDate : '';
            this.status = params.status || '';
            this.showPopup = params.showPopup || '';
            this.receivedDate = params.receivedDate || '';
            this.destinationType = params.destinationType || '';
            this.campaign = params.campaign || null;
            this.startTime = params.startTime || '';
            this.endTime = params.endTime || '';
        } else {
            this.setAllNull();
        }
    }

    setAllNull() {
        this.id = null;
    }

    createDataForList() {
        let object: any = Object.assign({}, this);
        object.fromDate =  object.fromDate ? DateToFormat.parseDate(object.fromDate, 'dd/MM/yyyy') : '';
        object.toDate =  object.toDate ? DateToFormat.parseDate(object.toDate, 'dd/MM/yyyy') : '';
        object.receivedDate = object.receivedDate ?  DateToFormat.parseDate(object.receivedDate, 'dd/MM/yyyy') : '';
        return object;
    }
}

export class UserRegisteredCampaignModel {
    id: string;
    registeredDate: string;
    name: string;
    phone: string;
    email: string;
    role: string;

    constructor(param?: any) {
        this.setValue(param);
    }

    setValue(params: any) {
        if (params) {
            this.id = params.id || '';
            this.registeredDate = params.registeredDate ? DateToFormat.parseDate(params.registeredDate, 'dd/MM/yyyy') : '';
            this.name = params.name || '';
            this.phone = params.phone || '';
            this.email = params.email || '';
            this.role = params.role || '';
        } else {
            this.setAllNull();
        }
    }

    setAllNull() {
        this.id = null;
    }
}
export class UserCampaignIntroducerModel {
    stt: number;
    id: string;
    employee: any;
    totalIntroducer: number;
    totalVerify: number;
    totalValid: number;

    constructor(param?: any) {
        this.setValue(param);
    }

    setValue(params: any) {
        if (params) {
            this.stt = params.stt || '';
            this.id = params.id || '';
            this.employee = params.employee || null;
            this.totalIntroducer = params.totalIntroducer || 0;
            this.totalVerify = params.totalVerify || 0;
            this.totalValid = params.totalValid || 0;
            // this.amountIntroducer = params.amountIntroducer || 0;
        } else {
            this.setAllNull();
        }
    }

    setAllNull() {
        this.id = null;
    }
}
export class UserCampaignIntroducerDetailModel {
    id: string;
    employee: any;
    detailVerify: any;

    constructor(param?: any) {
        this.setValue(param);
    }

    setValue(params: any) {
        if (params) {
            this.id = params.id || '';
            this.employee = params.employee || null;
            this.detailVerify = params.detailVerify || [];
        } else {
            this.setAllNull();
        }
    }

    setAllNull() {
        this.id = null;
    }
}

export class CampaignInTroducereModel {
    stt: number;
    referralNumber: number; // Số người giới thiệu
    amountForReferrer: number; // Số tiền người giới thiệu nhận
    referrerWalletType: string;; // Loại ví nhận tiền của người giới thiệu
    amountForUser: number; // Số tiền người được giới thiệu nhận
    userWalletType: string;
    isCycle: boolean;

    constructor(params?: any){
        this.referralNumber =  params && params.referralNumber ? params.referralNumber : 0;
        this.amountForReferrer =  params && params.amountForReferrer ? params.amountForReferrer : 0;
        this.referrerWalletType = params && params.referrerWalletType ? params.referrerWalletType : null;
        this.amountForUser = params && params.amountForUser ? params.amountForUser : 0;
        this.userWalletType = params && params.userWalletType ? params.userWalletType : null;
        this.isCycle = params && params.isCycle ? params.isCycle : null;
    }
    createDataForList() {
        let object: any = Object.assign({}, this);
        return object;
    }
}
export class CampaignBugdetModel {
    id: string;
    amountOfBudget: number; // Số người giới thiệu
    referralMilestone: [];

    constructor(params?: any){
        this.id = params && params.id ? params.id : '';
        this.amountOfBudget =  params && params.amountOfBudget ? params.amountOfBudget : 0;
        this.referralMilestone =  params && params.referralMilestone ? params.referralMilestone : [];
    }
    createDataForList() {
        let object: any = Object.assign({}, this);
        return object;
    }
}
export enum CampaignDestiontionEnum {
    NEWS = 'NEWS', // Tin tức
    EVENT = 'EVENT', // Sự kiện
    LINK = 'LINK', // LINK
    MISSION = 'MISSION', // nhiệm vụ
    CAMPAIGN_PROMOTION = 'CAMPAIGN_PROMOTION', // Campaign ưu đãi
    CAMPAIGN_INTRODUCER = 'CAMPAIGN_INTRODUCER', // Campaign giới thiệu
    GROUP = 'GROUP', // Nhóm
    SURVEY = 'SURVEY', // Khảo sát
    LUCKY_MONEY= 'LUCKY_MONEY', // Game: mở lì xì
    PROPSKILL = 'PROPSKILL', // Propskill
}
