export const environment = {
  production: true,
  urlBackEnd: 'https://spx-api.nws-dev.com/',
  urlRABackEnd: 'https://apistaging.realagent.vn/',
  brokerURL: 'https://devdxres.datxanhres.com.vn/',
  homePageURL: 'https://propcom.vn/',
  userPageURL:'https://spx-user.nws-dev.com/',
  forbiddenPageURL: '/forbidden',
  serverIP: 'datxanhres.com.vn',
  serverPort: '69',
  chatServerPort: '3051',
  rabbitmq: {
    ws: {
      url: 'wss://spx-wss.nws-dev.com/ws',
      host: '/',
      port: '56174',
      username: 'dxsrabb1tmq',
      password: 'dxsrabb1tmq',
      login: 'dxsrabb1tmq',
      passcode: 'dxsrabb1tmq',
    }
  },
  asset: '',
  capcha: '',
  googleMap: {
    apiKey: 'AIzaSyDe_oVpi9eRSN99G4o6TwVjJbFBNr58NxE'
  },
  voiceCall: {
    url: 'https://dx-icc-api.stringee.com/',
    uriAPI: 'https://dx-api.stringee.com/',
    phone: '842899959798',
  },
  stringChat: {
    liveChatUrl: 'https://dx-v2.stringee.com/stringeexchat/sdk/staging/StringeeXChatBox-2.0.2.js?key=dWtmTS9XVXZvZGhzRHpmcG84VzhlUT09&showBot=1',
    itSupportQueue: 'QU139X25'
  },
  urlStringeeUploadFile: 'https://dx-api.stringee.com/v1/file/upload?uploadType=multipart',
  urlErp: 'https://erp.datxanhres.com.vn/',
  campaignErp: [
    { '200 Triệu': 'CTBH_ABC' },
    { '50 Triệu': 'SGT' },
  ],
  releaseDate: '20200828',
  liveChatEmail: 'it.dxs@datxanh.com.vn',
  headersClient: {
    clientId: 'SPX',
    clientSecret: 'SXhxQ1RGODBZYWhqSjl6eUtYV2lGbzdEMEhlV2dmSkg='
  },
  fileSizeUpload: '30', 
  syncRA: {
    email: 'gds19@o2o.test',
    password: '12345678',
    projectGSWId: '',
    projectGSWCode: 'GSW',
    saleProgramGSWId: '',
  },
  xClientCode: 'PROPCOM',
  stringeeCallOutLead: [
    {
      clientId: 'SPX',
      stringeeMobiCallOutNumber: ['842899959798', '842899959798'],
      stringeeVinaCallOutNumber: ['842899959798', '842899959798'],
      stringeeViettelCallOutNumber: ['842899959798', '842899959798']
    }
  ]
};
