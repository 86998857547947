import {validate, Contains, IsInt, IsNumber, Length, IsEmail, IsFQDN, IsDate, Min, Max, IsBoolean, ValidateIf, IsNotEmpty} from "class-validator";
import { BaseModel } from "../../shared/models/base.model";
import { BaseModelInterface } from "../../shared/models/base.model.interface";
import { IsLongerThan } from "../../shared/decorators/IsLongerThan.decorator";
import { Constant } from "../../shared/constant/constant";

export class DefineAttribute extends BaseModel implements BaseModelInterface {
    
    @ValidateIf(o => o.otherProperty === "")
    @IsNotEmpty()
    id: string;

    @IsNotEmpty()
    attributeId: string;

    @IsNotEmpty()
    attributeName: string;

    class: string;

    value: any;

    from: any;

    to: any;

    weight: number;
    
    textPrompt: string;

    @IsNotEmpty()
    groupName: string;

    groupOrder: number;

    @IsBoolean()
    isRequired: boolean;

    @IsBoolean()
    isDisplay: boolean;

    @IsNotEmpty()
    controlType: string;

    displayOrder: number;

    dataInList: any;

    config: any;

    list: any = [];
    
    constructor(
        param?: any
    ) {
        super();
        this.setValue(param);
    }

    static getType() {
        return 'DefineAttributes';
    }

    setValue(params: any) {
        if (params) {
            this.id = params.id || '';
            this.attributeId = params.attributeId || params.id || '';
            this.attributeName = params.attributeName || params.name || '';
            this.textPrompt = params.textPrompt || '';
            this.config = params.config || {
                step: 5
            };
            this.class = params.class || '';
            this.controlType = params.controlType || Constant.CONTROL_TYPES_OBJECT.text;
            this.displayOrder = params.displayOrder || 0;
            this.groupName = params.groupName || 'Nhóm mới';
            this.groupOrder = params.groupOrder || 1;
            this.isRequired = params.isRequired || false;
            this.isDisplay = params.isDisplay || false;
            this.value = params.value || '';
            this.from = params.from || 0;
            this.to = params.to || 100;
            this.weight = params.weight || 0;
            this.dataInList = {
                key: '',
                value: ''
            };
            this.list = [];
            if (params.list) {
                params.list.forEach((item) => {
                    if (item.key && item.value) {
                        this.list.push(item);
                    }
                })
            };
        } else {
            this.setAllNull();
        }
        super.setValue(params);
    } 

    setAllNull() {
        this.id = '';
        this.attributeId = '';
        this.attributeName = '';
        this.textPrompt = '';
        this.controlType = Constant.CONTROL_TYPES_OBJECT.text;
        this.displayOrder = 0;
        this.isRequired = false;
        this.weight = 0;
        this.isDisplay = false;
        this.groupName = 'Nhóm mới ';
        this.groupOrder =  1;
        this.value = '';
        this.from = 0;
        this.to = 100;
        this.config = {
            step: 5
        };
        this.dataInList = {
            key: '',
            value: ''
        }
        this.list = [];
    }

    createObjectRequestAPI() {
        let object: any = {};
        this.id && (object.id =  this.id || '');
        object.attributeId = this.attributeId || '';
        object.attributeName = this.attributeName || '';
        object.textPrompt = this.textPrompt || '';
        object.controlType = this.controlType || '';
        object.displayOrder = this.displayOrder || 0;
        object.isRequired = this.isRequired || false;
        object.groupName = this.groupName || 'Nhóm mới';
        object.groupOrder = this.groupOrder || 1;
        object.value = this.value || '';
        object.class = this.class || '';
        object.from = this.from || 0;
        object.to = this.to || 0;
        if (this.weight) {
            object.weight = this.weight;
        }
        if (this.isDisplay) {
            object.isDisplay = this.isDisplay;
        }
        if (this.config) {
            this.config.floor = this.from;
            this.config.ceil = this.to;
            object.config = this.config;
        }
        if (this.list.length > 0) {
            object.list = this.list;
        }
        return object;
    }

    setValueOfAttributeName(attributeName, value){
        if(this.attributeName === attributeName){
            if (this.list.length > 0) {
                this.list.forEach(i => {
                    if (i.value === value) {
                        this.value = i.key;
                    }
                })
            } else {
                this.value = value;
            }
        }
    }
}
