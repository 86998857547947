export class NotificationMsg {
    id: string;
    sender: UserInfo;
    belongTo: UserInfo;
    msg: Message;
    isRead: boolean;
    createdAt: Date;
    readAt: Date;
    url: string;

    constructor(
        param?: any
    ) {
        this.setValue(param);
    }

    static getType() {
        return 'notification';
    }

    setValue(params: any) {
        if(!params) params = {};
        this.id = params.id || '';
        this.sender = params.sender || new UserInfo();
        this.belongTo = params.belongTo || new UserInfo();
        this.msg = params.msg || new Message();
        this.isRead = !!params.isRead;
        this.createdAt = params.createdAt || new Date();
        this.readAt = params.readAt || null;
        this.url = params.url || '';
    }
}

export class UserInfo {
    constructor(
        public id = '',
        public email = ''
    ) {}
}

export class Message {
    constructor(
        public title = '',
		public content = '',
		public entityName = '',
		public entityId = ''
    ) {}
}