import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { Constant } from 'app/shared/constant/constant';

@Component({
  selector: 'app-dialog-image-cropper',
  templateUrl: './dialog-image-cropper.component.html',
  styleUrls: ['./dialog-image-cropper.component.scss']
})
export class DialogImageCropperComponent implements OnInit {
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  Constant = Constant;
  imageFile: any = null;
  croppedImage: string = null;
  isLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogImageCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.isLoading = true;
    this.imageFile = this.data.file;
  }

  onNoClick(): void {
    this.imageFile = null;
    this.dialogRef.close();
  }

  async cropImage() {
    const image: ImageCroppedEvent = await this.imageCropper.crop();
    this.dialogRef.close({
      data: image.file
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
    this.isLoading = false;
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

}
