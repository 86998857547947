import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { SaleList } from '../sale-list.model';

@Component({
  selector: 'app-popup-confirm-register',
  styleUrls: ['./popup-confirm-register.component.scss'],
  templateUrl: './popup-confirm-register.component.html',
})
export class PopupConfirmReturn implements OnInit  {
  public item: SaleList;
  constructor(
    public dialogRef: MatDialogRef<PopupConfirmReturn>,
    @Inject(MAT_DIALOG_DATA) public data,
    ) {
        this.item = this.data.data;
    }

  ngOnInit() {
  }

  execute(): void {
    let data = {isSuccess: true,item: this.item};
    this.dialogRef.close(data);
  }

  onNoClick(): void {
    let data = {isSuccess: false, item: this.item};
    this.dialogRef.close(data);
  }
}
