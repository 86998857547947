import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

export interface  SuccessData {
  title: string;
  text: string;
  reason: string;
  execute: boolean;
}

@Component({
  selector: 'success-popup',
  templateUrl: 'success-popup.html',
})
export class SuccessPopup implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SuccessPopup>,
    @Inject(MAT_DIALOG_DATA) public data:  SuccessData) {}

  ngOnInit() {
    
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  execute() {
    this.data.execute = true;
    this.dialogRef.close(this.data);
  }
}