import { BaseModel } from "../../shared/models/base.model";
import { BaseModelInterface } from "../../shared/models/base.model.interface";
import { ValidateIf, IsNotEmpty, Length } from "class-validator";

export class Category extends BaseModel implements BaseModelInterface  {
    id: string;

    @IsNotEmpty()
    name: string;

    childrens: any = [];

    parentId: string;

    parentName: string;

    constructor(
        param?: any
    ) {
        super();
        this.setValue(param);
    }

    static getType() {
        return 'category';
    }

    setValue(params: any) {
        super.setValue(params);
        if (params) {
            this.id = params.id || '';
            this.name = params.name || '';
            this.parentId = params.parentId || '';
            
            this.childrens = params.childrens ? params.childrens.map((item) => {
                return new Category(item);
            }) : []
        } else {
            this.setAllNull();
        }
        this.setTexts();
    } 

    setAllNull() {
        this.id = '';
        this.name = '';
        this.parentId = '';
        this.childrens = [];
    }
    createObjectRequestAPI() {
        let object: any = {};
        this.id && (object.id =  this.id || null);
        object.name = this.name;
        object.active = this.active;
        object.parentId = this.parentId;
        object.images = this.images;
        return object;
    }
    createObjectReference() {
        let object: any = {};
        this.id && (object.id =  this.id || null);
        object.name = this.name;
        return object;
    }
}
