import { environment } from '../../../environments/environment';
export class ConstantUrl {
    public static url_sts_query = environment.urlBackEnd + 'spx-sts/api/query/v1/';
    public static url_sts_domain = environment.urlBackEnd + 'spx-sts/api/domain/v1/';
    public static url_sts = environment.urlBackEnd + 'spx-sts/api/v1/'
    // public static url_sts_query = 'http://localhost:3200/api/query/v1/';
    // public static url_sts_domain = 'http://localhost:3200/api/domain/v1/';
    // public static url_sts = 'http://localhost:3200/api/v1/'

    public static url_category_query = environment.urlBackEnd + 'spx-category/api/query/v1/';
    public static url_category_domain = environment.urlBackEnd + 'spx-category/api/domain/v1/';
    public static url_zone_query = environment.urlBackEnd + 'spx-zone/api/query/v1/';
    public static url_zone_domain = environment.urlBackEnd + 'spx-zone/api/domain/v1/';
    public static url_campaign_query = environment.urlBackEnd + 'spx-campaign/api/query/v1/';
    public static url_campaign_domain = environment.urlBackEnd + 'spx-campaign/api/domain/v1/';
    public static url_customer_query = environment.urlBackEnd + 'spx-customer/api/query/v1/';
    public static url_customer_domain = environment.urlBackEnd + 'spx-customer/api/domain/v1/';
    public static url_employee_query = environment.urlBackEnd + 'spx-employee/api/query/v1/';
    public static url_employee_domain = environment.urlBackEnd + 'spx-employee/api/domain/v1/';
    public static url_employee = environment.urlBackEnd + 'spx-employee/api/v1/'
    // public static url_employee_query = 'http://localhost:3037/api/query/v1/';
    // public static url_employee_domain = 'http://localhost:3037/api/domain/v1/';
    // public static url_employee = 'http://localhost:3037/api/v1/'

    public static url_report_query = environment.urlBackEnd + 'spx-dxs-report/api/query/v1/';
    public static url_report_domain = environment.urlBackEnd + 'spx-dxs-report/api/domain/v1/';
    public static url_realtime_query = environment.urlBackEnd + 'spx-realtime/api/query/v1/';
    public static url_realtime_domain = environment.urlBackEnd + 'spx-realtime/api/domain/v1/';
    public static url_agency_query = environment.urlBackEnd + 'spx-agency/api/query/v1/';
    public static url_agency_domain = environment.urlBackEnd + 'spx-agency/api/domain/v1/';
    public static url_profile_query = environment.urlBackEnd + 'spx-profile/api/query/v1/';
    public static url_profile_domain = environment.urlBackEnd + 'spx-profile/api/domain/v1/';
    public static url_contract_query = environment.urlBackEnd + 'spx-contract/api/query/v1/';
    public static url_contract_domain = environment.urlBackEnd + 'spx-contract/api/domain/v1/';
    // public static url_transaction_query = 'http://localhost:3046/api/query/v1/';
    // public static url_transaction_domain = 'http://localhost:3046/api/domain/v1/';
    public static url_transaction_query = environment.urlBackEnd + 'spx-transaction/api/query/v1/';
    public static url_transaction_domain = environment.urlBackEnd + 'spx-transaction/api/domain/v1/';
    public static url_product_query = environment.urlBackEnd + 'spx-product/api/query/v1/';
    public static url_product_domain = environment.urlBackEnd + 'spx-product/api/domain/v1/';
    // public static url_property_query = 'http://localhost:3040/api/query/v1/';
    // public static url_property_domain = 'http://localhost:3040/api/domain/v1/';
    public static url_property_query = environment.urlBackEnd + 'spx-property/api/query/v1/';
    public static url_property_domain = environment.urlBackEnd + 'spx-property/api/domain/v1/';
    public static url_pos_query = environment.urlBackEnd + 'spx-pos/api/query/v1/';
    public static url_pos_domain = environment.urlBackEnd + 'spx-pos/api/domain/v1/';
    // public static url_ticket_query = 'http://localhost:3052/' + 'api/query/v1/';
    // public static url_ticket_domain ='http://localhost:3052/' + 'api/domain/v1/';
    // public static url_ticket =  'http://localhost:3052/api/v1/';
    public static url_ticket = environment.urlBackEnd + 'spx-lead/api/v1/';
    public static url_ticket_query = environment.urlBackEnd + 'spx-lead/api/query/v1/';
    public static url_ticket_domain = environment.urlBackEnd + 'spx-lead/api/domain/v1/';
    public static url_demand_query = environment.urlBackEnd + 'spx-demand/api/query/v1/';
    public static url_demand_domain = environment.urlBackEnd + 'spx-demand/api/domain/v1/';
    public static url_matching_query = environment.urlBackEnd + 'spx-matching/api/query/v1/';
    public static url_matching_domain = environment.urlBackEnd + 'spx-matching/api/domain/v1/';
    public static url_consignment_query = environment.urlBackEnd + 'spx-consignment/api/query/v1/';
    public static url_consignment_domain = environment.urlBackEnd + 'spx-consignment/api/domain/v1/';
    public static url_orgchart = environment.urlBackEnd + 'spx-orgchart/api/v1/';
    public static url_orgchart_query = environment.urlBackEnd + 'spx-orgchart/api/query/v1/';
    public static url_orgchart_domain = environment.urlBackEnd + 'spx-orgchart/api/domain/v1/';
    // public static url_orgchart_query = 'http://localhost:3038/api/query/v1/';
    // public static url_orgchart_domain = 'http://localhost:3038/api/domain/v1/';
    public static url_deal_query = environment.urlBackEnd + 'spx-dealing/api/query/v1/';
    public static url_deal_domain = environment.urlBackEnd + 'spx-dealing/api/domain/v1/';
    public static url_dealt_query = environment.urlBackEnd + 'spx-dealt/api/query/v1/';
    public static url_dealt_domain = environment.urlBackEnd + 'spx-dealt/api/domain/v1/';
    public static url_user_query = ConstantUrl.url_sts_query + 'user';
    public static url_user_domain = ConstantUrl.url_sts_domain + 'user';
    public static url_get_all_role = ConstantUrl.url_sts_query + 'role';
    public static url_notification_domain = environment.urlBackEnd + 'spx-notification/api/domain/v1/';
    public static url_notification_query = environment.urlBackEnd + 'spx-notification/api/query/v1/';
    // public static url_notification_domain = 'http://localhost:3034/api/domain/v1/';
    // public static url_notification_query = 'http://localhost:3034/api/query/v1/';
    public static url_booking_query = environment.urlBackEnd + 'spx-booking-calendar/api/v1/';
    public static url_booking_domain = environment.urlBackEnd + 'spx-booking-calendar/api/v1/';
    public static url_geocoder_query = environment.urlBackEnd + 'spx-geocoder/api/query/v1/';
    public static url_communication_query = environment.urlRABackEnd + 'msx-communication/api/query/v1/';
    public static url_communication = environment.urlBackEnd + 'spx-communication/api/v1/';
    public static url_communication_domain = environment.urlRABackEnd + 'msx-communication/api/domain/v1/';
    public static url_upload = environment.urlBackEnd + 'spx-uploader/api/v1/fileupload';
    public static url_upload_mobile = environment.urlBackEnd + 'spx-uploader/api/v1/mobile/fileUpload';
    public static url_payment = environment.urlBackEnd + 'spx-payment/api/v1/';
    public static url_property = environment.urlBackEnd + 'spx-property/api/v1/';
    // public static url_property = 'https://th-api.spx.world/spx-property/api/v1/';
    public static url_commission_query = environment.urlBackEnd + 'spx-commission/api/query/v1/';
    public static url_commission_domain = environment.urlBackEnd + 'spx-commission/api/domain/v1/';
    public static url_policy_query = environment.urlBackEnd + 'spx-policy/api/query/v1/';
    public static url_policy_domain = environment.urlBackEnd + 'spx-policy/api/domain/v1/';
    public static url_policy = environment.urlBackEnd + 'spx-policy/api/v1/';
    // public static url_policy_query = 'http://localhost:3058/api/query/v1/';
    // public static url_policy_domain = 'http://localhost:3058/api/domain/v1/';
    // public static url_policy = 'http://localhost:3058/api/v1/';
    // public static url_decision_table_query = environment.urlBackEnd + 'spx-decision-table/api/query/v1/';
    // public static url_decision_table_domain = environment.urlBackEnd + 'spx-decision-table/api/domain/v1/';
    public static url_logger = environment.urlBackEnd + 'spx-logger/api/query/v1/';
    public static url_elearning = environment.urlBackEnd + 'spx-elearning/api/v1/';
    // public static url_elearning = 'http://localhost:3064/api/v1/';

    public static url_news_query = environment.urlBackEnd + 'spx-news/api/query/v1/';
    public static url_news_domain = environment.urlBackEnd + 'spx-news/api/domain/v1/';
    public static url_news = environment.urlBackEnd + 'spx-news/api/v1/';
    // public static url_news_query = 'http://localhost:3050/api/query/v1/';
    // public static url_news_domain = 'http://localhost:3050/api/domain/v1/';
    // public static url_news = 'http://localhost:3050/api/v1/';

    public static url_social_query = environment.urlBackEnd + 'spx-social/api/query/v1/';
    public static url_social_domain = environment.urlBackEnd + 'spx-social/api/domain/v1/';
    public static url_social = environment.urlBackEnd + 'spx-social/api/v1/';
    // public static url_social_query = 'http://localhost:3054/api/query/v1/';
    // public static url_social_domain = 'http://localhost:3054/api/domain/v1/';

    public static url_game_domain = environment.urlBackEnd + 'spx-game/api/domain/v1/';
    public static url_game_query = environment.urlBackEnd + 'spx-game/api/query/v1/';
    public static url_game = environment.urlBackEnd + 'spx-game/api/v1/';
    // public static url_game_domain = 'http://localhost:3061/api/domain/v1/';
    // public static url_game_query = 'http://localhost:3061/api/query/v1/';

    // Voice Call
    public static url_voice_call_call_log = environment.voiceCall.uriAPI + 'v1/call/log';
    // tavico
    public static url_erp = environment.urlErp;
    // Mission
    public static url_mission = environment.urlBackEnd + 'spx-mission/api/v1/';
    // public static url_mission_local =  'http://localhost:3063/api/v1/';

    // public static url_checkin = 'http://localhost:3065/api/v1/';
    public static url_checkin = environment.urlBackEnd + 'spx-checkin/api/v1/';

    // E-Voucher
    // public static url_evoucher =  'http://localhost:3066/api/v1/';
    public static url_evoucher =  environment.urlBackEnd + 'spx-evoucher/api/v1/';
}
