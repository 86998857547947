
declare let $: any;
export class MoneyToNumber {
      static parse(number, returnNull?) {
        number && (number+='');
        if (number && number.length > 0) {
          let array =number.split(',');
          number='';
          array.forEach((item) => {
            number+=item
          })
          return parseFloat(number)
        } else if (number !== 0) {
          return !returnNull ? 0 : null;
        } else {
          return 0
        }
      }
      static parseToNumber (text) {
        if(text) {
          text = text.replace(/,/g, '');
          return parseInt(text);
        }
      }
      static parseToNumberString (text) {
        return text ? text.replace(/,/g, ''): '';
      }
}