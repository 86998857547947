import { Injectable } from '@angular/core';
import { ConstantUrl } from 'app/shared/constant/url';
import { DataService } from 'app/shared/services';
import { HttpService } from 'app/shared/services/http.service';
import { BaseService } from 'app/shared/components/base.service';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SyncRaService {
    ConstantUrl = ConstantUrl;
    url = environment.urlRABackEnd;
    constructor(
        public service: DataService,
        public httpService: HttpService
    ) {
    }

    loginRA() {
        const body = {
            email: environment.syncRA.email,
            password: atob(environment.syncRA.password),
            authType: "AGENT"
        }
        return this.service.postV2(this.url + 'msx-sts/api/domain/v1/auth/login', body);
    }
    getPropertyUnit(token: string) {
        const params = {
            categoryId: 'baf92168-1529-462e-8687-d20d6acde1bc',
            projectId: environment.syncRA.projectGSWId,
            salesProgramId: environment.syncRA.saleProgramGSWId,
            _fields: 'id,project.name,project.id,pos.name,pos.id,code,apartmentType,areaWide,areaLong,primaryStatus,liquidateType,contract,contractType,shortCode,attributes.attributeName,block,floor,attributes.value,bedroom,direction,priceAbove,price,housePriceVat,landPriceVat,housePrice,landPrice,area,priorities,extendable,extendPos,reasonRevoke,modifiedDate,priceVat,category.id,stage'
        };
        const header = {
            authorization: 'Bearer ' + token
        };
        return this.service.getWithHeaders(this.url + 'msx-property/api/query/v1/propertyUnit/primary/assign', header, params);
    }
    getTicket(token: string, syncRAProjectId?: string, syncRASaleProgramId?: string) {
        const params = {
            categoryId: 'baf92168-1529-462e-8687-d20d6acde1bc',
            projectId: syncRAProjectId ? syncRAProjectId : environment.syncRA.projectGSWId,
            salesProgramId: syncRASaleProgramId ? syncRASaleProgramId : environment.syncRA.saleProgramGSWId,
            stage: 2,
            _fields: 'id,apartmentType,areaWide,areaLong,primaryStatus,bedroom,direction,priceAbove,price,area,extendable,code,block,floor,attributes.attributeName,attributes.id,attributes.value,project.name,project.id,priceVat,description,registeredDate,pos.name,pos.id,extendPos,priorities,isException,exceptionalReason,registeredQueue,registeredPos,stage,modifiedDate,salesProgram.id,proposalApproved'
        };
        const header = {
            authorization: 'Bearer ' + token
        };
        return this.service.getWithHeaders(this.url + 'msx-property/api/query/v1/propertyUnit/primary/tranx', header, params);
    }

    getTicketDetail(token: string, id: string) {
        const header = {
            authorization: 'Bearer ' + token
        };
        return this.service.getWithHeaders(this.url + 'msx-property/api/query/v1/primaryTransaction/ticketDetail/' + id, header);
    }

    registerUnit(unitId: string, token: string) {
        const body = {
            projectId: environment.syncRA.projectGSWId,
            unitId
        };
        return this.service.postCustomToken(this.url + 'msx-property/api/query/v1/propertyUnit/registerUnit', body, token);
    }
    sendCustomerInfo(body: any, token: string) {
        return this.service.postCustomToken(this.url + 'msx-property/api/domain/v1/primaryTransaction', body, token);
    }
    confirmTicket(ticketId: string, token: string) {
        const body = {
            id: ticketId,
            status: "POS_CONFIRM_LOCK",
            reason: ""
        }
        return this.service.putCustomToken(`${this.url}msx-property/api/domain/v1/primaryTransaction/${ticketId}`, body, token);
    }
    download(ticketId: string, token: string, code) {
        const headers = {
            authorization: 'Bearer ' + token
        };
        return this.service.dowloadFile(this.url + 'msx-property/api/query/v1/primaryTransaction/ticket/download/' + ticketId, {}, code, headers);
    }
    syncPropertyUnitRA(body: any) {
        const url = ConstantUrl.url_property_query + 'propertyUnit/syncPropertyUnitRA';
        return this.service.post(url, body, {}, 30000);
    }
}
