import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

export interface ConfirmData {
  title: string;
  message: string;
  textCancel: string;
  textOk: string;
  isChecked: boolean;
  execute: boolean;
}

@Component({
  selector: 'confirm-erp-popup',
  templateUrl: 'confirm-erp-popup.html',
})
export class ConfirmErpPopup implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmErpPopup>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmData) {}

  ngOnInit() {
    this.data.isChecked = true;
  }
  onNoClick(): void {
    this.data.execute = false;
    this.dialogRef.close(this.data);
  }
  execute() {
    this.data.execute = true;
    this.dialogRef.close(this.data);
  }
}
