import { GroupApprovalType, GroupShowType, GroupStatus, GroupType } from 'app/consts/group.const';
import { DateToFormat } from 'app/shared/parse/date-to-format';
import { param } from 'jquery';

export class CafeModel {
    stt: number;
    code: string;
    id: string;
    project: any;
    transaction: any;
    sender: any;
    senderPhone: string;
    receiver: any;
    numberOfCoffee: number;
    cafeCost: number;
    createdDate: string;
    status: string;
    message: string;
    updateDate: string;
    isReport: boolean;
    contentCancel: string;

    constructor(params?: any) {
        this.id = params && params.id ? params.id || null : null
        this.code =  params && params.code ? params.code || null : null
        this.project =  params && params.project ? params.project || null : null
        this.transaction =  params && params.transaction ? params.transaction || null : null
        this.sender =  params && params.sender ? params.sender || null : null
        this.senderPhone =  params && params.senderPhone ? params.senderPhone || null : null
        this.receiver =  params && params.receiver ? params.receiver : null
        this.numberOfCoffee =  params && params.numberOfCoffee ? params.numberOfCoffee || null : null
        this.cafeCost =  params && params.cafeCost ? params.cafeCost || null : null
        this.createdDate =  params && params.createdDate ?  DateToFormat.parseDate(params.createdDate, 'dd/MM/yyyy - hh:mm:ss') : '';
        this.status =  params && params.status ? params.status || null : null
        this.message =  params && params.message ? params.message || null : null
        this.updateDate =  params && params.modifiedDate ?DateToFormat.parseDate(params.modifiedDate, 'dd/MM/yyyy - hh:mm:ss') : '';
        this.isReport = params && params.isReport ? params.isReport : false;
        this.contentCancel = params && params.contentCancel ? params.contentCancel : null
    }
}

export class RepecientNotiCafeModel{
  listAddCoffeeManager: any

  constructor(params?: any){
    this.listAddCoffeeManager = params ?  params.listAddCoffeeManager || [] : []
  }
}
export  enum CafeStatusEnum {
  WAITING_FOR_CONFIRMATION = 'waiting_for_confirmation', // Chờ xác nhận
  WAITING_FOR_COMPARISON = 'waiting_for_comparison', // Chờ đối chiếu
  COMPLETED = 'completed', // Hoàn tất
  REFUSED = 'refused', // Đã từ chối
  WAITING_FOR_CANCELLED = 'waiting_for_cancelled', // Chờ hủy
  CANCELLED = 'cancelled', // Đã hủy
}
export enum CafeStatusEnumText {
  WAITING_FOR_CONFIRMATION = 'chờ xác nhận',
  WAITING_FOR_COMPARISON = 'chờ đối chiếu',
  COMPLETED = 'đã hoàn thành',
  REFUSED = 'đã từ chối',
  WAITING_FOR_CANCELLED = 'chờ hủy',
  CANCELLED = 'đã hủy',
}

  