import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
    private storage: any;
    private localStorage: any;
    private listAPI: any = [];
    constructor() {
        this.storage = sessionStorage; // localStorage;
        this.localStorage = localStorage;
    }

    public retrieve(key: string): any {
        const item = this.storage.getItem(key);

        if (item && item !== 'undefined') {
            return JSON.parse(this.storage.getItem(key));
        }

        return;
    }

    public store(key: string, value: any) {
        this.storage.setItem(key, JSON.stringify(value));
    }
    public remove(key: string) {
        this.storage.removeItem(key);
    }

    public retrieveLocal(key: string): any {
        const item = this.localStorage.getItem(key);

        if (item && item !== 'undefined') {
            return JSON.parse(this.localStorage.getItem(key));
        }

        return;
    }
    public storeLocal(key: string, value: any) {
        this.localStorage.setItem(key, value);
    }

    public removeLocal(key: string) {
      this.localStorage.removeItem(key);
    }

    setAPIQuery(url, data) {
        let api = {
            url,
            data
        }
        let result = false;
        this.listAPI = this.listAPI.map((item) => {
            if (item.url == url) {
                item.data = data;
                result = true;
            }
            return item;
        });
        if (!result) {
            this.listAPI.push(api);
        }
    }

    getAPIQuery(url) {
        return this.listAPI.find(api => api.url == url);
    }

    resetAPI() {
        this.listAPI = [];
    }

}
