import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ColDef, ColGroupDef, GridApi, GridOptions, SortChangedEvent } from 'ag-grid-community';
import { CPagination } from 'app/api-models';
import { TemplateRendererComponent } from 'app/pages/project/event-sale/list/template-renderer/template-renderer.component';
import { SurveyNoteDetailComponent } from 'app/pages/survey/survey-note-detail/survey-note-detail.component';
import { moment } from 'fullcalendar';



@Component({
  selector: 'app-grid-note-loan-list',
  templateUrl: './grid-note-loan-list.html',
  styleUrls: ['./grid-note-loan-list.scss']
})
export class GridNoteLoanListComponent implements AfterViewInit {
  @ViewChild('createdDateRow') createdDateRow: TemplateRef<any>;
  @ViewChild('actionRow') actionRow: TemplateRef<any>;
  @ViewChild('agDescription') agDescription: TemplateRef<any>;
  @Input() pagination: CPagination<any> = new CPagination<any>();
  @Input() isShowSurvey: boolean = true;
  @Input() isNoteAdmin: boolean = false;
  @Output() sort: EventEmitter<any> = new EventEmitter();
  public gridApi: GridApi;
  public columnDefs: ColDef[] | ColGroupDef;

  defaultGridOptions: GridOptions = {
    defaultColDef: {
      resizable: true,
      tooltipComponent: 'customTooltip',
      sortable: false,
      enableCellChangeFlash: false,
    },
    autoSizePadding: 10,
    cellFlashDelay: 500,
    rowBuffer: 30,
    animateRows: false,
    suppressRowTransform: true,
    enableCellTextSelection: true,
    singleClickEdit: true,
    enableBrowserTooltips: true,
    tooltipShowDelay: 1,
    overlayNoRowsTemplate: '<span> Không có ghi chú </span>',
    onSortChanged: (event: SortChangedEvent) => {
      this.getDataSortChange(event.api.getSortModel()[0]);
    },
  };
  gridOptions: GridOptions = { ...this.defaultGridOptions };

  constructor(
    public router: Router,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
  ) {

  }

  getInfoNote(data) {
    return `${data.note}`
  }

  getInfoNoteAdmins(note: any) {
    if(note.content) {
      note.content = note.content.trim();
    }
    return note ? `${note.createdBy.name} - ${moment(note.createdDate).format('DD/MM/YYYY HH:mm')} - ${note.content}` : '';
  }
  ngAfterViewInit(){
    this.columnDefs = [
      {
        headerName: 'STT',
        width: 70,
        maxWidth: 70,
        valueGetter: (params) => {
          return `${params.node.rowIndex + 1}`
        }
      },
      {
        headerName: 'Nội dung ghi chú',
        colId: 'name',
        width: 150,
        field: 'note',
        cellRendererFramework: TemplateRendererComponent,
        cellRendererParams: {
          ngTemplate: this.agDescription
        },
        autoHeight: true,
      },
      {
        headerName: 'Người tạo',
        colId: 'code',
        field: 'createdBy.name',
        width: 100,
        maxWidth: 100,
      },
      {
        headerName: 'Thời gian tạo',
        colId: 'name',
        field: 'createdDate',
        cellRendererFramework: TemplateRendererComponent,
        cellRendererParams: {
          ngTemplate: this.createdDateRow
        },
        width: 150,
        maxWidth: 150,
      },
      {
        headerName: 'Khảo sát',
        width: 50,
        valueGetter: (item => {
          return item.data.survey && item.data.survey.code ? item.data.survey.code : ''
        }),
        cellRendererFramework: TemplateRendererComponent,
        cellRendererParams: {
          ngTemplate: this.actionRow
        },
        hide: !this.isShowSurvey,
      },
    ];
    this.cdRef.detectChanges();
  }

  ngOnInit() {
  }
  openSurveyDetail(survey){
    const dialogRef = this.dialog.open(SurveyNoteDetailComponent,{
      width: '600px',
      data: survey
    })

  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.setAutoSize();
  }
  getDataSortChange(data: any){
    this.sort.emit(data);
  }

  setAutoSize() {
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  showLoadingOverlay() {
    this.gridApi && this.gridApi.showLoadingOverlay();
  }

  hideOverlay() {
    this.gridApi && this.gridApi.hideOverlay();
  }

  createShortText(longText: string = '') {
    longText = longText.trim();
    if (longText && longText.length >= 75) {
        return longText.substring(0, 75) + '...';
    }
    return longText;
}
checkTextHasWhiteSpace(longText: string = '') {
    if (longText) {
        if (longText.indexOf(' ') === -1) return false;
        return true;
    }
    return true;
}
}
