<div fxLayout="row" fxLayoutAlign="center" class="popup-title">
  <h2>{{data.title}}</h2>
</div>
<div mat-dialog-content>
  <div fxLayout="column" fxLayoutAlign="space-around center">
    <span class="popup-message" [ngClass]="{'popup-message_flex' : data.isMessageHTML}" [innerHTML]="data.message"></span>

    <div *ngIf="data.isCancelTicket" fxLayout="row" class="popup-input">
      <div fxFlex="50" class="app-form-group mr-16">
        <app-select [clearable]="false" [(ngModel)]="bankName" [items]="banksDropdown" bindLabel="NAME" bindValue="CODE"
          placeholder="Ngân hàng">
        </app-select>
      </div>
      <div fxFlex="50" class="app-form-group">
        <input [(ngModel)]="accountNo" [textMask]="{mask: numberBankMask}" class="app-input"
          placeholder="Số tài khoản ngân hàng" />
      </div>
    </div>

    <div *ngIf="data.isTextInput" fxLayout="row" class="popup-input">
      <div fxFlex="30" class="input-label">
        {{data.textInputLabel}}
      </div>
      <div fxFlex="70" class="app-form-group">
        <input class="app-input" [(ngModel)]="data.textInputValue" placeholder="{{data.placeholderReason}}"/>
      </div>
    </div>

    <div *ngIf="data.isReason" fxLayout="row" class="popup-input">
      <!-- <span fxFlex="20" style="font-weight: bold;">Ghi chú<span class="m-red" *ngIf="data.isRequireReason">*</span></span>
        <div fxFlex="80"> -->
      <mat-form-field fxFlex="100" class="popup-custom-mat">
        <textarea matInput [(ngModel)]="data.reason" rows="3" placeholder="{{data.isReasonReject === true ? 'Lý do *': data.reasonLabel}}">{{data.reason}}</textarea>
      </mat-form-field>
      <!-- </div> -->
    </div>

    <!-- Thêm kiểu input số cho popup -->
    <div *ngIf="data.isNumber"  fxLayout="row" class="popup-input">
      <div fxFlex="30" class="input-label">
        {{data.numberInputLabel}}
      </div>
      <div fxFlex="70" class="app-form-group">
        <input class="app-input" [(ngModel)]="data.numberInputValue" (keydown)="isNumber($event)" />
      </div>
    </div>

    <!-- Popup copy ticket -->
    <div *ngIf="data.isCopyTicket"  fxLayout="column" class="w-100">
      <div fxFlex="30" class="input-label fw-500">
        {{data.numberInputLabel}}<span class="m-red">*</span>
      </div>
      <div fxFlex="100" class="app-form-group">
        <input class="app-input" [(ngModel)]="data.numberInputValue" (keydown)="isNumber($event)" />
      </div>
    </div>

  </div>
  <div *ngIf="data.isCheckBox" fxLayout="row" class="popup-input-v2 mh-unset">
    <app-checkbox-button [(ngModel)]="data.checkBoxResult" (change)="onCbChange($event.target.checked)">
      <span class="popup-subtitle">{{data.checkBoxText}}</span>
    </app-checkbox-button>
  </div>
  <div *ngIf="data.isCheckBoxOtp" fxLayout="row" class="popup-input-v2 mh-unset">
    <app-checkbox-button [(ngModel)]="data.isWaitingOtp" (change)="onCbChange($event.target.checked)">
      <span class="popup-subtitle">
        Chờ OTP <em> (Chỉ áp dụng trong trường hợp ĐVKD nhận ủy quyền của khách hàng , Khách hàng không có mặt tại sự
          kiện để ký chứng từ). </em>
      </span>
    </app-checkbox-button>
  </div>
</div>
<div mat-dialog-actions class="pt-30">
  <div fxLayout="row" fxLayoutAlign="center center" class="w-100">
    <button mat-button class="m-bg-primary m-bg btn-disable" color="primary" (click)="execute()"
      [disabled]="checkRequireReason()">{{data.textOk}}</button>
    <button mat-button class="m-bg-grey m-bg" (click)="onNoClick()">{{data.textCancel}}</button>
  </div>
</div>
