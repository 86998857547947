import { IDecode } from './i-decode';

export class CDocument implements IDecode<CDocument> {

    public originalName: string;
    public uploadName: string;
    public size: number;
    public url: string;
    public file: File;

    constructor(params: Partial<CDocument> = {}) {
        this.copy(params);
    }

    copy(params: Partial<CDocument> = {}): CDocument {
        params = params || {};

        this.originalName = params.originalName || null;
        this.uploadName = params.uploadName || null;
        this.size = params.size || null;
        this.url = params.url || null;
        this.file = params.file || null;
        return this;
    }

    decode(paramsApi: any): CDocument {
        return new CDocument(paramsApi);
    }

    decodeFile(file: File): CDocument {
        return new CDocument(Object.assign(file, { file }));
    }

    decodeFileList(files: File[]): CDocument[] {
        return files.map(el => new CDocument().decodeFile(el));
    }

    decodeList(paramsApi: any[]): CDocument[] {
        return paramsApi.map(el => new CDocument().decode(el));
    }
}
