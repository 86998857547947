import { Component, OnInit, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { StorageService, SecurityService } from '../../../shared/services';
import { NotificationService } from 'app/shared/services/notification.service';
import { Subject } from 'rxjs';
import { EventChangeService } from 'app/shared/services/event.change.service';
import { CUser } from 'app/api-models';
import { UserV2Service } from 'app/shared/services/common/user-v2.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit, OnDestroy {

  notificationCount: number;
  private unsubscribe$: Subject<any> = new Subject();
  public user: CUser;
  @Input() isShowAccount: boolean = true;
  @Input() isShowNotification: boolean = true;

  constructor(
    public storageService: StorageService,
    public securityService: SecurityService,
    private notificationService: NotificationService,
    public eventChangeService: EventChangeService,
    private userV2Service: UserV2Service

  ) { }

  ngOnInit() {
    this.user = new CUser();

    this.notificationService
    .notifications$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (notifications) => {
        let count = 0;
        notifications.forEach((item) => {
          !item.isRead && count++;
        });
        this.notificationCount = count;
      }
    );

    this.userV2Service.user$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(((userRes: CUser) => {
      this.user = userRes;
    }));

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  logout() {
    this.securityService.logout();
  }

  checkAvatar(item) {
    let avatar = './assets/img/users/icon_avatar.svg';
    if(item) {
      avatar = item.avatar;
    }
    return `url('${avatar}')`;
  }
}
