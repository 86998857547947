import { Injectable } from '@angular/core';
import { DataService } from 'app/shared/services/data.service';
import { ConstantUrl } from 'app/shared/constant/url';
import { BaseService } from 'app/shared/components/base.service';
// Model
import { Observable, of, BehaviorSubject } from 'rxjs';
import { CPagination, CUser } from '../../api-models';
import { map } from 'rxjs/operators';
import { HttpService } from '../../shared/services/http.service';
import { TicketSale } from './event-sale/ticket-sale.model';

import { PrimaryTransactionStatus } from 'app/shared/enum/primary-transaction.enum';
import { getCurrentTimeSigned } from 'app/shared/utility/utility';
@Injectable()
export class PrimaryTransactionService extends BaseService {
  ConstantUrl = ConstantUrl;
  private event: BehaviorSubject<any> = new BehaviorSubject(null);
  public event$: Observable<any> = this.event.asObservable();

  constructor(public service: DataService,
    public httpService: HttpService) {
    super(service);
    this.domainUrl = ConstantUrl.url_property_domain + 'primaryTransaction';
    this.queryUrl = ConstantUrl.url_property_query + 'primaryTransaction';
  }

  public backData(data) {
    this.event.next(data);
  }

  unSubscribeEvent() {
    this.event.next(null);
    // this.event.complete();
  }

  public getTicketDetailById(id: string): Observable<any> {
    const uri = this.queryUrl + '/ticketDetail/' + id;
    return this.httpService.get(uri);
  }

  public findTicketsByAccountant(params) {
    let url = this.queryUrl + '/ticket/accountant';
    return this.service.get(url, params);
  }
  public getTicketsByEvent(params: any) {
    const url = this.queryUrl + '/ticket/event';
    return this.httpService.get(url, params);
  }

  public getTicket(id, params): Observable<CPagination<TicketSale>> {
    const uri = this.queryUrl + `/ticket/` + id;
    return this.httpService.get(uri, params).pipe(
      map((res: any) => {
        const pagination = new CPagination<TicketSale>().decode(res);
        pagination.items = pagination.items.map((item: any) => new TicketSale(item));
        pagination['sumStatus'] = res.sumStatus;
        return pagination;
      })
    );
  }

  public getTicketNeedToCompare(id, params): Observable<CPagination<TicketSale>> {
    const uri = this.queryUrl + `/ticket/` + id + '/need-to-compare';
    return this.httpService.get(uri, params).pipe(
      map((res: any) => {
        const pagination = new CPagination<TicketSale>().decode(res);
        pagination.items = pagination.items.map((item: any) => new TicketSale(item));
        pagination['sumStatus'] = res.sumStatus;
        return pagination;
      })
    );
  }

  public getTicketDowload(body, fileName) {
    const uri = `${this.queryUrl}/report/download-ticket/`;
    return this.service.dowloadFile(uri, body, fileName);
  }
  public getTicketDowloadTTTV(body, fileName) {
    const uri = `${this.queryUrl}/report/download-ticket-tttv/`;
    return this.service.dowloadFile(uri, body, fileName);
  }
  public getTicketDowloadChuyenCoc(body, fileName) {
    const uri = `${this.queryUrl}/report/download-transaction-chuyen-coc/`;
    return this.service.dowloadFile(uri, body, fileName);
  }

  public getTicketDowloadAll(id, ticketType, exchangeId) {
    const params = {
      download: 1,
      ticketType,
      exchangeId
    };
    const uri = `${this.queryUrl}/ticket/${id}`;
    return this.httpService.get(uri, params).pipe(
      map((res: any) => res.rows)
    );
  }

  public downloadTicket(id, fileName: any, body = {}) {
    const url = this.queryUrl + '/ticket/download/' + id;
    return this.service.dowloadFile(url, body, fileName);
  }

  // public getYCDCHInProject(projectId: string) {
  //   let params =  {
  //     isAccounted: true,
  //     ticketType: 'YCDCH',
  //     page: 1,
  //     pageSize: 9999
  //   };
  //   const url = this.queryUrl + '/ticket/' + projectId;
  //   return this.httpService.get(url, params);
  // }

  public getYCDCHInProjectYCDC(projectId: string, params) {
    let url = this.queryUrl + `/ticket/${projectId}/listBookingApproved`;
    return this.httpService.get(url, params);
  }

  getTicketByProject(params?, id?) {
    const projectId = id;
    const url = this.queryUrl + `/ticket/${projectId}`;
    return this.service.get(url, params || {});
  }

  public createPrimaryTransaction(params: any, isTransferEscrow: boolean = false) {
    const url = this.domainUrl;
    if (isTransferEscrow) {
      return this.httpService.put(url, params);
    } else {
      return this.httpService.post(url, params);
    }
  }

  public copyPrimaryTransaction(body: any) {
    const url = this.domainUrl + `/copyTicket`;
    return this.httpService.post(url, body);
  }

  public updateStatusTransaction(params: any) {
    const url = this.domainUrl + `/ticket`;
    return this.httpService.put(url, params);
  }

  public updateNoteFromAdminTransaction(params: any) {
    const url = this.domainUrl + `/updateNoteFromAdmin`;
    return this.httpService.put(url, params);
  }

  public updateStatusTransactionSyncErp(params: any) {
    const url = this.domainUrl + `/ticketSyncErp`;
    return this.httpService.put(url, params);
  }

  public sendEmail(id: string, ticketType: string) {
    const body = {
      id,
      ticketType
    };
    const url = this.domainUrl + '/sendEmailTicket';
    return this.httpService.post(url, body);
  }

  public adminApproveRejectTicket(params: any) {
    const body = {
      id: params.id,
      status: params.status,
      reason: params.reason,
      propertyUnitId: params.propertyUnitId
    };
    const url = this.domainUrl + '/adminApproveTicket';
    return this.httpService.post(url, body);
  }
  public saApproveRejectTicket(
    params: {
      id: string,
      status: PrimaryTransactionStatus.SA_CONFIRM_UNPAID | PrimaryTransactionStatus.SA_REJECT_UNPAID,
      reason: string
    }) {
    const url = this.domainUrl + '/saApproveTicket';
    return this.httpService.post(url, params);
  }

  public csApproveRejectTicket(params: any) {
    const url = this.domainUrl + '/csApproveTicket';
    return this.httpService.post(url, params);
  }

  public cancelRequestYCDCH(params: any) {
    const url = this.domainUrl + '/cancelRequest';
    return this.httpService.post(url, params);
  }
  public cancelYCDCH(
    params: { id: string, reason: string }) {
    const url = this.domainUrl + '/cancelTicket';
    return this.httpService.post(url, params);
  }

  public adminApproveCancelYCDCH(
    params: { id: string, reason: string }) {
    const url = this.domainUrl + '/adminApproveCancelRequest';
    return this.httpService.post(url, params);
  }

  public dvkhApproveCancelYCDCH(
    params: { id: string, reason: string }) {
    const url = this.domainUrl + '/csApproveCancelRequest';
    return this.httpService.post(url, params);
  }

  dowloadPrimaryDetailReport(params, fileName) {
    const uri = this.queryUrl + '/report/download-primary-detail';
    return this.service.dowloadFile(uri, params, fileName);
  }
  importTicketEscrow(files: File, dto) {
    const url = ConstantUrl.url_property_domain + 'primaryTransaction/importTicketEscrow';
    let formData = new FormData();
    formData.append('files', files);
    formData.append('projectId', dto.projectId);
    return this.service.post(url, formData);
  }
  createCustomerAccount(projectId, params = {}) {
    const url = ConstantUrl.url_property_domain + `primaryTransaction/createCustomerAccount/${projectId}`;
    return this.httpService.post(url, params);
  }
  public downloadDanhsachGD(body = {}) {
    const url = this.queryUrl + '/report/download-transaction-confirm';
    const fileName = 'Bang_ke_xac_nhan_giao_dich_' + getCurrentTimeSigned();
    return this.service.dowloadFile(url, body, fileName);
  }
  public downloadDanhsachSPGD(body = {}) {
    const url = this.queryUrl + '/report/download-transaction-success';
    const fileName = 'Bang_ke_san_pham_giao_dich_' + getCurrentTimeSigned();
    return this.service.dowloadFile(url, body, fileName);
  }
  public downloadDanhsachKHGD(body = {}) {
    const url = this.queryUrl + '/report/download-kh-tranx-success';
    const fileName = 'Bang_ke_kh_giao_dich_' + getCurrentTimeSigned();
    return this.service.dowloadFile(url, body, fileName);
  }

  dowloadKHTN(params, fileName) {
    const uri = this.queryUrl + '/report/download-khtn';
    return this.service.dowloadFile(uri, params, fileName);
  }

  extendBookingTime(params: any) {
    const body = {
      id: params.ticketId,
      dWellMinute: parseInt(params.dWellMinute),
    };
    return this.service.postV2(this.domainUrl + `/extendBookingTime`, body);
  }

  public updateDocuments(body) {
    const url = ConstantUrl.url_property_domain + 'primaryTransaction/updateDocuments';
    return this.service.put(url, body);
  }
}
