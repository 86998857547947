<div class="landing_page">
    <app-landing-header (openDialog)="openDialog()"></app-landing-header>
    <div class="landing_page-body">
        <img src="../../../../assets/img/landing/abstract.svg" alt="landingAbstract">
        <div class="landing_page-wrapper">
            <div class="landing_page-content">
                <div class="row" fxLayout="row">
                    <div class="col-5 landing_page-content-info" fxLayout="column" fxFlex="55">
                        <h3>PROPCOM - CỘNG ĐỒNG HÀNG ĐẦU</h3>
                        <h1>DÀNH CHO NHỮNG NGƯỜI YÊU THÍCH</h1>
                        <h1>CÔNG NGHệ, BẤT ĐỘNG SẢN, TÀI CHÍNH</h1>
                        <p>Propcom là nền tảng công nghệ bất động sản tại Việt Nam giúp kết nối những người có chung niềm đam mê với công nghệ, bất động sản, tài chính.</p>
                        <div class="btn-landing body-btn">
                            <button mat-raised-button (click)="openDialog()">Tải ứng dụng</button>
                        </div>
                    </div>
                    <div class="col-5 landing_page-content-img" fxLayout="column" fxFlex="45">
                        <img src="../../../../assets/img/landing/appHeader.png" alt="appHeader">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="landing_page-intro-wrapper">
        <div class="landing_page-intro" fxLayout="row" fxLayoutGap="60px">
            <div fxLayout="column" fxFlex="50" class="landing_page-intro-img">
                <img src="../../../../assets/img/landing/Mockup.png" alt="Mockup">
            </div>
            <div fxLayout="column" fxFlex="50" class="landing_page-intro-content">
                <div class="intro-title">
                    <h1>BÁN NHÀ KIỂU CÔNG NGHỆ</h1>
                    <h1 class="text-primary">GIẢI PHÁP CHO MÔI GIỚI BẤT ĐỘNG SẢN VƯỢT QUA MÙA DỊCH</h1>
                </div>
                <div class="intro-content">
                    <p>Trong thời điểm tình hình dịch bệnh diễn biến phức tạp khiến hoạt động kinh doanh của các doanh nghiệp môi giới bất động sản bị trì trệ, giao dịch online là lựa chọn hiệu quả nhất để giúp các doanh nghiệp vượt qua khó khăn mùa dịch.</p>
                    <p>Tuy nhiên, vấn đề ở đây là làm thế nào để môi giới có thể tìm kiếm được nguồn thông tin sản phẩm đầy đủ và uy tín nhằm cung cấp đến khách hàng? Thông qua việc sở hữu giỏ hàng sản phẩm được cập nhật theo thời gian thật và hỗ trợ kinh doanh 24/7, Propcom chính là lời giải mà chúng ta đang tìm kiếm bấy lâu nay.</p>
                </div>
                <div class="intro-button btn-landing">
                    <button mat-raised-button (click)="openDialog()">Tải ứng dụng</button>
                </div>
            </div>
        </div>
        <img src="../../../../assets/img/landing/abstract.png" alt="abstractFooter">
    </div> -->
    <app-landing-footer [shareUrl]="shareUrl"></app-landing-footer>
</div>