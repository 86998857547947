import { Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import moment = require("moment");
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Constant } from "app/shared/constant/constant";


@Component({
    selector: 'app-report-checkin-checkout-popup',
    templateUrl: './report-checkin-checkout-popup.component.html',
    styleUrls: ['./report-checkin-checkout-popup.component.scss']
})

export class ReportCheckinCheckoutPopupComponent {

    mainForm: FormGroup = null;
    startDateUpgrade = '';
    constant:any = Constant
    constructor(
        public dialogRef: MatDialogRef<ReportCheckinCheckoutPopupComponent>,
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        public toastr: ToastrService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {

    }

    exportExcel() {
        this.dialogRef.close(true)
    }

    close() {
        this.dialogRef.close()
    }
}