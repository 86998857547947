<div class="container-xl home-layout-header unselectable">
  <div class="home-layout-header__section-left">
    <img class="home-layout-header__logo" src="assets/public/logo.svg" />
    <div fxLayout="column">
      <span class="home-layout-header__title">PROPCOM</span>
      <span class="home-layout-header__sub-title">Kết nối môi giới bền vững</span>
    </div>
  </div>
  <div class="home-layout-header__section-middle">
    <a class="home-layout-header__menu-item">
      <img src="assets/public/icons/header_propcom.svg" />
    </a>
    <a class="home-layout-header__menu-item">
      <img src="assets/public/icons/header_home.svg" />
    </a>
    <a class="home-layout-header__menu-item">
      <img src="assets/public/icons/header_group.svg" />
    </a>
    <a class="home-layout-header__menu-item">
      <img src="assets/public/icons/header_event.svg" />
    </a>
    <a class="home-layout-header__menu-item">
      <img src="assets/public/icons/header_news.svg" />
    </a>
  </div>
  <div class="home-layout-header__section-right">
    <ng-template *ngIf="isLoggedIn">
      <div class="home-layout-header__item-user">
        <img src="assets/public/icons/icon_fill_noti.svg" />
      </div>
      <div class="home-layout-header__item-user">
        <img src="assets/public/icons/icon_fill_cart.svg" />
      </div>
      <div class="home-layout-header__item-user">
        <ng-template *ngIf="currentUser">
          <img :src="currentUser.avatar" />
        </ng-template>
        <ng-template *ngElse>
          <img src="assets/public/icons/icon_user.svg" />
        </ng-template>
      </div>
      <div class="home-layout-header__item-user" id="btnDropdownRef" (click)="showUserDropdown">
        <img src="assets/public/icons/icon_arrow_down.svg" />
      </div>
    </ng-template>
    <ng-template *ngElse>
      <button class="secondary" (click)="goToLoginPage">Đăng nhập</button>
      <button class="primary">Đăng ký</button>
    </ng-template>
  </div>
</div>