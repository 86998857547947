import { Component, OnInit, OnDestroy, AfterContentInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppSettings } from '../app.settings';
import { AuthorizeService } from '../shared/services/common/authorize.service';
import { StorageService } from '../shared/services';
import { EventChangeService } from 'app/shared/services/event.change.service';
import { ReplaySubject } from 'rxjs';
import { MatDialog } from '@angular/material';
import { environment } from 'environments/environment';
import { PopupUpdateFeatureComponent } from 'app/pages/project/event-sale/popup-update-feature/popup-update-feature.component';
export const STORAGE_UPDATE_FEATURE = 'STORAGE_UPDATE_FEATURE';

@Component({
  selector: 'app-layout-public',
  templateUrl: './layout-public.component.html',
  styleUrls: ['./layout-public.component.scss'],
})
export class LayoutPublicComponent implements OnInit, OnDestroy, AfterContentInit {
  public destroyUnsubscribe: ReplaySubject<any> = new ReplaySubject<any>(1);

  public releaseDate: string;

  constructor(
    public appSettings: AppSettings,
    public router: Router,
    public route: ActivatedRoute,
    private authorizeService: AuthorizeService,
    private storage: StorageService,
    private eventChangeService: EventChangeService,
    private dialog: MatDialog,
  ) {
    this.releaseDate = this.storage.retrieveLocal(STORAGE_UPDATE_FEATURE) || '';
  }

  ngAfterContentInit() {
    if (!environment.releaseDate.includes(this.releaseDate)) {
      this.openPopupUpdateFeature();
    }
  }

  openPopupUpdateFeature() {
    const createGroupPopup = this.dialog.open(PopupUpdateFeatureComponent, {
      width: '650px',
      data: {},
      disableClose: true,
      panelClass: 'no-padding-dialog-container'
    });
    createGroupPopup.afterClosed().subscribe((result: any) => {
      if (result) {
        this.storage.storeLocal(STORAGE_UPDATE_FEATURE, environment.releaseDate);
      }
    });
  }

  async ngOnInit() {
    this.eventChangeService.emitChangeSource.subscribe((data) => {
      switch (data.broadcast) {
        case 'log-out':
          this.logout();
          break;
      }
    });
    const lastRoute = this.getLastChild(this.route.snapshot);
    if (lastRoute.data.isAuth) {
      if (this.authorizeService.checkAuthorities()) {
        let permissions = this.storage.retrieve('permissions');
        if (permissions) {
          let authorities = JSON.parse(permissions);
          if (authorities) {
            this.authorizeService.setAuthorities(authorities);
          }
        }
        let isFullPermission = this.storage.retrieve('isFullPermission');
        this.authorizeService.setIsFullPermission(isFullPermission);
        if (!permissions && !isFullPermission) {
          if (this.router.url.length > 1 && this.router.url !== '/login') {
            this.storage.store('callbackUrl', this.router.url);
          }
          this.router.navigate(['/login']);
        }
      } else {
        if (this.router.url.length > 1 && this.router.url !== '/login') {
          this.storage.store('callbackUrl', this.router.url);
        }
        this.router.navigate(['/login']);
      }
    }
  }

  ngOnDestroy() {
    this.destroyUnsubscribe.next(null);
  }

  private getLastChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (route.firstChild) {
      return this.getLastChild(route.firstChild);
    } else {
      return route;
    }
  }

  private logout() {
    this.storage.remove('authorizationData');
    this.storage.remove('voice_call_id');
    this.storage.remove('voice_call_token');
    this.storage.remove('voice_call_authen_token');
    this.router.navigate(['/login']);
  }

}