<div fxLayout="row" fxLayoutAlign="center">
  <h1>{{data.title}}</h1>
</div>
<div mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="space-around center">
    <div fxFlex="100">
      {{data.body}}
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%">
    <button mat-raised-button color="default" (click)="onNoClick()">Không</button>
    <button mat-raised-button color="accent" (click)="execute()" [disabled]="!data.body" color="accent">Thực hiện</button>
  </div>
</div>


<!-- <mat-card class="custom-card">
  <mat-card-title >
    <div fxLayout="row" fxLayoutAlign="center">
      <h6>{{data.title}}</h6>
    </div>
  </mat-card-title>
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <div fxFlex="100">
        {{data.body}}
      </div>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions fxLayoutAlign="end left">
    <div fxFlex="100">
      <button mat-raised-button color="default"  (click)="onNoClick()">Không</button>
      <button mat-raised-button color="accent" (click)="execute()" [disabled]="!data.body">Thực hiện</button>
    </div>
  </mat-card-actions>
</mat-card> -->