import { BaseModel } from "../../shared/models/base.model";
import { Address } from "../../shared/models/address.model";
import { POS } from "../orgchart-pos/model/pos.model";
import { ValidateIf, IsNotEmpty, Length, Contains, IsEmail } from "class-validator";
import { Constant } from './../../shared/constant/constant'
import { BaseModelInterface } from 'app/shared/models/base.model.interface';

export enum EStatusCustomer {
  VIP = '0',
  NORMAL = '1',
  DONT_CARE = '2',
}

export class Customer extends BaseModel implements BaseModelInterface {
  id: number;
  index: number;
  personalInfo: PersonalInfo;
  info: InfoCustomer;
  bankInfo: BankInfo;
  pos: any;
  employee: any;
  code: any = '';
  isPotential: boolean;
  static type: any;
  data: any;
  countType: any;
  createdDate: any;
  updatedDate: any;
  status: EStatusCustomer;
  totalCall: any;
  takeNote: any;
  taxCode: string;
  constructor(params?) {
    super();
    this.setValue(params);
  }
  setValue(params?) {
    if (params) {
      super.setValue(params);
      this.id = params.id;
      this.index = params.index;
      this.personalInfo = params.personalInfo ? new PersonalInfo(params.personalInfo) : new PersonalInfo();
      this.info = params.info ? new InfoCustomer(params.info) : new InfoCustomer();
      this.bankInfo = params.bankInfo ? new BankInfo(params.bankInfo) : new BankInfo();
      this.pos = params.pos ? new POS(params.pos).createObjectReference() : new POS().createObjectReference();
      this.employee = params.employee || {};
      this.code = params.code || '';
      this.data = params.employee || {};
      this.countType = params.countType || {};
      this.isPotential = typeof params.isPotential === 'undefined' ? true : params.isPotential;
      this.createdDate = params.createdDate;
      this.updatedDate = params.updatedDate;
      this.totalCall = params.totalCall;
      this.takeNote = params.takeNote;
      this.taxCode = params.taxCode;
      this.status = params.status || params.status === 0 ? params.status.toString() : EStatusCustomer.NORMAL;
    } else {
      this.setAllNull();
      super.setValue(params);
    }

  }
  setAllNull() {
    this.id = null;
    this.index = null;
    this.personalInfo = new PersonalInfo();
    this.info = new InfoCustomer();
    this.bankInfo = new BankInfo();
    this.pos = new POS().createObjectReference();
    this.employee = null;
    this.code = null;
    this.data = null;
    this.countType = null;
    this.isPotential = true;
    this.createdDate = null;
    this.updatedDate = null;
    this.totalCall = null;
    this.status = EStatusCustomer.NORMAL;
    this.takeNote = null;
    this.taxCode = null;
  }
  static setType(type) {
    this.type = type;
  }
  static getType() {
    if (this.type) {
      return 'customer/' + this.type;
    } else return 'customer';
  }
  validatePartial() {
    this.errors = {};
    this.errors.personalInfo = this.personalInfo.validate();
    this.errors.info = this.info.validate();
    if (this.errors.personalInfo.isExist) {
      this.errors.isExist = true;
    }
    if (this.errors.info.isExist) {
      this.errors.isExist = true;
    }
    return this.errors;
  }

  validate() {
    this.errors = {};
    this.errors.personalInfo = this.personalInfo.validate();
    this.errors.info = this.info.validate();
    if (this.errors.personalInfo.isExist) {
      this.errors.isExist = true;
    }
    if (this.errors.info.isExist) {
      this.errors.isExist = true;
    }

    return this.errors;
  }
  createObjectRequestAPI() {
    let object: any = {};
    this.id && (object.id = this.id || '');
    object.personalInfo = this.personalInfo.createObjectRequestAPI();
    object.identities = this.personalInfo.identities.map((identity) => {
      return identity.createObjectCheckKey();
    });
    object.pos = this.pos;
    object.bankInfo = this.bankInfo ?
    (this.bankInfo instanceof BankInfo ? this.bankInfo.createObjectRequestAPI() : new BankInfo(this.bankInfo).createObjectRequestAPI()) :
    {};
    object.info = this.info.createObjectRequestAPI();
    object.employee = this.employee;
    object.code = this.code;
    object.isPotential = this.isPotential;
    object.takeNote = this.takeNote;
    object.taxCode = this.taxCode;
    return object;
  }
  createDataForList() {
    let object: any = {};
    object.id = this.id;
    object.name = this.personalInfo.name || this.personalInfo.lastName;
    object.phone = this.personalInfo.phone || '';
    object.email = this.personalInfo.email || '';
    object.gender = this.info.gender === 'male' ? 'Nam' : this.info.gender === 'female' ? 'Nữ' : '';
    object.birthday = this.info.birthday  !== '//' ? this.info.birthday : '';
    object.identityNumber = this.personalInfo.identities[0].value  !== '' ? this.personalInfo.identities[0].value : '';
    object.identityDate = this.personalInfo.identities[0].date  !== '' ? this.personalInfo.identities[0].date : '';
    object.pos = this.pos;
    object.employee = this.employee;
    object.income = this.getIncome(this.personalInfo.incomeFrom, this.personalInfo.incomeTo);
    object.code = this.code || '';
    object.employeeTakecareId = this.data.id;
    object.employeeTakecareName = this.data.name;
    object.demandCount = typeof this.countType.demand === 'undefined' ? 0 : this.countType.demand;
    object.consignmentCount = typeof this.countType.consignment === 'undefined' ? 0 : this.countType.consignment;
    object.contractCount = typeof this.countType.contract === 'undefined' ? 0 : this.countType.contract;
    object.province = this.info.rootAddress.province !== '' ? this.info.rootAddress.province : '';
    object.isPotential = this.isPotential;
    object.interestedArea = this.personalInfo.interestedArea.address || '';
    object.relationshipStatus = this.personalInfo.relationshipStatus || '';
    object.address = this.info.rootAddress;
    object.createdDate = this.createdDate;
    object.updatedDate = this.updatedDate;
    object.totalCall = this.totalCall;
    object.status = this.status;
    object.takeNote = this.takeNote;
    object.taxCode = this.taxCode;
    object.birthdayYear = this.info.birthdayYear  || '';
    object.onlyYear = this.info.onlyYear  || false;
    return object;
  }
  getIncome(incomeFrom, incomeTo) {
    let income = '';
    switch (incomeTo) {
      case 0: {
        income = incomeFrom === 80000000 ? '> 80 triệu' : '';
        break;
      }
      case 5000000:
        income = '< 5 triệu';
        break;
      case 10000000:
        income = '5 triệu - 10 triệu';
        break;
      case 18000000:
        income = '10 triệu - 18 triệu';
        break;
      case 32000000:
        income = '18 triệu - 32 triệu';
        break;
      case 52000000:
        income = '32 triệu - 52 triệu';
        break;
      case 80000000:
        income = '52 triệu - 80 triệu';
        break;
      default:
        income = '> 80 triệu';
        break;
    }
    return income;
  }
  getHeaders(index?) {
    return [
        { header: 'Mã NV O2O', key: 'employeeCode', width: 20 },
        { header: 'Tên NV', key: 'employeeName', width: 50 },
        { header: 'Email NV', key: 'employeeEmail', width: 20 },
        { header: 'ĐVBH', key: 'posName', width: 50 },
        { header: 'Ngày tạo KH', key: 'createdDateString', width: 20 },
        { header: 'Tên KH', key: 'name', width: 50 },
        { header: 'SL cuộc gọi', key: 'totalCall', width: 20 },
      ];
  }
}
export class PersonalInfo {
  @Length(1, 50)
  @IsNotEmpty()
  name: string;

  @Length(1, 20)
  @IsNotEmpty()
  lastName: string;

  fullName: string;

  email: string;
  incomeSource: string;
  job: string;
  relationshipStatus: string;
  interestedArea: Address;
  incomeFrom: number;
  incomeTo: number;

  @Length(8, 20)
  @IsNotEmpty()
  phone: string;

  identities: [IdentityInfo];
  constructor(params?) {
    this.setValue(params);
  }
  setValue(params?) {
    if (params) {
      this.name = params.name || params.firstName || '';
      this.name = this.name.trim();
      this.lastName = params.lastName || '';
      this.lastName = this.lastName.trim();
      this.fullName = this.lastName + ' ' + this.name;
      this.email = params.email || '';
      this.incomeSource = params.incomeSource || '';
      this.relationshipStatus = params.relationshipStatus || '';
      this.interestedArea = params.interestedArea ? new Address(params.interestedArea) : new Address();
      this.job = params.job || '';
      this.incomeFrom = params.incomeFrom || 0;
      this.incomeTo = params.incomeTo || 0;
      this.phone = params.phone || '';
      this.identities = params.identities ? params.identities.map((item) => {
        return new IdentityInfo(item);
      }) : [new IdentityInfo()];
    } else {
      this.setAllNull();
    }
  }
  setAllNull() {
    this.name = '';
    this.lastName = '';
    this.email = '';
    this.incomeSource = '';
    this.relationshipStatus = '';
    this.job = '';
    this.phone = '';
    this.incomeFrom = 0;
    this.incomeTo = 0;
    this.interestedArea = new Address();
    this.identities = [new IdentityInfo()];
  }
  validatePartial() {
    let errors: any = {};
    errors.identity = this.identities[0].validatePartial();
    if (!this.name) {
      errors.name = Constant.ERROR_REQUIRED;
      errors.isExist = true;
    } else if (!this.name.match(Constant.REGEX_TEXT)) {
      errors.name = 'Tên không hợp lệ';
      errors.isExist = true;
    }
    if (!this.lastName) {
      errors.isExist = true;
      errors.lastName = Constant.ERROR_REQUIRED;
    } else if (!this.lastName.match(Constant.REGEX_TEXT)) {
      errors.lastName = 'Họ không hợp lệ';
      errors.isExist = true;
    }
    if (this.email && !this.email.match(Constant.REGEX_EMAIL)) {
      errors.email = 'Email không hợp lệ.';
      errors.isExist = true;
    }

    if (!this.phone) {
      errors.phone = Constant.ERROR_REQUIRED;
      errors.isExist = true;
    }
    if (this.phone && this.phone.length < 8) {
      errors.phone = 'Số điện thoại không hợp lệ.';
      errors.isExist = true;
    }
    if (this.phone && this.phone.length > 20) {
      errors.phone = 'Số điện thoại không hợp lệ.';
      errors.isExist = true;
    }
    return errors;
  }

  validate() {
    let errors: any = {};
    errors.identity = this.identities[0].validate();
    if (!this.name) {
      errors.name = Constant.ERROR_REQUIRED;
      errors.isExist = true;
    } else if (!this.name.match(Constant.REGEX_TEXT)) {
      errors.name = 'Tên không hợp lệ';
      errors.isExist = true;
    }
    if (!this.lastName) {
      errors.isExist = true;
      errors.lastName = Constant.ERROR_REQUIRED;
    } else if (!this.lastName.match(Constant.REGEX_TEXT)) {
      errors.lastName = 'Họ không hợp lệ';
      errors.isExist = true;
    }
    if (this.email && !this.email.match(Constant.REGEX_EMAIL)) {
      errors.email = 'Email không hợp lệ.';
      errors.isExist = true;
    }

    if (!this.phone) {
      errors.phone = Constant.ERROR_REQUIRED;
      errors.isExist = true;
    }
    if (this.phone && this.phone.length < 8) {
      errors.phone = 'Số điện thoại không hợp lệ.';
      errors.isExist = true;
    }
    if (this.phone && this.phone.length > 20) {
      errors.phone = 'Số điện thoại không hợp lệ.';
      errors.isExist = true;
    }

    return errors;
  }

  createObjectRequestAPI() {
    let object: any = {};
    object.name = this.name;
    object.lastName = this.lastName;
    object.email = this.email;
    object.incomeSource = this.incomeSource;
    object.incomeFrom = this.incomeFrom;
    object.incomeTo = this.incomeTo;
    object.relationshipStatus = this.relationshipStatus;
    object.job = this.job;
    object.interestedArea = this.interestedArea.createObjectRequestAPI();
    object.phone = this.phone;
    object.identities = this.identities.map((identity) => {
      return identity.createObjectRequestAPI();
    });
    return object;
  }
}
export class InfoCustomer {
  gender: string;
  birthday: string;
  onlyYear: boolean;
  birthdayYear: string;
  birthDate: any;
  rootAddress: Address;
  address: Address;
  constructor(params?) {
    this.setValue(params);
  }
  setValue(params?) {
    if (params) {
      this.birthday = params.birthday || '';
      this.gender = params.gender || '';
      this.rootAddress = params.rootAddress ? new Address(params.rootAddress) : new Address();
      this.address = params.address ? new Address(params.address) : new Address();
      this.onlyYear = params.onlyYear || false;
      this.birthdayYear = params.birthdayYear || '';
      if (params.birthday && params.birthday !== '//') {
        let date = this.birthday.split('/');
        this.birthDate = {
          day: date[0],
          month: date[1],
          year: date[2]
        };
      } else {
        this.birthDate = {
          day: '',
          month: '',
          year: ''
        };
      }
    } else {
      this.setAllNull();
    }
  }
  setAllNull() {
    this.birthdayYear = '';
    this.onlyYear = false;
    this.birthday = '//';
    this.gender = '';
    this.birthDate = {
      day: '',
      month: '',
      year: ''
    };
    this.address = new Address();
    this.rootAddress = new Address();
  }

  validatePartial() {
    let errors: any = {};
    // if (!this.gender) {
    //   errors.gender = Constant.ERROR_REQUIRED;
    //   errors.isExist = true;
    // }
    if (this.birthday) {
      let split = this.birthday.split('/');
      if (split[0].length != 0 || split[1].length != 0) {
        let date = new Date(split[1] + '/' + split[0] + '/' + split[2]);
        if (date.getDate() != parseInt(split[0]) || date.getMonth() + 1 != parseInt(split[1]) || date.getFullYear() != parseInt(split[2])) {
          errors.birthday = 'Ngày sinh không hợp lệ.';
          errors.isExist = true;
        }
      }
      if (split[2].length !== 0 && parseInt(split[2]) > new Date().getFullYear() ||  new Date().getFullYear() - parseInt(split[2]) > 150) {
        errors.birthday = 'Ngày sinh không hợp lệ.';
        errors.isExist = true;
      };

    }
    errors.address = this.address.validate();
    if (errors.address.isExist) {
      errors.isExist = true;
    }
    errors.rootAddress = this.rootAddress.validate();
    if (errors.rootAddress.isExist) {
      errors.isExist = true;
    }
    return errors;
  }

  validate() {
    let errors: any = {};
    if (this.birthday) {
      let split = this.birthday.split('/');
      if (split[0].length != 0 || split[1].length != 0) {
        let date = new Date(split[1] + '/' + split[0] + '/' + split[2]);
        if (date.getDate() != parseInt(split[0]) || date.getMonth() + 1 != parseInt(split[1]) || date.getFullYear() != parseInt(split[2])) {
          errors.birthday = 'Ngày sinh không hợp lệ.';
          errors.isExist = true;
        }
      }
      if (split[2].length !== 0 && parseInt(split[2]) > new Date().getFullYear() ||  new Date().getFullYear() - parseInt(split[2]) > 150) {
        errors.birthday = 'Ngày sinh không hợp lệ.';
        errors.isExist = true;
      };

    }
    // errors.address = this.address.validate();
    // if (errors.address.isExist) {
    //   errors.isExist = true;
    // }
    // errors.rootAddress = this.rootAddress.validate();
    // if (errors.rootAddress.isExist) {
    //   errors.isExist = true;
    // }
    return errors;
  }
  createObjectRequestAPI() {
    let object: any = {};
    object.gender = this.gender;
    object.rootAddress = this.rootAddress.createObjectRequestAPI();
    object.address = this.address.createObjectRequestAPI();
    object.birthday = this.birthday;
    object.birthdayYear = this.birthdayYear;
    object.onlyYear = this.onlyYear;
    return object;
  }
}

export class IdentityInfo {
  type: string;

  value: string;

  date: string;

  place: string;
  constructor(params?) {
    this.setValue(params);

  }
  setValue(params?) {
    if (params) {
      this.type = params.type || 'CMND';
      this.value = params.value || '';
      this.date = params.date || '';
      this.place = params.place || '';
    } else {
      this.setAllNull();
    }
  }
  setAllNull() {
    this.type = 'CMND';
    this.value = '';
    this.date = '';
    this.place = '';
  }
  validatePartial() {
    let errors: any = {};
    if (this.type && !this.value) {
      errors.value = 'Số giấy tờ phải được nhập';
      errors.isExist = true;
    } else if (this.value && this.value.length < 8) {
      errors.value = 'Số giấy tờ không hợp lệ.';
      errors.isExist = true;
    } else if (this.value && this.value.length > 20) {
      errors.value = 'Số giấy tờ không hợp lệ.';
      errors.isExist = true;
    }
    if (!this.date) {
      errors.date = 'Ngày cấp phải được nhập';
      errors.isExist = true;
    } else {
      let date = new Date(this.date);
      let year = date.getFullYear();
      if (new Date().getFullYear() - year > 150) {
        errors.date = 'Ngày cấp không hợp lệ.';
        errors.isExist = true;
      }
    }
    return errors;
  }
  validate() {
    let errors: any = {};
    if (!this.type) {
      errors.type = Constant.ERROR_REQUIRED;
      errors.isExist = true;
    }
    // if (this.type && !this.value) {
    //   errors.value = 'Số giấy tờ phải được nhập';
    //   errors.isExist = true;
    // } else
    if (this.value && this.value.length < 8) {
      errors.value = 'Số giấy tờ không hợp lệ.';
      errors.isExist = true;
    } else if (this.value && this.value.length > 20) {
      errors.value = 'Số giấy tờ không hợp lệ.';
      errors.isExist = true;
    }

    if (this.date) {
      let date = new Date(this.date);
      let year = date.getFullYear();
      if (new Date().getFullYear() - year > 150) {
        errors.date = 'Ngày cấp không hợp lệ.';
        errors.isExist = true;
      };
    }

    return errors;
  }
  createObjectRequestAPI() {
    let object: any = {};
    object.type = this.type;
    object.value = this.value;
    object.date = this.date;
    object.place = this.place;
    return object;
  }
  createObjectCheckKey() {
    let object: any = {};
    object.type = this.type;
    object.value = this.value;
    return object;
  }
}

export class BankInfo {

  bank: string;

  value: string;

  branch: string;
  accountNumber: string;
  syntaxTransfer: string;
  code: string;
  name: string;
  logo: string;
  type: string;
  index: string;

  constructor(params?) {
    this.setValue(params);
  }
  setValue(params?) {
    if (params) {
      this.bank = params.CODE || params.code || '';
      this.value = params.NAME || params.name || '';
      this.branch = params.branch || '';
      this.accountNumber = params.accountNumber || '';
      this.syntaxTransfer = params.syntaxTransfer || '';
      this.code = params.CODE || params.code || '';
      this.name = params.NAME || params.name || '';
      this.logo = params.LOGO || params.logo || '';
      this.type = params.TYPE || params.type || '';
      this.index = params.INDEX || params.index || '';
    } else {
      this.setAllNull();
    }
  }
  setAllNull() {
    this.bank = 'VIETCOMBANK';
    this.value = '';
    this.branch = '';
    this.accountNumber = '';
    this.syntaxTransfer = '';
    this.code = '';
    this.name = '';
    this.logo = '';
    this.type = '';
    this.index = '';
  }
  createObjectRequestAPI() {
    let object: any = {};
    object.type = this.bank;
    object.value = this.value;
    object.branch = this.branch;
    object.accountNumber = this.accountNumber;
    object.syntaxTransfer = this.syntaxTransfer;
    object.CODE = this.code;
    object.NAME = this.name;
    object.LOGO = this.logo;
    object.TYPE = this.type;
    return object;
  }
}
