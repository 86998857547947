import { Component, OnInit, ViewChild, HostListener, OnDestroy, AfterContentInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRouteSnapshot, UrlSegment } from '@angular/router';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { MenuService } from '../theme/components/menu/menu.service';
import { AuthorizeService } from '../shared/services/common/authorize.service';
import { SecurityService, StorageService } from '../shared/services';
import { EventChangeService } from 'app/shared/services/event.change.service';
import { UserService } from 'app/shared/services/common/user.service';
import { VoiceCallService } from 'app/shared/services/voicecall.service';
import { UserV2Service } from 'app/shared/services/common/user-v2.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Menu } from 'app/theme/components/menu/menu.model';
import { MatSidenav, MatDialog } from '@angular/material';
import { PopupUpdateFeatureComponent } from './project/event-sale/popup-update-feature/popup-update-feature.component';
import { environment } from 'environments/environment';
import { Constant } from 'app/shared/constant/constant';
declare var StringeeClient: any;
export const STORAGE_UPDATE_FEATURE = 'STORAGE_UPDATE_FEATURE';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  // providers: [MenuService]
})
export class PagesComponent implements OnInit, OnDestroy, AfterContentInit {
  public destroyUnsubscribe: ReplaySubject<any> = new ReplaySubject<any>(1);

  @ViewChild('sidenav') sidenavRef: MatSidenav;
  public settings: Settings;
  public menus = ['vertical', 'horizontal'];
  public menuOption: string;
  public menuTypes = ['default', 'compact', 'mini'];
  public menuTypeOption: string;
  public isStickyMenu: boolean = false;
  public lastScrollTop: number = 0;
  public showBackToTop: boolean = false;
  public toggleSearchBar: boolean = false;
  public pageTitle: string = 'Management';
  private defaultMenu: string; // declared for return default menu when window resized
  public scrolledContent: any;
  public smallSidenavMode: boolean = false;
  public releaseDate: string;
  isMenuToggleMobile: boolean = false;
  notAllowLogin: boolean = false;

  constructor(
    public appSettings: AppSettings,
    public router: Router,
    private menuService: MenuService,
    private authorizeService: AuthorizeService,
    private storage: StorageService,
    private securityService: SecurityService,
    private eventChangeService: EventChangeService,
    private userService: UserService,
    private voiceCallService: VoiceCallService,
    private userV2Service: UserV2Service,
    private dialog: MatDialog,
  ) {
    this.settings = this.appSettings.settings;

    this.menuService.menuClick$.subscribe((menu: Menu) => {
      // this.toggleSidenav();
    });
    this.releaseDate = this.storage.retrieveLocal(STORAGE_UPDATE_FEATURE) || '';

  }

  ngAfterContentInit() {
    if (!environment.releaseDate.includes(this.releaseDate)) {
      this.openPopupUpdateFeature();
    }
  }

  openPopupUpdateFeature() {
    const createGroupPopup = this.dialog.open(PopupUpdateFeatureComponent, {
      width: '650px',
      data: {
      },
      disableClose: true,
      panelClass: 'no-padding-dialog-container'
    });
    createGroupPopup.afterClosed().subscribe((result: any) => {
      if (result) {
        this.storage.storeLocal(STORAGE_UPDATE_FEATURE, environment.releaseDate);
      }
    });
  }
  private setupPermission() {
    return this.authorizeService.hasAuthority(['USER', 'ALLOW', 'LOGIN', 'WEB', 'ADMIN'])
      .then(isHasRole => {
        this.notAllowLogin = !isHasRole;
      });
  }
  async ngOnInit() {
    await this.setupPermission();
    this.userV2Service.getCurrentUser().subscribe(res => {
      if (res.id) {
        this.loadVoiceCall();
      }
    });

    this.userV2Service.getCurrentEmployee()
      .pipe(takeUntil(this.destroyUnsubscribe))
      .subscribe((res: any) => {
        const isFullPermission = this.storage.retrieve('isFullPermission');
        if (this.notAllowLogin && !isFullPermission) {
          this.router.navigate([environment.forbiddenPageURL]);
        }
      });

    if (window.innerWidth <= 768) {
      this.settings.menu = 'vertical';
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
      this.isMenuToggleMobile = true;
    }
    this.settings.sidenavUserBlock = false;
    this.menuOption = this.settings.menu;
    this.menuTypeOption = this.settings.menuType;
    this.defaultMenu = this.settings.menu;

    if (this.authorizeService.checkAuthorities()) {
      let permissions = this.storage.retrieve('permissions');
      if (permissions) {
        let authorities = JSON.parse(permissions);
        if (authorities) {
          this.authorizeService.setAuthorities(authorities);
        }
      }
      let isFullPermission = this.storage.retrieve('isFullPermission');
      this.authorizeService.setIsFullPermission(isFullPermission);
      if (!permissions && !isFullPermission) {
        if (this.router.url.length > 1 && this.router.url !== '/login') {
          this.storage.store('callbackUrl', this.router.url);
        }
        this.router.navigate(['/login']);
      }
    } else {
      if (this.router.url.length > 1 && this.router.url !== '/login') {
        this.storage.store('callbackUrl', this.router.url);
      }
      this.router.navigate(['/login']);
    }

    this.parseRoute(this.router.routerState.snapshot.root);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.parseRoute(this.router.routerState.snapshot.root);
      }
    });
    this.eventChangeService.emitChangeSource.subscribe((data) => {
      switch (data.broadcast) {
        case 'log-out':
          this.logout();
          break;
      }
    });
  }

  ngOnDestroy() {
    this.destroyUnsubscribe.next(null);
  }

  logout() {
    this.storage.remove('authorizationData');
    this.storage.remove('voice_call_id');
    this.storage.remove('voice_call_token');
    this.storage.remove('voice_call_authen_token');
    this.router.navigate(['/login']);
  }

  private parseRoute(node: ActivatedRouteSnapshot) {
    if (node.data['breadcrumb']) {
      if (node.url.length) {
        this.pageTitle = node.data['breadcrumb'] || 'Management';
      }
    }
    if (node.firstChild) {
      this.parseRoute(node.firstChild);
    }
  }

  // public chooseMenu() {
  //   this.settings.menu = this.menuOption;
  //   this.defaultMenu = this.menuOption;
  //   this.router.navigate(['/dashboard']);
  // }

  public chooseMenuType() {
    this.settings.menuType = this.menuTypeOption;
  }

  public changeTheme(theme) {
    this.settings.theme = theme;
    this.changeBackgroupForStepper();
  }

  private changeBackgroupForStepper() {
    setTimeout(() => {
      const bg = $('app-pages > mat-sidenav-container').css('background-color');
      $('head').append(`<style>
      .mat-horizontal-stepper-header-container .mat-step-icon:after{border-left-color:${bg} !important;}
      .mat-horizontal-stepper-header-container .mat-step-header {border-color: ${bg};}
      </style>`);
    }, 0);
  }

  public toggleSidenav() {
    if(window.innerWidth <= 768){
      if(this.sidenavRef.opened) return this.sidenavRef.close();
    }
    this.sidenavRef.close();
    setTimeout(() => {
      if(window.innerWidth > 768){
        this.smallSidenavMode = !this.smallSidenavMode;
      }
      this.sidenavRef.open();
    }, 100);
  }

  public onPsScrollY(event) {
    this.scrolledContent = event.target;
    (this.scrolledContent.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;
    if (this.settings.menu == 'horizontal') {
      if (this.settings.fixedHeader) {
        var currentScrollTop = (this.scrolledContent.scrollTop > 56) ? this.scrolledContent.scrollTop : 0;
        (currentScrollTop > this.lastScrollTop) ? this.isStickyMenu = true : this.isStickyMenu = false;
        this.lastScrollTop = currentScrollTop;
      }
      else {
        (this.scrolledContent.scrollTop > 56) ? this.isStickyMenu = true : this.isStickyMenu = false;
      }
    }
  }

  public scrollToTop() {
    var scrollDuration = 200;

    var scrollStep = -this.scrolledContent.scrollTop / (scrollDuration / 20);
    var scrollInterval = setInterval(() => {
      if (this.scrolledContent.scrollTop != 0) {
        this.scrolledContent.scrollBy(0, scrollStep);
      }
      else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      this.scrolledContent.scrollTop = 0;
    }
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    if (window.innerWidth <= 768) {
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
      this.settings.menu = 'vertical';
      this.isMenuToggleMobile = true;
    }
    else {
      (this.defaultMenu == 'horizontal') ? this.settings.menu = 'horizontal' : this.settings.menu = 'vertical'
      this.settings.sidenavIsOpened = true;
      this.settings.sidenavIsPinned = true;
      this.isMenuToggleMobile = false;
    }
  }

  public closeSubMenus() {
    let menu = document.querySelector(".sidenav-menu-outer");
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }

  loadVoiceCall() {
    this.voiceCallService.getCallToken().then(res => {
      const stringeeClient = new StringeeClient();
      this.voiceCallService.settingClientEvents(stringeeClient);
      stringeeClient._stringeeServerAddr = 'https://dx-v2.stringee.com';
      stringeeClient.connect(res);
    });
  }

  answerCall() {
    this.voiceCallService.answerCall();
  }

  rejectCall() {
    this.voiceCallService.rejectCall();
  }

  hangupCall() {
    this.voiceCallService.hangupCall();
  }

}
