import { DateToFormat } from 'app/shared/parse/date-to-format';
import { TransformTypeTransaction } from 'app/shared/pipe/transformType.pipe';

// TODO refactor
export class CReportTicket {
  id: string;
  timeReceive: string;
  timeProcess: string;
  ticketCode: string;
  code: string;
  type: string;
  name: string;
  phone: string;
  address: string;
  description: string;
  createdDate: string;
  receiveDate: string;
  receiver: string;
  status: string;
  reason: string;
  processedHistory: any;
  modifiedDate: string;
  processedTicketDate: string;

  constructor(
    param?: any
  ) {
    this.setValue(param);
  }

  static getType() {
    return 'ticket';
  }

  setValue(params: any) {
    if (params) {
      this.id = params.id;
      this.timeReceive = (params.createdDate && params.assignedDate) ? this.calculateMinuteDiff(params.createdDate, params.assignedDate) : '';
      this.timeProcess = (params.assignedDate && params.processedTicketDate) ? this.calculateMinuteDiff(params.assignedDate, params.processedTicketDate) : '';
      this.ticketCode = params.code || '';
      this.code = params.processedTicketCode || '';
      this.type = params.type ? TransformTypeTransaction.transform(params.type) : '';
      this.name = this.getName(params) || '';
      // this.phone = params.customer ? (params.customer.personalInfo ? params.customer.personalInfo.phone : params.phone) : params.phone;
      this.phone = '';
      this.address = params.customer ? (params.customer.info ? this.getFullRootAddress(params.customer.info.address, params.address) : params.address) : params.address;
      this.description = params.description || '';
      this.createdDate = params.createdDate ? DateToFormat.parseDate(params.createdDate, 'dd/MM/yyyy') : '';
      this.receiveDate = params.assignedDate ? DateToFormat.parseDate(params.assignedDate, 'dd/MM/yyyy') : '';
      this.receiver = params.employeeTakeCare ? params.employeeTakeCare.name : '';
      this.status = params.lifeCycleStatus ? this.getDisplayStatus(params.lifeCycleStatus) : '';
      params.processedHistory = params.processedHistory ? params.processedHistory : [];
      this.reason = params.processedHistory.length > 0 ? this.transformReason(params.processedHistory) : '';
      this.processedHistory = params.processedHistory;
      this.modifiedDate = params.modifiedDate ? DateToFormat.parseDate(params.modifiedDate, 'dd/MM/yyyy') : '';
      this.processedTicketDate = params.processedTicketDate ? DateToFormat.parseDate(params.processedTicketDate, 'dd/MM/yyyy') : '';
    } else {
      this.setAllNull();
    }
  }

  setAllNull() {
    // set header
    this.id = '';
    this.timeReceive = '';
    this.timeProcess = '';
    this.ticketCode = '';
    this.code = '';
    this.type = '';
    this.name = '';
    this.phone = '';
    this.address = '';
    this.description = '';
    this.createdDate = '';
    this.receiveDate = '';
    this.receiver = '';
    this.status = '';
    this.reason = '';
    this.processedHistory = [];
    this.modifiedDate = '';
    this.processedTicketDate = '';
  }

  getHeaders(index) {
    if (index === 1) {
      return [
        { header: 'Thời gian tiếp nhận yêu cầu', key: 'timeReceive', width: 15 },
        { header: 'Thời gian xử lý', key: 'timeProcess', width: 20 },
        { header: 'Mã YCTV', key: 'ticketCode', width: 20 },
        { header: 'Mã khảo sát', key: 'code', width: 20 },
        { header: 'Loại nhu cầu', key: 'type', width: 20 },
        { header: 'Tên khách hàng', key: 'name', width: 20 },
        // { header: 'Số ĐT khách hàng', key: 'phone', width: 20 },
        { header: 'Địa chỉ KH', key: 'address', width: 30 },
        { header: 'Mô tả yêu cầu', key: 'description', width: 50 },
        { header: 'Ngày yêu cầu', key: 'createdDate', width: 13 },
        { header: 'Ngày tiếp nhận', key: 'receiveDate', width: 13 },
        { header: 'Người tiếp nhận', key: 'receiver', width: 20 },
        { header: 'Trạng thái', key: 'status', width: 20 },
        // { header: 'Lý do trả về', key: 'reason', width: 20 },
        { header: 'Ngày cập nhật gần nhất', key: 'processedTicketDate', width: 13 },
      ];
    }
    return [
      { header: 'Thời gian tiếp nhận yêu cầu', key: 'timeReceive', width: 15 },
      // { header: 'Thời gian xử lý', key: 'timeProcess', width: 20 },
      { header: 'Mã YCTV', key: 'ticketCode', width: 20 },
      // { header: 'Mã khảo sát', key: 'code', width: 20 },
      { header: 'Loại nhu cầu', key: 'type', width: 20 },
      { header: 'Tên khách hàng', key: 'name', width: 20 },
      // { header: 'Số ĐT khách hàng', key: 'phone', width: 20 },
      { header: 'Địa chỉ KH', key: 'address', width: 30 },
      { header: 'Mô tả yêu cầu', key: 'description', width: 50 },
      { header: 'Ngày yêu cầu', key: 'createdDate', width: 13 },
      { header: 'Ngày tiếp nhận', key: 'receiveDate', width: 13 },
      { header: 'Người tiếp nhận', key: 'receiver', width: 20 },
      { header: 'Trạng thái', key: 'status', width: 20 },
      // { header: 'Lý do trả về', key: 'reason', width: 20 },
      // { header: 'Ngày cập nhật gần nhất', key: 'modifiedDate', width: 13 },
    ];
  }
  getDisplayStatus(status: string) {
    let statusMappings = {
      'inpool': 'Chưa phân bổ',
      'assigned': 'Đã phân bổ',
      'processing': 'Đang thực hiện',
      'in-process': 'Đang thực hiện',
      'pending': 'Trì hoãn',
      'compeleted': 'Hoàn thành',
      'completed': 'Hoàn thành',
      'removed': 'Bị xóa'
    };
    const lowerStatus = status.toLocaleLowerCase();
    return statusMappings[lowerStatus];
  }
  getFullRootAddress(info, address): string {
    let result = '';
    const dashSpace = ', ';
    info.address ? result += `${dashSpace + info.address}` : result += '';
    info.ward ? result += `${dashSpace + info.ward}` : result += '';
    info.district ? result += `${dashSpace + info.district}` : result += '';
    info.province ? result += `${dashSpace + info.province}` : result += '';
    result = result.indexOf(',') === 0 ? result.substring(1) : result;
    return (result && result !== '') ? result : address;
  }
  getName(params) {
    let customer = '';
    if (params.customer) {
      if (params.customer.personalInfo) {
        const firstName = params.customer.personalInfo.firstName || '';
        const lastName = params.customer.personalInfo.lastName || '';
        const name = params.customer.personalInfo.name || '';
        customer = firstName + ' ' + lastName + ' ' + name;
      }
    }
    return customer.trim() !== '' ? customer : params.name;
  }
  calculateMinuteDiff(from, to) {
    const fromDate: number = new Date(from).getTime();
    const toDate: number = new Date(to).getTime();
    const diffMs = Math.abs(toDate - fromDate);
    return (Math.floor(diffMs / 60000) + 1) + ' phút';
  }
  transformReason(processedHistory) {
    return processedHistory.map(reason => reason.employeeTakeCare.name + ': ' + reason.causeReject.join(', ')).join('\r\n');
  }
}
