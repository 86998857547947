import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { DataService } from '../data.service';
import { HttpService } from '../http.service';
import { CDocument } from 'app/api-models/c-document';
import { map, delay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EUploadType } from 'app/enums/e-upload-type.enum';
import { StorageService } from '..';
import { ConstantUrl } from 'app/shared/constant/url';
import { Constant } from 'app/shared/constant/constant';

@Injectable()
export class UploadFileService {

  FOLDER = 'jsa-s3/';
  url = ConstantUrl.url_upload;
  url_mobile = ConstantUrl.url_upload_mobile;
  constructor(
    private http: DataService,
    private httpService: HttpService,
    private storageService: StorageService
  ) { }


  uploadFileV2(files: File[], typeValidate?: EUploadType): Observable<CDocument[]> {
    const params = { files, typeValidate: typeValidate };
    return this.httpService.post(this.url, this.httpService.convertAnyToFormData(params))
      .pipe(
        delay(1000),
        map((resApi: any) => new CDocument().decodeList(resApi))
      );
  }

  uploadFile(files, typeValidate?, createThumbnail?) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      if (files.length) {
        for (let file of files) {
          formData.append("files", file);
        }
      } else {
        formData.append("files", files);
      }
      if (typeValidate) {
        formData.append("typeValidate", typeValidate);
      }

      if (createThumbnail) {
        formData.append("createThumbnail", createThumbnail);
      }
      return this.http.post(this.url, formData, null, 180000)
        .subscribe((response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        })
    })

  }

  public uploadDocument(files: File[], url, datas?): Promise<any> {
    let formData = new FormData();
    for (let file of files) {
      formData.append('files', file);
    }
    if (datas) {
      for (let data of datas) {
        formData.append(data.key, data.value);
      }
    }
    return this.http.post(this.url + url, formData).toPromise().then((res) => res.json());
  }

  public uploadFileStringee(file: File): Promise<any> {
    let url = environment.urlStringeeUploadFile;
    let formData = new FormData();
    formData.append('file', file);
    const headers = { 'X-STRINGEE-AUTH': this.storageService.retrieve('stringeeToken') };
    return this.http.post(url, formData, headers).toPromise().then((res) => res.json());
  }

  public uploadAvatar(id: string, files: File[]): Observable<any> {
    const params = { files };
    return this.httpService.post(this.url_mobile + '/avatar/' + id, this.httpService.convertAnyToFormData(params));
  }

  public uploadFileESaleKit(files: File): Observable<any> {
    let formData = new FormData();
    formData.append('files', files);
    formData.append('featureName', 'esalekit');
    formData.append('id', '3');
    formData.append('createThumbnail', 'true');
    return this.httpService.post(this.url + '/upload-file', formData);
  }

  uploadFileVideoStore(files) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("files", files);
      formData.append("typeValidate", "video");
      formData.append('createThumbnail', 'true');
      return this.http.post(this.url, formData, null, 180000)
        .subscribe((response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        })
    })
  }

  getDurationByIdYoutube(id) {
      return new Promise((resolve, reject) => {
        const url = ConstantUrl.url_upload + '/getDurationYoutube/' + id;
        return this.httpService.get(url).subscribe((response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        });
    })
  }
}
