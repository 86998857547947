<ng-container *ngIf="!isProfile">
  <div class="app-input-icon">
    <input #inputDate rInputMask="99/99/9999" disabled="{{isDisabled}}" class="app-input" name="dp" ngbDatepicker [(ngModel)]="datepickerValue"
      #datepicker="ngbDatepicker" (dateSelect)="onDateSelection()" [minDate]="minDateNgbDate" [maxDate]="maxDateNgbDate"
      (blur)="callBlur()" [placeholder]="privatePlaceholder">
    <img class="input-icon" src="assets/img/icon/calendar.svg" (click)="datepicker.toggle()">
  </div>

  <ng-container *ngIf="isValidateDefault">
    <span *ngIf="isInvalidDate()" class="app-error"> Thông tin không hợp lệ </span>
    <span *ngIf="!datepickerValue" class="app-error"> Bạn chưa nhập thông tin </span>
  </ng-container>
</ng-container>

<ng-container *ngIf="isProfile">
  <div class="flex-input">
    <div class="input-date app-input-icon">
      <input #inputDate rInputMask="99/99/9999" disabled="{{isDisabled}}" class="app-input-profile app-input" name="dp" ngbDatepicker [(ngModel)]="datepickerValue"
      #datepicker="ngbDatepicker" (dateSelect)="onDateSelection()" [minDate]="minDateNgbDate" [maxDate]="maxDateNgbDate"
      (blur)="callBlur()" [placeholder]="privatePlaceholder">
      <img class="input-icon" src="assets/img/profile/calendar.svg" (click)="datepicker.toggle()">
      <div class="input-underline"></div>
    </div>
  </div>
</ng-container>