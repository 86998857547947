<mat-card class="custom-card" *ngIf="data.reject; else confirm">
  <div fxLayout="row" fxLayoutAlign="start center" class="title-area">
    <h1 class="title-warning">{{ data.title }}</h1>
  </div>
  <div mat-dialog-content class="content-area">
    <div fxLayout="column" fxLayoutAlign="space-around">
      <span>Bạn có muốn từ chối đề nghị thu tiền này không ?</span>
      <input type="text" placeholder="Nhập lý do từ chối" matInput class="reason-input" [(ngModel)]="data.reason" />
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <div class="action-area">
      <button class="action-btn" mat-raised-button color="warn" mat-button (click)="execute()" [disabled]="!data.reason">{{ data.action }}</button>
      <button mat-button class="m-black action-btn cancel" (click)="onNoClick()">Hủy bỏ</button>
    </div>
  </div>
</mat-card>

<ng-template #confirm>
  <mat-card class="custom-card" *ngIf="data.confirm; else liquidate">
    <div fxLayout="row" fxLayoutAlign="start center" class="title-area">
      <h1 class="title-confirm">{{ data.title }}</h1>
    </div>
    <div mat-dialog-content class="content-area">
      <div fxLayout="column" fxLayoutAlign="space-around">
        <span> Bạn có muốn tiếp tục yêu cầu này không ?</span>
      </div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <div class="action-area">
        <button class="m-bg m-bg-primary action-btn" mat-button (click)="execute()">{{ data.action }}</button>
        <button mat-button class="m-black action-btn cancel" (click)="onNoClick()">Hủy bỏ</button>
      </div>
    </div>
  </mat-card>
</ng-template>

<ng-template #liquidate>
  <mat-card class="custom-card" *ngIf="data.liquidate; else approve">
  <div fxLayout="column" class="confirm"  fxLayoutAlign="center">
    <div fxLayout="row" fxLayoutAlign="center" style="margin-bottom: 15px;">
      <h3>{{data.title}}</h3>
    </div>
    <div mat-dialog-content>
      <div fxLayout="column" fxLayoutAlign="space-around center">
        <span>Bạn chắc chắn muốn thực hiện thao tác này</span>
      </div>
    </div>
    <div mat-dialog-actions fxLayoutAlign="end center">
      <div fxLayout="row">
        <button class="m-bg m-bg-green" mat-button (click)="execute()">Xác nhận</button>
        <button mat-button (click)="onNoClick()">Hủy bỏ</button>
      </div>
    </div>
  </div>
</mat-card>
</ng-template>

<ng-template #approve>
  <mat-card class="custom-card" *ngIf="data.approve; else edit">
    <div fxLayout="row" fxLayoutAlign="start center" class="title-area">
      <h1 class="title-warning">{{ data.title }}</h1>
    </div>
    <div mat-dialog-content class="content-area" fxLayout="column" fxLayoutGap="8px">
      <div fxLayout="column" fxLayoutAlign="space-around">
        <span>Bạn có muốn duyệt đề nghị thu tiền này không ?</span>
        <input type="text" placeholder="Nhập ghi chú" matInput class="reason-input" [(ngModel)]="data.reason" />
      </div>

      <div fxLayout="column" fxLayoutAlign="space-around">
        <input type="text" placeholder="Số chứng từ" matInput class="reason-input" [(ngModel)]="data.items.receiptNum" />
      </div>

      <div fxLayout="column" fxLayoutAlign="space-around">
        <input type="text" placeholder="Lý do thanh toán" matInput class="reason-input" [(ngModel)]="data.items.description" />
      </div>

      <div fxLayout="column" fxLayoutAlign="space-around">
        <input type="text" placeholder="Nhập ngày giờ nhận tiền (DD/MM/YYYY HH:mm:ss | DD-MM-YYYY HH:mm:ss)" matInput class="reason-input" (change)="onChangeDate()" [(ngModel)]="receiptDate" />
        <mat-error
        *ngIf="receiptDateInvalid">
        <span>{{Constant.ERROR_INVALID}}</span>
      </mat-error>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-around">
        <span>Phương thức giao dịch<span class="m-red">*</span></span>
      </div>
      <div fxLayout="row">
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="100">
          <mat-radio-group [(ngModel)]="data.items.stateKT" fxLayout="row" fxFlex="100">
            <mat-radio-button fxFlex="50" *ngFor="let cash of listTransferType" [value]="cash.key" (change)="changeTransferType($event)">
              {{cash.value}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <section>
        <div fxLayout="row" fxFlex="100">
          <mat-form-field fxFlex="100">
            <mat-select [(ngModel)]="data.items.bankInfo" placeholder="Chọn tài khoản nhận tiền">
              <mat-option *ngFor="let item of dropdownBanks" [value]="item.key">
                {{item.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </section>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <div class="action-area">
        <button class="action-btn" mat-raised-button color="warn" mat-button (click)="execute()" 
        [disabled]="!data.reason || receiptDateInvalid || !data.items.bankInfo || !data.items.receiptDate || !data.items.description">{{ data.action }}</button>
        <button mat-button class="m-black action-btn cancel" (click)="onNoClick()">Hủy bỏ</button>
      </div>
    </div>
  </mat-card>
</ng-template>

<ng-template #edit>
  <mat-card class="custom-card">
    <div fxLayout="row" fxLayoutAlign="start center" class="title-area">
      <h1 class="title-warning">{{ data.title }}</h1>
    </div>
    <div mat-dialog-content class="content-area" fxLayout="column" fxLayoutGap="8px">
      <div fxLayout="column" fxLayoutAlign="space-around">
        <span>Bạn có muốn chỉnh sửa không ?</span>
        <input type="text" placeholder="Nhập ghi chú" matInput class="reason-input" [(ngModel)]="data.reason" />
      </div>

      
      <div fxLayout="column" fxLayoutAlign="space-around">
        <input type="text" placeholder="Số chứng từ" matInput class="reason-input" [(ngModel)]="data.items.receiptNum" />
      </div>

      <div fxLayout="column" fxLayoutAlign="space-around">
        <input type="text" placeholder="Lý do thanh toán" matInput class="reason-input" [(ngModel)]="data.items.description" />
      </div>

      <div fxLayout="column" fxLayoutAlign="space-around">
        <input type="text" placeholder="Nhập ngày giờ nhận tiền (DD/MM/YYYY HH:mm:ss | DD-MM-YYYY HH:mm:ss)" matInput class="reason-input" (change)="onChangeDate()" [(ngModel)]="receiptDate" />
        <mat-error
        *ngIf="receiptDateInvalid">
        <span>{{Constant.ERROR_INVALID}}</span>
      </mat-error>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-around">
        <span>Phương thức giao dịch<span class="m-red">*</span></span>
      </div>
      <div fxLayout="row">
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="100">
          <mat-radio-group [(ngModel)]="data.items.stateKT" fxLayout="row" fxFlex="100">
            <mat-radio-button fxFlex="50" *ngFor="let cash of listTransferType" [value]="cash.key" (change)="changeTransferType($event)">
              {{cash.value}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <section>
        <div fxLayout="row" fxFlex="100">
          <mat-form-field fxFlex="100">
            <mat-select [(ngModel)]="data.items.bankInfo" placeholder="Chọn tài khoản nhận tiền">
              <mat-option *ngFor="let item of dropdownBanks" [value]="item.key">
                {{item.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </section>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <div class="action-area">
        <button class="action-btn" mat-raised-button color="warn" mat-button (click)="execute()" 
          [disabled]="!data.reason || receiptDateInvalid || !data.items.receiptNum || !data.items.description|| !data.items.bankInfo || !data.items.receiptDate">{{ data.action }}</button>
        <button mat-button class="m-black action-btn cancel" (click)="onNoClick()">Hủy bỏ</button>
      </div>
    </div>
  </mat-card>
</ng-template>
