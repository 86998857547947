<ng-select [items]="items"
            [disabled]="isDisabled"
            [bindLabel]="bindLabel"
            [bindValue]="bindValue"
            [placeholder]="placeholder"
            (change)="onChangeSelection($event)"
            (search)="onSearch($event)"
            (ngModelChange)="onChangeNgModel($event)"
            [clearable]="clearable"
            (blur)="callBlur()"
            [appendTo]="appendTo"
            [multiple]="multiple"
            [notFoundText]="notFoundText"
            [ngModel]="ngModelValue"
            [virtualScroll]="true"
            (scrollToEnd)="onScrollToEnd()"
            [loading]="isLoading">
            <ng-template ng-option-tmp let-item="item">
                <div class="overflow-select" [title]="item[bindLabel] ? item[bindLabel] : item">{{item[bindLabel] ? item[bindLabel] : item}}</div>
            </ng-template>
</ng-select>
