import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { SaleList } from '../sale-list.model';

@Component({
  selector: 'app-popup-confirm-queue',
  styleUrls: ['./popup-confirm-queue.component.scss'],
  templateUrl: './popup-confirm-queue.component.html',
})
export class PopupConfirmQueue implements OnInit  {
  public item: SaleList;
  public unQueue: boolean;
  constructor(
    public dialogRef: MatDialogRef<PopupConfirmQueue>,
    @Inject(MAT_DIALOG_DATA) public data,
    ) {
        this.item = this.data.data;
        this.unQueue = this.data.unQueue;
    }

  ngOnInit() {
  }

  execute(): void {
    let data = {isSuccess: true,item: this.item};
    this.dialogRef.close(data);
  }

  onNoClick(): void {
    let data = {isSuccess: false, item: this.item};
    this.dialogRef.close(data);
  }
}
