<div class="app-input-icon">
  <input #inputDate disabled="{{isDisabled}}" class="app-input" name="dp" ngbDatepicker [(ngModel)]="datepickerValue" #datepicker="ngbDatepicker"
    (dateSelect)="onDateSelection()" [minDate]="minDateNgbDate" [maxDate]="maxDateNgbDate" (blur)="callBlur()" [placeholder]="privatePlaceholder" >
  <img class="input-icon" src="assets/img/icon/calendar.svg" (click)="datepicker.toggle()">
</div>

<ng-container>
  <span *ngIf="isInvalidDate()" class="app-error"> {{validErrorMsg ? validErrorMsg : 'Thông tin không hợp lệ'}} </span>
  <span *ngIf="!datepickerValue" class="app-error"> {{ requiredErrorsMsg ? requiredErrorsMsg: 'Bạn chưa nhập thông tin'}} </span>
</ng-container>
