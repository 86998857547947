<ag-grid-angular id="grid-kyc" class="ag-theme-balham" [rowData]="pagination" [columnDefs]="columnDefs" [gridOptions]="gridOptions"
  (gridReady)="onGridReady($event)">
</ag-grid-angular>

<ng-template #createdDateRow let-row>
  <span fxLayout="row" fxLayoutAlign="center">
    {{row.createdDate | date: "dd/MM/yyyy hh:mm:ss"}}
  </span>
</ng-template>
<ng-template #detailRow let-row>
  <ng-container *ngFor="let message of row.errorMessages">
    <p>{{ message }}</p>
  </ng-container>
</ng-template>
<!-- <ng-template #actionRow let-row>
  <a href="javascript:;" (click)="openDetail(row)">
    <span fxLayout="row" fxLayoutAlign="center">
    </span>
  </a>
</ng-template> -->