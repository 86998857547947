<div class="confirm-popup-v3">
    <mat-icon *ngIf="data.viewCloseBtn" class="close-button" (click)="onNoClick()"><img src="../../../../../assets/img/icon/close-btn-checkin.svg"></mat-icon>
    <div class="title-header-v3" >
        {{data.title}}
    </div>
    <div mat-dialog-content [ngStyle]="{ 'padding': data.contentPadding ? data.contentPadding + 'px 0' : '0 30px' }">
        <div fxLayout="column" fxLayoutAlign="space-around center">
            <div style="text-align: center;font-size: 16px" [ngStyle]="{ 'padding': data.messagePadding ? '0 ' + data.messagePadding + 'px' : '0' }"  [innerHTML]="data.message"></div>

            <div *ngIf="data.isCancelTicket" fxLayout="row" style="width: 100%; margin-top: 15px;">
            <div fxFlex="50" class="app-form-group" style="margin-right: 16px;">
                <app-select [clearable]="false" [(ngModel)]="bankName" [items]="banksDropdown" bindLabel="NAME"
                bindValue="CODE" placeholder="Ngân hàng">
                </app-select>
            </div>
            <div fxFlex="50" class="app-form-group">
                <input [(ngModel)]="accountNo" [textMask]="{mask: numberBankMask}" class="app-input"
                placeholder="Số tài khoản ngân hàng" />
            </div>
            </div>
            <div fxLayout="row" *ngIf="listRadio" style="width: 100%; margin-top: 15px;">
                <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="100">
                    <mat-radio-group [(ngModel)]="data.radioSelect" fxLayout="row" fxFlex="100">
                    <mat-radio-button fxFlex="50" *ngFor="let item of listRadio" [value]="item.key">
                        {{item.value}}
                    </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div *ngIf="data.isTextInput" fxLayout="column" fxLayoutGap="12px" style="width: 100%; margin-top: 15px;">
                <div class="input-label" >
                    <span class="label">{{data.textInputLabel}}</span>
                </div>
                <div  class="app-form-group">
                    <input class="app-input" [(ngModel)]="data.textInputValue" placeholder="{{data.placeholderReason}}"/>
                </div>
            </div>
            <div *ngIf="data.isReason" fxLayout="row" style="width: 100%; margin-top: 15px; padding: 20px;">
            <!-- <span fxFlex="20" style="font-weight: bold;">Ghi chú<span class="m-red" *ngIf="data.isRequireReason">*</span></span>
                <div fxFlex="80"> -->
            <mat-form-field fxFlex="100" style="border: 1px solid #dcdcdc; padding: 10px;">
                <textarea matInput [(ngModel)]="data.reason" rows="3" placeholder="{{data.placeholderReason}}">{{data.reason}}</textarea>
            </mat-form-field>
            <!-- </div> -->
            </div>
        </div>
        <div *ngIf="data.isCheckBox" fxLayout="row" style="width: 100%; margin: 15px 0px;">
            <app-checkbox-button [(ngModel)]="data.checkBoxResult" (change)="onCbChange($event.target.checked)"
            style="min-height: unset;">
            <span style="font-weight: bold; text-align: center;">{{data.checkBoxText}}</span>
            </app-checkbox-button>
        </div>
        <div *ngIf="data.isCheckBoxOtp" fxLayout="row" style="width: 100%; margin: 15px 0px;">
            <app-checkbox-button [(ngModel)]="data.isWaitingOtp" (change)="onCbChange($event.target.checked)"
            style="min-height: unset;">
            <span style="font-weight: bold; text-align: center;">
                Chờ OTP <em> (Chỉ áp dụng trong trường hợp ĐVKD nhận ủy quyền của khách hàng , Khách hàng không có mặt tại sự
                kiện để ký chứng từ). </em>
            </span>
            </app-checkbox-button>
        </div>
    </div>
    <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%;"  fxLayoutGap="16px">
            <button class="button-reject" mat-ripple (click)="onNoClick()">{{data.textCancel ? data.textCancel : 'Hủy bỏ'}}</button>
            <button class="button-accept" mat-ripple (click)="execute()"
            [disabled]="checkRequireReason() || checkRequireRadio()">{{data.textOk ? data.textOk :'Đồng ý'}}</button>
        </div>
    </div>
</div>
