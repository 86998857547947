import { AuthConfig } from 'angular-oauth2-oidc';

export const oauthConfig: AuthConfig = {
  clientId: 'DXSAPP1',
  redirectUri: window.location.origin,
  postLogoutRedirectUri: '',
  loginUrl: 'https://erp.datxanhres.com.vn/OAuth/Authorize',
  scope: 'auth userinfo',
  resource: '',
  rngUrl: '',
  oidc: false,
  requestAccessToken: true,
  options: null,
  issuer: 'https://erp.datxanhres.com.vn',
  clearHashAfterLogin: true,
  tokenEndpoint: 'https://erp.datxanhres.com.vn/OAuth/Token',
  userinfoEndpoint: 'https://erp.datxanhres.com.vn',
  responseType: 'token',
  showDebugInformation: true,
  silentRefreshRedirectUri: window.location.origin,
  silentRefreshMessagePrefix: '',
  silentRefreshShowIFrame: false,
  silentRefreshTimeout: 20000,
  dummyClientSecret: 'AF3490A2-F61B-4801-BDEC-7A17CB7F2075',
  requireHttps: 'remoteOnly',
  strictDiscoveryDocumentValidation: false,
  customQueryParams: null,
  timeoutFactor: 0.75,
  sessionCheckIntervall: 3000,
  disableAtHashCheck: false,
  skipSubjectCheck: false
};

