import { TextareaPopup } from 'app/shared/components/textarea-popup/textarea-popup';
import { DirectivesModule } from 'app/shared/directives/directives.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
  MatFormFieldModule,
  MatBadgeModule
} from '@angular/material';
import { ClipboardModule } from 'ngx-clipboard';
import { AgmCoreModule } from '@agm/core';
import { NgxViewerModule } from 'ngx-viewer';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';

import { DatePickerModule } from 'app/shared/components/date-picker/date-picker.module';
import { SelectModule } from 'app/shared/components/select/select.module';
import { CheckboxGroupModule } from 'app/shared/components/checkbox-group/checkbox-group.module';
import { RadioGroupModule } from 'app/shared/components/radio-group/radio-group.module';
import { UploadImageProductModule } from 'app/shared/components/upload-image-product/upload-image-product.module';
import { StepProgressBarModule } from 'app/shared/components/step-progress-bar/step-progress-bar.module';

import { ConfigurationService } from './services/configuration.service';
import { StorageService } from './services/storage.service';
import { DataService } from './services/data.service';

import { BaseComponent } from './components/base.component';
import { CommonService } from './services/common/common.service';
import { StompConfig, StompService } from '@stomp/ng2-stompjs';
import { environment } from '../../environments/environment';
import { WebsocketService } from './components/websocket.service';
import { UserService } from './services/common/user.service';
import { TextMaskModule } from "angular2-text-mask";
import { HierarchyComponent } from '../pages/common/hierarchy/hierarchy.component';
import { UserAuthorityDirective } from './services/common/user-authority.directive';
import { CustomInputComponent } from './components/custom-input/custom-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomFormComponent } from './components/custom-form/custom-form.component';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { PagingComponent } from './components/paging';
import { RouterModule } from '@angular/router';
import { DynamicAttributeComponent } from './components/dynamic-attribute/dynamic-attribute.component';
import { TransformMoneyPipe, TransformMoneyTextPipe, TransformCustomDatePipe, TransformPhonePipe, SafeHtmlPipe, TransformEmailPipe, TransformTxnStatus, TransformCampaignStatusPipe } from './pipe';
import { UploadFileService } from './services/common/upload-file.service';
import { UploadImagesComponent } from './components/upload-images/upload-images.component';
import { DialogService } from './services/dialog.service';
import { GroupsAttributeComponent } from './components/groups-attribute/groups-attribute.component';
import { ContainerTagComponent } from './components/container-tag/container-tag.component';
import { InputAddressComponent } from './components/input-address/input-address.component';
import { ComponentService } from './components/component.service';
import { InputDateComponent } from './components/input-date/input-date.component';
import { TransformTypeTransaction } from './pipe/transformType.pipe';
import { TransformReceiptTypeTransaction } from './pipe/transformReceiptType.pipe';
import { TransformSummaryText } from './pipe/transformSummaryText.pipe';
import { TypeaheadComponent } from './components/typeahead/typeahead.component';
import { PhoneMaskDirective } from './components/phone-mask/phone-mask.directive';
import { DigitOnlyDirective } from './../theme/directives/digit-only/digit-only.directive';
import { IpadBreakpointsProvider, IpadLayoutDirective } from 'app/shared/break-points/ipad-breakpoints';
import { MobileBreakpointsProvider, MobileLayoutDirective } from 'app/shared/break-points/mobile-breakpoints';
import { Ng5SliderModule } from 'ng5-slider';
import { CustomMaxDirective } from 'app/theme/directives/number-validation/numbers-max';
import { CustomMinDirective } from 'app/theme/directives/number-validation/numbers-min';
import { TransformDecimal } from './pipe/transformDecimal.pipe';
import { CustomExcelComponent } from './components/custom-excel/custom-excel.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog';
import { DialogImageCropperComponent } from './components/avatar-uploader';
import { ImageUploaderComponent } from './components/image-uploader';
import { FileUploaderComponent } from './components/file-uploader';
import { AvatarUploaderComponent } from './components/avatar-uploader';
import { InputInterestedAreaComponent } from './components/input-interested-area';
import { GridPopupComponent } from './components/grid-popup/grid-popup.component';
import { CdkTableModule } from '@angular/cdk/table';
import { SearchPipe } from './pipe/transformSearch.pipe';
import { ButtonCallComponent } from './components/button-call/button-call.component';
import { ButtonMessageComponent } from './components/button-message/button-message.component';
import { DialogMessageImageComponent } from './components/dialog-message-image/dialog-message-image.component';
import { DialogConfirmComponent } from './components/dialog-confirm/dialog-confirm.component';
// Form input
import {
  FormInputPropertyComponent,
  FormInputAddressComponent,
  FormInputLeadInfoComponent,
  FormInputLeadInNeedComponent,
  FormLeadHistoryCallComponent,
  FormInputDynamicComponent,
  FormInputCallHistoryComponent,
  FormInputCustomerComponent,
  FormInputContractComponent
} from './components/app-form-input';
// Paging
import {
  AppPagingComponent
} from './components/app-paging';
import { SurveyFormComponent } from 'app/pages/survey/survey-form/survey-form.component';
import { SidenavService } from './services/common/sidenav.service';
import { SearchPosPipe } from './pipe/transformSearchPos.pipe';
import { PopupDealChecker } from '../pages/project/event-sale/popup-deal-checker/popup-deal-checker';
import { ImageProjectUploaderComponent } from './components/image-project-uploader';
import { FileProjectUploaderComponent } from './components/file-project-uploader';
import { UploadProjectImagesComponent } from './components/upload-project-images/upload-project-images.component';
import { SortDsghPipe } from './pipe/sort-dsgh.pipe';
import { NgxUpperCaseDirectiveModule } from 'ngx-upper-case-directive';
import { PopupConfirmRegister } from 'app/pages/project/event-sale/table-deal-panel/popup-confirm-register/popup-confirm-register.component';
import { PopupConfirmReturn } from 'app/pages/project/event-sale/table-deal-panel/popup-confirm-return/popup-confirm-register.component';
import { PopupConfirmSyncRA } from 'app/pages/project/event-sale/table-deal-panel/popup-confirm-sync-ra/popup-confirm-sync-ra.component';
import { TemplateRendererComponent } from 'app/pages/project/event-sale/list/template-renderer/template-renderer.component';
import { PopupUpdateFeatureComponent } from 'app/pages/project/event-sale/popup-update-feature/popup-update-feature.component';
import { PopupConfirmQueue } from 'app/pages/project/event-sale/table-deal-panel/popup-confirm-queue/popup-confirm-queue.component';
import { ConfirmPayWaitingComponent } from './components/confirm-pay-waiting/confirm-pay-waiting.component';
import { TransformTypePaymentPipe } from './pipe/transform-type-payment.pipe';
import { TransformTypeTicketPipe } from './pipe/transform-type-ticket.pipe';
import { FormActionComponent } from './components/app-form-action/form-action.component';
import { SearchFilterComponent } from './components/app-search-filter/search-filter.component';
import { ImageUploaderContentBuilderComponent } from './components/image-uploader-contentbuilder';
import { FilterPropertyComponent } from './components/app-filter-property/filter-property.component';
import { UploadAttachmentImagesComponent } from './components/upload-attachment-images/upload-attachment-images.component';
import { InputOtpComponent } from 'app/pages/my-profile/input-otp/input-otp.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { GroupMemberFilterPipe } from './pipe/transform-group-member-filter.pipe';
import { TransformTypeTicketHistoryPaymentPipe } from './pipe/transform-type-ticket-history-payment.pipe';
import { PopupNoteComponent } from 'app/pages/project/event-sale/popup-note/popup-note.component';
import { TransformEmployeeStatusPipe } from './pipe/transform-employee-status.pipe';
import { AgGridModule } from 'ag-grid-angular';
import { GridNoteListComponent } from 'app/pages/employee/grid/grid-note-list/grid-note-list';
import { NotificationMessageFilterPipe } from './pipe/transform-notification-message-filter.pipe';
import { SurveyService } from 'app/pages/survey/survey.service';
import { SurveyNoteDetailComponent } from 'app/pages/survey/survey-note-detail/survey-note-detail.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { GridKycListComponent } from 'app/pages/employee/grid/grid-kyc-list/grid-kyv-list';
import { SvgComponent } from './components/app-svg/app-svg.component';
import { ExportAsModule } from 'ngx-export-as';
import { ImageUploaderRateAppComponent } from './components/image-uploader-rate-app/image-uploader-rate-app.component';
import { ESaleKitFileUploaderComponent } from './components/esale-kit-file-uploader/esale-kit-file-uploader.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ConfirmSendApproveDialog } from 'app/pages/contract/pop-up-confirm-send-approve/pop-up-confirm-send-approve.component';
import { ButtonV3Component } from './components/v3/button-v3/button-v3.component';
import { ButtonChipComponent } from './components/v3/button-chip/button-chip.component';
import { CheckboxGroupV3Module } from './components/v3/checkbox-group-v3/checkbox-group-v3.module';
import { TabComponentV3Component } from './components/v3/tab-component-v3/tab-component-v3.component';
import { AppPagingV3Component } from './components/v3/app-paging-v3/app-paging-v3.component';
import { TimePickerModule } from 'app/shared/components/v3/time-picker/time-picker.module';
import { AddHolidayComponent } from 'app/pages/checkin-checkout/add-holiday-popup/add-holiday-popup.component';
import { ReportCheckinCheckoutPopupComponent } from 'app/pages/checkin-checkout/report-checkin-checkout-popup/report-checkin-checkout-popup.component';
import { DatePickerV2Module } from 'app/shared/components/date-picker-v2/date-picker-v2.module';
import { GridNoteLoanListComponent } from 'app/pages/loan-package-interested/grid-note-list/grid-note-loan-list';
import { VideoUploaderVideoStoreComponent } from './components/video-uploader-video-store';
const stompConfig: StompConfig = {
  // Which server?
  url: environment.rabbitmq.ws.url,

  // Headers
  // Typical keys: login, passcode, host
  headers: {
    login: environment.rabbitmq.ws.login,
    passcode: environment.rabbitmq.ws.passcode
  },

  // How often to heartbeat?
  // Interval in milliseconds, set to 0 to disable
  heartbeat_in: 0, // Typical value 0 - disabled
  heartbeat_out: 20000, // Typical value 20000 - every 20 seconds

  // Wait in milliseconds before attempting auto reconnect
  // Set to 0 to disable
  // Typical value 5000 (5 seconds)
  reconnect_delay: 5000,

  // Will log diagnostics on console
  debug: false
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TextMaskModule,
    RouterModule,
    // mat component
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatBadgeModule,
    Ng5SliderModule,
    CdkTableModule,
    AgmCoreModule,
    NgxViewerModule,
    ImageCropperModule,
    DatePickerModule,
    SelectModule,
    CheckboxGroupModule,
    RadioGroupModule,
    UploadImageProductModule,
    StepProgressBarModule,
    NgbTimepickerModule,
    DirectivesModule,
    NgxUpperCaseDirectiveModule,
    NgOtpInputModule,
    QRCodeModule,
    AgGridModule.withComponents([
      TemplateRendererComponent,
    ]),
    NgxMaterialTimepickerModule,
    ExportAsModule,
    ClipboardModule,
    CheckboxGroupV3Module,
    TimePickerModule,
    DatePickerV2Module,
  ],
  declarations: [
    HierarchyComponent,
    UserAuthorityDirective,
    CustomInputComponent,
    CustomFormComponent,
    CustomExcelComponent,
    CustomTableComponent,
    DynamicAttributeComponent,
    GroupsAttributeComponent,
    ContainerTagComponent,
    TransformCustomDatePipe,
    TransformMoneyPipe,
    TransformMoneyTextPipe,
    TransformDecimal,
    TransformPhonePipe,
    TransformEmailPipe,
    SafeHtmlPipe,
    TransformSummaryText,
    TransformTypeTransaction,
    TransformReceiptTypeTransaction,
    PagingComponent,
    UploadImagesComponent,
    InputAddressComponent,
    InputDateComponent,
    TypeaheadComponent,
    ConfirmationDialogComponent,
    DialogImageCropperComponent,
    ImageUploaderComponent,
    ImageUploaderRateAppComponent,
    FileUploaderComponent,
    AvatarUploaderComponent,
    InputInterestedAreaComponent,
    PhoneMaskDirective,
    DigitOnlyDirective,
    CustomMaxDirective,
    CustomMinDirective,
    IpadLayoutDirective,
    MobileLayoutDirective,
    GridPopupComponent,
    SearchPipe,
    SearchPosPipe,
    SortDsghPipe,
    ButtonCallComponent,
    DialogMessageImageComponent,
    DialogConfirmComponent,
    FormInputPropertyComponent,
    AppPagingComponent,
    SurveyFormComponent,
    SurveyNoteDetailComponent,
    ButtonMessageComponent,
    PopupDealChecker,
    ImageProjectUploaderComponent,
    FileProjectUploaderComponent,
    UploadProjectImagesComponent,
    UploadAttachmentImagesComponent,
    FormInputAddressComponent,
    FormInputContractComponent,
    FormInputCustomerComponent,
    SortDsghPipe,
    PopupConfirmRegister,
    PopupConfirmQueue,
    PopupConfirmReturn,
    PopupConfirmSyncRA,
    FormInputLeadInfoComponent,
    FormInputLeadInNeedComponent,
    FormInputCallHistoryComponent,
    FormLeadHistoryCallComponent,
    FormInputDynamicComponent,
    TransformTxnStatus,
    TemplateRendererComponent,
    PopupUpdateFeatureComponent,
    ConfirmPayWaitingComponent,
    TransformTypePaymentPipe,
    TransformTypeTicketHistoryPaymentPipe,
    GroupMemberFilterPipe,
    NotificationMessageFilterPipe,
    TransformTypeTicketPipe,
    FormActionComponent,
    SearchFilterComponent,
    TransformCampaignStatusPipe,
    TransformEmployeeStatusPipe,
    FilterPropertyComponent,
    ImageUploaderContentBuilderComponent,
    InputOtpComponent,
    PopupNoteComponent,
    GridNoteListComponent,
    GridKycListComponent,
    TextareaPopup,
    TimePickerComponent,
    SvgComponent,
    ESaleKitFileUploaderComponent,
    ButtonV3Component,
    ButtonChipComponent,
    TabComponentV3Component,
    AppPagingV3Component,
    AddHolidayComponent,
    ReportCheckinCheckoutPopupComponent,
    VideoUploaderVideoStoreComponent,
    GridNoteLoanListComponent,
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    DialogImageCropperComponent,
    DialogMessageImageComponent,
    DialogConfirmComponent,
    GridPopupComponent,
    PopupDealChecker,
    PopupConfirmRegister,
    PopupConfirmQueue,
    PopupConfirmReturn,
    PopupConfirmSyncRA,
    PopupUpdateFeatureComponent,
    ConfirmPayWaitingComponent,
    ImageUploaderContentBuilderComponent,
    PopupNoteComponent,
    SurveyNoteDetailComponent,
    GridNoteListComponent,
    GridKycListComponent,
    InputOtpComponent,
    TextareaPopup,
    FormInputCustomerComponent,
    FormInputContractComponent,
    AddHolidayComponent,
    ReportCheckinCheckoutPopupComponent,
    GridNoteLoanListComponent,
  ],
  exports: [
    FlexLayoutModule,
    TextMaskModule,
    RouterModule,
    // mat component
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatBadgeModule,
    NgbTimepickerModule,
    HierarchyComponent,
    UserAuthorityDirective,
    CustomInputComponent,
    CustomFormComponent,
    CustomTableComponent,
    CustomExcelComponent,
    DynamicAttributeComponent,
    GroupsAttributeComponent,
    ContainerTagComponent,
    PagingComponent,
    TransformMoneyPipe,
    TransformMoneyTextPipe,
    TransformDecimal,
    TransformPhonePipe,
    TransformEmailPipe,
    SafeHtmlPipe,
    TransformCustomDatePipe,
    TransformTypeTransaction,
    TransformReceiptTypeTransaction,
    TransformSummaryText,
    UploadImagesComponent,
    InputAddressComponent,
    InputDateComponent,
    TypeaheadComponent,
    NgOtpInputModule,
    QRCodeModule,
    AgGridModule,
    ConfirmationDialogComponent,
    DialogImageCropperComponent,
    ImageUploaderComponent,
    ImageUploaderRateAppComponent,
    FileUploaderComponent,
    AvatarUploaderComponent,
    InputInterestedAreaComponent,
    SvgComponent,
    PhoneMaskDirective,
    DigitOnlyDirective,
    CustomMaxDirective,
    CustomMinDirective,
    IpadLayoutDirective,
    MobileLayoutDirective,
    Ng5SliderModule,
    GridPopupComponent,
    AgmCoreModule,
    NgxViewerModule,
    DirectivesModule,
    ImageCropperModule,
    DatePickerModule,
    SelectModule,
    CheckboxGroupModule,
    RadioGroupModule,
    UploadImageProductModule,
    StepProgressBarModule,
    ButtonCallComponent,
    SearchPipe,
    SearchPosPipe,
    SortDsghPipe,
    ImageCropperModule,
    DialogMessageImageComponent,
    DialogConfirmComponent,
    FormInputPropertyComponent,
    FormInputLeadInfoComponent,
    FormInputLeadInNeedComponent,
    FormInputCallHistoryComponent,
    FormLeadHistoryCallComponent,
    FormInputDynamicComponent,
    AppPagingComponent,
    SurveyFormComponent,
    SurveyNoteDetailComponent,
    ButtonMessageComponent,
    ImageProjectUploaderComponent,
    FileProjectUploaderComponent,
    UploadProjectImagesComponent,
    UploadAttachmentImagesComponent,
    FormInputAddressComponent,
    FormInputContractComponent,
    FormInputCustomerComponent,
    NgxUpperCaseDirectiveModule,
    TransformTxnStatus,
    TemplateRendererComponent,
    PopupUpdateFeatureComponent,
    ConfirmPayWaitingComponent,
    TransformTypePaymentPipe,
    TransformTypeTicketHistoryPaymentPipe,
    GroupMemberFilterPipe,
    NotificationMessageFilterPipe,
    TransformTypeTicketPipe,
    FormActionComponent,
    SearchFilterComponent,
    TransformCampaignStatusPipe,
    TransformEmployeeStatusPipe,
    FilterPropertyComponent,
    ImageUploaderContentBuilderComponent,
    PopupNoteComponent,
    GridNoteListComponent,
    GridKycListComponent,
    InputOtpComponent,
    TextareaPopup,
    TimePickerComponent,
    NgxMaterialTimepickerModule,
    ExportAsModule,
    ClipboardModule,
    ESaleKitFileUploaderComponent,
    ButtonV3Component,
    ButtonChipComponent,
    CheckboxGroupV3Module,
    TabComponentV3Component,
    AppPagingV3Component,
    TimePickerModule,
    AddHolidayComponent,
    ReportCheckinCheckoutPopupComponent,
    DatePickerV2Module,
    VideoUploaderVideoStoreComponent,
    GridNoteLoanListComponent,
  ],
  providers: [
    ComponentService,
    UserService,
    ConfigurationService,
    StorageService,
    DataService,
    CommonService,
    DialogService,
    StompService,
    UploadFileService,
    SurveyService,
    {
      provide: StompConfig,
      useValue: stompConfig
    },
    IpadBreakpointsProvider,
    MobileBreakpointsProvider,
    SidenavService,
  ]
})
export class SharedModule { }
