<div fxLayout="row wrap" *ngIf="router.url != '/'">
    <div fxFlex="100">
        <mat-card fxLayout="row" fxLayoutAlign="space-between center" class="breadcrumb">
            <div fxLayout="row">
                <a *ngIf="router.url != '/'" [routerLink]="routerLinkDefault" class="breadcrumb-item" fxLayout="row"
                    fxLayoutAlign="start center" (click)="closeSubMenus()">
                    <mat-icon> home </mat-icon>
                    <!-- <span class="breadcrumb-title {{ !breadcrumbs.length ? 'active' : ''}}"> Dashboard </span> -->
                </a>
                <div *ngFor="let breadcrumb of breadcrumbs; let i = index;" class="breadcrumb-item" fxLayout="row"
                    fxLayoutAlign="start center" [ngClass]="breadcrumb.name === 'Dashboard' && 'hidden'">
                    <a *ngIf="i !== (breadcrumbs.length - 1) && !disabledLink" [routerLink]="[breadcrumb.url]" [queryParams]="historyFilter">{{breadcrumb.name}}</a>
                    <a *ngIf="i !== (breadcrumbs.length - 1) && disabledLink">{{breadcrumb.name}}</a>
                    <span *ngIf="i === (breadcrumbs.length - 1)"
                        class="breadcrumb-title active">{{breadcrumb.name}}</span>
                </div>
            </div>
            <!-- <div fxLayout="row" style="padding-right: 6px;">
                <a *ngIf="breadcrumbs.length > 0" href="javascript:;" class="breadcrumb-item" fxLayout="row"
                    fxLayoutAlign="start center" (click)="goBack()">
                    <mat-icon> reply </mat-icon>
                    <span class="breadcrumb-title"> Quay lại </span>
                </a>
            </div> -->
        </mat-card>
    </div>
</div>