<button style="width: 100%;" mat-icon-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger" class="btn-user">
    <!-- <mat-icon *ngIf="!userImage" class="rounded mr-10">account_circle</mat-icon> -->
    <img src="{{user.avatar || '../assets/img/users/icon_avatar.svg'}}" alt="user-image" width="24" height="24"
        class="rounded mr-10" onError="this.src = './assets/img/users/icon_avatar.svg'">
    <span>{{user.name || user.email}}</span>
</button>

<mat-menu #userMenu="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu user-menu">
    <!--<span (mouseleave)="userMenuTrigger.closeMenu()">-->
        <mat-toolbar color="primary">
            <div fxLayout="row" fxLayoutAlign="left center" class="user-info">
                <div>
                    <!-- <img src="{{user.avatar || '../assets/img/users/icon_avatar.svg'}}" alt="user-image" width="80" height="80" class="rounded mat-elevation-z6"
                        onError="this.src = './assets/img/users/icon_avatar.svg'"> -->
                    <div class="rounded mat-elevation-z6 profile-image" [ngStyle]="{'background-image': checkAvatar(user)}"></div>

                </div>
                <div fxLayout="column" class="user-info-container">
                    <p style="text-align: left; padding-left: 10px;">{{user.name || user.email}}</p>
                    <p style="text-align: left; padding-left: 10px;">{{user.roleFullName}}</p>
                    <p style="text-align: left; padding-left: 10px;">{{user.pos.name | summary: '20'}}</p>
                </div>
            </div>
        </mat-toolbar>
        <a *ngIf="isShowAccount" mat-menu-item [routerLink]="['/profile']">
            <mat-icon>person</mat-icon>
            <span>Tài khoản</span>
        </a>
        <a *ngIf="isShowNotification" mat-menu-item [routerLink]="['/notification/1']">
            <mat-icon *ngIf="notificationCount > 0" [matBadge]="notificationCount" matBadgeColor="warn" >notifications</mat-icon>
            <mat-icon *ngIf="!notificationCount" matBadgeColor="warn" >notifications</mat-icon>
            <span>Thông báo</span>
        </a>
        <div class="divider"></div>
        <a mat-menu-item (click)="logout()">
            <mat-icon>power_settings_new</mat-icon>
            <span>Đăng xuất</span>
        </a>
</mat-menu>