<mat-card class="custom-card" *ngIf="data.note">
  <div fxLayout="row" fxLayoutAlign="start center" class="title-area">
    <h1 class="title-confirm">{{ data.title }}</h1>
  </div>
  <div mat-dialog-content class="content-area">
    <div fxLayout="column" fxLayoutAlign="space-around">
      <span>Nội dung ghi chú</span>
      <input type="text" placeholder="Nhập ghi chú" matInput class="reason-input" [(ngModel)]="data.reason" />
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <div class="action-area">
      <button class="action-btn" mat-raised-button color="primary" mat-button (click)="execute()" [disabled]="!data.reason">{{ data.action }}</button>
      <button mat-button class="m-black action-btn cancel" (click)="onNoClick()">Hủy bỏ</button>
    </div>
  </div>
</mat-card>
