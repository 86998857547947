<div class="uploader-container">
  <!-- BEGIN Content (No image) -->
  <label *ngIf="!isLoading && !hasImage" class="uploader-box" [class.disabled]="disabled">
    <mat-icon class="add-icon">add</mat-icon>
    <div class="sub-title">Tối đa 5Mb, định dạng: .png, .jpg, .svg</div>
    <input type="file" accept="image/*" id="uploader" (change)="onFileSelected($event)" [disabled]="disabled" />
  </label>
  <!-- END -->
  <!-- BEGIN Content (Loading) -->
  <div *ngIf="isLoading" class="loading-box">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
  <!-- END -->
  <!-- BEGIN Content (Has image) -->
  <img *ngIf="!isLoading && hasImage" [src]="imageUrl" [viewerOptions]="viewerOptions" ngxViewer class="preview" />
  <button *ngIf="!isLoading && hasImage && !disabled" type="button" (click)="clearImage()" mat-icon-button
    aria-label="Clear" class="btn-clear">
    <mat-icon>clear</mat-icon>
  </button>
 
  <!-- END -->
</div>
<button *ngIf="!isLoading && hasImage && !disabled" type="button" (click)="saveImage()" mat-raised-button color="primary" class="btn-center"> Lưu ảnh đã tải lên</button>