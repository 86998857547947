import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { SyncRaService } from 'app/shared/services/sync-ra.service';
import { PropertyTable } from '../../property-table.model';
import { PrimaryTransactionService } from 'app/pages/project/primary-transaction.service';
import { IPrimaryTransactionHistory, PrimaryTransactionHistory } from 'app/shared/models/primaryTransactionHistory.model';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-popup-confirm-sync-ra',
  styleUrls: ['./popup-confirm-sync-ra.component.scss'],
  templateUrl: './popup-confirm-sync-ra.component.html',
})
export class PopupConfirmSyncRA implements OnInit {
  public item: PropertyTable;
  isSyncing = false;
  isSuccess = false;

  currentStep = 1;
  totalSteps = 7;
  historySyncs: IPrimaryTransactionHistory[] = [];
  constructor(
    public dialogRef: MatDialogRef<PopupConfirmSyncRA>,
    private syncRaService: SyncRaService,
    public toastr: ToastrService,
    private primaryTransactionService: PrimaryTransactionService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.item = this.data.data;
  }

  ngOnInit() {
  }

  execute(): void {
    this.isSyncing = true;
    // Step 1
    const history = new PrimaryTransactionHistory({
      step: this.currentStep,
      name: 'loginRA'
    });
    this.syncRaService.loginRA().subscribe(res => {
      if (res && res['access_token']) {
        this.currentStep++;
        history.toSuccess();
        this.historySyncs.push(history);
        this.syncRAGetUnits(res['access_token']);
      } else {
        this.toastr.error('Không xác thực được thông tin', 'Không thành công');
        this.isSyncing = false;
        history.toFail('Không xác thực được thông tin');
        this.historySyncs.push(history);
      }
    }, (err) => {
      history.toFail(err);
      this.historySyncs.push(history);
      this.isSyncing = false;
      this.toastr.error('Lỗi không xác định', 'Không thành công');
    });
  }

  onNoClick(): void {
    if (!this.isSyncing) {
      let data = { isSuccess: this.isSuccess, item: this.item };
      this.dialogRef.close(data);
    }
  }

  loadTicket(unitId: string, token: string) {
    // Step 4
    const history = new PrimaryTransactionHistory({
      step: this.currentStep,
      name: 'getTicketDetailById'
    });
    this.primaryTransactionService.getTicketDetailById(this.item.priority0.id).subscribe((res: any) => {
      if (res) {
        this.currentStep++;
        history.toSuccess();
        this.historySyncs.push(history);
        const body = this.createTicketInfo(unitId, res);
        this.syncRASendCustomerInfo(body, token);
      } else {
        this.toastr.error('Không lấy thông tin giao dịch', 'Không thành công');
        this.isSyncing = false;
        history.toFail('Không lấy thông tin giao dịch');
        this.historySyncs.push(history);
      }
    }, (err) => {
      history.toFail(err);
      this.isSyncing = false;
      this.toastr.error('Lỗi không xác định', 'Không thành công');
      this.historySyncs.push(history);
    });
  }
  syncRAGetUnits(token: string) {
    // Step 2
    const history = new PrimaryTransactionHistory({
      step: this.currentStep,
      name: 'getPropertyUnit'
    });
    this.syncRaService.getPropertyUnit(token).subscribe(res => {
      if (res) {
        const listUnits = [...res];
        const currentUnit = listUnits.find(x => x.code === this.item.code);
        if (currentUnit) {
          this.currentStep++;
          history.toSuccess();
          this.historySyncs.push(history);
          this.toastr.success('Không tìm thấy sản phẩm', 'Thành công');
          this.syncRARegisterUnit(currentUnit.id, token)
        } else {
          this.isSyncing = false;
          history.toFail('Không tìm thấy sản phẩm');
          this.historySyncs.push(history);
          this.toastr.error('Không tìm thấy sản phẩm', 'Không thành công');
        }
      } else {
        this.isSyncing = false;
        history.toFail('Không lấy được sản phẩm');
        this.historySyncs.push(history);
        this.toastr.error('Không lấy được sản phẩm', 'Không thành công');
      }
    }, (err) => {
      history.toFail(err);
      this.historySyncs.push(history);
      this.isSyncing = false;
      this.toastr.error('Lỗi không xác định', 'Không thành công');
    })
  }
  syncRAGetTicket(token: string) {
    // Step 6
    const history = new PrimaryTransactionHistory({
      step: this.currentStep,
      name: 'getTicket'
    });
    this.syncRaService.getTicket(token).subscribe(res => {
      if (res) {
        const listUnits = [...res];
        const currentUnit = listUnits.find(x => x.code === this.item.code);
        if (currentUnit && currentUnit.priorities && currentUnit.priorities[0]) {
          this.currentStep++;
          history.toSuccess();
          this.historySyncs.push(history);
          this.syncRAConfirmTicket(currentUnit.priorities[0].id, token)
        } else {
          this.isSyncing = false;
          this.toastr.error('Không lấy thông tin giao dịch RA', 'Không thành công');
          history.toFail('Không lấy thông tin giao dịch RA');
          this.historySyncs.push(history);
        }
      } else {
        this.isSyncing = false;
        this.toastr.error('Không lấy thông tin giao dịch RA', 'Không thành công');
        history.toFail('Không lấy thông tin giao dịch RA');
        this.historySyncs.push(history);
      }
    }, (err) => {
      history.toFail(err);
      this.isSyncing = false;
      this.toastr.error('Lỗi không xác định', 'Không thành công');
      this.historySyncs.push(history);
    })
  }
  syncRARegisterUnit(unitId: string, token: string) {
    // Step 3
    const history = new PrimaryTransactionHistory({
      step: this.currentStep,
      name: 'registerUnit'
    });
    this.syncRaService.registerUnit(unitId, token).subscribe(res => {
      if (res && (res.status === 200 || res.status === 201)) {
        this.currentStep++;
        history.toSuccess();
        this.historySyncs.push(history);
        this.loadTicket(unitId, token);
      } else {
        this.isSyncing = false;
        history.toFail('Không đăng ký sản phẩm');
        this.historySyncs.push(history);
        this.toastr.error('Không đăng ký sản phẩm', 'Không thành công');
      }
    }, (err) => {
      history.toFail(err);
      this.historySyncs.push(history);
      this.isSyncing = false;
      this.toastr.error('Lỗi không xác định', 'Không thành công');
    })
  }
  syncRASendCustomerInfo(ticket: any, token: string,) {
    // Step 5
    const history = new PrimaryTransactionHistory({
      step: this.currentStep,
      name: 'sendCustomerInfo'
    });
    this.syncRaService.sendCustomerInfo(ticket, token).subscribe(res => {
      if (res && (res.status === 200 || res.status === 201)) {
        this.currentStep++;
        history.toSuccess();
        this.historySyncs.push(history);
        setTimeout(() => {
          this.syncRAGetTicket(token);
        }, 5000);
      } else {
        history.toFail('Gửi thông tin khách hàng thất bại');
        this.historySyncs.push(history);
        this.isSyncing = false;
        this.toastr.error('Gửi thông tin khách hàng thất bại', 'Không thành công');
      }
    }, (err) => {
      history.toFail(err);
      this.historySyncs.push(history);
      this.isSyncing = false;
      this.toastr.error('Lỗi không xác định', 'Không thành công');
    })
  }
  syncRAConfirmTicket(ticketId: string, token: string,) {
    // Step 7
    const history = new PrimaryTransactionHistory({
      step: this.currentStep,
      name: 'confirmTicket'
    });
    this.syncRaService.confirmTicket(ticketId, token).subscribe(res => {
      if (res && (res.status === 200 || res.status === 201)) {
        history.toSuccess();
        this.historySyncs.push(history);
        this.isSyncing = false;
        this.isSuccess = true;
      } else {
        history.toFail('Xác nhận thông tin khách hàng không thành công');
        this.historySyncs.push(history);
        this.isSyncing = false;
        this.toastr.error('Xác nhận thông tin khách hàng không thành công', 'Không thành công');
      }
      console.log(this.historySyncs);
    }, (err) => {
      history.toFail(err);
      this.historySyncs.push(history);
      this.isSyncing = false;
      this.toastr.error('Lỗi không xác định', 'Không thành công');
    })
  }



  createTicketInfo(unitId: string, item: any) {
    return {
      "id": null,
      "ticketType": "YCDC",
      "employeeTakeCareId": null,
      "customer": this.createCustomerInfo(item.customer),
      "projectType": item.projectType,
      "projectId": environment.syncRA.projectGSWId,
      "demandPropertyId": unitId,
      "demandCategory": null,
      "amountRegistration": null,
      "files": [],
      "status": "PROCESSING_LOCK",
      "ticketEditTime": "",
      "isCreateAtStage2": true,
      "surveys": [],
      "customer2": this.createCustomerInfo(item.customer2),
      "note": null,
      "bookingTicketCode": null,
      "escrowTicketCode": null
    }
  }
  createCustomerInfo(data: any) {
    return {
      "code": null,
      "id": null,
      "name": data.personalInfo.name,
      "gender": data.info.gender,
      "birthday": data.info.birthday,
      "birthdayYear": data.info.birthdayYear,
      "onlyYear": data.info.onlyYear,
      "phone": data.personalInfo.phone,
      "email": data.personalInfo.email,
      "identityNumber": data.identities[0].value,
      "identityIssueDate": data.identities[0].date,
      "identityIssueLocation": data.identities[0].place,
      "rootAddress": data.info.rootAddress,
      "address": data.info.address,
      "taxCode": data.taxCode,
      "bankInfo": data.bankInfo,
      "position": "",
      "type": "individual",
      "company": {
        "number": "",
        "name": "",
        "issueDate": "",
        "issueLocation": ""
      }
    }
  }
}
