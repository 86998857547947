import { DialogJoinComponent } from './../dialog-join/dialog-join.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { LandingPageGroupModel } from '../landing-page.model';
import { LandingPageService } from '../landing-page.service';
import { MatDialog } from '@angular/material';
import { environment } from 'environments/environment';
@Component({
  selector: 'app-landing-group',
  templateUrl: './landing-group.component.html',
  styleUrls: ['./landing-group.component.scss']
})
export class LandingGroupComponent implements OnInit {
  model: LandingPageGroupModel = new LandingPageGroupModel();
  id: string = '';
  checkDataGroup: boolean = false;

  viewerOptions: any = {
    navbar: false,
    toolbar: {},
  };

  constructor(
    public route: ActivatedRoute,
    private service: LandingPageService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
    });
    const params = this.route.snapshot.params;
    const id = params['id'];
    if (id) { 
      this.service.getGroupById(id).subscribe({
        next: (res: any) => {
          this.checkDataGroup = true
          this.model = new LandingPageGroupModel(res);
        },
        error: (err) => {
        }
    });
    } else {
      this.model = new LandingPageGroupModel();
    }
  }

  openDialog() {
    const dialog = this.dialog.open(DialogJoinComponent, {
      width: '800px',
      data: {
        shareUrl: this.model.shareUrl,
      }
    });
    dialog.afterClosed().subscribe(result => { });
  }
}
