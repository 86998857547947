<div class="landing_page">
    <app-landing-header (openDialog)="openDialog()"></app-landing-header>
    <div class="landing_page-body" [ngSwitch]="checkDataEvent">
        <img src="../../../../assets/img/landing/abstract.svg" alt="landingAbstract">
        <div class="landing_page-wrapper" *ngSwitchCase="true">
            <div class="landing_page-content">
                <div class="header">
                    <div fxLayout="column" fxFlex="50">
                        <h1>{{model.title}}</h1>
                    </div>
                    <div fxLayout="column" fxFlex="50" class="auction" *ngIf="model.isAuction">
                        <div>
                            <img src="../../../../assets/img/landing/auction-fill.png" alt="info" class="vertical-bot">
                            <span>Đấu giá</span>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" class="auctionGroup" *ngIf="model.isAuction">
                    <div fxLayout="column" fxFlex="50">
                        <div class="info-auction">
                            <span>Đấu giá sẽ kết thúc sau : {{model.auction.endDate}}</span>
                        </div>
                        <div class="info-auction">
                            Giá khởi điểm: 
                            <span>{{model.auction.startingPrice}} VNĐ</span>
                        </div>
                    </div>
                </div>
                <div class="infoGroup" fxLayout="row">
                    <div fxLayout="column" fxFlex="50">
                        <div class="info-land">
                            <span fxLayoutAlign="center center">Đất nền</span>
                        </div>
                        <div class="info">
                            Ký quỹ:
                            <span>{{model.escrowFee}} VNĐ</span>
                        </div>
                        <div class="info">
                            Ngày đăng:
                            <span>{{model.createdDate}}</span>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50">
                        <div class="info">
                            Giá bán:
                            <span>{{model.price}} VNĐ</span>
                        </div>
                        <div class="info">
                            Hoa hồng:
                            <span *ngIf="iscommissionType">{{model.commissionAmount}} VNĐ</span>
                            <span *ngIf="!iscommissionType">{{model.commissionPercent}} %</span>
                        </div>
                        <div class="info">
                            Hạn bán:
                            <span>{{model.expiredDate}}</span>
                        </div>
                    </div>
                </div>
                <!-- Image-event -->
                <div>
                    <mat-carousel
                    timings="250ms ease-in"
                    [autoplay]="true"
                    interval="5000"
                    color="accent"
                    maxWidth="auto"
                    proportion="60"
                    slides="5"
                    [loop]="true"
                    [hideArrows]="false"
                    [hideIndicators]="false"
                    [useKeyboard]="true"
                    [useMouseWheel]="false"
                    orientation="ltr"
                    >
                    <mat-carousel-slide
                        #matCarouselSlide
                        *ngFor="let slide of model.propertyUnitImageUrls; let i = index"
                        [image]="slide.absoluteUrl"
                        overlayColor="#00000040"
                        [hideOverlay]="false"
                    ></mat-carousel-slide>
                    </mat-carousel>
                </div>
                <div fxLayout="row" class="infoGroup">
                    <div fxLayout="column" fxFlex="50">
                        <div class="info-user">
                            <img src="../../../../assets/img/landing/Ava.png" alt="info" class="vertical-bot">
                            <span>{{model.ownerName}}</span>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50">
                        <div class="info-contact">
                            <img src="../../../../assets/img/landing/Group 17263.png" alt="info" class="vertical-bot">
                            {{model.favoriteNumber}}
                            <img src="../../../../assets/img/landing/Group 17264.png" alt="info" class="vertical-bot">
                            {{model.commentChannels.length}}
                        </div>
                    </div>
                </div>
                <div class="btn-wrap btn-landing">
                    <button mat-raised-button (click)="openDialog()">
                        <img class="img-btn" src="../../../../assets/img/landing/login.svg" alt="action">
                        <span class="txt-btn">Đăng ký</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="landing_page-wrapper-empty" *ngSwitchCase="false" >
            <div class="landing_page-content">
                <div fxLayout="column"
                    fxLayoutAlign="start center"
                    fxLayoutGap="12px"
                >                
                    <img src="../../../../assets/img/landing/Group404.svg" alt="landingAbstract"> 
                    <div class="infoGroup" fxLayoutAlign="center center">
                        <div fxLayoutAlign="center center" fxLayoutGap="5px">
                            <span class="text-er">Xin lỗi! Bạn không thể xem được bài đăng bán này</span>
                        </div>                 
                    </div>   
            </div>
        </div>
    </div>
    <div class="landing_page-intro-wrapper"></div>
    <app-landing-footer [shareUrl]="model.shareUrl"></app-landing-footer>
</div>