import { ToastrService } from 'ngx-toastr';
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { BankInfo } from 'app/pages/customer/customer.model';
import { Constant } from 'app/shared/constant/constant';
import { BankService, IBankDropdown } from 'app/shared/services/bank.service';

export interface ConfirmData {
  title: string;
  message: string;
  textCancel: string;
  textOk: string;
  isReason: boolean;
  isNumber: boolean;
  isCopyTicket: boolean;
  isMessageHTML: boolean;
  isReasonReject: boolean;
  isCancelTicket: boolean;
  isTextInput: boolean;
  reason: string;
  bankInfo: any;
  isRequireReason: boolean;
  execute: boolean;
  placeholderReason: string;
  isCheckBox: boolean;
  bindCbTxtToReason: boolean;
  checkBoxText: string;
  checkBoxResult: boolean;
  isCheckBoxOtp: boolean;
  textInputLabel: string;
  textInputValue: string;
  numberInputLabel: string;
  numberInputValue: string;
  dataTicket: any;
  reasonLabel: string;
}

@Component({
  selector: 'confirm-popup',
  templateUrl: 'confirm-popup.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopup implements OnInit {

  public numberBankMask = Constant.numberBankMask;
  public banksDropdown: IBankDropdown[] = [];
  bankName: string;
  accountNo: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmPopup>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmData,
    public toastr: ToastrService,
    public bankService: BankService,) { }

  ngOnInit() {
    if (this.data.isCancelTicket) {
      this.bankService.banksDropdown().subscribe((banks: IBankDropdown[]) => {
        this.banksDropdown = banks || [];
      });
      if (this.data.bankInfo) {
        this.bankName = this.data.bankInfo.code;
        this.accountNo = this.data.bankInfo.accountNumber;
      }
      if (!this.data.reasonLabel) {
        this.data.reasonLabel = 'Ghi chú';
      }
    }
  }
  onNoClick(): void {
    this.data.execute = false;
    this.dialogRef.close(this.data);
  }
  execute() {
    if (this.data.isCopyTicket && !this.data.numberInputValue) {
      return this.toastr.error("Vui lòng nhập số lượng phiếu muốn sao chép.", "Thông báo!"); 
    }
    this.data.execute = true;
    if (this.data.isCancelTicket) {
      this.data.bankInfo = this.banksDropdown.find(x => x.CODE === this.bankName);
      this.data.bankInfo = new BankInfo(this.data.bankInfo);
      this.data.bankInfo.accountNumber = this.accountNo;
    }
    this.dialogRef.close(this.data);
  }
  checkRequireReason() {
    if (typeof this.data.isRequireReason !== "undefined") {
      return this.data.isRequireReason && !this.data.reason;
    } else {
      return this.data.isReason && !this.data.reason;
    }
  }
  onCbChange(checked) {
    if (this.data.bindCbTxtToReason) {
      if (checked) {
        this.data.reason = this.data.reason ? this.data.reason : this.data.checkBoxText;
      } else {
        this.data.reason = this.data.reason.replace(this.data.checkBoxText, '');
      }
    }
  }

  public isNumber($event) {
    var charCode = $event.key;
    if (charCode !== 'Backspace' && charCode !== 'Delete') {
      if (!Constant.REGEX_NUMBER.test(charCode)) {
        $event.preventDefault();
      }
    }
}
}
