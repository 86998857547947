import { Injector, EventEmitter } from '@angular/core';
import { } from './base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { TableItem } from './custom-table/table.model';
import { NotifyComponent } from './notify.component';
import { Subject } from 'rxjs';
import { addAllToArray } from '@angular/core/src/render3/util';

export abstract class BaseComponent extends NotifyComponent {
    service: any;
    Entity: any;
    route: any;
    router: any;
    tableProperty: any;
    settings: any;
    action: string = 'view-list';
    public emitTable = new Subject<any>();
    // sorting //
    currentSort = {
        propertyName: '', // property Name
        sortOrder: false, //  false Asc, true Desc
    }
    onSorted = new EventEmitter<any>();
    valueSearch: any;
    eventSubscribe: any;
    titleMessage = '';
    paging: any;
    list: any;
    ////
    constructor(
        service: any,
        Entity: any,
        injector: Injector
    ) {
        super(injector);
        this.service = service;
        this.Entity = Entity;
        this.route = injector.get(ActivatedRoute);
        this.router = injector.get(Router);
        this.tableProperty = new TableItem(this.Entity);
        this.action = this.route.snapshot.data.action || 'view-list';
    }
    setDefault(params?) {
        this.route.snapshot.data.action == 'history' && ( this.action = 'history' );
        // check for consignment status : done or process
        this.tableProperty.isLoading = true;
        if (params && params.status === 'process') {
            params.status = this.action === 'history' ? 'done' : params.status;
        }
        return new Promise((resolve, reject) => {
            this.tableProperty.methods = {
                edit: this.edit.bind(this),
                review: this.review.bind(this),
                delete: this.delete.bind(this),
            };
            this.tableProperty.data = [];
            return this.service.get(params ? params : null)
            .subscribe((res) => {
                let resdata = res.rows ? res.rows : res;
                this.tableProperty.data = resdata.map((item) => {
                    return new this.Entity(item);
                });
                this.list = this.tableProperty.data;
                this.action == 'history' && (this.historyData())
                this.emitTable.subscribe((data) => {
                    this.refreshDataTable(data);
                });
                this.tableProperty.isLoading = false;
                resolve(res);
            });
        });
    }
    historyData() {
        //override
    }
    create() {
        this.router.navigate([this.Entity.getType() + '/create']);
    }
    edit(id) {
        this.router.navigate([this.Entity.getType() + '/edit/' + id]);
    }
    review(id) {
        this.router.navigate([this.Entity.getType() + '/review/' + id]);
    }
    checkDelete(id) {
        return true;
    }
    delete(id) {
        if (this.checkDelete(id)) {
            return this.service.delete(id);
        } else {
            this.errorNotice(this.errorMessage);
        }
    }
    refreshDataTable(data) {
        this.tableProperty.data = data.map((item) => {
            return new this.Entity(item);
        });
    }
    // Service message commands
    emitChange(data: any) {
        this.emitTable.next(data);
    }
    executeDelete() {
        return new Promise((resolve, reject) => {
            this.tableProperty.isLoading = true;
            return this.service.get()
            .subscribe((res) => {
                this.emitChange(res);
                resolve(true);
            })
        })
    }
    searchAll(value, list) {
        let items = [];
        list.forEach((item) => {
            if (this.findObject(value, item)) {
                items.push(item);
            }
        })
        return items;
    }
    findObject(value, object) {
        let result = false;
        for (let property in object) {
            if (property != 'texts' && !result) {
                if (typeof object[property] === 'object') {
                    result = this.findObject(value, object[property])
                } else if (typeof object[property] === 'string' &&  object[property]) {
                    if (object[property].toLowerCase().includes(value.toLowerCase())) {
                        result = true;
                    }
                }  else if (typeof object[property] === 'number' &&  object[property]) {
                    object[property] += '';
                    if (object[property].toLowerCase().includes(value)) {
                        result = true;
                    }
                }
            }
        }
        return result;
    }
    setDefaultSort(propertyName, sortOrder = true, currentSort?) {
        currentSort = currentSort || this.currentSort || {};
        currentSort.propertyName = propertyName;
        currentSort.sortOrder = sortOrder;
        this.onSorted.emit(true);
    }

    sortIcon(propertyName: string, currentSort?) {
        currentSort = currentSort || this.currentSort;
        return  currentSort.propertyName !== propertyName && 'unfold_more'
                || currentSort.sortOrder === true && 'expand_more'
                || 'expand_less';
    }

    sort(propertyName: string, sortOrder?, currentSort?) {
        currentSort = currentSort || this.currentSort;
        if (currentSort.propertyName === propertyName) {
            currentSort.sortOrder = sortOrder !== undefined ? sortOrder : !currentSort.sortOrder;
        } else {
            currentSort.propertyName = propertyName;
            currentSort.sortOrder = sortOrder !== undefined ? sortOrder : false;
        }
        this.onSorted.emit(currentSort);
    }

    dynamicSort( dataSource: any[], currentSort?) {
        currentSort = currentSort || this.currentSort;
        if (!dataSource || !dataSource.length) { return; }
        const sortDirection = currentSort.sortOrder ? 1 : -1;
        const propertyName = currentSort.propertyName;
        dataSource.sort( function (a, b) {
            const valueA = a[propertyName] && typeof a[propertyName] === 'string' ? a[propertyName].toLowerCase() : a[propertyName];
            const valueB = b[propertyName] && typeof b[propertyName] === 'string' ? b[propertyName].toLowerCase() : b[propertyName];
            const result = (valueA > valueB) ? 1 : (valueA < valueB) ? -1 : 0;
            return result * sortDirection;
        });
        currentSort === this.currentSort && this.paging.setDefault(dataSource);
        return dataSource;
    }
    transformCurrentSort() {
      return (!this.currentSort.sortOrder ? '' : '-') + this.currentSort.propertyName;
    }
}
