import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxButtonV3Component } from './checkbox-button-v3/checkbox-button-v3.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxGroupV3Component } from './checkbox-group-v3.component';

@NgModule({
  declarations: [
    CheckboxButtonV3Component,
    CheckboxGroupV3Component
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CheckboxButtonV3Component,
    CheckboxGroupV3Component
  ]
})
export class CheckboxGroupV3Module { }
