<!-- <div class="nav-wrapper">
    <div class="a"><a href="#">&nbsp;</a></div>
    <div class="b">
        <nav aria-label="pagination">
            <ul class="pagination">
                <li class="page-item"
                    [ngClass]="(pageModel.selectedPage == 0 || pages.length == 1 ) && 'disabled-page-item'"
                    (click)="changePage(0)">
                    <mat-icon class="icon-paging">first_page</mat-icon>
                </li>
                <li class="page-item"
                    [ngClass]="(pageModel.selectedPage == 0 || pages.length == 1 ) && 'disabled-page-item'"
                    (click)="changePage(pageModel.selectedPage-1)">
                    <mat-icon class="icon-paging">chevron_left</mat-icon>
                </li>
                <li class="page-item number-page" *ngFor="let page of pages, index as index"
                    [ngClass]="index == pages.length - 1 && 'number-page-last-child'"  fxLayout="row" fxLayoutAlign="center center">
                    <span class="page-link" [ngClass]="pageModel.selectedPage == index && 'active-link'"
                        (click)="changePage(index)">
                        {{index+1}}
                    </span>
                </li>
                <li class="page-item"
                    [ngClass]="(pageModel.selectedPage == pages.length -1 || pages.length == 1 ) && 'disabled-page-item'"
                    (click)="changePage(pageModel.selectedPage+1)">
                    <mat-icon class="icon-paging">chevron_right</mat-icon>
                </li>
                <li class="page-item"
                    [ngClass]="(pageModel.selectedPage == pages.length -1 || pages.length == 1 ) && 'disabled-page-item'"
                    (click)="changePage(pages.length -1)">
                    <mat-icon class="icon-paging">last_page</mat-icon>
                </li>
            </ul>
        </nav>
    </div>
    <div class="c">
        <div *ngIf="tableItems && tableItems.length > 0"  fxLayout="row" fxLayoutAlign="center center"
            class="margin-right">
            <span class="text-pagesize">Page size:&nbsp;</span>
            <mat-form-field class="pagesize"  style="width: 45px;">
                <mat-select [(ngModel)]="pageModel.itemsPerPage" (ngModelChange)="changeItemsPerPage($event)">
                    <mat-option *ngFor="let value of [5,10,20,50,100]" [value]="value">{{value}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div> -->

<div class="m-paginator">


    <div class="m-paginator-outer-container">
        <div class="m-paginator-container">
            <div class="m-paginator-page-size">
                <div class="m-paginator-page-size-label">Số lượng: </div>
                <mat-form-field>
                    <mat-select disableOptionCentering [(ngModel)]="itemsPerPage" (ngModelChange)="changeItemsPerPage()">
                        <mat-option *ngFor="let num of [5, 10, 15, 20, 25, 50, 100]" [value]="num">
                            {{num}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="m-paginator-range-actions">
            {{((selectedPage-1)*itemsPerPage) + 1}} -
            {{selectedPage*itemsPerPage < total ? selectedPage*itemsPerPage : total}}
             of {{total}}
            </div>

            <!-- <button mat-button class="mat-paginator-navigation-previous mat-icon-button"
                style="touch-action: none; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
                <span class="mat-button-wrapper">
                    <svg class="m-paginator-icon" focusable="false" viewBox="0 0 24 24">
                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                    </svg>
                </span>
                <div class="mat-button-ripple mat-ripple mat-button-ripple-round"></div>
                <div class="mat-button-focus-overlay"></div>

            </button> -->
            <!-- <i class="material-icons">keyboard_arrow_left</i> -->


            <!-- <button class="mat-paginator-navigation-next mat-icon-button"
                style="touch-action: none; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
                <span class="mat-button-wrapper">
                    <svg class="m-paginator-icon" focusable="false" viewBox="0 0 24 24">
                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                    </svg>
                </span>
                <div class="mat-button-ripple mat-ripple mat-button-ripple-round"></div>
                <div class="mat-button-focus-overlay"></div>

            </button> -->

            <button mat-icon-button [ngClass]="(selectedPage == 1 || totalPages == 1 ) && 'disabled-page-item'"
            (click)="changePage(1)">
                <mat-icon>first_page</mat-icon>
            </button>
            <button mat-icon-button [ngClass]="(selectedPage == 1 || totalPages == 1 ) && 'disabled-page-item'"
            (click)="changePage(+selectedPage - 1)">
                <mat-icon>chevron_left</mat-icon>
            </button>
            <button mat-icon-button [ngClass]="(selectedPage == totalPages || totalPages == 1 ) && 'disabled-page-item'"
             (click)="changePage(+selectedPage + 1)">
                <mat-icon>chevron_right</mat-icon>
            </button>
            <button mat-icon-button [ngClass]="(selectedPage == totalPages || totalPages == 1 ) && 'disabled-page-item'"
             (click)="changePage(totalPages)">
                <mat-icon>last_page</mat-icon>
            </button>
            <!-- <i class="material-icons">keyboard_arrow_right</i> -->
        </div>
    </div>
</div>
