<div class="container">
    <header class="header">
        <h1>{{data.title}}</h1>
    </header>
    <mat-form-field appearance="fill" class="w-100">
        <mat-label>Nội dung tin nhắn</mat-label>
        <textarea matInput [(ngModel)]="htmlContent" rows='5' style="font-size: 14px;"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <div fxLayout="row" fxLayoutAlign="center center" class="w-100">
        <button mat-button class="m-bg-primary m-bg" color="primary" (click)="execute()">{{data.textOk}}</button>
        <button mat-button class="m-bg-grey m-bg" (click)="onNoClick()">{{data.textCancel}}</button>
    </div>
</div>