import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

export interface DialogData {
  type: string;
  title: string;
  reason: string;
  execute: boolean;
  body: string;
  files?: [object];
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'popup-dialog.html',
})
export class InfoDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {

  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  execute() {
    this.data.execute = true;
    this.dialogRef.close(this.data);
  }
  
  openFile(fileUrl) {
    window.open(fileUrl);
  }
  getFileName(fileUrl) {
    const fileNames = fileUrl.split('/');
    return fileNames[fileNames.length - 1];
  }
}