import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'summary'})
export class TransformSummaryText implements PipeTransform {
  transform(value: number, length:any) {
      if (!length) {
        length = '20';
      }
      length = parseInt(length);
      return this.parse(value, length);
  }
  parse(text, length?) {
    if (text) {
      if (length <= text.length) {
        text = text.substring(0 , length) + '...';
      } else  text = text.substring(0 , length);
    }
    return text;
  }
}