export enum PropertyUnitStatus {
  CLOSE = 'CLOSE', // Chưa mở bán
  COMING = 'COMING', // Đang chờ xác nhận
  PROCESSING = 'PROCESSING', // Đang xử lý
  CONFIRM = 'CONFIRM', // ĐVBH xác nhận giao dịch
  LOCK_CONFIRM = 'LOCK_CONFIRM',
  SUCCESS = 'SUCCESS', // Giao dịch thành công
  UNSUCCESS = 'UNSUCCESS', // Giao dịch không thành công
}

export enum TicketTypeEnum {
  YCDCH = 'YCDCH',
  YCDC = 'YCDC',
}