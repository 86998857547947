<div class="home-layout-footer unselectable">
  <div fxLayout="row" fxLayoutAlign="center start">
    <span class="home-layout-footer__sub-title">Đồng hành cùng Propcom trên cơ sở phát triển đôi bên cùng có lợi và
      cung cấp những sản phẩm dịch vụ tốt nhất đến với khách hàng.</span>
  </div>
  <div class="home-layout-footer__section-contact">
    <div class="container-xl" fxLayout="row">
      <div class="home-layout-footer__item">
        <div class="home-layout-footer__item__icon">
          <img src="assets/public/icons/icon_fill_phone.svg" />
        </div>
        <div class="home-layout-footer__item__text">
          <span class="home-layout-footer__item__text__title">Hotline</span>
          <span class="home-layout-footer__item__text__sub-title">0937 055 368</span>
        </div>
      </div>
      <div class="home-layout-footer__item">
        <div class="home-layout-footer__item__icon">
          <img src="assets/public/icons/icon_fill_chat.svg" />
        </div>
        <div class="home-layout-footer__item__text">
          <span class="home-layout-footer__item__text__title">Tư vấn trực tiếp</span>
          <span class="home-layout-footer__item__text__sub-title"></span>
        </div>
      </div>
      <div class="home-layout-footer__item">
        <div class="home-layout-footer__item__icon">
          <img src="assets/public/icons/icon_fill_faq.svg" />
        </div>
        <div class="home-layout-footer__item__text">
          <span class="home-layout-footer__item__text__title">FAQ</span>
          <a href="https://propcom.vn/ho-tro/" class="home-layout-footer__item__text__sub-title">
            Hỗ trợ
          </a>
        </div>
      </div>
      <div class="home-layout-footer__item">
        <div class="home-layout-footer__item__icon">
          <img src="assets/public/icons/icon_fill_star.svg" />
        </div>
        <div class="home-layout-footer__item__text">
          <span class="home-layout-footer__item__text__title">Hướng dẫn sử dụng
          </span>
          <a href="https://propcom.vn/huong-dan/" class="home-layout-footer__item__text__sub-title">
            Hướng dẫn sử dụng
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="home-layout-footer__section-social">
    <div class="container-xl" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="d-flex align-items-center">
        <span class="home-layout-footer__sub-title">Copyright © Propcom. All rights reserved</span>
      </div>
      <div fxLayout="row">
        <div class="home-layout-footer__item-social">
          <img src="assets/public/icons/icon_social_fb.svg" />
        </div>
        <div class="home-layout-footer__item-social">
          <img src="assets/public/icons/icon_social_youtube.svg" />
        </div>
        <div class="home-layout-footer__item-social">
          <img src="assets/public/icons/icon_social_tw.svg" />
        </div>
      </div>
    </div>
  </div>
</div>