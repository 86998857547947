<div class="container">
    <header class="header">
        <h1>{{data.title}}</h1>
    </header>
    <angular-editor [(ngModel)]="htmlContent" [config]="config"></angular-editor>
</div>
<div mat-dialog-actions>
    <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%;">
        <button mat-button class="m-bg-primary m-bg" color="primary" (click)="execute()">{{data.textOk}}</button>
        <button mat-button class="m-bg-grey m-bg" (click)="onNoClick()">{{data.textCancel}}</button>
    </div>
</div>