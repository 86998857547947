import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { ConfirmPopup } from 'app/shared/components/confirm-popup/confirm-popup';
import { MatDialog } from '@angular/material';

@Injectable()
export class CommonService {

    constructor(
      private dialog: MatDialog
    ){
    }

    public disabledToggle(component: any) {
        component.disabled = !component.disabled;
    }

    public noWhitespaceValidator(control: FormControl) {
        if (control && (control.value === null || (control.value && control.value.length === 0))) {
            return null;
        } else {
            const isWhitespace = (control.value || '').trim().length === 0;
            const isValid = !isWhitespace;
            return isValid ? null : { 'whitespace': true };
        }
    }
    public getAmountByDemandCategory(category) {
      const amountInBil = parseInt(category.split(' ')[0]);
      if (amountInBil > 0) {
        return amountInBil * 1000000;
      } else {
        return 0;
      }
    }

    public showNonProductionNotice() {
      if (environment.production) { return; }
      const dialogRef = this.dialog.open(ConfirmPopup, {
        width: '650px',
        data: {
          title: 'Website Demo!!!',
          isReason: false,
          message: 'Dữ liệu thao tác tại đây sẽ không được ghi nhận trên hệ thống chính thức.',
          textCancel: 'Ra khỏi đây',
          textOk: 'Tiếp tục sử dụng',
        }
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result && result.execute) {
          return false;
        } else {
          window.location.href = 'https://dxres.vn';
        }
      });
    }

    public refreshLiveChat() {

      $('.stringeeX_chatbox_iframe_wrapper').remove();
      dispatchEvent(new Event('load'));
      let interval = setInterval(() => {
        if ($('#stringeeChatIframe')[0]) {
          $('.stringeeX_chatbox_iframe_wrapper').css('left', 0);
          let inputInfo = $($('#stringeeChatIframe')[0]['contentWindow'].document).find('#inputInfo');
          if (inputInfo && inputInfo.length) {
            inputInfo.attr('onclick', `$('#select_queue').val('${environment.stringChat.itSupportQueue}');`);
            setTimeout(() => {
              if (($($('#stringeeChatIframe')[0]['contentWindow'].document).find('#inputName')[0] as HTMLInputElement).value.length > 0) {
                $($('#stringeeChatIframe')[0]['contentWindow'].document).find('#inputName').attr('readonly', 'true');
                $($('#stringeeChatIframe')[0]['contentWindow'].document).find('#inputEmail').attr('readonly', 'true');
              }
            }, 2000);

            $($('#stringeeChatIframe')[0]['contentWindow'].document).find('.chat_close_icon').click(() => {
              $('.stringeeX_chatbox_iframe_wrapper').hide();
              setTimeout(() => {
                $('.stringeeX_chatbox_iframe_wrapper').css('left', 0);
                $('.stringeeX_chatbox_iframe_wrapper').show();
              }, 1000);
            });
            clearInterval(interval);
          }
        }
      }, 1000);
    }
}
