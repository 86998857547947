<div fxLayout="column" fxLayoutAlign="center stretch" class="h-100">
    <div class="top-bar"></div>
    <!-- Logo -->
    <div class="logo-container">
      <img src="assets/img/login/icon-logo-propcom.svg">
    </div>
    <!-- Main form -->
    <div fxLayout="row" fxFlex="40" fxLayoutAlign="center stretch">
      <div fxLayout="row" fxFlex="40" fxLayoutAlign="stretch stretch">
        <mat-card class="flex-1 mat-elevation-z4">
          <!-- Loading -->
          <mat-card-content [class.hidden]="!isLoading" fxLayout="row" class="h-100" fxLayoutAlign="center center">
            <mat-spinner color="primary"></mat-spinner>
          </mat-card-content>
          <!-- Token invalid -->
          <mat-card-content [class.hidden]="isLoading || isTokenValidate" fxLayout="column" class="h-100"
            fxLayoutAlign="start center">
            <div class="flex-1" fxLayout="column" fxLayoutAlign="center center">
              <div class="w-100 text-center form-login__header">
                <span>Yêu cầu thay đổi email không xác định được hoặc đã hết hạn</span>
              </div>
            </div>
            <div class="main-image">
              <img src="assets/img/dxres/img-reset-pass.svg">
            </div>
            <div class="flex-1"></div>
          </mat-card-content>
          <!-- Token valid -->
          <mat-card-content [class.hidden]="isLoading || !isTokenValidate" fxLayout="row" class="h-100"
            fxLayoutAlign="center center">
            <div *ngIf="isSend" fxLayout="column" fxLayoutAlign="center center" class="flex-1">
              <div class="flex-1" fxLayout="column" fxLayoutAlign="center center">
                <div class="w-100 text-center form-login__header">
                  <span>Thay đổi email thành công</span>
                </div>
              </div>
              <div class="main-image">
                <img src="assets/img/dxres/img-reset-pass.svg">
              </div>
              <div class="flex-1">
                <button (click)="goToLogin()" class="form-login__button" style="width: 280px;">Đăng nhập</button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <!-- bottom -->
    <div class="bottom-container"></div>
  </div>