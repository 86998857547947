<div class="dialog">
  <div fxLayout="row" fxLayoutAlign="center center" class="title-area">
    <h1 class="title-confirm">{{data.title ? data.title : 'BẠN CÓ MUỐN THAM GIA?'}}</h1>
  </div>
  <div mat-dialog-content class="content-area">
    <div fxLayout="column" fxLayoutAlign="space-around center" class="wrapper">
      <ng-container *ngIf="shareUrl && shareUrl.length > 0; else elseQRCode">
        <div class="qrcode">
          <qrcode class="qrcode__qrcode-wrapper" [qrdata]="shareUrl" [size]="140" [level]="'H'"></qrcode>
        </div>
      </ng-container>
      <ng-template #elseQRCode>
          <img src="../../../../assets/img/landing/QRcode.svg" alt="qrCode">
      </ng-template>
      <p>Tải ứng dụng ngay để trải nghiệm tính năng của Propcom</p>
      <div class="btn-app">
        <a href="https://apps.apple.com/vn/app/propcom-c%E1%BB%99ng-%C4%91%E1%BB%93ng-m%C3%B4i-gi%E1%BB%9Bi/id1551955496?l=vi"
          target="_blank" rel="noopener noreferrer">
          <img src="../../../../assets/img/landing/Appstore@2x.png" alt="appstore">
        </a>
        <a href="https://play.google.com/store/apps/details?id=vn.propcom.app" target="_blank"
          rel="noopener noreferrer">
          <img src="../../../../assets/img/landing/Googleplay@2x.png" alt="ggplay">
        </a>
      </div>
    </div>
  </div>
  <mat-icon (click)="onClose()" class="close-button">close</mat-icon>
</div>