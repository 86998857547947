import { validate, Contains, IsInt, IsNumber, Length, IsEmail, IsFQDN, IsDate, Min, Max, IsBoolean, ValidateIf, IsNotEmpty } from "class-validator";
import { BaseModel } from "../../shared/models/base.model";
import { BaseModelInterface } from "../../shared/models/base.model.interface";
import { IsLongerThan } from "../../shared/decorators/IsLongerThan.decorator";
import { DefineAttribute } from "../define-attributes/define-attributes.model";
import { GeoLocation } from "./location.model";
import { MoneyToNumber } from "../../shared/parse/money-to-number";
import { InfoTrading } from "./info.trading.model";
import { Images } from "../../shared/components/upload-images/image.model";
import { Category } from "../category/category.model";
import { Constant } from "../../shared/constant/constant";
import { NumberToMoneyVN } from 'app/shared/parse/number-to-money-vn';
import { Project } from '../project/project.model';
export class Property extends BaseModel implements BaseModelInterface {

    id: string;

    code: string;

    name: string;

    @IsNotEmpty()
    address: string;

    @IsNotEmpty()
    fakeAddress: string;

    @IsNotEmpty()
    ward: string;

    @IsNotEmpty()
    district: string;

    city: string;

    @IsNotEmpty()
    province: string;

    category: any;

    categoryName: any;

    attributes: any = [];

    location: GeoLocation;

    @IsNotEmpty()
    transactionType: string;

    images: Images;

    @IsNumber()
    @Min(100000)
    price: number;

    priceAbove: number;
    priceEval:number;

    priceText: string;

    priceNumber: number;

    @IsNumber()
    @Min(10)
    area: number;

    // @IsNotEmpty()
    direction: string;

    description: string;

    infoTrading: InfoTrading;

    @IsNotEmpty()
    source: string;

    status: string;

    isHot: boolean;
    pos: object;
    active: boolean;
    project: any;
    contractId: string;

    isPublic: boolean;

    employeeTakeCare: any;
    linkListing: any;
    constructor(
        param?: any
    ) {
        super();
        this.setValue(param);
    }

    static getType() {
        return 'property';
    }

    setValue(params: any) {
        if (params) {
            this.id = params.id || '';
            this.code = params.code || '';
            this.name = params.name || '';
            this.category = params.category ? new Category(params.category).createObjectReference() : new Category().createObjectReference();
            this.categoryName = this.category.name;
            this.project = params.project ? new Project(params.project).createObjectReference() : new Project().createObjectReference();
            this.attributes = params.attributes ? [...params.attributes] : [];
            this.price = params.price || params.originPrice || null;
            this.priceAbove = params.priceAbove || null;
            this.priceNumber = params.price || 0;
            this.priceText = NumberToMoneyVN.parse(this.price || 0);
            this.area = params.area || null;
            this.infoTrading = new InfoTrading();
            this.description = params.description || '';
            this.source = params.source || 'Web Broker';
            this.status = params.status || 'init';
            this.direction = params.direction || '';
            this.location = params.location ? new GeoLocation(params.location) : new GeoLocation();
            this.images = params.images ? new Images(params.images) : new Images();
            this.address = params.address || '';
            this.fakeAddress = params.fakeAddress || '';
            this.province = params.province || '';
            this.district = params.district || '';
            this.ward = params.ward || '';
            this.transactionType = params.transactionType || Constant.SELL_TRANSACTION;
            this.isHot = params.isHot || false;
            this.pos = params.pos || null;
            this.active = params.active === null ? true : params.active;
            this.contractId = params.contractId || '';
            this.isPublic = params.isPublic === null ? true : params.isPublic;
            this.employeeTakeCare = params.employeeTakeCare;
            this.priceEval = params.priceEval || 0;            
            this.linkListing = params.linkListing;
        } else {
            this.setAllNull();
        }
        super.setValue(params);
    }

    setAllNull() {
        this.id = '';
        this.code = '';
        this.name = '';
        this.address = '';
        this.fakeAddress = '';
        this.category = new Category().createObjectReference();
        this.attributes = [];
        this.area = null;
        this.price = null;
        this.priceAbove = null;
        this.priceNumber = 0;
        this.infoTrading = new InfoTrading();
        this.direction = '';
        this.description = '';
        this.source = 'Web Broker';
        this.status = 'init';
        this.location = new GeoLocation();
        this.images = new Images();
        this.transactionType = Constant.SELL_TRANSACTION;
        this.isHot = false;
        this.pos = null;
        this.active = true;
        this.project = new Project().createObjectReference();
        this.contractId =  '';
        this.isPublic = true;
        this.priceEval = 0;
        this.linkListing= []; 
    }
    validate() {
        this.errors = {};
        if (!this.price) {
            this.errors.price = 'Giá phải có giá trị';
            this.errors.isExist = true;
        }
        if (!this.area) {
            this.errors.area = 'Diện tích phải có giá trị';
            this.errors.isExist = true;
        }
        if (!this.direction) {
            this.errors.direction = 'Hướng phải có giá trị';
            this.errors.isExist = true;
        }
        if (!this.category.id) {
            this.errors.category = 'Danh mục phải có giá trị';
            this.errors.isExist = true;
        }
        if (!this.address) {
            this.errors.address = 'Địa chỉ phải có giá trị';
            this.errors.isExist = true;
        }
        if (!this.ward) {
            this.errors.ward = 'Phường/ Xã phải có giá trị';
            this.errors.isExist = true;
        }
        if (!this.district) {
            this.errors.district = 'Quận/ Huyện phải có giá trị';
            this.errors.isExist = true;
        }
        if (!this.province) {
            this.errors.province = 'Tỉnh/ Thành phố phải có giá trị';
            this.errors.isExist = true;
        }
        this.errors.location = this.location.validate();
        if (this.errors.location.isExist) {
            this.errors.isExist = true;
        }
        if (this.attributes.length == 0) {
            this.errors.attributes = {
                text: 'Thuộc tính phải được nhập vào'
            };
            this.errors.isExist = true;
        }
        this.errors.images = this.images.validate();
        if (this.errors.images.isExist) {
            this.errors.isExist = true;
        }
        return this.errors;
    }
    createObjectRequestAPI() {
        let object: any = {};
        this.id && (object.id = this.id || '');
        object.code = this.code || '';
        object.name = this.name || '';
        object.infoAddress = {};
        object.address = this.address || '';
        object.fakeAddress = this.fakeAddress || '';
        object.ward = this.ward || '';
        object.district = this.district || '';
        object.city = this.city || '';
        object.province = this.province || '';
        object.direction = this.direction || '';
        object.category = this.category;
        object.attributes = this.attributes ? this.attributes.map((attribute) => {
            return new DefineAttribute(attribute).createObjectRequestAPI();
        })
            : [];
        object.commonAttributes = [
            { name: 'price', type: "Number", weight: this.price, value: this.price },
            { name: 'area', type: "Number", value: this.area },
            { name: 'direction', type: "Text", value: this.direction },
        ];
        object.transactionType = this.transactionType;
        object.price = MoneyToNumber.parse(this.price);
        object.priceAbove = MoneyToNumber.parse(this.priceAbove);
        object.area = MoneyToNumber.parse(this.area);
        object.location = this.location.createObjectRequestAPI();
        object.description = this.description;
        object.source = this.source;
        object.status = this.status;
        object.infoTrading = this.infoTrading;
        object.images = this.images;
        object.isHot = this.isHot === null ? false: this.isHot;
        object.pos = this.pos || null;
        object.active = this.active === null ? true : this.active;
        object.project = this.project;
        object.contractId = this.contractId || '';
        object.isPublic = this.isPublic === null ? true : this.isPublic;
        object.priceEval = this.priceEval;
        object.linkListing = this.linkListing;
        return object;
    }
}
