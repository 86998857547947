<div fxLayout="column" class="otp-validation-component">
  <div fxLayout="column" fxLayoutAlign="center center" class="header-modal">
    <span class="header-modal__title">NHẬP MÃ OTP</span>
  </div>
  <div class="body-modal" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="center center">
    <ng-otp-input #otpInput (onInputChange)="onOtpChange($event)" [config]="otpConfig"></ng-otp-input>
    <span *ngIf="isOtpInvalid" class="warning-text">Mã OTP không chính xác, vui lòng thử lại</span>
    <button (click)="accept()" [disabled]="!validateOtp" class="btn-confirm">Xác nhận</button>
    <div fxLayout="column" fxLayoutAlign="center center">
      <ng-container>
        <ng-container *ngIf="!isSendOtp; else sentOtp">
          <span class="body-text">Bạn chưa nhận được mã?</span>
          <a href="javascript:;" (click)="reGenerateOtp()">Gửi lại</a>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #sentOtp>
  <span>Vui lòng nhập mã OTP</span>
</ng-template>
