

export interface IPrimaryTransactionHistory {
    step: number;
    name: string;
    isSuccess: boolean;
    message: any;
    startTime: string;
    endTime: string;
}

export class PrimaryTransactionHistory implements IPrimaryTransactionHistory {
    step: number;
    name: string;
    isSuccess: boolean;
    message: any;
    startTime: string;
    endTime: string;

    constructor(params?: any) {
        params = params || {};
        this.step = params.step || '';
        this.name = params.name || '';
        this.message = params.message || null;
        this.isSuccess = params.isSuccess || false;
        this.startTime = Date.now().toString();
    }

    toSuccess() {
        this.isSuccess = true;
        this.endTime = Date.now().toString();
    }

    toFail(message: any) {
        this.isSuccess = false;
        this.message = message;
        this.endTime = Date.now().toString();
    } 

}