import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { environment } from "environments/environment";
import { DialogJoinComponent } from "../dialog-join/dialog-join.component";
import { LandingPageEventModel } from "../landing-page.model";
import { LandingPageService } from "../landing-page.service";
@Component({
  selector: "app-landing-event",
  templateUrl: "./landing-event.component.html",
  styleUrls: ["./landing-event.component.scss"],
})
export class LandingEventComponent implements OnInit {
  model: LandingPageEventModel = new LandingPageEventModel();
  id: string = "";
  checkDataEvent: boolean = false;

  viewerOptions: any = {
    navbar: false,
    toolbar: {},
  };

  constructor(
    public route: ActivatedRoute,
    private service: LandingPageService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    // Router subscribe
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get("id");
    });
    const params = this.route.snapshot.params;
    const id = params["id"];
    if (id) {
      this.service.getEventById(id).subscribe({
        next: (res: any) => {
          this.checkDataEvent = true
          this.model = new LandingPageEventModel(res);
        },
        error: (err) => {
        }
      });
    } else {
      this.model = new LandingPageEventModel();
    }
  }

  openDialog() {
    const dialog = this.dialog.open(DialogJoinComponent, {
      width: '800px',
      data: {
        shareUrl: this.model.shareUrl,
      }
    });
    dialog.afterClosed().subscribe(result => { });
  }
}
