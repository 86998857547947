export class CHelper {
    static RemoveEmpty(obj: any) {
        Object.keys(obj).forEach(key => {

            if (!obj[key]) {
                delete obj[key];
            } else if (obj[key] && typeof obj[key] === 'object') {
                CHelper.RemoveEmpty(obj[key]);
            }
        });

        return obj;
    }
}
