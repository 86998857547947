import { Component, OnInit, ViewEncapsulation, ViewChild, Injector, HostListener, ElementRef } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { NotificationService } from '../../../shared/services/notification.service';
import { Router } from '@angular/router';
import { NotificationMsg } from '../../../shared/models/notification-msg.model';
import { EventChangeService } from '../../../shared/services/event.change.service';
import { DataService, StorageService, SecurityService } from '../../../shared/services';
import { NotifyComponent } from '../../../shared/components/notify.component';
import {
  Message, StompService, ToastrService
} from '../../../shared/services/common';
import { MessagesService } from './messages.service';
import { StringeeService } from 'app/pages/message/stringee.service';
import { Conversation } from 'app/shared/models/conversation.model';
import { Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { UserV2Service } from 'app/shared/services/common/user-v2.service';
import { VoiceCallService } from 'app/shared/services/voicecall.service';
import { WsNotifyService } from 'app/shared/components/ws-notify.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  providers: [MessagesService],
  encapsulation: ViewEncapsulation.None
})
export class MessagesComponent extends NotifyComponent implements OnInit {
  [x: string]: any;
  public notificationCount = 0;
  authenticated: boolean = false;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  public selectedTab: number = 1;
  public msgs: any = [];
  public msgsTicket: any = [];
  public msgsContract: any = [];
  public msgsDeal: any = [];
  public msgsCall: any = [];
  public msgsOther: any = [];
  public isShow: boolean = false;
  public isShowDialpad: boolean = false;
  public isActive: boolean = false;
  tabSelected: number = 1;
  public files: Array<Object>;
  public meetings: Array<Object>;
  conversations: Conversation[] = [];
  private updateConversationsCallback = new Subject<boolean>();
  updateConversationsCallback$ = this.updateConversationsCallback.asObservable();
  public totalUnreadMessage: any = 0;
  public liveChatEmail: string = environment.liveChatEmail;

  authSubscription: Subscription;

  dialpadNumber: string = '+84 ';

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
    } else {
      this.isShow = false;
      this.isShowDialpad = false;
    }
  }

  constructor(
    private injector: Injector,
    public router: Router,
    private notificationService: NotificationService,
    public eventChangeService: EventChangeService,
    private service: DataService,
    public storageService: StorageService,
    private messagesService: MessagesService,
    private stringeeService: StringeeService,
    private eRef: ElementRef,
    private securityService: SecurityService,
    private userV2Service: UserV2Service,
    private voicallService: VoiceCallService,
    private wsNotifyService: WsNotifyService

  ) {
    super(injector);
    this.files = messagesService.getFiles();
    this.meetings = messagesService.getMeetings();
    this.authenticated = securityService.IsAuthorized;
  }
  // ngOnDestroy() {
  //   this.notificationService.stopPolling();
  // }

  ngOnInit() {
    this.notificationService.getNotifications(false);
    this.notificationService.notifications$.subscribe(
      (notifications) => {
        let count = 0;
        notifications.forEach((item) => {
          !item.isRead && count++;
        })
        this.notificationCount = count;
        this.msgs = notifications.filter(item => item);
        this.msgs = this.msgs.slice(0, 5);

        this.msgsTicket = notifications.filter(item => item.msg.entityName == "lead");
        this.msgsTicket = this.msgsTicket.slice(0, 5);

        this.msgsContract = notifications.filter(item => item.msg.entityName == "contract");
        this.msgsContract = this.msgsContract.slice(0, 5);

        this.msgsDeal = notifications.filter(item => item.msg.entityName == "dealt" || item.msg.entityName == "dealing");
        this.msgsDeal = this.msgsDeal.slice(0, 5);

        this.msgsCall = notifications.filter(item => item.msg.entityName == "call");
        this.msgsCall = this.msgsCall.slice(0, 5);

        this.msgsOther = notifications.filter(item =>
          item.msg.entityName != "dealt" &&
          item.msg.entityName != "contract" &&
          item.msg.entityName != "lead" &&
          item.msg.entityName != "dealing" &&
          item.msg.entityName != "call"
        );
        this.msgsOther = this.msgsOther.slice(0, 5);
      }
    );
    // Subscribe to login and logout observable
    this.authSubscription = this.securityService.IsAuthenticated.subscribe(res => {
      this.authenticated = res;
    });
    let userId = this.storageService.retrieve('userId');
    userId && (this.infoMessagingPattern = this.messagingPattern + 'spx-adsg.notification.user-' + userId);
    this.wsNotifyService.subscribe([...this.infoMessagingPattern]);

    // Start polling request notification service
    //this.notificationService.startPolling();
    // this.notificationService.observable$.subscribe(() => {
    //   if (this.authenticated) {
    //     this.infoMessagingPattern !== this.messagingPattern && this.subscribeMessage();
    //     return this.notificationService.getNotifications(false);
    //   }
    // });
    // this.eventSubscribe = this.eventChangeService.emitChangeSource.subscribe((data) => {
    //   try {
    //     if (data.type && data.type === "notification") {
    //       if (this.authenticated) {
    //         this.notificationService.getNotifications(false);
    //       }
    //     }
    //   } catch (error) {
    //     console.log('MessagesComponent', error);
    //   }
      // switch (data.broadcast) {
      //   case 'contractDeleted':
      //     // show toast
      //     this.toastr.success('Thành công', 'Xóa hợp đồng');
      //   break;
      //   case this.userId:
      //     this.setDefault();
      //   break;
      // }
  // });

    this.stringeeService.isMessage = false;
    this.userV2Service.user$.subscribe(res => {
      if (res && res.id) {
        // this.stringeeService.connectStringee();
      }
    });
    this.updateConversationsCallback = this.stringeeService.updateConversationsCallback;
    this.updateConversationsCallback.subscribe((res) => {
      this.conversations = StringeeService.conversations;
      this.totalUnreadMessage = _.sumBy(this.conversations, function (conv) {
        return conv.unreadCount;
      });
      this.totalUnreadMessage = (this.totalUnreadMessage > 99) ? '99+' : this.totalUnreadMessage;
    });
  }

  markAsReadAll(even: any) {
    this.notificationService.readAllNotification().pipe()
      .subscribe((res) => {
        this.msgs = JSON.parse(res['_body']).slice(0, 5);
        let count = 0;
        this.msgs.forEach((item) => {
          !item.isRead && count++;
        })
        this.notificationCount = count;
      })
  }

  tabChanged(event) {
    let tab = event.tab.textLabel;
    switch (tab) {
      case 'Tất cả': {
        this.tabSelected = 1;
        break;
      }
      case 'Yêu cầu': {
        this.tabSelected = 2;
        break;
      }
      case 'Hợp đồng': {
        this.tabSelected = 3;
        break;
      }
      case 'Giao dịch': {
        this.tabSelected = 4;
        break;
      }
      case 'Cuộc gọi': {
        this.tabSelected = 5;
        break;
      }
      default:
        this.tabSelected = 6;
    }
  }

  openMessagesMenu() {
    this.trigger.openMenu();
    this.selectedTab = 0;
  }

  stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  markAsRead(message) {
    this.isShow = false;
    if (!message.isRead) {
      return this.notificationService.markAsRead(message.id)
        .then(() => {
          this.directUrl(message);
        });
    } else {
      this.directUrl(message);
    }
  }
  directUrl(message) {
    if (message.url) {
      message.url = message.url.replace('{{id}}', message.msg.entityId);
      this.reloadCurrentRoute([message.url]);
    } else {
      this.toastrService.info('Do not url', 'Notifcation');
    }
  }
  reloadCurrentRoute(currentUrl) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(currentUrl);
    });
  }
  getList() {
    let url = '';
    return this.service.get(url)
      .subscribe((res) => {
        this.notificationService.getNotifications(false);
      });
  }

  goNotification() {
    this.isShow = false;
    this.router.navigate(['/notification', this.tabSelected]);
  }

  public subscribeMessage(isReset?) {
    if (!isReset) {
      if (this.websocketService.getChannel(this.infoMessagingPattern)) {
        return;
      } else {
        this.websocketService.setChannel(this.infoMessagingPattern, null);
      }
    } else {
      this.websocketService.setChannel(this.infoMessagingPattern, null);
      this.websocketService.getChannel(this.infoMessagingPattern);
    }
    // Stream of messages
    // info
    if (this.infoMessagingPattern !== this.messagingPattern) {
      this.messages = this.stompService.subscribe(this.infoMessagingPattern);
      this.subscription = this.messages.subscribe(this.infoSubscribe.bind(this));
    }
  }
  /** Consume a message from the stompService */
  public infoSubscribe(message: Message) {
    if (message && message.body) {
      let object = JSON.parse(message.body);
      object = new NotificationMsg(object);
      if (!object.msg.eventName) {
        return;
      }
      this.notificationService.receiveNotification([object]);
      !object.msg.eventName && (object.msg.eventName = '');
      let title = object.msg.eventName.toUpperCase();
      // this.toastrService.success('Thành Công!', title);
      if (object.msg.eventName.toLowerCase().includes('delete')) {
        return this.executeDelete();
      }
    }
  }

  // showAll() {
  //   this.isActive = true;
  // }

  // showContract() {
  //   this.isActive = true;
  //   this.notificationService.notifications$.subscribe(
  //     (notifications) => {
  //       let count = 0;
  //       notifications.forEach((item) => {
  //         !item.isRead && count++;
  //       })
  //       this.notificationCount = count;
  //       this.msgs = notifications.filter(item => item.msg.entityName == 'booking-calendar');
  //       this.msgs = this.msgs.slice(0,5);
  //     }
  //   );
  // }

  pressDialpad(key: any) {
    this.dialpadNumber += key;
  }

  clearDialpad() {
    this.dialpadNumber = '+84 ';
  }

  callDialpad() {
    this.isShowDialpad = false;
    const toNumber = this.dialpadNumber.replace('+', '').replace(' ', '');
    this.voicallService.callOutWithRouteCall(toNumber, this.dialpadNumber);
  }

}
