<div fxLayout="row" fxLayoutAlign="end center" class="deal-checker-title">
  <div (click)="onNoClick()" class="button-close">
    <mat-icon>close</mat-icon>
  </div>
</div>
<mat-card class="deal-checker">
  <div class="text-center text-header">Đồng bộ giao dịch </div>
  <div mat-dialog-content *ngIf="!isSyncing" class="deal-checker-content">
    <p class="text-center" *ngIf="!isSuccess && currentStep === 1">Bạn xác nhận đồng bộ sản phẩm {{item?.code}}?</p>
    <p class="text-center" *ngIf="isSuccess">Hoàn thành đồng bộ sản phẩm {{item?.code}}!</p>
  </div>
  <div mat-dialog-content class="deal-checker-content">
    <p class="text-center" *ngIf="isSyncing || isSuccess">Tiến độ: {{currentStep}}/{{totalSteps}}</p>
    <br>
    <p class="text-center" *ngIf="isSyncing">Đang đồng bộ, vui lòng không tắt trình duyệt</p>
    <ng-container *ngFor="let history of historySyncs; let ind = ind;">

      <p class="text-center">Bước {{history.step}} 
        <img src="assets/img/icon/icon_check.svg" alt="" *ngIf="history.isSuccess" class="history-success"> 
        <img src="assets/img/icon/cancel.svg" alt="" *ngIf="!history.isSuccess && history.endTime" class="history-success"> 

      </p>
    </ng-container>
  </div>
  <mat-progress-bar *ngIf="isSyncing" class="example-margin" color="primary" mode="indeterminate">
  </mat-progress-bar>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
    <button *ngIf="!isSyncing && currentStep === 1" class="m-bg action-btn" mat-button (click)="execute()">Xác nhận</button>
    <button *ngIf="isSuccess" class="m-bg action-btn" mat-button (click)="onNoClick()">Hoàn tất</button>
    <button *ngIf="!isSyncing && !isSuccess" class="m-bg close-btn" mat-button (click)="onNoClick()">Đóng</button>
  </div>
</mat-card>