export class TableItem {
    protected Entity : any;
    titles: any;
    values: any;
    valuesShow: any = [];
    types: any;
    data: any;
    sortList: any;
    configuration: any;
    methods: any;
    // editable: boolean = false;
    constructor( 
        Entity: any
    ) {
        this.Entity = Entity;
        this.setDefault();
        this.setValues();
    }
    setDefault() {
        this.titles = [];
        this.values = [];
        this.types = {};
        this.data = [];
        this.configuration = {
            editable: true,
            choose: false
        }
    }
    parseTitleFromValue(value) {
        let array = [];
        var object = '';
        for (let i=0; i< value.length; i++) {
            let char = value.charAt(i);
            if (char.charCodeAt(0) >= 97 && char.charCodeAt(0) <= 122) {
                !object && (char = char.toUpperCase())
                object+= char;
            } else {
                array.push(object);
                object = '';
                if (char.charCodeAt(0) >= 65 && char.charCodeAt(0) <= 90) {
                    object+= char;
                }
            }
        }
        array.push(object);
        return array.join(' ');
    }
    setTitles(titles?: any) {
        this.titles = [];
        if (!titles) {
            this.titles = this.values.map((value) => {
                return this.parseTitleFromValue(value);
            })
        } else {
            this.titles = titles;
        }
    }
    setValuesSort(sorts?: any) {
        if (sorts) {
            this.sortList = sorts.map((value) => {
                return {
                    properties: [value],
                    value: 'allitem',
                    asc: true
                }
            });
        } else {
            this.sortList = this.values.map((value) => {
                return {
                    properties: [value],
                    value: 'allitem',
                    asc: true
                }  
            });
        }
    }
    chooseValueShow(values) {
        this.values = values;
        this.valuesShow = values;
        let object: any = {};
        values.forEach((value) => {
            if (value === 'edit') {
                this.configuration.editable = true;
            } else {
                object[value] = 'set';
            }
        });

        this.setValuesSort();
        this.setValues(object);
    }
    setValues(object?: any) {
        let entity: any = new this.Entity();
        let propterties: any = object ? object : new this.Entity();
        this.values = [];
        this.types = {};
        for ( let property in propterties) {
            if (typeof entity[property] !== 'function') {
                this.values.push(property);
                let type: any = property.toUpperCase();
                if (type.includes('IMAGE')) {
                    this.types[property] = 'image';
                } else if (type.includes('DATE')) {
                    this.types[property] = 'date';
                } else if (type.includes('LOGO')) {
                    this.types[property] = 'logo';
                } else if (typeof entity[property] === 'boolean') {
                    this.types[property] = 'boolean';
                } else if (typeof entity[property] === 'number') {
                    this.types[property] = 'number';
                } else {
                    this.types[property] = 'text';
                }
                if (property === 'roles') {
                    this.types[property] = 'roles';
                }
            }
        }
        this.setTitles();
    }
    setConfigurationChecked() {
        this.configuration.choose = true;
        this.values.unshift('checked');
        this.types['checked'] = 'check';
        this.titles.unshift('');
    }
    setDefaultUpdate() {
        
    }
    setNoCreate() {
        this.configuration.noCreate = true;
    }
    setNoEdit() {
        this.configuration.editable = false;
    }
    setInputTable() {
        this.configuration.isInputTable = true;
    }
}