<div class="landing_page">
    <app-landing-header (openDialog)="openDialog()"></app-landing-header>
    <div class="landing_page-body" [ngSwitch]="checkDataEsalekit">
        <img src="../../../../assets/img/landing/abstract.svg" alt="landingAbstract" />
        <div class="landing_page-wrapper" *ngSwitchCase="true">
            <div (click)="url360Clicked()" class="header" [ngClass]="toggleHoverCss">
                <img class="banner-img" [src]="model.bannerUrl ? model.bannerUrl : bannerUrlDemo" alt="" srcset="" />
                <img *ngIf="model.project.url360" class="icon360" src="../../../../assets/img/landing/icon360.svg" />
                <div class="title">{{ model.project.name }}</div>
            </div>

            <div class="folders" *ngIf="model.folders.length">
                <div class="item" (click)="openDialog()" *ngFor="let item of model.folders">
                    <img [src]="
                            item.iconFolder ? item.iconFolder : srcFolderIcon
                        " alt="" />
                    <div>{{ item.name }}</div>
                </div>
            </div>

            <div class="container" *ngIf="model.folderImages.length">
                <div class="linear-left"></div>
                <div class="text mat-typography">HÌNH ẢNH TIÊU BIỂU</div>
                <div class="linear-right"></div>
            </div>
            <div class="folderImages" *ngIf="model.folderImages.length">
                <div class="item" (click)="openDialog()" *ngFor="let item of model.folderImages">
                    <img [src]="item.iconFolder? item.iconFolder : srcFolderImageIcon" alt="" />
                    <div>{{ item.name }}</div>
                </div>
            </div>

            <div class="container" *ngIf="model.folderVideos.length">
                <div class="linear-left"></div>
                <div class="text mat-typography">VIDEO DỰ ÁN</div>
                <div class="linear-right"></div>
            </div>
            <div class="folderVideos" *ngIf="model.folderVideos.length">
                <div class="item" (click)="openDialog()" *ngFor="let item of model.folderVideos">
                    <img [src]="srcFolderVideoIcon" alt="" />
                    <div class="video-center">
                        <span>{{ item.name }}</span>
                        <span>{{ item.totalDetail }}
                            {{
                            item.totalDetail > 1 ? "videos" : "video"
                            }}</span>
                    </div>
                </div>
            </div>

            <div class="container">
                <div class="linear-left"></div>
                <div class="text mat-typography">VỊ TRÍ DỰ ÁN</div>
                <div class="linear-right"></div>
            </div>
            <agm-map
                class="map-container"
                [latitude]="coordinates[1] ? coordinates[1] : 21"
                [longitude]="coordinates[0] ? coordinates[0] : 105"
            >
                <agm-marker
                    [latitude]="coordinates[1] ? coordinates[1] : null"
                    [longitude]="coordinates[0] ? coordinates[0] : null"
                ></agm-marker>
            </agm-map>
        </div>

        <div class="landing_page-wrapper-empty" *ngSwitchCase="false">
            <div class="landing_page-content">
                <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="12px">
                    <img src="../../../../assets/img/landing/Group404.svg" alt="landingAbstract" />
                    <div class="infoGroup" fxLayoutAlign="center center">
                        <div fxLayoutAlign="center center" fxLayoutGap="5px">
                            <span class="text-er">Xin lỗi! Bạn không thể xem được tài liệu
                                này</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="landing_page-intro-wrapper">
            <div class="landing_page-intro" fxLayout="row" fxLayoutGap="60px">
                <div fxLayout="column" fxFlex="50" class="landing_page-intro-img">
                    <img src="../../../../assets/img/landing/Mockup.png" alt="Mockup" />
                </div>
                <div fxLayout="column" fxFlex="50" class="landing_page-intro-content">
                    <div class="intro-title">
                        <h1>QUẢNG BÁ SẢN PHẨM</h1>
                        <h1 class="text-primary">
                            THAM GIA TALKSHOW VỚI TÍNH NĂNG SỰ KIỆN
                        </h1>
                    </div>
                    <div class="intro-content">
                        <p>
                            Dễ dàng tham gia sự kiện quảng bá sản phẩm, talkshow
                            trao đổi kinh nghiệm với 2 hình thức linh hoạt trực
                            tuyến hoặc ngoại tuyến.
                        </p>
                        <p>
                            Nâng cao trải nghiệm các sự kiện qua toàn bộ giải
                            pháp công nghệ về môi giới bất động sản trên ứng
                            dụng Propcom của chúng tôi — dù đó là sự kiện miễn
                            phí hoặc có phí.
                        </p>
                    </div>
                    <div class="intro-button btn-landing">
                        <button mat-raised-button (click)="openDialog()">
                            Tải ứng dụng
                        </button>
                    </div>
                </div>
            </div>
            <img src="../../../../assets/img/landing/abstract.png" alt="abstractFooter" />
        </div>
        <app-landing-footer [shareUrl]="model.shareUrl"></app-landing-footer>
    </div>
</div>
