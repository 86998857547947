export class EmployeeReport {

    id: string;
    name: string;
    data: any[];
    total: string;

    constructor(
        param?: any
    ) {
      this.setValue(param);
    }

    setValue(params: any) {
        this.id = params ? params.id || null : null;
        this.name = params ? params.name || null : null;
        this.data = params ? params.data || [] : [];
        let total = 0;
        this.data.forEach(v => total += v.count);
        this.total = String(total).padStart(2, '0');
    }
}
