export class Conversation {
    id: string;
    name: string;
    isGroup: boolean;
    updatedAt: Date;
    lastMsgSender: string;
    lastMsgContent: object; // { content }
    lastMsgType: number;
    unreadCount: number;
    lastMsgId: string;
    creator: string;
    created: Date;
    pinMsgId: string;
    participants: Participant[];
    constructor(parrams: object) {
    }
    checked?: boolean = false;
}

export class Participant {
    userId: string;
    name: string;
    avatar: string;
    lastMsgSeqReceived: number;
    lastMsgSeqSeen: number;
}

export class Message {
    id: string;
    localId: string;
    conversationId: string;
    sender: string;
    createdAt: Date;
    sequence: number;
    type: number;
    content: any; // { content }
    state: number;
}
