import { Injectable } from '@angular/core';
import { Conversation, Message } from 'app/shared/models/conversation.model';
import { Observable, Subject } from 'rxjs';
import {DataService, StorageService} from 'app/shared/services';
import {BaseService} from '../../shared/components/base.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService extends BaseService {
  public convs: Conversation[] = [];
  private eventCallback = new Subject<Conversation[]>();
  eventCallback$ = this.eventCallback.asObservable();
  constructor(
    private storageService: StorageService,
    public service: DataService
  ) {
    super(service);
  }

  incomingMessage(convId, msgId, text, time, isIncoming) {
    // const conv = this.convs.find(x => x.id = convId);
    // if (conv) {
    //   const newMessage = new Message({
    //     content: text,
    //     time: time,
    //     isIncoming: isIncoming
    //   });
    //   conv.messages.push(newMessage);
    //   conv.lastMgs = text;
    // }
    // else {
    //   const newConv = new Conversation({
    //     id: convId,
    //     to: '',
    //     lastMgs: text,
    //     messages: [new Message({
    //       content: text,
    //       time: time,
    //       isIncoming: isIncoming
    //     })],

    //   });
    //   this.convs.push(newConv);
    // }

    this.eventCallback.next(this.convs);
  }

  updateLastMessage(mgs: any) {
    // const conv = this.convs.find(x => x.id = mgs.id);
    // if (conv) {
    //   conv.lastMgs = mgs.lastMsgContent.content;
    // }
    // else {
    //   const from = mgs.name;
    //   const newConv = new Conversation({
    //     id: mgs.id,
    //     to: from,
    //     time: mgs.updatedAt,
    //     lastMgs: mgs.lastMsgContent.content
    //   });
    //   this.convs.push(newConv);
    // }
    // this.eventCallback.next(this.convs);
  }

  addMessage(convId: string, textMessage: string) {
    // const conv = this.convs.find(x => x.id === convId);
    // if (conv) {
    //   const newMessage = new Message({
    //     content: textMessage,
    //     isIncoming: false
    //   });
    //   // conv.messages.push(newMessage);
    // }
    this.eventCallback.next(this.convs);
  }

  getHistoryConversation() {

  }

  findUserByQueryString(query) {
    // TODO: find user by string query
    const url = '';
    return this.service.get(url, {query : query});
  }
}
