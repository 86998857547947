<div fxLayout="column" fxLayoutAlign="center stretch" class="h-100">
  <div class="top-bar"></div>
  <!-- Logo -->
  <div class="logo-container">
    <img src="assets/img/login/icon-logo-propcom.svg">
  </div>
  <!-- Main form -->
  <div fxLayout="row" fxFlex="40" fxLayoutAlign="center stretch">
    <div [formGroup]="form" fxLayout="row" fxFlex="40" fxLayoutAlign="stretch stretch">
      <mat-card class="flex-1 mat-elevation-z4">
        <!-- Loading -->
        <mat-card-content [class.hidden]="!isLoading" fxLayout="row" class="h-100" fxLayoutAlign="center center">
          <mat-spinner color="primary"></mat-spinner>
        </mat-card-content>
        <!-- Token invalid -->
        <mat-card-content [class.hidden]="isLoading || isTokenValidate" fxLayout="column" class="h-100"
          fxLayoutAlign="start center">
          <div class="flex-1" fxLayout="column" fxLayoutAlign="center center">
            <div class="w-100 text-center form-login__header">
              <span>Yêu cầu lấy lại mật khẩu không xác định được hoặc đã hết hạn</span>
            </div>
          </div>
          <div class="main-image">
            <img src="assets/img/dxres/img-reset-pass.svg">
          </div>
          <div class="flex-1"></div>
        </mat-card-content>
        <!-- Token valid -->
        <mat-card-content [class.hidden]="isLoading || !isTokenValidate" fxLayout="row" class="h-100"
          fxLayoutAlign="center center">
          <div *ngIf="!isSend" fxLayout="row" class="flex-1">
            <div class="left-section">
              <img src="assets/img/dxres/img-reset-pass.svg">
            </div>
            <div class="seperator-vertical"></div>
            <div fxLayout="column" fxLayoutAlign="baseline center" class="right-section">
              <div class="controls">
                <div class="w-100 text-center form-login__header">
                  <span>Tạo lại mật khẩu</span>
                </div>
                <mat-form-field class="w-100">
                  <mat-label>Nhập mật khẩu mới</mat-label>
                  <input matInput type="password" formControlName="password" class="pd-5">
                  <mat-error *ngIf="getFormControl('password').invalid">
                    <span *ngIf="getFormControl('password').errors.required">{{Constant.ERROR_REQUIRED}}</span>
                    <span *ngIf="getFormControl('password').errors.minlength">Vui lòng nhập tối thiểu {{minLength}} ký
                      tự</span>
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="w-100">
                  <mat-label>Nhập lại mật khẩu</mat-label>
                  <input matInput type="password" formControlName="confirmPassword" class="pd-5">
                  <mat-error *ngIf="getFormControl('confirmPassword').invalid">
                    <span *ngIf="getFormControl('confirmPassword').errors.required">{{Constant.ERROR_REQUIRED}}</span>
                    <span *ngIf="getFormControl('confirmPassword').errors.mustMatch">Mật khẩu nhập lại khác mật khẩu đã nhập.</span>
                  </mat-error>
                </mat-form-field>
                <button (click)="changePassword()" class="form-login__button">Thay đổi mật khẩu</button>
              </div>
            </div>
          </div>
          <div *ngIf="isSend" fxLayout="column" fxLayoutAlign="center center" class="flex-1">
            <div class="flex-1" fxLayout="column" fxLayoutAlign="center center">
              <div class="w-100 text-center form-login__header">
                <span>Thay đổi mật khẩu thành công</span>
              </div>
            </div>
            <div class="main-image">
              <img src="assets/img/dxres/img-reset-pass.svg">
            </div>
            <div class="flex-1">
              <button (click)="goToLogin()" class="form-login__button" style="width: 280px;">Đăng nhập</button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <!-- bottom -->
  <div class="bottom-container"></div>
</div>