
<button mat-icon-button (click)="isShowDialpad = !isShowDialpad; isShow = false">
  <mat-icon>dialpad</mat-icon>
</button>

<button mat-icon-button (click)="isShow = !isShow; isShowDialpad = false">
  <mat-icon *ngIf="notificationCount > 0" [matBadge]="notificationCount" matBadgeColor="warn">notifications</mat-icon>
  <mat-icon *ngIf="!notificationCount" matBadgeColor="warn">notifications</mat-icon>
</button>

<div class="notification container" [ngClass]="isShow ? 'show' : 'hide'">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="header__title">
      Thông báo
    </div>
    <div class="header__cleal" (click)="markAsReadAll($event)">
      Đánh dấu tất cả là đã đọc
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabChanged($event)">
      <mat-tab label="Tất cả">
        <div class="item" *ngFor="let message of msgs" [ngClass]="!message.isRead && 'notRead'"
          (click)="markAsRead(message)">
          <div class="icon">
            <img matListAvatar src="assets/img/dxres/icon/icon_hd.svg" *ngIf="message.msg.entityName === 'contract'">
            <img matListAvatar src="assets/img/dxres/icon/icon-lienhencskh.svg"
              *ngIf="message.msg.entityName === 'lead'">
            <img matListAvatar src="assets/img/dxres/icon/icon-yctv.svg"
              *ngIf="message.msg.entityName === 'dealt' || message.msg.entityName === 'dealing'">
            <img matListAvatar src="assets/img/dxres/icon/icon-call.svg" *ngIf="message.msg.entityName === 'call'">
            <img matListAvatar src="assets/img/dxres/icon/icon-profile-lichhen.svg"
              *ngIf="message.msg.entityName === 'booking-calendar'">
            <img matListAvatar src="assets/img/dxres/icon/icon-pass.svg" *ngIf="message.msg.entityName === 'user'">
          </div>
          <div class="content">
            <div class="w-100" fxLayout="row" fxLayoutAlign="space-between">
              <div class="title-content">
                {{message.msg?.title}}
              </div>
              <div class="time">
                {{message.createdAt | date: 'dd/MM/yyyy, hh:mm'}}
              </div>
            </div>
            <div class="w-100 text-content text-bold">
              {{message.sender?.email}}
            </div>
            <div class="w-100 text-content">
              {{message.msg?.content}}
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Yêu cầu">
        <div class="item" *ngFor="let message of msgsTicket" [ngClass]="!message.isRead && 'notRead'"
          (click)="markAsRead(message)">
          <div class="icon">
            <img matListAvatar src="assets/img/dxres/icon/icon_hd.svg" *ngIf="message.msg.entityName === 'contract'">
            <img matListAvatar src="assets/img/dxres/icon/icon-lienhencskh.svg"
              *ngIf="message.msg.entityName === 'lead'">
            <img matListAvatar src="assets/img/dxres/icon/icon-yctv.svg"
              *ngIf="message.msg.entityName === 'dealt' || message.msg.entityName === 'dealing'">
            <img matListAvatar src="assets/img/dxres/icon/icon-call.svg" *ngIf="message.msg.entityName === 'call'">
            <img matListAvatar src="assets/img/dxres/icon/icon-profile-lichhen.svg"
              *ngIf="message.msg.entityName === 'booking-calendar'">
            <img matListAvatar src="assets/img/dxres/icon/icon-pass.svg" *ngIf="message.msg.entityName === 'user'">
          </div>
          <div class="content">
            <div class="w-100" fxLayout="row" fxLayoutAlign="space-between">
              <div class="title-content">
                {{message.msg?.title}}
              </div>
              <div class="time">
                {{message.createdAt | date: 'dd/MM/yyyy, hh:mm'}}
              </div>
            </div>
            <div class="w-100 text-content text-bold">
              {{message.sender?.email}}
            </div>
            <div class="w-100 text-content">
              {{message.msg?.content}}
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Hợp đồng">
        <div class="item" *ngFor="let message of msgsContract" [ngClass]="!message.isRead && 'notRead'"
          (click)="markAsRead(message)">
          <div class="icon">
            <img matListAvatar src="assets/img/dxres/icon/icon_hd.svg" *ngIf="message.msg.entityName === 'contract'">
            <img matListAvatar src="assets/img/dxres/icon/icon-lienhencskh.svg"
              *ngIf="message.msg.entityName === 'lead'">
            <img matListAvatar src="assets/img/dxres/icon/icon-yctv.svg"
              *ngIf="message.msg.entityName === 'dealt' || message.msg.entityName === 'dealing'">
            <img matListAvatar src="assets/img/dxres/icon/icon-call.svg" *ngIf="message.msg.entityName === 'call'">
            <img matListAvatar src="assets/img/dxres/icon/icon-profile-lichhen.svg"
              *ngIf="message.msg.entityName === 'booking-calendar'">
            <img matListAvatar src="assets/img/dxres/icon/icon-pass.svg" *ngIf="message.msg.entityName === 'user'">
          </div>
          <div class="content">
            <div class="w-100" fxLayout="row" fxLayoutAlign="space-between">
              <div class="title-content">
                {{message.msg?.title}}
              </div>
              <div class="time">
                {{message.createdAt | date: 'dd/MM/yyyy, hh:mm'}}
              </div>
            </div>
            <div class="w-100 text-content text-bold">
              {{message.sender?.email}}
            </div>
            <div class="w-100 text-content">
              {{message.msg?.content}}
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Giao dịch">
        <div class="item" *ngFor="let message of msgsDeal" [ngClass]="!message.isRead && 'notRead'"
          (click)="markAsRead(message)">
          <div class="icon">
            <img matListAvatar src="assets/img/dxres/icon/icon_hd.svg" *ngIf="message.msg.entityName === 'contract'">
            <img matListAvatar src="assets/img/dxres/icon/icon-lienhencskh.svg"
              *ngIf="message.msg.entityName === 'lead'">
            <img matListAvatar src="assets/img/dxres/icon/icon-yctv.svg"
              *ngIf="message.msg.entityName === 'dealt' || message.msg.entityName === 'dealing'">
            <img matListAvatar src="assets/img/dxres/icon/icon-call.svg" *ngIf="message.msg.entityName === 'call'">
            <img matListAvatar src="assets/img/dxres/icon/icon-profile-lichhen.svg"
              *ngIf="message.msg.entityName === 'booking-calendar'">
            <img matListAvatar src="assets/img/dxres/icon/icon-pass.svg" *ngIf="message.msg.entityName === 'user'">
          </div>
          <div class="content">
            <div class="w-100" fxLayout="row" fxLayoutAlign="space-between">
              <div class="title-content">
                {{message.msg?.title}}
              </div>
              <div class="time">
                {{message.createdAt | date: 'dd/MM/yyyy, hh:mm'}}
              </div>
            </div>
            <div class="w-100 text-content text-bold">
              {{message.sender?.email}}
            </div>
            <div class="w-100 text-content">
              {{message.msg?.content}}
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Cuộc gọi">
        <div class="item" *ngFor="let message of msgsCall" [ngClass]="!message.isRead && 'notRead'"
          (click)="markAsRead(message)">
          <div class="icon">
            <img matListAvatar src="assets/img/dxres/icon/icon_hd.svg" *ngIf="message.msg.entityName === 'contract'">
            <img matListAvatar src="assets/img/dxres/icon/icon-lienhencskh.svg"
              *ngIf="message.msg.entityName === 'lead'">
            <img matListAvatar src="assets/img/dxres/icon/icon-yctv.svg"
              *ngIf="message.msg.entityName === 'dealt' || message.msg.entityName === 'dealing'">
            <img matListAvatar src="assets/img/dxres/icon/icon-call.svg" *ngIf="message.msg.entityName === 'call'">
            <img matListAvatar src="assets/img/dxres/icon/icon-profile-lichhen.svg"
              *ngIf="message.msg.entityName === 'booking-calendar'">
            <img matListAvatar src="assets/img/dxres/icon/icon-pass.svg" *ngIf="message.msg.entityName === 'user'">
          </div>
          <div class="content">
            <div class="w-100" fxLayout="row" fxLayoutAlign="space-between">
              <div class="title-content">
                {{message.msg?.title}}
              </div>
              <div class="time">
                {{message.createdAt | date: 'dd/MM/yyyy, hh:mm'}}
              </div>
            </div>
            <div class="w-100 text-content text-bold">
              {{message.sender?.email}}
            </div>
            <div class="w-100 text-content">
              {{message.msg?.content}}
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Khác">
        <div class="item" *ngFor="let message of msgsOther" [ngClass]="!message.isRead && 'notRead'"
          (click)="markAsRead(message)">
          <div class="icon">
            <img matListAvatar src="assets/img/dxres/icon/icon_hd.svg" *ngIf="message.msg.entityName === 'contract'">
            <img matListAvatar src="assets/img/dxres/icon/icon-lienhencskh.svg"
              *ngIf="message.msg.entityName === 'lead'">
            <img matListAvatar src="assets/img/dxres/icon/icon-yctv.svg"
              *ngIf="message.msg.entityName === 'dealt' || message.msg.entityName === 'dealing'">
            <img matListAvatar src="assets/img/dxres/icon/icon-call.svg" *ngIf="message.msg.entityName === 'call'">
            <img matListAvatar src="assets/img/dxres/icon/icon-profile-lichhen.svg"
              *ngIf="message.msg.entityName === 'booking-calendar'">
            <img matListAvatar src="assets/img/dxres/icon/icon-pass.svg" *ngIf="message.msg.entityName === 'user'">
          </div>
          <div class="content">
            <div class="w-100" fxLayout="row" fxLayoutAlign="space-between">
              <div class="title-content">
                {{message.msg?.title}}
              </div>
              <div class="time">
                {{message.createdAt | date: 'dd/MM/yyyy, hh:mm'}}
              </div>
            </div>
            <div class="w-100 text-content text-bold">
              {{message.sender?.email}}
            </div>
            <div class="w-100 text-content">
              {{message.msg?.content}}
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <!-- <div class="sub-header" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="menu" [ngClass]="isActive ? 'active' : ''" (click)="showAll()">
            Tất cả
        </div>
        <div class="menu" [ngClass]="isActive ? 'active' : ''" (click)="showContract()">
            Hợp đồng
        </div>
        <div class="menu">
            Giao dịch 
        </div>
        <div class="menu">
            Cuộc gọi
        </div>
        <div class="menu">
            Thông báo khác
        </div>
    </div> -->
  <div class="footer" (click)="goNotification()">
    Xem tất cả thông báo
  </div>
</div>

<div class="dialpad container" [ngClass]="isShowDialpad ? 'show' : 'hide'">
  <div class="wrap-typing-number">
    <input type="text" placeholder="+84966050926" [(ngModel)]="dialpadNumber">
    <button class="btn-close" (click)="clearDialpad()">
      <img src="https://static.stringee.com/stringeex/web_phone/2.1.2/images/icon-close.svg">
    </button>
  </div>
  <div class="wrap-diapad">
    <div class="diapad-row">
      <button class="diapad-key" (click)="pressDialpad(1)">
        <span class="diapad-key-number"> 1 </span>
        <span class="diapad-key-text"> </span>
      </button>
      <button class="diapad-key" (click)="pressDialpad(2)">
        <span class="diapad-key-number"> 2 </span>
        <span class="diapad-key-text"> </span>
      </button>
      <button class="diapad-key" (click)="pressDialpad(3)">
        <span class="diapad-key-number"> 3 </span>
        <span class="diapad-key-text"> </span>
      </button>
    </div>
    <div class="diapad-row">
      <button class="diapad-key" (click)="pressDialpad(4)">
        <span class="diapad-key-number"> 4 </span>
        <span class="diapad-key-text"> </span>
      </button>
      <button class="diapad-key" (click)="pressDialpad(5)">
        <span class="diapad-key-number"> 5 </span> <span class="diapad-key-text"> </span>
      </button>
      <button class="diapad-key" (click)="pressDialpad(6)">
        <span class="diapad-key-number"> 6 </span>
        <span class="diapad-key-text"> </span>
      </button>
    </div>
    <div class="diapad-row">
      <button class="diapad-key" (click)="pressDialpad(7)">
        <span class="diapad-key-number"> 7 </span>
        <span class="diapad-key-text"> </span>
      </button>
      <button class="diapad-key" (click)="pressDialpad(8)">
        <span class="diapad-key-number"> 8 </span>
        <span class="diapad-key-text"> </span>
      </button>
      <button class="diapad-key" (click)="pressDialpad(9)">
        <span class="diapad-key-number"> 9 </span>
        <span class="diapad-key-text"> </span>
      </button>
    </div>
    <div class="diapad-row">
      <button class="diapad-key" id="diapad-key-star">
        <span class="diapad-key-number"> </span>
        <span class="diapad-key-text"> </span>
      </button>
      <button class="diapad-key" (click)="pressDialpad(0)">
        <span class="diapad-key-number"> 0 </span>
        <span class="diapad-key-text" style="font-size: 18px;">
          <span>
            <!-- + -->
          </span>
        </span>
      </button>
      <button class="diapad-key" id="diapad-key-sharp">
        <span class="diapad-key-number"> </span>
        <span class="diapad-key-text"> </span>
      </button>
    </div>
  </div>
  <div class="dialpad-action">
    <button class="btn-action-incomming" (click)="callDialpad()">
      <img src="https://static.stringee.com/stringeex/web_phone/2.1.2/images/icon-phone.svg" class="icon-gray">
    </button>
  </div>
</div>
