<div id="endSurvey"></div>
<div>
  <div mat-dialog-title class="d-center justy-between">
    <div class="text-center w-100"><h3 class="title-color">{{title}}</h3></div>
    <mat-icon (click)="closeDialog()" class="close-button cs-pointer">close</mat-icon>
  </div>
  <div class="app-card__body" fxLayout="column" [formGroup]="mainForm">
    <div fxLayout="row">
      <div fxLayout="column" fxFlex="100">
          <div class="input-container">
              <div fxFlex="100" class="input-value app-form-group">
                  <textarea [maxlength]="maxLength" formControlName="note" class="app-textarea" placeholder="Nhập ghi chú" rows="5"></textarea>
              </div>
          </div>
          <mat-error>
            <span *ngIf="mainForm.controls.note.touched && mainForm.controls.note.invalid && mainForm.controls.note.errors?.required">
                {{ Constant.ERROR_REQUIRED }}
            </span>
          </mat-error>
      </div>
    </div>
    <div *ngIf="data.survey ? true : false">
      <div class="main-content__info" fxLayout="row" fxLayoutAlign="space-between center">
        <div></div>
        <div (click)="openSurveyFilter()" style="cursor: pointer;">
          <img [src]="openCloseSurvey ? './assets/img/dxres/collapse.svg' : './assets/img/dxres/uncollapse.svg'" alt="">
        </div>
      </div>
      <div *ngIf="openCloseSurvey" [@expandCollapse]="openCloseSurvey">
        <!-- <app-survey-form [mainForm]="mainForm" [data]="survey" (answer)="checkAnswer($event)"></app-survey-form> -->
        <div class="top-row">
          <span class="top-row__title">Tên khảo sát: {{data.survey?.name}}</span>
        </div>
        
        <div *ngFor="let question of survey.questions; let i = index" >
          <span *ngIf="question.type === questionEnum.TEXTBOX">
              <div class="input-container">
                  <div fxFlex="40">                               
                      <span class="app-label"
                          >{{question.name}}</span
                      ><span class="m-red" *ngIf="question.isRequired"> *</span>
                  </div>
                  <div
                      fxFlex="50"
                      class="input-value app-form-group"
                  >
                      <input
                          type="text"
                          class="app-input"
                          placeholder="Nhập câu trả lời"
                          formControlName="{{question.id}}"
                      />
                  </div>
              </div>
              <mat-error>
                <span *ngIf="mainForm.controls[question.id].touched && mainForm.controls[question.id].invalid && mainForm.controls[question.id].errors?.required">
                  {{ Constant.ERROR_REQUIRED }}
                </span>
              </mat-error>
          </span>
          <div *ngIf="question.type === questionEnum.DROPDOWN">
              <div fxLayout="row">
                  <div fxLayout="column" fxFlex="100">
                      <div class = "input-container">
                          <div fxFlex="40">
                              <span class="app-label">{{question.name}}</span>
                              <span class="m-red" *ngIf="question.isRequired"> *</span>
                          </div>
                          <div fxFlex="50" class="input-value app-form-group">
                              <app-select
                                  [clearable]="false"
                                  [items]="question.answers"
                                  (change)="setShowTextBox($event, question)"
                                  bindLabel="name"
                                  bindValue="code"
                                  placeholder="Nhập câu trả lời"
                                  formControlName="{{question.id}}"
                              >
                              </app-select>
                              <mat-error>
                                <span *ngIf="mainForm.controls[question.id].touched && mainForm.controls[question.id].invalid && mainForm.controls[question.id].errors?.required">
                                  {{ Constant.ERROR_REQUIRED }}
                                </span>
                              </mat-error>
                          </div>
                      </div>
                  </div>
              </div>
              <div fxLayout="row">
                  <div fxLayout="column" fxFlex="100">
                      <div class = "input-container">
                          <div fxFlex="40"> 
                              <span class="app-label"></span>
                          </div>
                          <div fxFlex="50">
                              <div class="input-value app-form-group ">
                                  <textarea class="app-textarea hiddenTextBox" formControlName="textbox_{{question.id}}" rows="3" [disabled]="isUpdate" 
                                  placeholder="Nhập câu trả lời" id="{{question.id}}"></textarea>
                                  <mat-error>
                                    <span *ngIf="mainForm.controls['textbox_'+ question.id].touched && 
                                          mainForm.controls['textbox_'+ question.id].invalid && mainForm.controls['textbox_'+ question.id].errors?.required">
                                      {{ Constant.ERROR_REQUIRED }}
                                    </span>
                                  </mat-error>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
  
          <span *ngIf="question.type === questionEnum.TEXTAREA">
              <div class="input-container">
                  <div fxFlex="40">
                      <span class="app-label">{{question.name}}</span>
                      <span class="m-red" *ngIf="question.isRequired"> *</span>
                  </div>
                  <div
                      fxFlex="50"
                      class="input-value app-form-group"
                  >
                    <textarea class="app-textarea" rows="3" placeholder="Nhập lời nhắn" [disabled]="isUpdate" formControlName="{{question.id}}"></textarea>
                    <mat-error>
                      <span *ngIf="mainForm.controls[question.id].touched && mainForm.controls[question.id].invalid && mainForm.controls[question.id].errors?.required">
                        {{ Constant.ERROR_REQUIRED }}
                      </span>
                    </mat-error>
                  </div>
              </div>
          </span>
        </div>
        
      </div>
    </div>
    <div fxLayout="row" class="button-center">
      <button (click)="onSubmit()" mat-stroked-button color="primary"
          class="action__btn">
          <span>Lưu</span>
      </button>
    </div>
    <div *ngIf="isShowHistory === true" class="mt-1">
        <div class="main-content__info" style="padding: 16px 0;" fxLayout="row" fxLayoutAlign="space-between center">
          <div>Lịch sử ghi chú</div>
          <div (click)="openHistoriesFilter()" style="cursor: pointer;">
            <img [src]="openCloseHistory ? './assets/img/dxres/collapse.svg' : './assets/img/dxres/uncollapse.svg'" alt="">
          </div>
        </div>

      <div fxLayout="column" class="action-container" *ngIf="openCloseHistory && isLoanPackage" [@expandCollapse]="openCloseHistory">
        <app-grid-note-list [pagination]="histories" [isShowSurvey]="isShowSurvey"></app-grid-note-list>
      </div>
      <div fxLayout="column" class="action-container" *ngIf="openCloseHistory && !isLoanPackage"
        [@expandCollapse]="openCloseHistory">
        <app-grid-note-loan-list [pagination]="histories" [isShowSurvey]="isShowSurvey"></app-grid-note-loan-list>
      </div>
      <div id="endPage"></div>
    </div>
  </div>
</div>
