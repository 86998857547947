import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ColDef, ColGroupDef, GridApi, GridOptions, SortChangedEvent } from 'ag-grid-community';
import { CPagination } from 'app/api-models';
import { TemplateRendererComponent } from 'app/pages/project/event-sale/list/template-renderer/template-renderer.component';
import { moment } from 'fullcalendar';



@Component({
  selector: 'app-grid-kyc-list',
  templateUrl: './grid-kyc-list.html',
  styleUrls: ['./grid-kyc-list.scss']
})
export class GridKycListComponent implements AfterViewInit {
  @ViewChild('createdDateRow') createdDateRow: TemplateRef<any>;
  @ViewChild('detailRow') detailRow: TemplateRef<any>;
  @ViewChild('actionRow') actionRow: TemplateRef<any>;
  @Input() pagination: CPagination<any> = new CPagination<any>();
  @Output() sort: EventEmitter<any> = new EventEmitter();
  public gridApi: GridApi;
  public columnDefs: ColDef[] | ColGroupDef;

  defaultGridOptions: GridOptions = {
    defaultColDef: {
      resizable: true,
      autoHeight: true,
      tooltipComponent: 'customTooltip',
      sortable: false,
      enableCellChangeFlash: false,
    },
    autoSizePadding: 10,
    cellFlashDelay: 500,
    rowBuffer: 30,
    animateRows: false,
    suppressRowTransform: true,
    enableCellTextSelection: true,
    singleClickEdit: true,
    enableBrowserTooltips: true,
    tooltipShowDelay: 1,
    overlayNoRowsTemplate: '<span> Không có dữ liệu </span>',
    onSortChanged: (event: SortChangedEvent) => {
      this.getDataSortChange(event.api.getSortModel()[0]);
    },
  };
  gridOptions: GridOptions = { ...this.defaultGridOptions };

  constructor(
    public router: Router,
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
  ) { }

  getInfoNote(data) {
    return `${data.createdBy.name} - ${moment(data.createdDate).format('DD/MM/YYYY HH:mm')} - ${data.note}`;
  }

  ngAfterViewInit() {
    this.columnDefs = [
      {
        headerName: 'STT',
        width: 50,
        valueGetter: (params) => {
          return `${params.node.rowIndex + 1}`;
        }
      },
      {
        headerName: 'Thời gian tạo',
        width: 120,
        cellRendererFramework: TemplateRendererComponent,
        cellRendererParams: {
          ngTemplate: this.createdDateRow
        },
      },
      {
        headerName: 'Trạng thái',
        width: 70,
        valueGetter: (item => {
          return item.data.isError ? 'Thất bại' : 'Thành công';
        }),
      },
      {
        headerName: 'Chi tiết',
        cellRendererFramework: TemplateRendererComponent,
        cellRendererParams: {
          ngTemplate: this.detailRow
        },
        autoHeight: true,
      },
      // {
      //   headerName: 'Data',
      //   width: 70,
      //   cellRendererFramework: TemplateRendererComponent,
      //   cellRendererParams: {
      //     ngTemplate: this.actionRow
      //   },
      // },
    ];
    this.cdRef.detectChanges();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.setAutoSize();
  }

  getDataSortChange(data: any) {
    this.sort.emit(data);
  }

  setAutoSize() {
    this.gridApi.setDomLayout('autoHeight');
    if (this.pagination) {
      this.gridApi.setDomLayout('normal');
    }
    this.gridApi.sizeColumnsToFit();
  }

  showLoadingOverlay() {
    this.gridApi && this.gridApi.showLoadingOverlay();
  }

  hideOverlay() {
    this.gridApi && this.gridApi.hideOverlay();
  }

  // openDetail(item: any) {
  // }
}
