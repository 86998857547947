import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class SidenavService {
  private sidenav: MatSidenav;
  private sidenavPropertyTicket: MatSidenav;
  private sidenavTicketDetail: MatSidenav;
  public selectedTicket$: BehaviorSubject<{ticket: any, isCopy: boolean}> = new BehaviorSubject(null);

  public setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  public open() {
    return this.sidenav.open();
  }


  public close() {
    return this.sidenav.close();
  }

  public toggle(): void {
    this.sidenav.toggle();
  }

  // Sidenav tạo yêu cầu tư vấn dự án
  public setSidenavPropertyTicket(sidenav: MatSidenav) {
    this.sidenavPropertyTicket = sidenav;
  }

  public openPropertyTicket() {
    return this.sidenavPropertyTicket.open();
  }

  public closePropertyTicket() {
    if (this.sidenavPropertyTicket) {
      return this.sidenavPropertyTicket.close();
    }
  }

  public togglePropertyTicket(): void {
    this.sidenavPropertyTicket.toggle();
  }

  // Sidenav chi tiết yêu cầu
  public setSidenavTicketDetail(sidenav: MatSidenav) {
    this.sidenavTicketDetail = sidenav;
  }

  public setTicketDetailData(ticket: any, isCopy = false) {
    this.selectedTicket$.next({ticket: ticket, isCopy: isCopy});
  }

  public openTicketDetail() {
    return this.sidenavTicketDetail.open();
  }

  public closeTicketDetail() {
    return this.sidenavTicketDetail && this.sidenavTicketDetail.close();
  }

  public toggleTicketDetail(): void {
    this.sidenavTicketDetail.toggle();
  }

}
