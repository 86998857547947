<mat-card class="custom-card" *ngIf="!data.isEdit; else edit">
  <div fxLayout="row" fxLayoutAlign="center center" class="title-area">
    <h1 class="title-confirm">{{ data.title }}</h1>
  </div>
  <div mat-dialog-content class="content-area">
    <mat-form-field class="search-area">
      <input matInput type="text" placeholder="Nhập từ khóa..." [(ngModel)]="data.query"
        (ngModelChange)="inputChanged()">
      <button mat-button *ngIf="data.query" matSuffix mat-icon-button aria-label="Clear" (click)="data.query=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div *ngIf="isLoading; else loaded" fxLayout="column" fxLayoutAlign="center center" style="margin-top: 10px;">
      <mat-spinner diameter="40" color="primary"></mat-spinner>
    </div>
    <ng-template #loaded>
      <div class="loaded">
        <h3 class="list-title">Danh sách tin nhắn hiện tại</h3>
        <div class="user-list">
          <div
            *ngFor="let conv of data.conversations | search:'name':data.query: false: existStringeeUsers: data.currentUserId">
            <div fxLayout="row" class="user-list-item" *ngIf="!conv.isCustomer">
              <div fxLayout="column" fxFlex="17" fxLayoutAlign="center center" class="part">
                <img alt="user-image" class="rounded mat-elevation-z6 list-avatar"
                  src="{{ conv.avatar && conv.avatar !== 'undefined' ? conv.avatar : './assets/img/users/icon_avatar.svg'  }}">
              </div>
              <div fxLayout="column" fxFlex="73" fxLayoutAlign="center start" class="part">{{ conv.name | phone }}</div>
              <div fxLayout="column" fxFlex="10" fxLayoutAlign="center center" class="part">
                <mat-checkbox [(ngModel)]="conv.checked"
                  (ngModelChange)="getCheckboxValues($event, conv.name, conv.checked, conv)"></mat-checkbox>
              </div>
            </div>
          </div>
        </div>
        <h3 class="list-title" [hidden]="!data.query">Danh sách NVKD</h3>
        <div class="user-list" [ngClass]="(employees.length === 0 || data.query === '') && 'hidden'">
          <div fxLayout="row" class="user-list-item"
            *ngFor="let emp of employees | search:'name,email':data.query: null: existUsers">
            <div fxLayout="column" fxFlex="17" fxLayoutAlign="center center" class="part">
              <img alt="user-image" class="rounded mat-elevation-z6 list-avatar"
                src="{{ emp.images?.avatar ? emp.images?.avatar : './assets/img/users/icon_avatar.svg' }}">
            </div>
            <div fxLayout="column" fxFlex="73" fxLayoutAlign="center start" class="part nvkd-name">
              {{ emp.name || emp.email }}</div>
            <div fxLayout="column" fxFlex="10" fxLayoutAlign="center center" class="part">
              <mat-checkbox [(ngModel)]="emp.checked"
                (ngModelChange)="changeSelectedUser($event, getEmployeeEmail(emp), emp.checked)"></mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <div class="action-area">
      <button mat-raised-button class="action-btn" color="primary" [disabled]="data.selectedUser.length === 0"
        mat-button (click)="execute()">{{ data.action }}</button>
      <button mat-raised-button class="action-btn" style="background: #CCCCCC;" (click)="onNoClick()">Hủy</button>
    </div>
  </div>
</mat-card>
<ng-template #edit>
  <mat-card class="custom-card">
    <div fxLayout="row" fxLayoutAlign="center center" class="title-area">
      <h1 class="title-confirm">{{ data.title }}</h1>
    </div>
    <div mat-dialog-content class="content-area" [ngClass]="isLoading && 'hidden'">
      <mat-form-field class="search-area">
        <input matInput type="text" placeholder="Nhập tên nhóm..." [(ngModel)]="data.currentConversation.name">
        <button mat-button *ngIf="data.currentConversation.name" matSuffix mat-icon-button aria-label="Clear"
          (click)="data.currentConversation.name=''">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <h3 class="list-title">{{ data.currentConversation.participants.length }} thành viên</h3>
      <div class="user-list member-list">
        <div fxLayout="row" class="user-list-item pointer" (click)="addParticipant()">
          <div fxLayout="column" fxFlex="17" fxLayoutAlign="center center" class="part">
            <button mat-mini-fab aria-label="Remove" color="warn" class="add-part">
              <img src="./assets/img/chat/add_people.svg">
            </button>
          </div>
          <div fxLayout="column" fxFlex="73" fxLayoutAlign="center start" class="part">Thêm thành viên</div>
          <div fxLayout="column" fxFlex="10" fxLayoutAlign="center center" class="part">

          </div>
        </div>
        <div fxLayout="row" class="user-list-item" *ngFor="let part of data.currentConversation.participants">
          <div fxLayout="column" fxFlex="17" fxLayoutAlign="center center" class="part">
            <img alt="user-image" class="rounded mat-elevation-z6 list-avatar" src="{{ part.avatar && part.avatar !== 'undefined' ? part.avatar : './assets/img/users/icon_avatar.svg' }}">
          </div>
          <div fxLayout="column" fxFlex="73" fxLayoutAlign="center start" class="part">{{ part.name | phone }}
            {{ data.currentConversation.creator == part.userId ? '(Admin)' : ''}}</div>
          <div fxLayout="column" fxFlex="10" fxLayoutAlign="center center" class="part">
            <button mat-mini-fab aria-label="Remove" color="warn" class="my-fab" (click)="removeParticipant(part)"
              *ngIf="part.userId !== data.currentUserId && data.currentConversation.creator === data.currentUserId">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <h3 class="list-title">Cài đặt nhóm</h3>
      <div class="user-list">
        <div fxLayout="row" class="user-list-item" style="height: 40px;">
          <div fxLayout="column" fxFlex="10" fxLayoutAlign="center center" class="part">
            <img src="./assets/img/chat/leave_group.svg">
          </div>
          <div fxLayout="column" fxFlex="90" fxLayoutAlign="center start" class="part nvkd-name"><button mat-icon-button
              (click)="leaveGroup()">Rời nhóm</button></div>
        </div>
        <div fxLayout="row" class="user-list-item" style="height: 40px;"
          *ngIf="data.currentConversation.creator === data.currentUserId">
          <div fxLayout="column" fxFlex="10" fxLayoutAlign="center center" class="part">
            <img src="./assets/img/chat/delete_group.svg">
          </div>
          <div fxLayout="column" fxFlex="90" fxLayoutAlign="center start" class="part nvkd-name"><button mat-icon-button
              (click)="deleteGroup()">Xóa nhóm</button></div>
        </div>
      </div>
      <mat-form-field class="search-area">
        <input #groupid matInput type="text" placeholder="Mã nhóm" [(ngModel)]="data.currentConversation.id" readonly>
        <button mat-button matSuffix mat-icon-button aria-label="Copy" (click)="copy()" title="Copy">
          <mat-icon>file_copy</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <div class="action-area">
        <button mat-raised-button [disabled]="data.currentConversation.name.length === 0" class="action-btn"
          color="primary" mat-button (click)="execute()">{{ data.action }}</button>
        <button mat-raised-button class="action-btn" style="background: #CCCCCC;" (click)="onNoClick()">Hủy</button>
      </div>
    </div>
  </mat-card>
</ng-template>
