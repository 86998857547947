export enum StringeeObjectTypeEnum {
    Conversation = 0,
    Message = 1
}

export enum StringeeChangeTypeEnum {
    Insert = 0,
    Update = 1,
    Delete = 2
}

export enum StringeeMessageTypeEnum {
    Text = 1,
    Photo = 2,
    Video = 3,
    Audio = 4,
    File = 5,
    Link = 6,
    // Message dạng text có nội dung khi group được tạo
    CreateGroup = 7,
    // Message dạng text có nội dung khi group đổi tên.
    RenameGroup = 8,
    Location = 9,
    Contact = 10,
    Sticker = 11,
    // Message kiểu thông báo (thêm thành viên, xoá thành viên).
    Notify = 100
}

export enum StringeeMessageStateEnum {
    Pending = 0,
    Sending = 1,
    Sent = 2,
    Delivered = 3,
    Read = 4
}