import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadImageProductComponent } from './upload-image-product.component';
import { LoadingAnimationModule } from '../loading-animation/loading-animation.module';

@NgModule({
  declarations: [
    UploadImageProductComponent
  ],
  imports: [
    CommonModule,
    LoadingAnimationModule
  ],
  exports: [
    UploadImageProductComponent
  ]
})
export class UploadImageProductModule { }
