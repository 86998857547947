<div fxLayout="row" fxLayoutAlign="center">
  <h1>{{data.title}}</h1>
</div>
<div mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="space-around center">
    <div fxFlex="100">
      {{data.body}}
    </div>
    <ng-container *ngIf="data.type && data.type === 'FILE'">
      <ol>
        <ng-container *ngFor="let r of data.files; let idx = index">
          <ng-container *ngIf="r.type === 'FILE'">
            <li><a href="javascript:;" (click)="openFile(r.absoluteUrl)">{{getFileName(r.absoluteUrl)}}</a></li>
          </ng-container>
        </ng-container>
      </ol>
    </ng-container>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button color="default" (click)="onNoClick()" *ngIf="data.type=='info'">Tôi đã biết</button> 
    <button mat-raised-button color="primary" (click)="execute()" *ngIf="data.type=='navigate'">Thực hiện</button>
  </div>
</div>
