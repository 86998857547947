import { DateToFormat } from 'app/shared/parse/date-to-format';

export class SurveyModel {
    stt: number;
    code: string;
    id: string;
    name: string;
    createdDate: string;
    status: boolean;
    type: string;
    questions: [SurveyQuestionModel];
    modifiedDate: string;
    description: string;
    bannerImage: string;

    constructor(params?: any){
        this.code = params  ? params.code || null: null;
        this.id = params ? params.id || null : null;
        this.name = params ? params.name || null : null;
        this.status = params ? params.status || null : null;
        this.type = params ? params.type || null : null;
        this.createdDate = params && params.createdDate ? DateToFormat.parseDate(params.createdDate, 'dd/MM/yyyy - hh:mm:ss') : '';
        this.modifiedDate = params && params.modifiedDate ? DateToFormat.parseDate(params.modifiedDate, 'dd/MM/yyyy - hh:mm:ss') : '';
        this.questions = params && params.questions ? params.questions : {};
        this.description = params ? params.description || null: null;
        this.bannerImage = params ? params.bannerImage || null : null;
    }
}
export class SurveyQuestionModel{
    stt: number;
    name: string;
    code: string;
    isMultipleChoice: boolean;
    answers?: [SurveyAnswerModel];
    type: string;
    id: string;
    isRequired: boolean;
    constructor(params? : any){
        this.name = params ? params.name || null: '';
        this.code = params ? params.code || null: '';
        this.answers = params ? params.answers || null: '';
        this.id = params ? params.id || null: '';
        this.type = params ? params.type || null: '';
        this.isMultipleChoice = params ? params.isMultipleChoice || false : false;
        this.isRequired = params ? params.isRequired || false : false;
    }
}
export class SurveyAnswerModel {
    id: string;
    code: string;
    name: string;
    isShowTextBox: boolean

    constructor(params?: any){
        this.code = params  ? params.code || null: null;
        this.id = params ? params.id || null : null;
        this.name = params ? params.name || null: null;
        this.isShowTextBox = params ? params.isShowTextBox || false: false;
    }
}

export class SurveyTVVReportModel {
    stt: number;
    id: string;
    createdDate: Date;
    modifiedDate: Date;
    employeeId: string;
    employeeCode: string;
    employeeName: string;
    employeePhone: string;
    createdBy: string;
    questions: any;

    constructor(params?: any){
        this.stt = params ? params.stt || 0 : 0;
        this.id = params ? params.id || null : null;
        this.employeeId = params && params.employee.id ? params.employee.id || null: null;
        this.employeeCode = params && params.employee.code ? params.employee.code || null: null;
        this.employeeName = params && params.employee.name ? params.employee.name || null: null;
        this.employeePhone = params && params.employee.phone ? params.employee.phone || null: null;
        this.questions = params ? params.questions || null: null;
        this.createdBy = params ? params.createdBy || null: null;
        this.createdDate =  params && params.createdDate ? DateToFormat.parseDate(params.createdDate, 'dd/MM/yyyy - hh:mm:ss') : '';
        this.modifiedDate =  params && params.modifiedDate ? DateToFormat.parseDate(params.modifiedDate, 'dd/MM/yyyy - hh:mm:ss') : '';
    }
    getHeaders() {
        return [
          { header: 'STT', key: 'stt', width: 10 },
          { header: 'Mã TVV', key: 'employeeCode', width: 20 },
          { header: 'TÊN TVV', key: 'employeeName', width: 20 },
          { header: 'NGÀY TẠO', key: 'createdDate', width: 20 },
          { header: 'NGÀY CẬP NHẬT', key: 'modifiedDate', width: 20 },
        ];
    }
    getHeadersBooth() {
        return [
          { header: 'STT', key: 'stt', width: 10 },
          { header: 'Mã TVV', key: 'employeeCode', width: 20 },
          { header: 'TÊN TVV', key: 'employeeName', width: 20 },
          { header: 'SĐT TVV', key: 'employeePhone', width: 20 },
          { header: 'THỜI GIAN GỬI', key: 'modifiedDate', width: 20 },
        ];
    }
    getHeaders2() {
        return [
          { header: 'Mã hợp đồng', key: 'contractName', width: 20 },
          { header: 'Chi tiết hợp đồng', key: 'contractPrice', width: 20 },
          { header: 'Địa chỉ', key: 'contractAddress', width: 40 },
          { header: 'Trạng thái', key: 'contractStatus', width: 20 },
          { header: 'Thời hạn', key: 'contractExpired', width: 20 },
          { header: 'Mã giao dịch', key: 'code', width: 20 },
          { header: 'Tỉ lệ', key: 'percent', width: 20 },
          { header: 'Hoa hồng ký gửi', key: 'sourceFee', width: 20 },
          { header: 'Hoa hồng môi giới', key: 'employeeFee', width: 20 },
          { header: 'Tư vấn viên ký gửi', key: 'sourceName', width: 20 },
          { header: 'Tư vấn viên môi giới', key: 'employeeName', width: 20 },
          { header: 'Ngày bắt đầu giao dịch', key: 'startDate', width: 20 },
          { header: 'Trạng thái giao dịch', key: 'status', width: 20 }
        ];
    }
}
