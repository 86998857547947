import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { environment } from "environments/environment";
import { DialogJoinComponent } from "../dialog-join/dialog-join.component";
import { LandingPageEventModel, LandingPageVideoModel } from "../landing-page.model";
import { LandingPageService } from "../landing-page.service";
@Component({
  selector: "app-landing-video",
  templateUrl: "./landing-video.component.html",
  styleUrls: ["./landing-video.component.scss"],
})
export class LandingVideoComponent implements OnInit {
  model: LandingPageVideoModel = new LandingPageVideoModel();
  id: string = "";
  checkDataEvent: boolean = false;

  viewerOptions: any = {
    navbar: false,
    toolbar: {},
  };

  constructor(
    public route: ActivatedRoute,
    private service: LandingPageService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    // Router subscribe
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get("id");
    });
    const params = this.route.snapshot.params;
    const id = params["id"];
    if (id) {
      this.service.getVideo(id).subscribe({
        next: (res: any) => {
          this.checkDataEvent = true;
          this.model = new LandingPageVideoModel(res);
          this.model.createdByUser.avatarUrl =  this.model.createdByUser.avatarUrl ?  this.model.createdByUser.avatarUrl : '../../../../assets/img/landing/Ava.png';
        },
        error: (err) => {
        }
      });
    } else {
      this.model = new LandingPageVideoModel();
    }
  }

  openDialog() {
    const dialog = this.dialog.open(DialogJoinComponent, {
      width: '800px',
      data: {
        title: 'Bạn có muốn xem video',
        shareUrl: this.model.shareUrl,
      }
    });
    dialog.afterClosed().subscribe(result => { });
  }
}
