import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { environment } from "environments/environment";
@Component({
  selector: 'app-dialog-join',
  templateUrl: './dialog-join.component.html',
  styleUrls: ['./dialog-join.component.scss']
})
export class DialogJoinComponent implements OnInit {
  shareUrl: string = '';
  constructor(
    public dialogRef: MatDialogRef<DialogJoinComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data.shareUrl && this.data.shareUrl.length) {
      this.shareUrl = this.data.shareUrl;
    } else {
      this.shareUrl = environment.homePageURL;
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
