import { Component, ViewChild, OnInit, OnDestroy, Renderer2, AfterViewInit } from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { SecurityService } from './shared/services/security.service';
import { ConfigurationService } from './shared/services/configuration.service';
import { Subscription } from 'rxjs/Subscription';
import { Router, NavigationEnd } from '@angular/router';
import { VoiceCallService } from './shared/services/voicecall.service';
import { OAuthService, NullValidationHandler } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import { oauthConfig } from './oauth.config';
import { UserV2Service } from './shared/services/common/user-v2.service';
import { CUser } from './api-models';
import { StorageService } from './shared/services';
import { CommonService } from './shared/services/common';
import { environment } from 'environments/environment';
declare let ga: Function;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

    Authenticated = false;
    subscription: Subscription;
    sub: Subscription;

    public settings: Settings;
    user: CUser = null;
    constructor(public appSettings: AppSettings,
        private securityService: SecurityService,
        private configurationService: ConfigurationService,
        private router: Router,
        private voiceCallService: VoiceCallService,
        private oauthService: OAuthService,
        private userV2Service: UserV2Service,
        private storageService: StorageService,
        private commonService: CommonService,
        private _renderer: Renderer2
    ) {

        this.settings = this.appSettings.settings;

        this.Authenticated = this.securityService.IsAuthorized;
        this.configureWithoutDiscovery();
        // Automatically load user profile
        this.oauthService.events
            .pipe(filter(e => e.type === 'token_received'))
            .subscribe(_ => {
                this.oauthService.loadUserProfile();
            });

        this.storageService.storeLocal('stringeeChatQueue', environment.stringChat.itSupportQueue);
        this.userV2Service.user$.subscribe((user: CUser) => {


          if (user.id) {
            this.user = user;
            this.storageService.storeLocal('stringeeChatCusEmail', user.email);
            this.storageService.storeLocal('stringeeChatCusName', user.name);
          } else {
            this.storageService.removeLocal('stringeeChatCusEmail');
            this.storageService.removeLocal('stringeeChatCusName');
          }
          commonService.refreshLiveChat();
        });

    }

    ngOnInit() {

        //   this.socketService.load();
        this.configurationService.load();

        let chatDiv = '.chat-container div';
        $(window).bind('scroll', function () {
            $(chatDiv).removeClass('ani');
        });
        $(document).on('click', '.chat-button', function () {
            $(chatDiv).toggleClass('ani');
        })
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const gaUserId = localStorage.getItem('ga_user_id');
                ga('set', 'userId', gaUserId);
                ga('set', 'page', event.urlAfterRedirects);
                ga('send', 'pageview');
            }
        });
        window.onbeforeunload = (e) => {
            e = e || window.event;

            if (e) {
                if (VoiceCallService.isInCalling) {
                    // For IE and Firefox prior to version 4
                    e.returnValue = 'true';
                    // For Safari
                    return 'true';
                }
            }
        };

        window.onunload = (e) => {
            if (VoiceCallService.isInCalling) {
                this.voiceCallService.hangupCall();
            }
        };
    }

    ngAfterViewInit() {
        const preloader = this._renderer.selectRootElement('#preloader');
        this._renderer.setStyle(preloader, 'display', 'none');
    }

    ngOnDestroy() {
        // this.subscription.unsubscribe();
    }

    private configureWithoutDiscovery() {
        this.oauthService.configure(oauthConfig);
        this.oauthService.tokenValidationHandler = new NullValidationHandler();
        this.oauthService.tryLogin();
    }
}
