<div *ngFor="let menu of parentMenu" class="menu-item" [id]="'menu-'+menu.id">

    <ng-container *ngIf="(menu.routerLink && !menu.hasSubMenu); else elseTemplateSubMenu">
        <ng-container *ngIf="!small; else elseTemplateNoSubMenu">
            <div class="parent-menu" *ngIf="menu.routerLink && !menu.hasSubMenu" >
                <a  mat-button [id]="'menu-item-'+menu.id" 
                    *userAuthority="menu.authorities"
                    fxLayout="row" [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
                    [routerLink]="[menu.routerLink]" routerLinkActive="active-link" 
                    [routerLinkActiveOptions]="{exact:  true}"
                    [matTooltip]="menu.title" matTooltipPosition="after" 
                    [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'"
                    (click)="onClick(menu)" >

                    <mat-icon class="menu-icon"><img src="/assets/img/left-menu/{{menu.icon}}.svg"></mat-icon>
                    <span class="menu-title">{{menu.title}}</span>
                </a>
            </div>
        </ng-container>
        <ng-template #elseTemplateNoSubMenu>
            <div class="parent-menu" *ngIf="menu.routerLink && !menu.hasSubMenu" >
                <a  mat-button
                    class="a-small-menu"
                    *userAuthority="menu.authorities"
                    [routerLink]="[menu.routerLink]" 
                    routerLinkActive="active-link" 
                    [routerLinkActiveOptions]="{exact:  true}"
                    (click)="onClick(menu)" >

                    <mat-icon class="menu-icon"><img src="/assets/img/left-menu/{{menu.icon}}.svg"></mat-icon>
                </a>
            </div>
        </ng-template>
    </ng-container>
    <ng-template #elseTemplateSubMenu>
        <ng-container *ngIf="menu.hasSubMenu">
            <ng-container *ngIf="!small; else elseTemplateSubMenu">
                <div class="parent-menu" 
                    [ngClass]="!menu.hasSubMenu ? 'custom-hidden' : ''" 
                    *userAuthority="menu.authorities">
                    
                    <a  [id]="'menu-item-'+menu.id"
                        mat-button 
                        fxLayout="row" 
                        [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
                        [matTooltip]="menu.title" matTooltipPosition="after" 
                        [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'"
                        (click)="onClick(menu)">

                        <mat-icon class="menu-icon"><img src="/assets/img/left-menu/{{menu.icon}}.svg"></mat-icon>
                        <span class="menu-title">{{menu.title}}</span>
                        <mat-icon class="menu-expand-icon transition-2">arrow_drop_down</mat-icon>
                    </a>
                </div>
            
                <div class="sub-menu" [id]="'sub-menu-'+menu.id">
                    <app-vertical-menu [menuItems]="menuItems" [menuParentId]="menu.id"></app-vertical-menu>
                </div>
            </ng-container>
            <ng-template #elseTemplateSubMenu>
                <div class="parent-menu" 
                    [ngClass]="!menu.hasSubMenu ? 'custom-hidden' : ''" 
                    *userAuthority="menu.authorities">
                    
                    <a  class="a-small-menu"
                        [matMenuTriggerFor]="smallMenu"
                        (click)="onChildrenMenu(menu)"
                        mat-button>

                        <mat-icon class="menu-icon"><img src="/assets/img/left-menu/{{menu.icon}}.svg"></mat-icon>
                    </a>

                    <mat-menu class="smaill-mat-menu" xPosition="after" #smallMenu="matMenu">
                        <a mat-menu-item
                        *ngFor="let childMenu of childrenMenu"
                        [routerLink]="[childMenu.routerLink]"> 
                            {{childMenu.title}} 
                        </a>
                    </mat-menu>
                </div>
            </ng-template>
        </ng-container>
    </ng-template>


    <!-- <div class="hr" *ngIf="menu.title == 'Dự án'"></div> -->
    
    <!-- <div class="parent-menu" [ngClass]="!menu.href || menu.subMenu ? 'custom-hidden' : ''" *userAuthority="menu.authorities" >
        <a *ngIf="menu.href && !menu.subMenu" mat-button 
            fxLayout="row" [fxLayoutAlign]="(settings.menuType=='default') ? 'start center' : 'center center'"
            [attr.href]="menu.href || ''" [attr.target]="menu.target || ''"
            [matTooltip]="menu.title" matTooltipPosition="after" [matTooltipDisabled]="(settings.menuType=='mini') ? 'false' : 'true'"
            (click)="onClick(menu)" [id]="'menu-item-'+menu.id">
            <mat-icon class="menu-icon"><img src="/assets/img/left-menu/{{menu.icon}}.svg"></mat-icon>
            <span class="menu-title">{{menu.title}}</span>
        </a>
    </div> -->
</div>