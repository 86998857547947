import { Injectable } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { ConstantUrl } from '../../shared/constant/url';
import { BaseService } from '../../shared/components/base.service';
import {Subject} from 'rxjs';

@Injectable()
export class POSService extends BaseService {
    ConstantUrl = ConstantUrl;
    // Observable string sources
    public emitChangeSource = new Subject<any>();
    public emitTable = new Subject<any>();
    constructor(
        public service: DataService
    ) {
        super(service);
        this.domainUrl = ConstantUrl.url_pos_domain + 'orgchart';
        this.queryUrl = ConstantUrl.url_orgchart_query + 'orgchart';
    }
    getPrepareEdit(id: string) {
        const url = this.queryUrl + '/prepare/edit/' + id;
        return this.service.get(url);
    }
    getForReview(id: string) {
      const url = this.queryUrl + '/pos/id/' + id;
      return this.service.get(url);
    }
    getEmployees() {
        const url = this.ConstantUrl.url_employee_query + 'employee';
        return this.service.get(url);
    }
    // Service message commands
    emitChange(data: any) {
        this.emitChangeSource.next(data);
    }
    emitChangeTable(data: any) {
        this.emitTable.next(data);
    }
    getListAssign(params?: any) {
        const url = this.ConstantUrl.url_orgchart_query + 'orgchart/prepare/assign';
        return this.service.get(url, params);
    }
    postAssign(request) {
        const url = this.ConstantUrl.url_orgchart_domain + 'orgchart/assign';
        return this.service.post(url, request);
    }
    release(request) {
        const url = this.ConstantUrl.url_orgchart_domain + 'orgchart/release';
        return this.service.post(url, request);
    }
}
