import { Component } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, UrlSegment, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { AppSettings } from 'app/app.settings';
import { Settings } from 'app/app.settings.model';
import { RouterLinkDefault } from '../menu/menu';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
    historyFilter: any = {};
    disabledLink: boolean = false;

    public routerLinkDefault = RouterLinkDefault;
    public pageTitle: string;
    public breadcrumbs: {
        name: string;
        url: string
    }[] = [];

    public settings: Settings;
    constructor(public appSettings: AppSettings,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public location: Location,
        public route: ActivatedRoute,
        public title: Title) {
        this.settings = this.appSettings.settings;
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.breadcrumbs = [];
                this.parseRoute(this.router.routerState.snapshot.root);
                this.pageTitle = '';
                this.breadcrumbs.forEach(breadcrumb => {
                    this.pageTitle += ' > ' + breadcrumb.name;
                });
                this.title.setTitle(this.settings.name + this.pageTitle);
            }
        });
        this.route.queryParams.subscribe((params) => {
            if (params) {
                this.historyFilter = { ...params };
            }
        })
    }

    private parseRoute(node: ActivatedRouteSnapshot) {
        this.disabledLink = false;
        if (node.data['breadcrumb']) {
            if (node.url.length || node.data['showBreadcrumb']) {
                let urlSegments: UrlSegment[] = [];
                node.pathFromRoot.forEach(routerState => {
                    urlSegments = urlSegments.concat(routerState.url);
                });
                let url = urlSegments.map(urlSegment => {
                    return urlSegment.path;
                }).join('/');
                let expandRoute = node.data['expandRoute'];
                let beforeExtendsion = node.data['beforeExtendsion'];
                if (beforeExtendsion) {
                    let routes = url.split('/');
                    let breadcrumb = this.breadcrumbs[this.breadcrumbs.length - 1];
                    breadcrumb.url = '/' + routes[0] + '/' + routes[1];
                }
                if (!expandRoute) {
                    let name = node.data['breadcrumb'];
                    this.breadcrumbs.push({
                        name: name,
                        url: '/' + url
                    });
                    if (node.data['subBreadcrumb']) {
                        if (node.data['notParentBreadcrumb']) {
                            this.disabledLink = true;
                        }
                        this.breadcrumbs[0].name = node.data['subBreadcrumb'];
                    }
                } else {
                    let breadcrumb = this.breadcrumbs[this.breadcrumbs.length - 1];
                    breadcrumb.url = '/' + url;
                }
            }
        }
        if (node.firstChild) {
            this.parseRoute(node.firstChild);
        } else {
            let noParent = node.data['noParent'];
            if (noParent) {
                this.breadcrumbs.shift();
            }
        }
    }

    public closeSubMenus() {
        let menu = document.querySelector('.sidenav-menu-outer');
        if (menu) {
            for (let i = 0; i < menu.children[0].children.length; i++) {
                let child = menu.children[0].children[i];
                if (child) {
                    if (child.children[0].classList.contains('expanded')) {
                        child.children[0].classList.remove('expanded');
                        child.children[1].classList.remove('show');
                    }
                }
            }
        }
    }

    public goBack() {
        this.location.back();
    }

}
