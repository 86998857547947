


<div class="add-holiday-checkin">
    <mat-icon class="close-button" (click)="close()"><img src="../../../../../assets/img/icon/close-btn-checkin.svg"></mat-icon>
    <div class="title-header-v3" >
        Thêm ngày nghỉ
    </div>
    <div mat-dialog-content >
        <div [formGroup]="mainForm" fxLayout="column" fxLayoutGap="30px">
            <div fxLayout="column" fxLayoutGap="12px">
                <div class="input-label">
                    <span class="label">Tên ngày nghỉ<span class="m-red">*</span></span>
                </div>
                <div class="app-form-group">
                    <input type="text" formControlName="name" class="app-input" placeholder="Nhập tên ngày nghỉ">
                    <mat-error >
                        <span *ngIf="mainForm.controls.name.touched && mainForm.controls.name.errors?.required" class="app-error">{{constant.ERROR_REQUIRED_2}}</span>
                        <span  *ngIf="mainForm.controls.name.touched && mainForm.controls.name.errors?.pattern" class="app-error">{{constant.ERROR_INVALID_2}}</span>
                    </mat-error>
    
                </div>
            </div>
    
            <div fxLayout="column" fxLayoutGap="12px">
                <div class="input-label">
                    <span class="label">Ngày<span class="m-red">*</span></span>
                </div>
                <div class="app-form-group">
                    <app-date-picker-v2 formControlName="date" [validErrorMsg]="constant.ERROR_INVALID_2" [requiredErrorsMsg]="constant.ERROR_REQUIRED_2" [placeholder]="'Ngày nghỉ'"></app-date-picker-v2>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%;"  fxLayoutGap="16px">
            <button class="button-accept" mat-ripple (click)="onSave()">Thêm</button>
            <button class="button-reject" mat-ripple (click)="close()">Hủy</button>
        </div>
    </div>
    
</div>

