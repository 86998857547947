import { Injectable } from '@angular/core';
@Injectable()
export class PageService {
    static getData(req: any, itemsFull: any) {
        let object = {
            count: null,
            data: []
        };
            let i = 0;
            let start = (req.p-1) * req.pSize;
            let end = (req.p) * req.pSize;
            itemsFull.map((item) => {
                if (i >= start  && i < end) {
                    object.data.push(item);
                }
                i++;
            });
        object.count = itemsFull.length;
        return object;
    }
}
