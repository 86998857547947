<div [formGroup]="mainForm" fxLayout="row" fxFlex="100" fxLayoutGap="16px">
  <!-- First column -->
  <div fxLayout="column" fxFlex="50" fxLayoutGap="16px">
    <!-- Thông tin cá nhân -->
    <div class="app-card">
      <div class="app-card__header">Thông tin cá nhân</div>
      <div class="app-card__body" fxLayout="column">
        <!-- Mã khách hàng -->
        <div class="input-container">
          <div fxFlex="30" class="input-label">
            <span class="app-label">Mã khách hàng</span>
          </div>
          <div class="input-value app-form-group" fxLayoutAlign="start center">
            <span class="app-text-bold">{{data?.code}}</span>
          </div>
        </div>
        <!-- Họ và tên -->
        <div class="input-container">
          <div fxFlex="30" class="input-label">
            <span class="app-label">Họ và tên <span class="m-red">*</span></span>
          </div>
          <div class="input-value app-form-group">
            <input formControlName="name" class="app-input" placeholder="Nhập họ và tên"
              [maxlength]="Constant.LENGTH_100">
            <ng-container *ngIf="getFormControl('name').invalid
              && (getFormControl('name').dirty || getFormControl('name').touched)">
              <span *ngIf="getFormControl('name').errors.required" class="app-error">{{Constant.ERROR_REQUIRED}}</span>
            </ng-container>
          </div>
        </div>
        <!-- Giới tính -->
        <div class="input-container">
          <div fxFlex="30" class="input-label">
            <span class="app-label">Giới tính</span>
          </div>
          <div class="input-value app-form-group">
            <app-radio-group formControlName="gender">
              <app-radio-button value="male">
                Nam
              </app-radio-button>
              <app-radio-button value="female">
                Nữ
              </app-radio-button>
            </app-radio-group>
          </div>
        </div>
        <!-- Ngày sinh -->
        <div class="input-container">

          <div fxFlex="30" class="input-label">
            <span class="app-label">Ngày sinh</span>
          </div>

          <div class="input-value app-form-group" fxLayout="row">

            <div fxFlex="150px">
              <app-checkbox-button formControlName="onlyYear">
                Chỉ năm sinh
              </app-checkbox-button>
            </div>

            <div fxFlex="auto">
              <ng-container *ngIf="getFormControl('onlyYear').value; else elseTemplateYear">
                <input formControlName="birthdayYear" class="app-input" [placeholder]="'Năm sinh'" [textMask]="{mask: Constant.number4IntMask}">
              </ng-container>
              <ng-template #elseTemplateYear>
                <app-date-picker [placeholder]="'Ngày sinh'" formControlName="birthday"></app-date-picker>
              </ng-template>
              

 
              <!-- <ng-container *ngIf="isControlInvalid('birthday')">
                <p *ngIf="mainForm.get('birthday').errors.required" class="app-error"></p>
              </ng-container> -->


              <!-- <ng-container *ngIf="isControlInvalid('birthdayYear')">
                <p *ngIf="mainForm.get('birthdayYear').errors.required" class="app-error">Năm sinh là bắt buộc</p>
              </ng-container> -->
            </div>

            <!-- <app-date-picker formControlName="birthday"></app-date-picker> -->
          </div>


        </div>
        <!-- Điện thoại -->
        <div class="input-container">
          <div fxFlex="30" class="input-label">
            <span class="app-label">Điện thoại <span class="m-red">*</span></span>
          </div>
          <div class="input-value app-form-group">
            <input formControlName="phone" class="app-input" placeholder="Nhập số điện thoại"
              [textMask]="{mask: Constant.phoneMask}">
            <ng-container *ngIf="getFormControl('phone').invalid
              && (getFormControl('phone').dirty || getFormControl('phone').touched)">
              <span *ngIf="getFormControl('phone').errors.required" class="app-error">
                {{Constant.ERROR_REQUIRED}}
              </span>
            </ng-container>
          </div>
        </div>
        <!-- Địa chỉ Email -->
        <div class="input-container">
          <div fxFlex="30" class="input-label">
            <span class="app-label">Địa chỉ Email <span *ngIf="isContractValidate" class="m-red">*</span></span>
          </div>
          <div class="input-value app-form-group">
            <input formControlName="email" class="app-input" placeholder="Nhập địa chỉ Email">
            <ng-container *ngIf="getFormControl('email').invalid
              && (getFormControl('email').dirty || getFormControl('email').touched)">
              <span *ngIf="getFormControl('email').errors.required" class="app-error">{{Constant.ERROR_REQUIRED}}</span>
              <span *ngIf="getFormControl('email').errors.email" class="app-error">{{Constant.ERROR_INVALID}}</span>
            </ng-container>
          </div>
        </div>
        <!-- Giấy tờ -->
        <div class="input-container">
          <div fxFlex="30" class="input-label">
            <span class="app-label">Giấy tờ <span *ngIf="isContractValidate" class="m-red">*</span></span>
          </div>
          <div fxLayout="column" fxLayoutGap="6px" class="input-value">
            <div fxFlex="100" fxLayout="row" fxLayoutGap="6px">
              <div class="app-form-group" fxFlex="calc(50% - 3px)">
                <app-select formControlName="identityType" [items]="listIdentity" (change)="changeIdentity()"
                  bindValue="value" bindLabel="label" fxFlex="100">
                </app-select>
              </div>
              <div class="app-form-group" fxLayout="column" fxFlex="calc(50% - 3px)">
                <input formControlName="identityNumber" (input)="changeIdentity()" fxFlex="100" class="app-input"
                  placeholder="Nhập số giấy tờ">
                <ng-container *ngIf="getFormControl('identityNumber').invalid
                  && (getFormControl('identityNumber').dirty || getFormControl('identityNumber').touched)">
                  <span *ngIf="getFormControl('identityNumber').errors.required"
                    class="app-error">{{Constant.ERROR_REQUIRED}}</span>
                </ng-container>
              </div>
            </div>
            <div fxFlex="100" fxLayout="row" fxLayoutGap="6px">
              <div class="app-form-group" fxFlex="calc(50% - 3px)">
                <app-date-picker formControlName="identityIssuedDate"></app-date-picker>
              </div>
              <div class="app-form-group" fxFlex="calc(50% - 3px)">
                <app-select formControlName="identityIssuedBy" [items]="dropdowPlace" bindLabel="name"
                  bindValue="name" placeholder="Nơi cấp" (search)="onModalChange($event)">
                </app-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Thông tin khác -->
    <div class="app-card">
      <div class="app-card__header">Thông tin khác</div>
      <div class="app-card__body" fxLayout="column">
        <!-- Thông tin thanh toán -->
        <div class="input-container">
          <div fxFlex="30" class="input-label">
            <span class="app-label">Thông tin thanh toán</span>
          </div>
          <div fxFlex="70" class="input-value app-form-group" fxLayout="row" fxLayoutGap="6px">
            <div class="app-form-group" fxFlex="calc(33% - 3px)">
              <input formControlName="taxCode" class="app-input" placeholder="Mã số thuế">
            </div>
            <div class="app-form-group" fxFlex="calc(33% - 3px)">
              <app-select formControlName="bankName" placeholder="Ngân hàng" [items]="listBank" bindLabel="NAME"
                          bindValue="CODE">
              </app-select>
            </div>
            <div class="app-form-group" fxFlex="calc(33% - 3px)">
              <input formControlName="bankNumber" class="app-input" placeholder="Số tài khoản ngân hàng">
            </div>
          </div>
        </div>
        <!-- Ngành nghề -->
        <div class="input-container">
          <div fxFlex="30" class="input-label">
            <span class="app-label">Ngành nghề</span>
          </div>
          <div class="input-value app-form-group">
            <input formControlName="job" class="app-input" placeholder="Nhập ngành nghề">
          </div>
        </div>
        <!-- Thu nhập/ tháng -->
        <div class="input-container">
          <div fxFlex="30" class="input-label">
            <span class="app-label">Thu nhập/ tháng</span>
          </div>
          <div class="input-value app-form-group">
            <app-select formControlName="income" placeholder="Thu nhập/ tháng" [items]="listIncome" bindValue="value"
              bindLabel="label">
            </app-select>
          </div>
        </div>
        <!-- Nguồn thu nhập -->
        <div class="input-container">
          <div fxFlex="30" class="input-label">
            <span class="app-label">Nguồn thu nhập</span>
          </div>
          <div class="input-value app-form-group">
            <input formControlName="incomeSource" class="app-input" placeholder="Vd: Công việc hành chính">
          </div>
        </div>
        <!-- Tình trạng hôn nhân -->
        <div class="input-container">
          <div fxFlex="30" class="input-label">
            <span class="app-label">Tình trạng hôn nhân</span>
          </div>
          <div class="input-value app-form-group">
            <app-select formControlName="relationshipStatus" placeholder="Tình trạng hôn nhân"
              [items]="listRelationshipStatus" bindValue="value" bindLabel="label">
            </app-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Second column -->
  <div fxLayout="column" fxFlex="50" fxLayoutGap="16px">
    <!-- Địa chỉ thường trú -->
    <div class="app-card">
      <div class="app-card__header">Địa chỉ thường trú</div>
      <div class="app-card__body">
        <div fxLayout="column" fxLayoutGap="6px">
          <div fxLayout="row" fxLayoutGap="6px">
            <div class="app-form-group" fxFlex="calc(50% - 3px)">
              <app-select formControlName="rootAddressCountry"
                [placeholder]="'Quốc gia' + (isContractValidate ? ' *' : '')">
              </app-select>
            </div>
            <div class="app-form-group" fxFlex="calc(50% - 3px)">
              <app-select formControlName="rootAddressProvince" [items]="listProvinceRootAddress" bindValue="n"
                (ngModelChange)="onChangeProvinceRootAddress($event)" bindLabel="n"
                [placeholder]="'Chọn Tỉnh/ TP' + (isContractValidate ? ' *' : '')">
              </app-select>
              <ng-container *ngIf="getFormControl('rootAddressProvince').invalid
                && (getFormControl('rootAddressProvince').dirty || getFormControl('rootAddressProvince').touched)">
                <span *ngIf="getFormControl('rootAddressProvince').errors.required"
                  class="app-error">{{Constant.ERROR_REQUIRED}}</span>
              </ng-container>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="6px">
            <div class="app-form-group" fxFlex="calc(50% - 3px)">
              <app-select formControlName="rootAddressDistrict" [items]="listDistrictRootAddress" bindValue="n"
                (ngModelChange)="onChangeDistrictRootAddress($event)" bindLabel="n"
                [placeholder]="'Chọn Quận/ Huyện' + (isContractValidate ? ' *' : '')">
              </app-select>
              <ng-container *ngIf="getFormControl('rootAddressDistrict').invalid
                && (getFormControl('rootAddressDistrict').dirty || getFormControl('rootAddressDistrict').touched)">
                <span *ngIf="getFormControl('rootAddressDistrict').errors.required"
                  class="app-error">{{Constant.ERROR_REQUIRED}}</span>
              </ng-container>
            </div>
            <div class="app-form-group" fxFlex="calc(50% - 3px)">
              <app-select formControlName="rootAddressWard" [items]="listWardRootAddress"
                [placeholder]="'Chọn Phường/ Xã' + (isContractValidate ? ' *' : '')">
              </app-select>
              <ng-container *ngIf="getFormControl('rootAddressWard').invalid
                && (getFormControl('rootAddressWard').dirty || getFormControl('rootAddressWard').touched)">
                <span *ngIf="getFormControl('rootAddressWard').errors.required"
                  class="app-error">{{Constant.ERROR_REQUIRED}}</span>
              </ng-container>
            </div>
          </div>
          <div class="app-form-group">
            <input formControlName="rootAddressText" class="app-input"
              [placeholder]="'Địa chỉ/ số nhà, Hẽm, ngõ….vv' + (isContractValidate ? ' *' : '')">
            <ng-container *ngIf="getFormControl('rootAddressText').invalid
              && (getFormControl('rootAddressText').dirty || getFormControl('rootAddressText').touched)">
              <span *ngIf="getFormControl('rootAddressText').errors.required"
                class="app-error">{{Constant.ERROR_REQUIRED}}</span>
            </ng-container>
          </div>
          <div fxLayout="row" fxLayoutAlign="start baseline" fxLayoutGap="6px">
            <img height="16" src="assets/img/dxres/icon/icon-map.svg" />
            <span>{{fullRootAddress}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Địa chỉ liên lạc -->
    <div class="app-card">
      <div class="app-card__header">Địa chỉ liên lạc</div>
      <div class="app-card__body">
        <div fxLayout="column" fxLayoutGap="6px">
          <div class="app-form-group">
            <app-checkbox-button formControlName="isCopyRootAddress">Sử dụng địa chỉ thường trú</app-checkbox-button>
          </div>
          <div fxLayout="row" fxLayoutGap="6px">
            <div class="app-form-group" fxFlex="calc(50% - 3px)">
              <app-select formControlName="addressCountry" placeholder="Quốc gia">
              </app-select>
            </div>
            <div class="app-form-group" fxFlex="calc(50% - 3px)">
              <app-select formControlName="addressProvince" [items]="listProvinceAddress" bindValue="n" bindLabel="n"
                (ngModelChange)="onChangeProvinceAddress($event)"
                [placeholder]="'Chọn Tỉnh/ TP' + (isContractValidate ? ' *' : '')">
              </app-select>
              <ng-container *ngIf="getFormControl('addressProvince').invalid
                && (getFormControl('addressProvince').dirty || getFormControl('addressProvince').touched)">
                <span *ngIf="getFormControl('addressProvince').errors.required"
                  class="app-error">{{Constant.ERROR_REQUIRED}}</span>
              </ng-container>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="6px">
            <div class="app-form-group" fxFlex="calc(50% - 3px)">
              <app-select formControlName="addressDistrict" [items]="listDistrictAddress" bindValue="n" bindLabel="n"
                (ngModelChange)="onChangeDistrictAddress($event)"
                [placeholder]="'Chọn Quận/ Huyện' + (isContractValidate ? ' *' : '')">
              </app-select>
              <ng-container *ngIf="getFormControl('addressDistrict').invalid
                && (getFormControl('addressDistrict').dirty || getFormControl('addressDistrict').touched)">
                <span *ngIf="getFormControl('addressDistrict').errors.required"
                  class="app-error">{{Constant.ERROR_REQUIRED}}</span>
              </ng-container>
            </div>
            <div class="app-form-group" fxFlex="calc(50% - 3px)">
              <app-select formControlName="addressWard" [items]="listWardAddress"
                [placeholder]="'Chọn Phường/ Xã' + (isContractValidate ? ' *' : '')">
              </app-select>
              <ng-container *ngIf="getFormControl('addressWard').invalid
                && (getFormControl('addressWard').dirty || getFormControl('addressWard').touched)">
                <span *ngIf="getFormControl('addressWard').errors.required"
                  class="app-error">{{Constant.ERROR_REQUIRED}}</span>
              </ng-container>
            </div>
          </div>
          <div class="app-form-group">
            <input formControlName="addressText" class="app-input"
              [placeholder]="'Địa chỉ/ số nhà, Hẽm, ngõ….vv' + (isContractValidate ? ' *' : '')">
            <ng-container *ngIf="getFormControl('addressText').invalid
              && (getFormControl('addressText').dirty || getFormControl('addressText').touched)">
              <span *ngIf="getFormControl('addressText').errors.required"
                class="app-error">{{Constant.ERROR_REQUIRED}}</span>
            </ng-container>
          </div>
          <div fxLayout="row" fxLayoutAlign="start baseline" fxLayoutGap="6px">
            <img height="16" src="assets/img/dxres/icon/icon-map.svg" />
            <span>{{fullAddress}}</span>
          </div>
        </div>
      </div>
    </div>
        <!-- Ghi chú nhanh -->
        <div class="app-card">
          <div class="app-card__header">Ghi chú nhanh</div>
          <div class="app-card__body">
            <div fxLayout="column" fxLayoutGap="6px">
                <div class="input-value app-form-group">
                  <textarea formControlName="takeNote" class="app-textarea" rows="5" [maxlength]="Constant.LENGTH_1000"
                    placeholder="Ghi chú nhanh"></textarea>
              </div>
            </div>
          </div>
        </div>
  </div>
</div>
