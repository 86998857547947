import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UploadFileService } from 'app/shared/services/common/upload-file.service';
import { Constant } from 'app/shared/constant/constant';
import { ToastrService } from 'ngx-toastr';
import { Image } from 'app/shared/components/upload-images/image.model';
import { MatDialogRef } from '@angular/material';
// https://medium.com/@dancornilov/angular-5-image-uploader-component-api-integration-69dadf3e2728

@Component({
  selector: 'app-image-uploader-contentbuilder',
  templateUrl: './image-uploader-contentbuilder.component.html',
  styleUrls: ['./image-uploader-contentbuilder.component.scss']
})
export class ImageUploaderContentBuilderComponent {
  // @Input() image?: Image;
  // @Output() imageChange = new EventEmitter<Image>();
  @Input() imageUrl?: string;
  @Output() imageUrlChange = new EventEmitter<string>();
  @Input() disabled?: boolean;
  @Input() type?: string;
  @Output() selected = new EventEmitter<File>();
  isLoading: boolean = false;
  fileExtensions: string[] = [];
  viewerOptions: any = {
    navbar: false,
    toolbar: {
      zoomIn: { show: true, size: 'large' },
      zoomOut: { show: true, size: 'large' },
      // prev: 4,
      // play: {
      //   show: 4,
      //   size: 'large',
      // },
      // next: 4,
      oneToOne: { show: true, size: 'large' },
      reset: { show: true, size: 'large' },
    }
  };
  get hasImage() {
    return this.imageUrl ? true : false;
    // return (this.image && this.image.url) || this.imageUrl;
  }

  constructor(
    public uploadFileService: UploadFileService,
    public toastr: ToastrService,
    public dialogRef: MatDialogRef<ImageUploaderContentBuilderComponent>,
  ) {
    this.fileExtensions = ['jpeg', 'jpg', 'png', 'svg','heic'];
  }

  onFileSelected(event: any) {
    const selectedFile = event.target.files[0] as File;
    this.selected.emit(selectedFile);
    // Validate image
    if (!this.validateIfImage(selectedFile)) {
      this.toastr.error('Lỗi!', 'Loại tệp tin không hợp lệ. Tệp được tải nhập phải là loại .png, .jpg hoặc .svg, tối thiểu 1000 x 1000 pixels và tối đa 5MB.');
      return;
    }
    // Upload image
    this.isLoading = true;
    return this.uploadFileService.uploadFile([selectedFile], this.type)
      .then((res: any) => {
        const data = res.json();
        if (data && data[0]) {
          // Emit image url
          setTimeout(() => {  // Amazon s3 file return 403 right after file uploaded
            this.imageUrl = data[0].url;
            this.imageUrlChange.emit(this.imageUrl);
            this.isLoading = false;
          }, 500);
          // Emit image
          // this.image = new Image(data[0]);
          // this.imageChange.emit(this.image);
        } else {
          this.isLoading = false;
        }
      })
      .catch((error) => {
        this.isLoading = false;
        this.toastr.error('Lỗi!', error);
      });
  }

  clearImage() {
    this.selected.emit(null);
    // Emit image url
    this.imageUrl = null;
    this.imageUrlChange.emit(this.imageUrl);
    // Emit image
    // const emptyImage = new Image();
    // emptyImage.setAllNull();
    // this.imageChange.emit(emptyImage);
  }

  validateIfImage(file: File): boolean {
    if (!file) {
      return false;
    }
    // check max size image 5MB
    if (file.size > Constant.MAX_SIZE_IMAGE) {
      return false;
    }
    return this.fileExtensions.includes(file.name.split('.').pop().toLocaleLowerCase());
  }

  saveImage() {
    this.dialogRef.close(this.imageUrl);
  }

}
