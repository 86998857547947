import { Injectable } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { StorageService } from '../../shared/services/storage.service';
import { ConstantUrl } from '../../shared/constant/url';
import { BaseService } from '../../shared/components/base.service';
import { VoiceCallService } from 'app/shared/services/voicecall.service';
import { Observable } from 'rxjs';
import { CPagination } from '../../api-models';
import { EmployeeReport } from './employee-report/employee-report.model';
import { map } from 'rxjs/operators';
import { parseEmail } from '../../shared/utility/utility';
import { HttpService } from 'app/shared/services/http.service';
import { CallHistoryModel } from 'app/shared/models/employee-callHistory.model';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { EmployeeReferralReport } from './employee-report-referral/employee-report-referral.model';
import { RewardHistoryModel } from './reward-history/reward-history.model';
import { ChangeSupporterModel, EmployeeMLMDiagram, EmployeeSupportModel } from './employee.model';

@Injectable()
export class EmployeeService extends BaseService {
    ConstantUrl = ConstantUrl;
    url = ConstantUrl.url_voice_call_call_log;

    constructor(
        public service: DataService,
        private storageService: StorageService,
        private voiceCallService: VoiceCallService,
        protected http: HttpService,
    ) {
        super(service);
        this.domainUrl = ConstantUrl.url_employee_domain + 'employee';
        this.queryUrl = ConstantUrl.url_employee_query + 'employee';

    }
    getType() {
        const url = `${ConstantUrl.url_employee}public/title-management/getListType`
        return this.service.get(url)
    }
    getExcelPoint(p: any): Observable<any> {
        const url = `${ConstantUrl.url_employee_query}employee/exportExcelPoint`
        return this.service.get(url, p);
    }
    getTemplates() {
        const url = ConstantUrl.url_profile_query + 'profileTemplate';
        return this.service.get(url);
    }
    getRoles() {
        const url = ConstantUrl.url_sts_query + 'role';
        return this.service.get(url);
    }
    getPos() {
        const url = ConstantUrl.url_pos_query + 'pos';
        return this.service.get(url);
    }
    getEmployeePOS(posId, params?) {
        const url = ConstantUrl.url_orgchart_query + 'orgchart/pub/info/' + posId + '/list';
        return this.service.get(url, params || {});
    }
    public getAcl(id?) {
        const url = ConstantUrl.url_user_query + '/' + this.storageService.retrieve('userId');
        return this.service.get(url);
    }
    getUSerPermission(agencyId) {
        return this.service.get(ConstantUrl.url_user_query + '/' + agencyId)
            .map((res) => {
                let data: any = res || {};
                return data;
            });
    }
    setEmployeePermission(body) {
        const url = this.ConstantUrl.url_user_domain + '/setUserPermission';
        return this.service.post(url, body);
    }
    public getMsxs() {
        const url = ConstantUrl.url_sts_query + 'msx';
        return this.service.get(url);
    }
    public getFeatures() {
        const url = ConstantUrl.url_sts_query + 'feature';
        return this.service.get(url);
    }

    public getTavicoEmployee(code: string) {
        const url = ConstantUrl.url_employee_query + 'tavico/employee/' + code;
        return this.service.get(url);
    }
    public resetPassword(params: any) {
        const url = ConstantUrl.url_sts_domain + 'user/admin/resetPwd';
        return this.service.put(url, params);
    }
    public updateEmployee(params: any) {
        const url = ConstantUrl.url_employee_domain + 'employee';
        return this.service.put(url, params);
    }

    public approve(id: string) {
        const url = this.domainUrl + '/' + id + '/approve';
        return this.service.put(url, {})
    }

    public signedContract(id: string) {
        const url = this.domainUrl + '/' + id + '/signedContract';
        return this.service.put(url, {})
    }

    public updateContract(params: any) {
        return this.service.put(this.domainUrl + '/updateContract', params);
    }

    public reject(id: string, reasonReject: string) {
        const url = this.domainUrl + '/' + id + '/reject';
        return this.service.put(url, { reasonReject })
    }

    public getAccountant() {
        const url = ConstantUrl.url_orgchart_query + 'employee/pub/accountants';
        return this.service.get(url);
    }
    public getDevBusiness() {
        const url = ConstantUrl.url_orgchart_query + 'employee/pub/devBusiness';
        return this.service.get(url);
    }
    public getSaleAdmins() {
        const url = ConstantUrl.url_orgchart_query + 'employee/pub/saleAdmins';
        return this.service.get(url);
    }


    public getCurrentEmployee() {
        const url = this.queryUrl + '/current';
        return this.service.get(url);
    }

    public selfUpdate(params: any) {
        const url = this.domainUrl + '/selfUpdate';
        return this.service.put(url, params);
    }
    getEmployeeListByNameOrEmail(params) {
        params.limitSize = 99999;
        const url = this.queryUrl + '/getLikeEmailOrName';
        return this.service.get(url, params);
    }
    getRegisredEmployee(params) {
        const url = this.queryUrl + '/registered';
        return this.service.get(url, params ? params : null);
    }
    getListEmployeeCompareKPI(params) {
        const url = this.queryUrl + '/list-compare-kpi';
        return this.service.get(url, params ? params : null);
    }

    // Report
    public getAllEmployees(params): Observable<CPagination<EmployeeReport>> {
        const url = this.queryUrl + '/report';
        return this.service.get(url, params).pipe(
            map((res: any) => {
                const pagination = new CPagination<EmployeeReport>().decode(res);
                pagination.items = pagination.items.map((item: any) => new EmployeeReport(item));
                return pagination;
            })
        );
    }

    public getAllEmployeeExport(params) {
        params.pageSize = '';
        params.page = '';
        const url = this.queryUrl + '/report';
        return this.service.get(url, params);
    }

    getIncomeCallLogs(agentId, filter) {
        this.voiceCallService.getAuthenticationToken();
        const headers = { 'X-STRINGEE-AUTH': this.storageService.retrieve('voice_call_authen_token') };
        let queryUrl = this.url + '?to_number=' + agentId + '&from_created_time=' + (filter.dateFrom / 1000) + '&to_created_time=' + (filter.dateTo / 1000);
        return this.service.getWithHeaders(queryUrl, headers);
    }

    getCallOutLogs(agentId, filter) {
        this.voiceCallService.getAuthenticationToken();
        const headers = { 'X-STRINGEE-AUTH': this.storageService.retrieve('voice_call_authen_token') };
        let queryUrl = this.url + '?from_user_id=' + agentId + '&from_created_time=' + (filter.dateFrom / 1000) + '&to_created_time=' + (filter.dateTo / 1000);
        return this.service.getWithHeaders(queryUrl, headers);
    }

    getApprovalContract(params) {
        const url = ConstantUrl.url_contract_query + 'contract';
        return this.service.get(url, params ? params : null);
    }

    getOtherReport(params) {
        const url = ConstantUrl.url_ticket_query + 'lead/report/count';
        return this.service.get(url, params ? params : null);
    }

    changeEmail(headerToken: string) {
        const url = `${ConstantUrl.url_sts_domain}user/changeEmail`;

        const options = {
            headers: {
                token: headerToken
            }
        };
        return this.http.post(url, {}, options);
    }
    sendEmailConfirmChange(token: string) {
        return this.service.put(`${ConstantUrl.url_sts_domain}user/emailConfirmChangeEmail`, { token });
    }

    updateStatusContract(params) {
        return this.service.put(`${this.domainUrl}/updateContract`, params);
    }

    getUrl() {
        const url = ConstantUrl.url_sts_query + 'user/getUrlPrivacyTerms';
        return this.service.get(url);
    }
    updateNote(params) {
        return this.service.put(`${this.domainUrl}/updateNote`, params);
    }
    updateSupporter(params) {
        return this.service.put(`${this.domainUrl}/updateSupporter`, params);
    }
    getEmployeeSupport(params) {
        return this.service.get(`${this.queryUrl}/getEmployeeSupport`, params);
    }
    getEmployeeToSupport(params) {
        return this.service.get(`${this.queryUrl}/getEmployeeToSupport`, params);
    }
    getSurvey() {
        const url = `${ConstantUrl.url_employee_query}survey/pub/getLatestSurvey`
        return this.service.get(url)
    }
    getCallHistory(params) {
        const url = ConstantUrl.url_communication + 'public/communication/callLog';
        return this.service.get(url, params).pipe(
            map(res => {
                const pagination = new CPagination<CallHistoryModel>().decode(res);
                pagination.items = pagination.items.map((item, index) => {
                    const event = new CallHistoryModel(item);
                    event.stt = index + 1 + (params ? params.pageSize * (params.page - 1) : 0);
                    return event;
                });
                return pagination;
            })
        );
    }

    getPointHistory(params) {
        const url = ConstantUrl.url_employee + 'admin/point-history';
        return this.service.get(url, params).pipe(
            map(res => {
                const pagination: any = new CPagination<RewardHistoryModel>().decode(res);
                pagination.items = pagination.items.map((item, index) => {
                    const event = new RewardHistoryModel(item);
                    event.stt = index + 1 + (params ? params.pageSize * (params.page - 1) : 0);
                    return event;
                });
                pagination.userInfo = res.userInfo;
                return pagination;
            })
        );
    }
    searchEmployee(q: string) {
        const url = this.queryUrl + '/searchAllEmployee?q=' + q;
        return this.service.get(url);
    }

    getEmployeeByQuery(params): Observable<CPagination<EmployeeReferralReport>> {
        const url = this.queryUrl + '/getListReferralPerson';
        return this.service.post(url, params).pipe(
            map((res: any) => {
                const pagination = new CPagination<EmployeeReferralReport>().decode(JSON.parse(res['_body']));
                pagination.items = pagination.items.map((item, index) => {
                    const event = new EmployeeReferralReport(item);
                    event.stt = index + 1 + (params ? params.pageSize * (params.page - 1) : 0);
                    return event;
                });
                return pagination;
            })
        );
    }

    // MLM
    getAllUserLevel(params) {
        const url = ConstantUrl.url_employee_query + 'admin/employee' + '/user-level/f0';
        return this.service.get(url, params).pipe(
            map(res => {
                const pagination: any = new CPagination<EmployeeMLMDiagram>().decode(res);
                pagination.items = pagination.items.map((item, index) => {
                    const event = new EmployeeMLMDiagram(item);
                    event.stt = index + 1 + (params ? params.pageSize * (params.page - 1) : 0);
                    event.isParent = true;
                    if (event.userLevel.maxChildLevel) {
                        event.userLevel.group = [];
                        event.isHasAction = true;
                    }
                    return event;
                });
                return pagination;
            })
        )
    }

    getReportUserLevel(params) {
        const url = ConstantUrl.url_employee_query + 'admin/employee' + '/user-level/report';
        return this.service.get(url, params).pipe(
            map(res => {
                const pagination: any = new CPagination<EmployeeMLMDiagram>().decode(res);
                let maxLevel = 0;
                pagination.items = pagination.items.map((item, index) => {
                    const event = new EmployeeMLMDiagram(item);
                    event.stt = index + 1 + (params ? params.pageSize * (params.page - 1) : 0);
                    if (item.userLevel && maxLevel < item.userLevel.totalChildPerLevel.length) {
                        maxLevel = item.userLevel.totalChildPerLevel.length;
                    }
                    return event;
                });
                pagination.maxLevel = maxLevel;
                return pagination;
            })
        )
    }

    getAllUserLevelChildGroup(id: string) {
        const url = ConstantUrl.url_employee_query + 'admin/employee' + `/user-level/f0/${id}/child-group`;
        return this.service.get(url)
    }

    getAllUserLevelChild(id: string, params: any) {
        const url = ConstantUrl.url_employee_query + 'admin/employee' + `/user-level/f0/${id}/child`;
        return this.service.get(url, params).pipe(
            map(res => {
                const pagination: any = new CPagination<EmployeeMLMDiagram>().decode(res);
                pagination.items = pagination.items.map((item, index) => {
                    const event = new EmployeeMLMDiagram(item);
                    event.stt = index + 1 + (params ? params.pageSize * (params.page - 1) : 0);
                    return event;
                });
                return pagination;
            })
        )
    }

    getListRequestSupport(params?: any): Observable<CPagination<ChangeSupporterModel>> {
        const url = ConstantUrl.url_employee + `admin/request-support-history`;
        return this.service.get(url, params || {}).pipe(
            map((res) => {
                const pagination = new CPagination<ChangeSupporterModel>().decode(res);
                pagination.items = pagination.items.map((item, index) => {
                    const event = new ChangeSupporterModel(item)
                    console.log(event)
                    event.stt =
                        index +
                        1 +
                        (params ? params.pageSize * (params.page - 1) : 0);
                    return event;
                });
                return pagination;
            })
        );
    }

    getUserSupporter(params?: any): Observable<CPagination<EmployeeSupportModel>> {
        const url = ConstantUrl.url_employee_query + `admin/employee/user-support`;
        return this.service.get(url, params || {}).pipe(
            map((res) => {
                const pagination = new CPagination<EmployeeSupportModel>().decode(res);
                pagination.items = pagination.items.map((item, index) => {
                    const event = new EmployeeSupportModel(item)
                    event.stt =
                        index +
                        1 +
                        (params ? params.pageSize * (params.page - 1) : 0);
                    return event;
                });
                return pagination;
            })
        );
    }

    getUserSupporting(id: string, params?: any): Observable<CPagination<EmployeeSupportModel>> {
        const url = ConstantUrl.url_employee_query + `admin/employee/user-supporting/${id}/`;
        return this.service.get(url, params || {}).pipe(
            map((res) => {
                const pagination = new CPagination<EmployeeSupportModel>().decode(res);
                pagination.items = pagination.items.map((item, index) => {
                    const event = new EmployeeSupportModel(item)
                    event.stt =
                        index +
                        1 +
                        (params ? params.pageSize * (params.page - 1) : 0);
                    return event;
                });
                return pagination;
            })
        );
    }

    updateRequestChangeSupporter(body) {
        const url = ConstantUrl.url_employee + `admin/request-support-history`;
        return this.service.put(url, body);
    }

    updateSupporterByListIds(body) {
        const url = ConstantUrl.url_employee_query + `employee/updateSupporterByListIds`;
        return this.service.post(url, body);
    }
}
