import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { environment } from "environments/environment";
import { DialogJoinComponent } from "../dialog-join/dialog-join.component";
import { LandingPageService } from "../landing-page.service";
@Component({
  selector: "app-landing-promotion",
  templateUrl: "./landing-promotion.component.html",
  styleUrls: ["./landing-promotion.component.scss"],
})
export class LandingPromotionComponent implements OnInit {
  shareUrl: string = '';
  constructor(
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public landingPageService: LandingPageService,
  ) { }

  ngOnInit() {
    const params = this.route.snapshot.params;
    const phone = params['id'];
    if (phone) { 
      this.landingPageService.getShareLink(phone).subscribe({
        next: (res: any) => {
          if (res && res.shareUrl) {
            this.shareUrl = res.shareUrl;
          } else {
            this.shareUrl = environment.homePageURL;
          }
        },
        error: (err) => {
          this.shareUrl = environment.homePageURL;
        }
      });
    }
   }

  openDialog() {
    const dialog = this.dialog.open(DialogJoinComponent, {
      width: '800px',
      data: {
        shareUrl: this.shareUrl,
      }
    });
    dialog.afterClosed().subscribe(result => { });
  }
}
