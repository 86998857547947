<mat-sidenav-container>
  
  <div fxLayout="row" fxLayoutAlign="center center" class="h-100">

        <div fxFlex="80" fxFlex.gt-sm="30" fxFlex.sm="60">

            <mat-card class="p-0 mat-elevation-z24 box">
                <div  fxLayout="column">
                    <img src="assets/img/app/coming-soon.jpg" width="400" height="400">
                    <button mat-raised-button class="m-bg m-bg-secondary" (click)="back()">
                      Trở về
                    </button>
                </div>
                
            </mat-card>
            
          </div>    
  
  </div>

</mat-sidenav-container>