export enum SurveyQuestionTypeEnum {
  TEXTBOX = "textbox",
  DROPDOWN = "dropdown",
  TEXTAREA = "textarea",
  RADIO = "radio",
}
export enum SurveyQuestionTypeTextEnum{
  TEXTBOX = "Text-box",
  DROPDOWN = "Drop-down list",
  TEXTAREA = "Text-area",
  RADIO_BUTTON = "Radio button",
}
export enum SurveyStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE"
}
export enum SurveyTypeEnum {
  ADMIN = "ADMIN",
  TVV = "TVV",
  FREE = "FREE",
  BOOTH = "BOOTH",
}
export class SurveyConstant {
  public static CONTROL_TYPES = [
    {
        id: 1,
        code: SurveyQuestionTypeEnum.DROPDOWN,
        name: SurveyQuestionTypeTextEnum.DROPDOWN
    },
    {
        id: 2,
        code: SurveyQuestionTypeEnum.TEXTAREA,
        name: SurveyQuestionTypeTextEnum.TEXTAREA
    },
    {
        id: 3,
        code: SurveyQuestionTypeEnum.TEXTBOX,
        name: SurveyQuestionTypeTextEnum.TEXTBOX
    },
  ];

  public static listSurveyStatus = [
    {
      id: "ACTIVE",
      name: "Kích hoạt"
    },
    {
      id: "INACTIVE",
      name: "Vô Hiệu"
    }
  ]

  public static listSurveyType = [
    {
      id: "ADMIN",
      name: "Khảo sát trong ghi chú"
    },
    {
      id: "TVV",
      name: "Khảo sát trong Sở trường của TVV"
    },
    {
      id: "BOOTH",
      name: "Khảo sát tạo gian hàng"
    },
    {
      id: "FREE",
      name: "Khảo sát tự do"
    }
  ]
}
