import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {

  @Input() isDisabled: boolean = false;
  @Input() value: any;

  public isChecked: boolean = false;
  private changeRadioButton: Subject<any> = new Subject();
  public changeRadioButton$: Observable<any> = this.changeRadioButton.asObservable();

  constructor() { }

  ngOnInit() {
  }

  onChangeRadio() {
    this.changeRadioButton.next(this.value);
  }

  setCheckedRadio(value: any) {
    this.isChecked = this.value === value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
