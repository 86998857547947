import { Injectable } from '@angular/core';
import { ConstantUrl } from 'app/shared/constant/url';
import { DataService } from 'app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class NewsPublicService {

  constructor(
    public service: DataService
  ) { }

  getSlug(id: string){
    return this.service.get(ConstantUrl.url_news_query + `pub/news/slug/${id}`);
  }
}
