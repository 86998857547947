export class LanguageValidateEnglish {

    // english

    public static not_empty__english = 'should not be empty';
    public static large_than_equal__english = 'must be longer than or equal to';
    public static large_than__english = 'must be longer than';
    public static small_than_equal__english = 'must be less than or equal to';
    public static small_than__english = 'must not be less than';
    public static shorter_than_equal__english = 'must be shorter than or equal to';
    public static shorter_than__english = 'must be shorter than';
    public static character__english = 'characters';

}

export class LanguageValidateVietnamese{

    // vietnamese

    public static not_empty__vietnamese = 'không được trống';
    public static large_than_equal__vietnamese = 'phải lớn hơn hoặc bằng';
    public static large_than__vietnamese= 'phải lớn hơn';
    public static small_than_equal__vietnamese = 'phải nhỏ hơn hoặc bằng';
    public static small_than__vietnamese = 'phải không nhỏ hơn';
    public static shorter_than_equal__vietnamese = 'phải ngắn hơn hoặc bằng';
    public static shorter_than__vietnamese = 'phải ngắn hơn';
    public static character__vietnamese = 'ký tự';
}
