<div fxLayout="row" fxLayoutAlign="center" style="margin-bottom: 8px;">
    <h2>{{data.title}}</h2>
</div>
<div mat-dialog-content fxLayoutAlign="center"  style="margin-bottom: 8px;">
  <div fxLayout="column" fxLayoutAlign="space-around center">
      <span style="font-weight: bold; text-align: center;">{{data.message}}</span>
      <div fxLayout="row" style="width: 100%; margin-top: 10px;">
        <app-checkbox-button [(ngModel)]="data.isChecked">
        Đồng bộ ERP
        </app-checkbox-button>
      </div>
  </div>
</div>
<div mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%;">
    <button mat-button class="m-bg-primary m-bg" color="primary" (click)="execute()">{{data.textOk}}</button>
    <button mat-button class="m-bg-grey m-bg" (click)="onNoClick()">{{data.textCancel}}</button>
  </div>
</div>
