import { Component, Inject, OnDestroy } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { NewsPublicService } from "./news-public.service";
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from "@angular/common";
@Component({
    selector: "app-news-public",
    templateUrl: "./news-public.component.html",
    styleUrls: ["./news-public.component.scss"],
})
export class NewsPublicComponent implements OnDestroy {
    content: string = "";
    builder;

    constructor(
        public newsPublicService: NewsPublicService,
        public route: ActivatedRoute,
        public meta: Meta,
        @Inject(DOCUMENT) private _document: Document
    ) {
        // if (!this.builder || !this.builder.builderStuff) {
        //     setTimeout(() => {
        //         this.builder = new ContentBuilder({
        //             container: ".content-builder",
        //             snippetPath: "/assets/minimalist-blocks/",
        //             fontAssetPath: "/assets/fonts/",
        //             scriptPath: "/assets/contentbuilder/",
        //             snippetOpen: false,
        //             imageEmbed: 0,
        //         });
        //     }, 1);
        // }
        // xóa những scripts không dùng đến để cải thiện performance
        const scripts: any = this._document.getElementsByTagName('script');
        if (scripts.length > 0) {
            for (const script of scripts) {
                script.src = '';
            }
        }

        // Thêm thẻ meta để fix font size lệch trên IOS
        this.meta.updateTag({ name: 'viewport', content: 'width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no' })
        this.route.paramMap.subscribe((paramMap: ParamMap) => {
            const slugId = paramMap.get('id');
            if (slugId) {
                this.newsPublicService.getSlug(slugId).subscribe(res=> {
                    if (res && res.content) {
                        this.content = res.content;
                    }
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.builder && this.builder.destroy();
    }
}
