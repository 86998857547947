import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'app/shared/services';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-layout-public-header',
  templateUrl: './layout-public-header.component.html',
  styleUrls: ['./layout-public-header.component.scss'],
})
export class LayoutPublicHeaderComponent implements OnInit, OnDestroy {
  public destroyUnsubscribe: ReplaySubject<any> = new ReplaySubject<any>(1);
  public isLoggedIn = false;
  public currentUser = null;

  constructor(
    public router: Router,
    private storage: StorageService,
  ) { }

  async ngOnInit() {
  }

  ngOnDestroy() {
    this.destroyUnsubscribe.next(null);
  }

  public goToLoginPage() {

  }

  public showUserDropdown() {

  }

  public logout() {
    this.storage.remove('authorizationData');
    this.storage.remove('voice_call_id');
    this.storage.remove('voice_call_token');
    this.storage.remove('voice_call_authen_token');
    this.router.navigate(['/login']);
  }

}