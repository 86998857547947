import { Menu } from './menu.model';
import { RouterLink } from '@angular/router';
import { Category } from 'app/pages/category/category.model';

export const RouterLinkDefault = '/project';


const menus = [

    // (id, title, routerLink, href, icon, target, hasSubMenu, parentId, authorite)
    // new Menu(10, 'Dashboard', '/dashboard', null, 'leftmenu-dashboard', null, false, 0, []),
    // new Menu(10, 'Project Dashboard', '/project-dashboard', null, 'leftmenu-dashboard', null, false, 0, ['report', 'dashboard', 'booking']),

    new Menu(20, 'Khai thác Lead', null, null, 'icon-lead', null, true, 0, ['lead', 'primary', 'view', 'menu']),
    // new Menu(201, 'Lấy Lead mới', '/lead/survey', null, 'icon-dot', null, false, 20, ['lead', 'get', 'all', 'lead']),
    // new Menu(205, 'Khai thác Lead', '/lead/exploitation', null, 'icon-dot', null, false, 20, ['lead', 'get', 'all', 'lead']),
    new Menu(206, 'Cấu hình nguồn Lead', '/lead/config/list', null, 'icon-dot', null, false, 20, ['lead', 'repository', 'manage']),
    // new Menu(202, 'DS Lead đang khảo sát', '/lead/list', null, 'icon-dot', null, false, 20, ['lead', 'get', 'all', 'lead']),
    new Menu(203, 'Quản lý Leads', '/import-ticket/demand', null, 'icon-dot', null, false, 20, ['lead', 'import']),
    new Menu(204, 'Lịch sử tải nhập', '/import-ticket/history', null, 'icon-dot', null, false, 20, ['lead', 'import']),
    new Menu(207, 'Kết quả phân loại Lead', '/lead/report/exploitation', null, 'icon-dot', null, false, 20, ['lead', 'report', 'view', 'all']),
    new Menu(208, 'Danh sách nhóm khai thác lead', '/group-lead', null, 'icon-dot', null, false, 20, ['group-lead', 'get', 'all']),

    // new Menu(70, 'Khách hàng', null, null, 'leftmenu-khachhang', null, true, 0, ['customer', 'get', 'all']),
    // new Menu(701, 'Khách hàng tiềm năng', '/customer/pos', null, 'icon-dot', null, false, 70, ['property', 'customer', 'get', 'all']),
    // new Menu(702, 'Khách hàng giao dịch', '/customer/dxs', null, 'icon-dot', null, false, 70, ['customer', 'get', 'all']),


    new Menu(100, 'Dự án', null, null, 'leftmenu-duan', null, true, 0, ['property', 'project', 'get', 'all']),
    new Menu(101, 'Danh sách dự án', '/project', null, 'icon-dot', null, false, 100, ['property', 'project', 'get', 'all']),
    new Menu(108, 'Thiết lập flash sale', '/sale-hours', null, 'icon-dot', null, false, 100, ['sale-hours', 'get', 'all']),
    // new Menu(102, 'Quản lý bán hàng', '/project/event-sale', null, 'icon-dot', null, false, 100, ['property', 'project', 'get', 'all']),
    new Menu(101, 'Danh sách gian hàng', '/project/investor', null, 'icon-dot', null, false, 100, ['investor', 'get', 'all']),
    new Menu(103, 'Lịch sử tải nhập', '/project/history', null, 'icon-dot', null, false, 100, ['property', 'import', 'view', 'history']),
    new Menu(104, 'Lịch sử gửi email', '/project/email/history', null, 'icon-dot', null, false, 100, ['property', 'primary', 'mailer', 'history']),
    new Menu(105, 'Chương trình bán hàng', '/project/sales-program', null, 'icon-dot', null, false, 100, ['sales', 'program', 'get']),
    new Menu(106, 'Hợp đồng dự án', '/project/contract', null, 'icon-dot', null, false, 100, ['contract', 'get', 'all']),
    new Menu(107, 'Thiết lập E-Saleskit', '/project/esale-kit', null, 'icon-dot', null, false, 100, ['esalekit', 'get', 'all']),
    new Menu(107, 'Dự án khai thác', '/project-lead', null, 'icon-dot', null, false, 100, ['project-lead', 'get', 'all']),
    new Menu(109, 'Danh sách gói vay', '/loan-package', null, 'icon-dot', null, false, 100, ['loan-package', 'get', 'all']),
    new Menu(111, 'Danh sách quan tâm gói vay', '/loan-package-interested', null, 'icon-dot', null, false, 100, ['loan-package-interest', 'get', 'all']),

    new Menu(170, 'Phí/ Hoa hồng', null, null, 'leftmenu-commission', null, true, 0, ['commission', 'compare', 'get', 'id']),
    // new Menu(1701, 'Tính phí/ hoa hồng', '/commission', null, 'icon-dot', null, false, 170, ['commission', 'create']),
    // new Menu(1705, 'Chính sách hoa hồng', '/commission/commission-policy', null, 'icon-dot', null, false, 170, ['commission', 'create']),
    // new Menu(1702, 'Hoa hồng cá nhân', '/commission/publish', null, 'icon-dot', null, false, 170, ['commission', 'list', 'get', 'id']),
    // new Menu(1703, 'Lịch sử tải nhập', '/commission/import-history', null, 'icon-dot', null, false, 170, ['commission', 'view', 'history']),
    new Menu(1701, 'Danh sách đối chiếu', '/compare', null, 'icon-dot', null, false, 170, ['commission', 'compare', 'get', 'all']),
    // new Menu(1706, 'Chính sách hoa hồng', '/decision-table', null, 'icon-dot', null, false, 170, []),
    new Menu(1707, 'Danh sách chính sách hoa hồng', '/decision-table/detail', null, 'icon-dot', null, false, 170, []),
    new Menu(1707, 'Danh sách cốc cafe', '/cafe', null, 'icon-dot', null, false, 170, ['coffee', 'get', 'all']),

    new Menu(180, 'KPI - Thưởng', null, null, 'leftmenu-commission', null, true, 0, ['kpi-policy', 'get', 'id']),
    new Menu(1806, 'Chính sách KPI', '/kpi', null, 'icon-dot', null, false, 180, ['kpi-policy', 'get', 'all']),
    new Menu(1807, 'Danh sách đối chiếu', '/kpi/manager', null, 'icon-dot', null, false, 180, []),


    new Menu(80, 'Thanh toán', null, null, 'leftmenu-thanhtoan', null, true, 0, ['transaction', 'get', 'all']),
    new Menu(801, 'Đề nghị thu tiền', '/receipt/create', null, 'icon-dot', null, false, 80, ['transaction', 'create']),
    new Menu(802, 'Danh sách đề nghị thu tiền', '/money-order', null, 'icon-dot', null, false, 80, ['transaction', 'get', 'all']),
    new Menu(803, 'Danh sách đề nghị hoàn tiền', '/money-order/refund', null, 'icon-dot', null, false, 80, ['transaction-refund', 'get', 'all']),

    // new Menu(70, 'Thanh toán', null, null, 'leftmenu-thanhtoan', null, true, 0, ['transaction-refund', 'reject']),
    // new Menu(505, 'Danh sách đề nghị hoàn tiền', '/money-order/refund', null, 'icon-dot', null, false, 70, ['transaction-refund', 'get', 'all']),

    new Menu(85, 'Kế toán', null, null, 'leftmenu-ketoan', null, true, 0, ['transaction-order', 'approve']),
    new Menu(851, 'Tiền đặt cọc/đặt chỗ', null, null, 'icon-dot', null, true, 85, ['transaction-order', 'approve']),
    // new Menu(850, 'Phiếu thu', '/money-order/accounting/create', null, 'icon-dot', null, false, 85, ['transaction', 'create']),
    new Menu(8511, 'Đề nghị thu tiền chờ duyệt', '/money-order/accounting/waiting', null, 'icon-dot', null, false, 851, ['transaction-order', 'approve']),
    new Menu(8512, 'Đề nghị thu tiền đã xử lý', '/money-order/accounting', null, 'icon-dot', null, false, 851, ['transaction-order', 'approve']),
    new Menu(8513, 'Danh sách đề nghị hoàn tiền', '/money-order/refund', null, 'icon-dot', null, false, 851, ['transaction-refund', 'get', 'all']),
    // new Menu(853, 'Chuyển khoản chờ xác nhận', '/money-order/event', null, 'icon-dot', null, false, 85, ['transaction-order', 'approve']),
    // new Menu(854, 'Lịch sử giao dịch Gateway', '/money-order/payment-history', null, 'icon-dot', null, false, 85, ['payment', 'gateway', 'log']),
    new Menu(852, 'Nạp rút tiền', null, null, 'icon-dot', null, true, 85, ['transaction-order', 'approve']),
    new Menu(8521, 'Danh sách thu tiền chờ duyệt', '/money-order/pay', null, 'icon-dot', null, false, 852, ['transaction-refund', 'get', 'all']),
    new Menu(8522, 'Danh sách chi tiền chờ duyệt', '/money-order/waiting', null, 'icon-dot', null, false, 852, ['transaction-refund', 'get', 'all']),
    new Menu(8523, 'Danh sách chi tiền đã xử lý', '/money-order/waiting-processed', null, 'icon-dot', null, false, 852, ['transaction-refund', 'get', 'all']),
    // new Menu(858, 'Lịch sử giao dịch', '/money-order/history', null, 'icon-dot', null, false, 85, ['transaction-refund', 'get', 'all']),
    new Menu(853, 'Giao dịch trực tuyến', null, null, 'icon-dot', null, true, 85, ['transaction-order', 'approve']),
    new Menu(8531, 'Nạp tiền trực tuyến', '/money-order/transactions-history', null, 'icon-dot', null, false, 853, ['transaction-refund', 'get', 'all']),
    new Menu(854, 'Lịch sử sao kê ví của TVV', '/statement', null, 'icon-dot', null, false, 85, ['statement', 'get', 'all']),

    new Menu(120, 'Báo cáo', null, null, 'leftmenu-baocao', null, true, 0, ['report', 'view', 'menu']),
    // new Menu(1201, 'Báo cáo yêu cầu tư vấn', '/ticket/report', null, 'icon-dot', null, false, 120, ['lead', 'report']),
    // new Menu(1202, 'Báo cáo sản phẩm', '/property/report', null, 'icon-dot', null, false, 120, ['contract', 'report', 'property']),
    // new Menu(1204, 'Báo cáo giao dịch', '/deal/report', null, 'icon-dot', null, false, 120, ['dealt', 'report']),
    new Menu(1203, 'Báo cáo trạng thái người dùng', '/employee/report', null, 'icon-dot', null, false, 120, ['employee', 'report']),
    new Menu(1203, 'Báo cáo kinh doanh', '/contract/report', null, 'icon-dot', null, false, 120, ['property', 'project', 'report', 'ticket']),
    // new Menu(1205, 'Báo cáo bán mới', '/property/primary-report', null, 'icon-dot', null, false, 120,  []),
    // new Menu(1205, 'Báo cáo bán mới', '/property/primary-report', null, 'icon-dot', null, false, 120, ['property', 'primary', 'report']),
    new Menu(1206, 'Báo cáo giao dịch', '/property/primary-detail-report', null, 'icon-dot', null, false, 120, ['property', 'ticket', 'report', 'primary', 'detail']),
    new Menu(12010, 'Báo cáo kết quả khảo sát Lead', '/lead/survey-report', null, 'icon-dot', null, false, 120, ['lead', 'report', 'view', 'menu']),
    // new Menu(12011, 'Báo cáo khai thác KHTN', '/customer/pos-report', null, 'icon-dot', null, false, 120, ['property', 'customer', 'report']),
    new Menu(12012, 'Báo cáo tương tác của TVV', '/group-admin/report', null, 'icon-dot', null, false, 120, ['group', 'get', 'overview']),
    new Menu(12013, 'Tra cứu giới thiệu của từng TVV', '/employee/report-referral', null, 'icon-dot', null, false, 120, ['employee', 'report']),
    // new Menu(1203, 'Lịch sử cuộc gọi', '/call-log/report', null, 'icon-dot', null, false, 120, []),
    // new Menu(202, 'Lịch sử khai thác', '/ticket/history', null, 'icon-dot', null, false, 120, ['lead', 'get', 'all', 'lead']),
    // new Menu(304, 'Lịch sử tư vấn ký gửi', '/consignment/history', null, 'icon-dot', null, false, 120, []),
    // new Menu(12012, 'Lịch sử cuộc gọi', '/call-log/report', null, 'icon-dot', null, false, 120, []),
    // new Menu(1202, 'Cuộc gọi đi', '/call-log/call-out', null, 'icon-dot', null, false, 120, []),
    // new Menu(1103, 'Cuộc gọi đến', '/call-log/income-call', null, 'icon-dot', null, false, 130, []),

    new Menu(140, 'Trung tâm giao dịch', null, null, 'leftmenu-ttgiaodich', null, true, 0, ['orgchart', 'get', 'all']),
    new Menu(1402, 'Danh sách TTGD', '/orgchart-pos', null, 'icon-dot', null, false, 140, ['pos']),
    // new Menu(703, 'Danh sách TVV chờ duyệt', '/', null, 'icon-dot', null, false, 140, ['employee']),
    new Menu(1403, 'Danh sách TVV', '/employee', null, 'icon-dot', null, false, 140, ['employee']),
    new Menu(1404, 'Chức năng người dùng', '/role', null, 'icon-dot', null, false, 140, ['role']),
    // new Menu(706, 'Phân quyền', '/', null, 'icon-dot', null, false, 140, ['role']),
    new Menu(1405, 'Gán nhân viên', '/orgchart-pos/assignment', null, 'icon-dot', null, false, 140, ['employee', 'sys', 'admin']),

    new Menu(150, 'Cài đặt', null, null, 'leftmenu-caidat', null, true, 0, ['role']),
    // new Menu(1500, 'Loại sản phẩm', '/category', null, 'icon-dot', null, false, 150, ['property', 'unit']),
    // new Menu(1501, 'Thuộc tính sản phẩm', '/attribute/property', null, 'icon-dot', null, false, 150, ['property', 'attribute', 'get', 'all']),
    // new Menu(1502, 'Mẫu sản phẩm', '/define-attributes/property', null, 'icon-dot', null, false, 150, ['property', 'template', 'get', 'all']),
    // new Menu(1503, 'Mẫu nhu cầu ', '/define-attributes/demand', null, 'icon-dot', null, false, 150, ['demand']),
    new Menu(1504, 'Campaign', '/settings/campaigns', null, 'icon-dot', null, false, 150, ['campaign', 'get', 'all']),
    new Menu(1505, 'Master Data', '/settings/masterData', null, 'icon-dot', null, false, 150, ['homemenu', 'get', 'all']),
    new Menu(1506, 'Lối tắt', '/home-menu', null, 'icon-dot', null, false, 150, ['homemenu', 'get', 'all']),
    new Menu(1507, 'Đánh giá ứng dụng', null, null, 'icon-dot', null, true, 150, ['rateapp', 'get', 'all']),
    new Menu(1508, 'Lựa chọn đánh giá', '/settings/rateApp', null, 'icon-dot', null, false, 1507, ['rateapp', 'get', 'all']),
    new Menu(1509, 'Quản lý đánh giá App', '/settings/rateAppUser', null, 'icon-dot', null, false, 1507, ['rateapp', 'get', 'all']),
    new Menu(1510, 'Khảo sát', '/survey', null, 'leftmenu-hopdong', null, false, 0, ['survey', 'get', 'all']),
    new Menu(160, 'Quản lý TVV', null, null, 'leftmenu-duan', null, true, 0, ['employee', 'orgchart']),
    new Menu(161, 'Danh sách TVV', '/employee', null, 'leftmenu-khachhang', null, false, 160, ['employee', 'orgchart']),
    new Menu(1604, 'Quản  lý Nhân viên hỗ trợ', '/employee/employee-support', null, 'leftmenu-khachhang', null, false, 160, ['employee', 'support', 'get', 'all']),
    new Menu(162, 'Level TVV', '/employee/mlm-diagram', null, 'leftmenu-khachhang', null, false, 160, ['employee', 'user-level', 'get', 'f0']),
    new Menu(163, 'Báo cáo level TVV', '/employee/report-mlm-diagram', null, 'leftmenu-baocao', null, false, 160, ['employee', 'user-level', 'get', 'report']),
    new Menu(1601, 'Quản lý thưởng đa cấp', '/mlm/mlm', null, 'icon-dot', null, false, 160, ['mlm', 'get', 'all']),
    new Menu(164, 'Danh sách Top User', '/top-user/list', null, 'icon-dot', null, false, 160, ['top-user', 'get', 'all']),
    new Menu(165, 'Quản lý danh hiệu', '/title-management', null, 'icon-dot', null, false, 160, ['title-management', 'get', 'all']),
    new Menu(16006, 'Quản lý tài khoản ngân hàng', '/bank-info', null, 'icon-dot', null, false, 160, ['employee', 'bank', 'info']),
    // new Menu(1600, 'Danh sách TVV old', '/employee/old', null, 'leftmenu-khachhang', null, false, 0, ['employee', 'orgchart']),

    new Menu(240, 'Thông báo', null, null, 'leftmenu-notification', null, true, 0, ['notification', 'get', 'all']),
    new Menu(2401, 'Nhóm thông báo', '/notification-category', null, 'icon-dot', null, false, 240, ['notification', 'category', 'get', 'all']),
    new Menu(2402, 'Tin nhắn thông báo', '/notification-message', null, 'icon-dot', null, false, 240, ['notification', 'message', 'get', 'all']),
    new Menu(2404, 'Danh sách thông báo tự do', '/notification-global', null, 'icon-dot', null, false, 240, ['notification', 'global', 'message', 'get', 'all']),
    // new Menu(2403, 'Push Noti', '/notification/push-noti', null, 'icon-dot', null, false, 240, ['notification', 'send', 'global']),

    // new Menu(30, 'Yêu cầu tư vấn', null, null, 'icon-yeucautuvan', null, true, 0, ['lead', 'view', 'menu']),
    // new Menu(301, 'Lấy YCTV mới', '/ticket', null, 'icon-dot', null, false, 30, ['lead', 'get', 'all', 'lead']),
    // new Menu(302, 'TV Môi Giới đang khảo sát', '/demand', null, 'icon-dot', null, false, 30, ['demand', 'get', 'all']),
    // new Menu(303, 'TV Ký Gửi đang khảo sát', '/consignment', null, 'icon-dot', null, false, 30, ['consignment', 'get', 'all']),
    // new Menu(304, 'Tư vấn dịch vụ', '/ticket-advising', null, 'icon-dot', null, false, 30, ['lead', 'get', 'all', 'lead', 'advising']),


    // new Menu(40, 'Hợp đồng', null, null, 'leftmenu-hopdong', null, true, 0, []),
    // new Menu(405, 'HDMG chưa duyệt', '/contract/draft', null, 'icon-dot', null, false, 40, ['contract', 'get', 'all']),
    // new Menu(406, 'HDMG đã duyệt', '/contract/list', null, 'icon-dot', null, false, 40, ['contract', 'get', 'all']),
    // new Menu(407, 'HDMG cần duyệt', '/contract/need-approve', null, 'icon-dot', null, false, 40, ['contract', 'approve']),

    // new Menu(45, 'Rổ yêu cầu tư vấn', null, null, 'products', null, true, 0, ['lead', 'common', 'view', 'menu']),
    // new Menu(450, 'TV Môi Giới', '/import-ticket/demand', null, 'icon-dot', null, false, 45, ['lead', 'import']),
    // new Menu(451, 'TV Ký Gửi', '/import-ticket/consignment', null, 'icon-dot', null, false, 45, ['lead', 'import']),
    // new Menu(452, 'Lịch sử', '/import-ticket/history', null, 'icon-dot', null, false, 45, ['lead', 'import']),

    // new Menu(50, 'Sản phẩm', null, null, 'icon-quanlysp', null, true, 0, []),
    // new Menu(57, 'Sản phẩm khai thác', '/property-common', null, 'icon-dot', null, false, 50, ['property', 'unit', 'import']),
    // new Menu(58, 'Sản phẩm O2O', '/property', null, 'icon-dot', null, false, 50, ['property', 'unit', 'get', 'all']),

    // new Menu(60, 'Danh sách giao dịch', null, null, 'leftmenu-dsgiaodich', null, true, 0, []),
    // new Menu(601, 'Giao dịch đang xử lý', '/deal/dealt', null, 'icon-dot', null, false, 60, ['dealt', 'get', 'all']),
    // new Menu(602, 'Giao dịch cần duyệt', '/deal/dealt-approve', null, 'icon-dot', null, false, 60, ['dealing', 'get', 'waiting', 'all']),
    // new Menu(603, 'Giao dịch đã xử lý', '/deal/dealt-completed', null, 'icon-dot', null, false, 60, ['dealing', 'get', 'waiting', 'all']),
    // new Menu(310, 'Giao dịch đã xử lý', '/', null, 'icon-dot', null, false, 50, ['dealt','get','all']),

    // new Menu(90, 'Hậu kiểm', null, null, 'leftmenu-haukiem', null, true, 0, ['property', 'unit', 'pull']),
    // new Menu(901, 'Hậu kiểm sản phẩm', '/property-authentication', null, 'icon-dot', null, false, 90, ['property', 'unit', 'pull']),
    // new Menu(902, 'Danh sách hậu kiểm', '/property-authentication/authenticated', null, 'icon-dot', null, false, 90, ['property', 'unit', 'pull']),

    new Menu(110, 'Tiện ích', null, null, 'leftmenu-tienich', null, true, 0, []),
    // new Menu(11001, 'Lịch hẹn', '/booking', null, 'icon-dot', null, false, 110, []),
    new Menu(11004, 'E-Voucher', '/e-voucher', null, 'icon-dot', null, false, 110, ['evoucher', 'view', 'menu']),
    new Menu(11005, 'Campaign', '/settings/campaigns', null, 'icon-dot', null, false, 110, ['campaign', 'get', 'all']),

    new Menu(250, 'Tin tức', null, null, 'leftmenu-hopdong', null, true, 0, ['news', 'get', 'all']),
    // new Menu(11001, 'Lịch hẹn', '/booking', null, 'icon-dot', null, false, 110, []),
    new Menu(25002, 'Quản lý thư mục', '/news-category', null, 'icon-dot', null, false, 250, []),
    new Menu(25003, 'Quản lý tin tức', '/news', null, 'icon-dot', null, false, 250, []),

    // new Menu(130, 'Chuyển ĐVBH', null, null, 'leftmenu-chuyendvbh', null, true, 0, ['orgchart']),
    // new Menu(131, 'Tạo yêu cầu', '/transfer-unit-create', null, 'icon-dot', null, false, 130, ['orgchart', 'create', 'transfer', 'pos']),
    // new Menu(132, 'Danh sách yêu cầu', '/transfer-unit-list', null, 'icon-dot', null, false, 130, ['orgchart', 'accept', 'transfer', 'pos']),
    // new Menu(112, 'Thông tin cập nhật', '/', null, 'icon-dot', null, false, 110, []),

    new Menu(200, 'User log', null, null, 'icon-userlog', null, true, 0, ['userBusinessLog', 'get', 'all']),
    new Menu(20001, 'User log', '/user-log', null, 'icon-dot', null, false, 200, ['logger', 'get', 'in', 'project']),
    new Menu(20002, 'Thay đổi sơ đồ tổ chức', '/user-log-change', null, 'icon-dot', null, false, 200, ['userBusinessLog', 'get', 'all']),

    new Menu(210, 'Đăng tin', null, null, 'leftmenu-hopdong', null, true, 0, ['post', 'get', 'all']),
    new Menu(21001, 'Danh sách bài đăng', '/social', null, 'icon-dot', null, false, 210, []),

    new Menu(220, 'Sự kiện', '/event-admin', null, 'leftmenu-sukien', null, false, 0, ['event', 'get', 'all']),

    new Menu(230, 'Nhóm', '/group-admin', null, 'leftmenu-khachhang', null, false, 0, ['group', 'get', 'all']),

    new Menu(231, 'Trò chơi', null, null, 'leftmenu-trochoi', null, true, 0, ['game']),
    new Menu(23101, 'Quản lý trò chơi', '/game-info', null, 'icon-dot', null, false, 231, ['game', 'get', 'all']),
    new Menu(23104, 'Quản lý người chơi', '/game-info/user-info', null, 'icon-dot', null, false, 231, ['game-user', 'get', 'all']),
    new Menu(23102, 'Quản lý bộ câu hỏi', '/game-info/quiz', null, 'icon-dot', null, false, 231, ['game-data-quiz', 'get', 'all']),

    new Menu(2198, 'Nhiệm vụ', null, null, 'leftmenu-hopdong', null, true, 0, []),
    new Menu(219801, 'Danh sách nhiệm vụ', '/mission/list', null, 'icon-dot', null, false, 2198, ['mission', 'get', 'all']),
    new Menu(219802, 'Danh sách điều kiện nhiệm vụ', '/mission/app-event', null, 'icon-dot', null, false, 2198, ['mission', 'get', 'all']),
    // new Menu(219803, 'Danh sách danh hiệu', '/mission/title', null, 'icon-dot', null, false, 2198, ['title', 'get', 'all']),
    new Menu(219804, 'Quản lý người tham gia', '/mission/management-users', null, 'icon-dot', null, false, 2198, ['user', 'get', 'all']),

    new Menu(232, 'Propskill', null, null, 'leftmenu-learning', null, true, 0, []),
    new Menu(23201, 'Quản lý khóa học', '/e-learning/course', null, 'icon-dot', null, false, 232, ['course', 'get', 'all']),
    new Menu(23202, 'Quản lý bài học', '/e-learning/lesson', null, 'icon-dot', null, false, 232, ['lesson', 'get', 'all']),
    // new Menu(23203, 'Quản lý danh hiệu', '/e-learning/title', null, 'icon-dot', null, false, 232, ['title','get','all']),
    // new Menu(23204, 'Quản lý mục tiêu', '/e-learning/target', null, 'icon-dot', null, false, 232, ['goal', 'get', 'all']),
    new Menu(23205, 'Danh sách người chơi', '/e-learning/users', null, 'icon-dot', null, false, 232, ['user', 'get', 'all']),
    new Menu(23206, 'Danh sách thư mục', '/e-learning/category', null, 'icon-dot', null, false, 232, ['category', 'get', 'all']),
    new Menu(23207, 'Danh sách người diễn giả', '/e-learning/instructor', null, 'icon-dot', null, false, 232, ['instructor', 'get', 'all']),

    new Menu(233, 'Check in địa điểm', null, null, 'leftmenu-exploration', null, true, 0, []),
    new Menu(23301, 'Quản lý địa điểm', '/exploration/', null, 'icon-dot', null, false, 233, ['exploration', 'get', 'all']),
    new Menu(23302, 'Lịch sử checkin', '/exploration/exploration-history/', null, 'icon-dot', null, false, 233, ['exploration-history', 'get', 'all']),

    // new Menu(2331, 'Game', null, null, 'leftmenu-sukien', null, true, 0, []),
    new Menu(234, 'Video', null, null, 'leftmenu-video', null, true, 0, []),
    new Menu(23401, 'Danh sách video', '/list-video', null, 'icon-dot', null, false, 234, ['video-store','get','all']),
    new Menu(270, 'Check-in ', null, null, 'icon-userlog', null, true, 0, ['checkin', 'view', 'menu']),
    new Menu(271, 'Cấu hình check-in', '/checkin-checkout/config', null, 'icon-dot', null, false, 270, ['checkin', 'config', 'view', 'menu']),
    new Menu(272, 'Báo cáo check-in', '/checkin-checkout/report-checkin-checkout', null, 'icon-dot', null, false, 270, ['checkin', 'report', 'view', 'menu']),
    new Menu(273, 'Cấu hình điểm check-in', '/checkin-checkout/point', null, 'icon-dot', null, false, 270, ['checkin', 'position', 'view', 'menu']),
];

export const verticalMenuItems = menus;
export const horizontalMenuItems = menus;
