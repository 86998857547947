import { Component, OnInit, forwardRef, Input, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl, Validator, NG_VALIDATORS } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { isString } from 'util';
import * as moment from 'moment';
import { DateConfigEnum } from 'app/enums/date-config.enum';
import { Moment } from 'moment';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatePickerComponent), multi: true },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() placeholder: string;
  @Input() isProfile: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isValidateDefault: boolean = true;
  @Output() dateChange: EventEmitter<string> = new EventEmitter();

  private _changeCb: any;
  private _blurCb: any;
  public datepickerValue: NgbDate;
  public minDateDefault = moment('1900-1-1');
  public maxDateDefault = moment().add(100, 'years');

  get privatePlaceholder() { return this.placeholder || DateConfigEnum.DisplayDatepicker; }

  public minDateNgbDate: NgbDate = new NgbDate(this.minDateDefault.get('year'), this.minDateDefault.get('month') + 1, this.minDateDefault.get('date'));
  @Input() set minDate(value: number) {
    const date: Moment = value ? moment(value) : this.minDateDefault;
    this.minDateNgbDate = date ? new NgbDate(date.get('year'), date.get('month') + 1, date.get('date')) : null;
  }

  public maxDateNgbDate: NgbDate = new NgbDate(this.maxDateDefault.get('year'), this.maxDateDefault.get('month') + 1, this.maxDateDefault.get('date'));
  @Input() set maxDate(value: number) {
    const date: Moment = value ? moment(value) : this.maxDateDefault;
    this.maxDateNgbDate = date ? new NgbDate(date.get('year'), date.get('month') + 1, date.get('date')) : null;
  }

  constructor() {
  }

  ngOnInit() {

  }

  convertControlValueAccessorNgModel(date: NgbDate): string {
    return date ?
      moment([date.year, date.month, date.day].join('-')).format(DateConfigEnum.Standard) : null;
  }

  onDateSelection() {
    if (this.datepickerValue && typeof this.datepickerValue === 'object' && !this.datepickerValue.year) {
      this.datepickerValue = null;
    }
    this.callChangeCb();
    this.dateChange.emit(this.convertControlValueAccessorNgModel(this.datepickerValue));
  }

  writeValue(value: number): void {
    const date: Moment = value ? moment(value) : null;
    this.datepickerValue = date ? new NgbDate(date.get('year'), date.get('month') + 1, date.get('date')) : null;
  }

  registerOnChange(fn: any) {
    this._changeCb = fn;
  }

  registerOnTouched(fn: any) {
    this._blurCb = fn;
  }

  callChangeCb() {
    this._changeCb(this.convertControlValueAccessorNgModel(this.datepickerValue));
  }

  callBlur() {
    this.onDateSelection();
    this._blurCb();
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  validate(c: FormControl) {
    return c.valid && !this.isInvalidDate() ? null : {
      type: {
        valid: false,
        actual: null
      }
    };
  }

  isInvalidDate(): boolean {
    return isString(this.datepickerValue);
  }

}
