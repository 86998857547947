import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerV2Component } from './date-picker-v2.component';
import { NgbDatepickerModule, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateCustomParserFormatter } from 'app/pages/share-components/filter/custom-datepicker';

@NgModule({
  declarations: [DatePickerV2Component],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgbModule,
    FormsModule
  ],

  exports: [
    DatePickerV2Component
  ],

  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class DatePickerV2Module { }
