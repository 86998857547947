import { IDecode } from './i-decode';
import * as moment from 'moment';

export class CBaseDate implements IDecode<CBaseDate> {
    public createdBy: number;
    public createdDate: number;
    public updatedBy: number;
    public updatedDate: number;

    constructor(params: Partial<CBaseDate> = {}) {
        this.copy(params);
    }

    static copyList(params: Partial<CBaseDate>[] = []): CBaseDate[] {
        return params.map(el => new CBaseDate().copy(el));
    }

    copy(params: Partial<CBaseDate> = {}): CBaseDate {
        this.createdBy = params.createdBy ? moment(params.createdBy).valueOf() : null;
        this.createdDate = params.createdDate ? moment(params.createdDate).valueOf() : null;
        this.updatedBy =  params.updatedBy ? moment(params.updatedBy).valueOf() : null;
        this.updatedDate = params.updatedDate ? moment(params.updatedDate).valueOf() : null;
        return this;
    }

    decode(paramsApi: any): CBaseDate {
        return new CBaseDate(paramsApi);
    }

    decodeList(paramsApi: any[]): CBaseDate[] {
        return paramsApi.map(el => new CBaseDate().decode(el));
    }
}
