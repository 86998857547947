import {validate, IsBoolean, IsDate} from "class-validator";
import { LanguageValidateEnglish, LanguageValidateVietnamese } from "../constant/language.validate";
import { Images } from "../components/upload-images/image.model";
export const PROPERTY_BASE_MODEL = [
    'createdDate', 'modifiedDate' , 'texts' , 'errors' , 'images'
]
export class BaseModel  {
    // LanguageValidateEnglish = LanguageValidateEnglish;
    
    description: string;

    @IsBoolean()
    active: boolean;

    status: string;

    softDelete: boolean;

    createdBy: string;

    createdDate: Date;

    modifiedBy: string;
    
    modifiedDate: Date;

    images: Images;
    texts: any = {};
    errors: any = {};
    static getType() {
        return 'Base-Model';
    }
    
    setValue(params: any) {
        if (params) {
            this.active = params.active === true || params.active === false ?  params.active : true;
            this.createdBy = params.createdBy || '';
            this.description = params.description || '';
            this.status = params.status || '';
            this.images = params.images ? new Images(params.images) :  new Images();
            this.createdDate = params.createdDate || params.createDate ;
            this.createdDate = this.createdDate ? new Date(this.createdDate) : new Date();
            this.modifiedBy = params.modifiedBy || '';
            this.modifiedDate = params.modifiedDate ? new Date(params.modifiedDate) :  params.timestamp ? new Date(params.timestamp) : new Date();
        } else {
            this.setNull();
        }
        this.setTexts();
    } 
    

    setNull() {
        this.active = true;
        this.images = new Images();
        this.createdBy = '';
        this.status = '';
        this.createdDate = new Date();
        this.modifiedBy = '';
        this.modifiedDate = new Date();
    }

    setTexts(changes?) {
        for (let property in this) {
            if (typeof this[property] != 'function' && property != 'errors' && property != 'texts') {
                this.texts[property] = property[0].toUpperCase() + property.substring(1, property.length);
            }
        }
        if (changes) {
            for (let property in changes) {
                this.texts[property] = changes[property];
            }
        }
    }
    static createHierarchy(children, item , index) {
        !children && (children = [])
        if (item.parentId) {
            let position = 0;
            while (position < children.length) {
                let category = children[position];
                if (!category.children) {
                    category.children = [];
                }
                if (category.children.length > 0) {
                    category.children = this.createHierarchy(category.children,item , 0);
                }
                if (category.id === item.parentId) {
                    category.children.push(item);
                    position = children.length + 1;
                }
                position++;
            }
            if (position === children.length) {
                index++;
            }
            let object = children.splice(index, 1)[0];
            if (object) {
                children = this.createHierarchy(children, object, index)
            }
            return children;
        } else {
            children.unshift(item);
            index++;
            if (index < children.length ) {
                let object = children.splice(index, 1)[0];
                children = this.createHierarchy(children, object, index)
            }
        }
        return children;
    }
    createObjectRequestAPI(object) {
        object.active = this.active || false;
        object.status = this.status || '';
        object.description = this.description;
        object.images = this.images;
        return object;
    }
    // tslint:disable-next-line: member-ordering
    static validateObject(that, topProperty, fucnt?) {
        let errors = {
            property: topProperty,
            isExist: false
        };
        return new Promise((resolve, reject) => {
            !topProperty && (resolve(errors))
            validate(that).then(result => { // errors is an array of validation errors
                if (result.length > 0) {
                    errors.isExist = true;
                }
                result.forEach((item) => {
                    let message = '';
                    for (let property in item.constraints) {
                        message = item.constraints[property];
                    }
                    if (!message) {
                        return;
                    }
                    for (let property in LanguageValidateEnglish) {
                        if (message.includes(LanguageValidateEnglish[property])) {
                            let split = property.split('__');
                            let codeReplace = split[0] + '__vietnamese';
                            let textReplace = LanguageValidateVietnamese[codeReplace];
                            message = message.replace(LanguageValidateEnglish[property], textReplace);
                        }
                    }
                    let splitMessage = message.split(' ');
                    splitMessage[0] = 'Giá trị nhập vào';
                    message = splitMessage.join(' ');
                    if (!errors[item.property]) {
                        errors[item.property] = [message];
                    } else {
                        errors[item.property].push(message)
                    }
                });
                let promiseAll = [];
                for (let property in that) {
                    if (that[property] && typeof that[property] == 'object') {
                        if (!PROPERTY_BASE_MODEL.find(pro => pro == property)) {
                            if (typeof that[property][fucnt || 'validate'] == 'function') {
                                let er = that[property][fucnt || 'validate']();
                                er.property = property;
                                promiseAll.push(
                                    new Promise((resolve, reject) => {
                                        resolve(er)
                                    })
                                )
                            } else if (!fucnt) {
                                promiseAll.push(BaseModel.validateObject(that[property], property, fucnt));
                            }
                        }
                    }
                }
                Promise.all(promiseAll)
                .then((result) => {
                    result.forEach((item) => {
                        errors[item.property] = item;
                        !errors.isExist && (errors.isExist = item.isExist)
                    });
                    resolve(errors);
                })
            });
        })
    }
}
