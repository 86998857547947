export interface ILevelPoint {
    Rank: string;
    Level: number;
    MaxPoint: number;
}

export const EEndRankNew: ILevelPoint = { Rank: 'New', Level: 0, MaxPoint: 40 };
export const EEndRankBronze: ILevelPoint = { Rank: 'Bronze', Level: 4, MaxPoint: 2400 };
export const EEndRankSilver: ILevelPoint = { Rank: 'Silver', Level: 12, MaxPoint: 18000 };
export const EEndRankGold: ILevelPoint = { Rank: 'Gold', Level: 28, MaxPoint: 144000 };
export const EEndRankPlatinum: ILevelPoint = { Rank: 'Platinum', Level: 60, MaxPoint: 400000 };
export const EEndRankDiamond: ILevelPoint = { Rank: 'Diamond', Level: 100, MaxPoint: 720000 };

export const EStartRank: ILevelPoint[] = [
    EEndRankNew,
    EEndRankBronze,
    EEndRankSilver,
    EEndRankGold,
    EEndRankPlatinum,
    EEndRankDiamond
];



export const ELevelPoint: ILevelPoint[] = [
    EEndRankNew,
    { Rank: 'Bronze', Level: 1, MaxPoint: 200 },
    { Rank: 'Bronze', Level: 2, MaxPoint: 600 },
    { Rank: 'Bronze', Level: 3, MaxPoint: 1200 },
    EEndRankBronze,
    { Rank: 'Silver', Level: 5, MaxPoint: 4000 },
    { Rank: 'Silver', Level: 6, MaxPoint: 6000 },
    { Rank: 'Silver', Level: 7, MaxPoint: 8000 },
    { Rank: 'Silver', Level: 8, MaxPoint: 10000 },
    { Rank: 'Silver', Level: 9, MaxPoint: 12000 },
    { Rank: 'Silver', Level: 10, MaxPoint: 14000 },
    { Rank: 'Silver', Level: 11, MaxPoint: 16000 },
    EEndRankSilver,
    { Rank: 'Gold', Level: 13, MaxPoint: 24000 },
    { Rank: 'Gold', Level: 14, MaxPoint: 32000 },
    { Rank: 'Gold', Level: 15, MaxPoint: 40000 },
    { Rank: 'Gold', Level: 16, MaxPoint: 48000 },
    { Rank: 'Gold', Level: 17, MaxPoint: 56000 },
    { Rank: 'Gold', Level: 18, MaxPoint: 64000 },
    { Rank: 'Gold', Level: 19, MaxPoint: 72000 },
    { Rank: 'Gold', Level: 20, MaxPoint: 80000 },
    { Rank: 'Gold', Level: 21, MaxPoint: 88000 },
    { Rank: 'Gold', Level: 22, MaxPoint: 96000 },
    { Rank: 'Gold', Level: 23, MaxPoint: 104000 },
    { Rank: 'Gold', Level: 24, MaxPoint: 112000 },
    { Rank: 'Gold', Level: 25, MaxPoint: 120000 },
    { Rank: 'Gold', Level: 26, MaxPoint: 128000 },
    { Rank: 'Gold', Level: 27, MaxPoint: 136000 },
    EEndRankGold,
    { Rank: 'Platinum', Level: 29, MaxPoint: 152000 },
    { Rank: 'Platinum', Level: 30, MaxPoint: 160000 },
    { Rank: 'Platinum', Level: 31, MaxPoint: 168000 },
    { Rank: 'Platinum', Level: 32, MaxPoint: 176000 },
    { Rank: 'Platinum', Level: 33, MaxPoint: 184000 },
    { Rank: 'Platinum', Level: 34, MaxPoint: 192000 },
    { Rank: 'Platinum', Level: 35, MaxPoint: 200000 },
    { Rank: 'Platinum', Level: 36, MaxPoint: 208000 },
    { Rank: 'Platinum', Level: 37, MaxPoint: 216000 },
    { Rank: 'Platinum', Level: 38, MaxPoint: 224000 },
    { Rank: 'Platinum', Level: 39, MaxPoint: 232000 },
    { Rank: 'Platinum', Level: 40, MaxPoint: 240000 },
    { Rank: 'Platinum', Level: 41, MaxPoint: 248000 },
    { Rank: 'Platinum', Level: 42, MaxPoint: 256000 },
    { Rank: 'Platinum', Level: 43, MaxPoint: 264000 },
    { Rank: 'Platinum', Level: 44, MaxPoint: 272000 },
    { Rank: 'Platinum', Level: 45, MaxPoint: 280000 },
    { Rank: 'Platinum', Level: 46, MaxPoint: 288000 },
    { Rank: 'Platinum', Level: 47, MaxPoint: 296000 },
    { Rank: 'Platinum', Level: 48, MaxPoint: 304000 },
    { Rank: 'Platinum', Level: 49, MaxPoint: 312000 },
    { Rank: 'Platinum', Level: 50, MaxPoint: 320000 },
    { Rank: 'Platinum', Level: 51, MaxPoint: 328000 },
    { Rank: 'Platinum', Level: 52, MaxPoint: 336000 },
    { Rank: 'Platinum', Level: 53, MaxPoint: 344000 },
    { Rank: 'Platinum', Level: 54, MaxPoint: 352000 },
    { Rank: 'Platinum', Level: 55, MaxPoint: 360000 },
    { Rank: 'Platinum', Level: 56, MaxPoint: 368000 },
    { Rank: 'Platinum', Level: 57, MaxPoint: 376000 },
    { Rank: 'Platinum', Level: 58, MaxPoint: 384000 },
    { Rank: 'Platinum', Level: 59, MaxPoint: 392000 },
    EEndRankPlatinum,
    { Rank: 'Diamond', Level: 61, MaxPoint: 408000 },
    { Rank: 'Diamond', Level: 62, MaxPoint: 416000 },
    { Rank: 'Diamond', Level: 63, MaxPoint: 424000 },
    { Rank: 'Diamond', Level: 64, MaxPoint: 432000 },
    { Rank: 'Diamond', Level: 65, MaxPoint: 440000 },
    { Rank: 'Diamond', Level: 66, MaxPoint: 448000 },
    { Rank: 'Diamond', Level: 67, MaxPoint: 456000 },
    { Rank: 'Diamond', Level: 68, MaxPoint: 464000 },
    { Rank: 'Diamond', Level: 69, MaxPoint: 472000 },
    { Rank: 'Diamond', Level: 70, MaxPoint: 480000 },
    { Rank: 'Diamond', Level: 71, MaxPoint: 488000 },
    { Rank: 'Diamond', Level: 72, MaxPoint: 496000 },
    { Rank: 'Diamond', Level: 73, MaxPoint: 504000 },
    { Rank: 'Diamond', Level: 74, MaxPoint: 512000 },
    { Rank: 'Diamond', Level: 75, MaxPoint: 520000 },
    { Rank: 'Diamond', Level: 76, MaxPoint: 528000 },
    { Rank: 'Diamond', Level: 77, MaxPoint: 536000 },
    { Rank: 'Diamond', Level: 78, MaxPoint: 544000 },
    { Rank: 'Diamond', Level: 79, MaxPoint: 552000 },
    { Rank: 'Diamond', Level: 80, MaxPoint: 560000 },
    { Rank: 'Diamond', Level: 81, MaxPoint: 568000 },
    { Rank: 'Diamond', Level: 82, MaxPoint: 576000 },
    { Rank: 'Diamond', Level: 83, MaxPoint: 584000 },
    { Rank: 'Diamond', Level: 84, MaxPoint: 592000 },
    { Rank: 'Diamond', Level: 85, MaxPoint: 600000 },
    { Rank: 'Diamond', Level: 86, MaxPoint: 608000 },
    { Rank: 'Diamond', Level: 87, MaxPoint: 616000 },
    { Rank: 'Diamond', Level: 88, MaxPoint: 624000 },
    { Rank: 'Diamond', Level: 89, MaxPoint: 632000 },
    { Rank: 'Diamond', Level: 90, MaxPoint: 640000 },
    { Rank: 'Diamond', Level: 91, MaxPoint: 648000 },
    { Rank: 'Diamond', Level: 92, MaxPoint: 656000 },
    { Rank: 'Diamond', Level: 93, MaxPoint: 664000 },
    { Rank: 'Diamond', Level: 94, MaxPoint: 672000 },
    { Rank: 'Diamond', Level: 95, MaxPoint: 680000 },
    { Rank: 'Diamond', Level: 96, MaxPoint: 688000 },
    { Rank: 'Diamond', Level: 97, MaxPoint: 696000 },
    { Rank: 'Diamond', Level: 98, MaxPoint: 704000 },
    { Rank: 'Diamond', Level: 99, MaxPoint: 712000 },
    EEndRankDiamond,
];
