import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent {
  public message: string;
  public btnTextConfirm: string = 'Xác nhận';
  public btnTextCancel: string = 'Huỷ';

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message;
    this.btnTextConfirm = data.textConfirm ? data.textConfirm : this.btnTextConfirm;
    this.btnTextCancel = data.textCancel ? data.textCancel : this.btnTextCancel;
  }

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close();
  }

}
