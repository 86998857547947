
<div class="app-card__header">
    <div mat-dialog-title class="d-center justy-between">
        <div class="text-center w-100"><h3 class="title-color">Mã khảo sát: {{survey.code}}</h3></div>
        <mat-icon (click)="closeDialog()" class="close-button cs-pointer">close</mat-icon>
    </div>
</div>
<div class="app-card__body" fxLayout="column">
    <ng-container *ngFor="let question of survey.questions; let i = index">
        <div class="input-container">
            <div fxFlex="35">
                <span class="app-label">{{question.name}}</span>
            </div>
            <div fxFlex="40" class="input-value app-form-group">
                <input type="text" class="app-input" [value]="getValueAnswer(question)" readonly/>
            </div>
        </div>
    </ng-container>
</div>
