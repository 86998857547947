
declare let $: any;
export class FloorRoundFloatNumber {
  static parse(type, value, exp) {
    if (exp < 0) {
      exp = exp * -1;
    }
    let valueMul = 1;
    for (let i=1; i <= exp; i++) {
      valueMul= valueMul * 10;
    }
    return Math[type](value * valueMul) / valueMul;
  }
  static floor(value, exp) {
    if (exp < 0) {
      exp = exp * -1;
    }
    let valueMul = 1;
    for (let i=1; i <= exp; i++) {
      valueMul= valueMul * 10;
    }
    return Math.floor(value * valueMul) / valueMul;
  }
  static round(value, exp) {
    if (exp < 0) {
      exp = exp * -1;
    }
    let valueMul = 1;
    for (let i=1; i <= exp; i++) {
      valueMul= valueMul * 10;
    }
    return Math.round(value * valueMul) / valueMul;
  }
}