import { Component, EventEmitter, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.scss']
})
export class LandingHeaderComponent implements OnInit {
  @Output() openDialog: EventEmitter<any> = new EventEmitter();
  constructor(
  ) {
  }

  ngOnInit() {
  }

  showDialog(){
    this.openDialog.emit();
  }
}
