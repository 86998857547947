import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AppSettings } from 'app/app.settings';
import { Settings } from 'app/app.settings.model';
import { environment } from 'environments/environment';
import { DialogJoinComponent } from '../dialog-join/dialog-join.component';
import { LandingPagePostGroupModel } from '../landing-page.model';
import { LandingPageService } from '../landing-page.service';
@Component({
  selector: 'app-landing-group-post',
  templateUrl: './landing-group-post.component.html',
  styleUrls: ['./landing-group-post.component.scss']
})
export class LandingGroupPostComponent {
  public settings: Settings;
  form: FormGroup;
  model: LandingPagePostGroupModel = new LandingPagePostGroupModel();
  id: string = '';
  count: number = 0;
  checkDataGroupPost: boolean = false;
  constructor(
    public appSettings:AppSettings,
    public route: ActivatedRoute,
    private service: LandingPageService,
    private _sanitizer: DomSanitizer,
    public dialog: MatDialog,
    ) {
    this.settings = this.appSettings.settings; 
  }

  ngOnInit(){
    // Router subscribe
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
    });
    const params = this.route.snapshot.params;
        const id = params['id'];        
        if (id) {
          this.service.getGroupPost(id).subscribe({
            next: (res: any) => {
              this.checkDataGroupPost = true
              this.model = new LandingPagePostGroupModel(res);
              if (this.model.medias && this.model.medias.length) {
                this.model.medias = this.model.medias.map(item => {
                    if (item.type === 'YOUTUBE') {
                        item.url = this.onSafeUrl(item.url);
                    }
                    return item;
                });
              }
              this.count = this.model.medias.length;
            },
            error: (err) => {            
            }
          });
        } else {
            this.model = new LandingPagePostGroupModel();
        }    
  }

  initForm() {}

  ngAfterViewInit(){
    this.settings.loadingSpinner = false; 
  }

  public scrollToDemos(){
    setTimeout(() => { window.scrollTo(0,520) });
  }

  onSafeUrl (url: string) {
    if (url.indexOf('watch?v=') > -1) {
        url = url.replace('watch?v=','embed/');
    }
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  openDialog() {
    const dialog = this.dialog.open(DialogJoinComponent, {
      width: '800px',
      data: {
        shareUrl: this.model.shareUrl,
      }
    });
    dialog.afterClosed().subscribe(result => { });
  }
}
