import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from './date-picker.component';
import { NgbDatepickerModule, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateCustomParserFormatter } from 'app/pages/share-components/filter/custom-datepicker';
import { InputMaskModule } from 'racoon-mask-raw';

@NgModule({
  declarations: [DatePickerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgbModule,
    FormsModule,
    InputMaskModule
  ],

  exports: [
    DatePickerComponent
  ],

  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class DatePickerModule { }
