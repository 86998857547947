export class RewardHistoryModel {
    stt: number;
    id: string;
    createdDate: Date;
    createdBy: string;
    modifiedDate: Date;
    modifiedBy: string;
    point: number;
    description: string;
    type: string
    constructor(params?: any) {
        params = params || {};
        this.id = params.id ? params.id : null;
        this.createdDate = params.createdDate ? params.createdDate : null;
        this.createdBy = params.createdBy ? params.createdBy : null;
        this.modifiedDate = params.modifiedDate ? params.modifiedDate : null;
        this.modifiedBy = params.modifiedBy ? params.modifiedBy : null;
        this.point = params.point ? params.point : 0;
        this.description = params.description ? params.description : '';
        this.type = params.type ? params.type : null;
    }
}
