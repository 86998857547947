import { DateToFormat } from 'app/shared/parse/date-to-format';

export enum MissionTypeEnum {
    INTRODUCER = 'INTRODUCER',
    POST_PROJECT = 'POST_PROJECT',
    VERIFY = 'VERIFY',
    SURVEY = 'SURVEY', // sở trường của tôi
    EVENT = 'EVENT',
    PROJECT_FAV = 'PROJECT_FAV',
    SOCIAL = 'SOCIAL',
    SURVEY_LEAD = 'SURVEY_LEAD',
    CHECK_IN = 'CHECK_IN',
    TRANSACTION = 'TRANSACTION',
    INTERACT = 'INTERACT'
}
export enum MissionMissionTypeEnum {
    DEFAULT = 'DEFAULT', // Mặc định (làm 1 lần)
    DAILY = 'DAILY', // Hàng ngày
    WEEKLY = 'WEEKLY', // Hàng tuần
    MONTHLY = 'MONTHLY', // Hàng tháng
}

export class MissionType {
    public static INTRODUCER = 'Giới thiệu';
    public static POST_PROJECT = 'Đăng tin dự án';
    public static VERIFY = 'Xác thực; nhập sở trường';
    public static EVENT = 'Sự kiện';
    public static PROJECT_FAV = 'Dự án yêu thích';
    public static SOCIAL = 'Cộng đồng';
    public static SURVEY_LEAD = 'Khảo sát lead';
    public static CHECK_IN = 'Checkin';
    public static TRANSACTION = 'Giao dịch'
    public static SURVEY = 'Sở trường của tôi'
    public static INTERACT = 'Tương tác';
}

export enum TypeValueStatus {
    TEXT = "TEXT",
    ARRAY = "ARRAY"
}

export enum IconEnum {
    AWESOME = 'AWESOME',
    PROFESSIONAL = 'PROFESSIONAL',
    AMATEURS = 'AMATEURS',
    STREET =  'STREET',
    NEWBIE = 'NEWBIE'
}

export enum IconUrlEnum {
    AWESOME = '/assets/img/mission/awesome_medal.svg',
    PROFESSIONAL = '/assets/img/mission/professional_medal.svg',
    AMATEURS = '/assets/img/mission/amateurs_medal.svg',
    STREET =  '/assets/img/mission/street_medal.svg',
    NEWBIE = '/assets/img/mission/newbie_medal.svg'
}

export class WalletTransactionTransType {
    public static EXTERNAL_PAY = 'Không thêm vào ví';
    public static CASH_PROMOTION = 'Ví khuyến mãi';
    public static CASH = 'Ví chính';
}

export enum MissionNavigationScreen {
    SCREEN_PROMOTION_DETAIL = 'SCREEN_PROMOTION_DETAIL', // Chương trình giới thiệu
    SCREEN_EXPLOIT_PROJECT = 'SCREEN_EXPLOIT_PROJECT', // Màn tạo khai thác dự án
    SCREEN_CUSTOMER_SURVEY = 'SCREEN_CUSTOMER_SURVEY', // Sở trường của tôi
    SCREEN_CUSTOMER_VERIFY = 'SCREEN_CUSTOMER_VERIFY', // Xác thực tài khoản
    SCREEN_GROUP = 'SCREEN_GROUP', // Màn hình danh sách nhóm
    SCREEN_PROJECT_HOME = 'SCREEN_PROJECT_HOME', // Màn hình home dự án
    SCREEN_EVENT = 'SCREEN_EVENT', // Sự kiện

    SCREEN_CHARITY_DETAIL = 'SCREEN_CHARITY_DETAIL', // Màn sự kiện thiện nguyện
    SCREEN_EVENT_LIVESTREAM = 'SCREEN_EVENT_LIVESTREAM', // Màn hình Livestream
    SCREEN_EVENT_DETAIL = 'SCREEN_EVENT_DETAIL', // Chi tiết sự kiện
    SCREEN_EXPLOIT_LEAD = 'SCREEN_EXPLOIT_LEAD', // Màn khai thác lead
    SCREEN_CREATE_CHECK_IN = 'SCREEN_CREATE_CHECK_IN', // Địa điểm check in
    SCREEN_HISTORY_CHECK_IN  = 'SCREEN_HISTORY_CHECK_IN', // Lịch sử checkin
}

export enum MissionTargetType {
    CURRENT_USER = 'CURRENT_USER', // Chỉ có 1 chỉ tiêu (người dùng hiện tại)
    EMPLOYEE = 'EMPLOYEE', // Đếm chỉ tiêu theo từng Nhân viên/TVV
    PROJECT = 'PROJECT', // Đếm chỉ tiêu theo từng dự án
    PROPERTY_UNIT = 'PROPERTY_UNIT', // Đếm chỉ tiêu theo từng sản phẩm
    PROJECT_LEAD = 'PROJECT_LEAD', // Đếm chỉ tiêu theo từng khai thác dự án
    LEAD_MINING = 'LEAD_MINING', // Đếm chỉ tiêu theo từng khai thác lead
    CHECKIN = 'CHECKIN', // Checkin địa điểm
}

export const IconImageConstant = [
    {id: IconEnum.NEWBIE , image: IconUrlEnum.NEWBIE},
    {id: IconEnum.STREET, image: IconUrlEnum.STREET},
    {id: IconEnum.AMATEURS , image: IconUrlEnum.AMATEURS },
    {id: IconEnum.PROFESSIONAL, image: IconUrlEnum.PROFESSIONAL },
    {id: IconEnum.AWESOME , image: IconUrlEnum.AWESOME }
]
export class Mission {
    public static DEFAULT_COLOR = '#F32727';

    public static MissionNavigationScreenDropList = [
        { key: 'SCREEN_PROMOTION_DETAIL', value: 'Chương trình giới thiệu' },           // Navigate chung
        { key: 'SCREEN_EXPLOIT_PROJECT', value: 'Màn tạo khai thác dự án' },            // Navigate chung
        { key: 'SCREEN_CUSTOMER_SURVEY', value: 'Màn sở trường của tôi' },
        { key: 'SCREEN_CUSTOMER_VERIFY', value: 'Màn xác thực tài khoản' },
        { key: 'SCREEN_PROJECT_HOME', value: 'Màn hình home dự án' },                   // Navigate chung
        { key: 'SCREEN_EVENT', value: 'Màn hình sự kiện' },                             // Navigate chung
        { key: 'SCREEN_GROUP', value: 'Màn hình danh sách nhóm' },                      // Navigate chung
        { key: 'SCREEN_CREATE_CHECK_IN', value: 'Màn hình danh sách địa điểm checkin' },          // Navigate chung
        { key: 'LEAD_MINING', value: 'Màn khai thác lead' },  // Khai thác lead
    ];

    public static MissionNavigationScreenTargetDropList = [
        { key: 'SCREEN_FAVORITE_PROJECT', value: 'Màn hình danh sách dự án yêu thích' },    // Navigate chỉ tiêu
        { key: 'SCREEN_MY_GROUP', value: 'Màn hình nhóm đã tham gia' },                    // Navigate chỉ tiêu
        { key: 'SCREEN_HISTORY_REFERRAL', value: 'Màn hình lịch sử giới thiệu' },       // Navigate chỉ tiêu
        { key: 'SCREEN_MY_EVENT', value: 'Màn hình sự kiện của tôi' },                     // Navigate chỉ tiêu
        { key: 'SCREEN_MINING_PROJECT', value: 'Màn hình danh sách khai thác dự án' },  // Navigate chỉ tiêu
        { key: 'SCREEN_HISTORY_CHECK_IN', value: 'Màn hình lịch sử checkin' },  // Navigate chỉ tiêu
        { key: 'SCREEN_CUSTOMER_SURVEY', value: 'Màn sở trường của tôi' },  // Navigate chỉ tiêu
        { key: 'SCREEN_CUSTOMER_VERIFY', value: 'Màn xác thực tài khoản' },  // Navigate chỉ tiêu
        { key: 'LEAD_MINING', value: 'Màn khai thác lead' },  // Khai thác lead
    ];

    public static MissionFilter = [
        { id: 'INTRODUCER', name: 'Giới thiệu' },
        { id: 'POST_PROJECT', name: 'Đăng tin dự án' },
        { id: 'VERIFY', name: 'Xác thực; nhập sở trường' },
        { id: 'EVENT', name: 'Sự kiện' },
        { id: 'PROJECT_FAV', name: 'Dự án yêu thích' },
        { id: 'SOCIAL', name: 'Cộng đồng' },
        { id: 'SURVEY_LEAD', name: 'Khảo sát lead' },
        { id: 'CHECK_IN', name: 'Checkin' },
        { id: 'SURVEY_LEAD', name: 'Khai thác lead' },
        { id: 'TRANSACTION', name: 'Giao dịch' },
        { id: 'INTERACT', name: 'Tương tác' },
    ]

    public static typeValueList = [
        { id: 'TEXT', name: 'Một' },
        { id: 'ARRAY', name: 'Nhiều' }
    ]

    public static MissionTargetTypeDropList = [
        { key: 'CURRENT_USER', value: 'Chỉ có 1 chỉ tiêu (người dùng hiện tại)' }, // Chỉ có 1 chỉ tiêu (người dùng hiện tại)
        { key: 'EMPLOYEE', value: 'Đếm chỉ tiêu theo từng Nhân viên/TVV' }, // Đếm chỉ tiêu theo từng Nhân viên/TVV
        { key: 'PROJECT', value: 'Đếm chỉ tiêu theo từng dự án' }, // Đếm chỉ tiêu theo từng dự án
        { key: 'PROPERTY_UNIT', value: 'Đếm chỉ tiêu theo từng sản phẩm' }, // Đếm chỉ tiêu theo từng sản phẩm
        { key: 'PROJECT_LEAD', value: 'Đếm chỉ tiêu theo từng khai thác dự án' }, // Đếm chỉ tiêu theo từng khai thác dự án
        { key: 'TRANSACTION', value: 'Đếm chỉ tiêu theo giao dịch' }, // Đếm chỉ tiêu theo giao dịch
        { key: 'LEAD_MINING', value: 'Đếm chỉ tiêu theo khai thác lead' }, // Đếm chỉ tiêu theo khai thác lead
    ];

    public static InteractTargetTypeDropList = [
        { key: 'CURRENT_USER', value: 'Chỉ có 1 chỉ tiêu (người dùng hiện tại)' }, // Chỉ có 1 chỉ tiêu (người dùng hiện tại)
    ];
}

export enum MissionTypeEnum {
    COMPLETED = 'missionCompleted',
    INPROGRESS = 'missionInProgress',
    FAILED = 'missionFailed',
}

export enum MissionTypeTransEnum {
    missionCompleted = 'Nhiệm vụ đã hoàn thành',
    missionInProgress = 'Nhiệm vụ đang thực hiệ',
    missionFailed = 'Nhiệm vụ hết hạn',
}
export class MissionModel {
    stt: number;
    id: string;
    isActive: boolean;
    name: string;
    iconImgUrl: string;
    type: string;
    startTime: string;
    endTime: string;
    targetType: string;
    totalTarget: number;
    limitPaticipants: number;
    totalCompleted: number;
    totalPaticipants: number;
    description: string;
    events: any[];
    destinationLink: string;
    destinationId: string;
    targetDestinationLink: string;
    targetDestinationId: string;
    eventNavigate: any;
    targetEventNavigate: any;
    isSuccessMissionAll: boolean;
    isInteract: boolean;
    isSendNoti: boolean;
    prizes: MissionPrizeSchema[];


    constructor(params?: any) {
        this.id = params ? params.id || null : null;
        this.iconImgUrl = params ? params.iconImgUrl || null : null;
        this.isActive = params ? params.isActive : true;
        this.name = params ? params.name || null : null;
        this.type = params ? params.type || null : null;
        this.startTime = params ? params.startTime || null : null;
        this.endTime = params ? params.endTime || null : null;
        this.targetType = params ? params.targetType || null : null;
        this.totalTarget = params ? params.totalTarget || 0 : 0;
        this.totalCompleted = params ? params.totalCompleted || 0 : 0;
        this.totalPaticipants = params ? params.totalPaticipants || 0 : 0;
        this.limitPaticipants = params ? params.limitPaticipants || 0 : 0;
        this.description = params ? params.description || null : null;
        this.destinationLink = params ? params.destinationLink || null : null;
        this.destinationId = params ? params.destinationId || null : null;
        this.targetDestinationLink = params ? params.targetDestinationLink || null : null;
        this.targetDestinationId = params ? params.targetDestinationId || null : null;
        this.eventNavigate = params ? params.eventNavigate || null : null;
        this.targetEventNavigate = params ? params.targetEventNavigate || null : null;
        this.events = params ? params.events || [] : [];
        this.isSuccessMissionAll = params ? params.isSuccessMissionAll || false : false;
        this.isInteract = params ? params.isInteract || false : false;
        this.isSendNoti = params ? params.isSendNoti || false : false;
        this.prizes = params ? params.prizes || [] : [];
    }
}

export class MissionPrizeSchema {
    type: string;
    value: number;

    constructor(params?: any) {
        this.type = params ? params.type || null : null;
        this.value = params ? params.value || 0 : 0;
    }
}
export class MissionRankModel {
    stt: number;
    id: string;
    name: string;
    iconImage: string;
    title: string;
    createdDate: string;
    fromPoint: string;
    toPoint: string;
    isActive: boolean;
    levelUpBenefit: any;
    colorLabel: string;


    constructor(params?: any) {
        this.id = params ? params.id || null : null;
        this.name = params ? params.name || null : null;
        this.iconImage = params ? params.iconImage || IconEnum.NEWBIE : IconEnum.NEWBIE;
        this.colorLabel = params ? params.colorLabel || Mission.DEFAULT_COLOR : Mission.DEFAULT_COLOR;
        this.title = params ? params.title || null : null;
        this.createdDate = params && params.createdDate ? DateToFormat.parseDate(params.createdDate, 'dd/MM/yyyy - hh:mm:ss') : '';
        this.fromPoint = params ? params.fromPoint || 0 : 0;
        this.toPoint = params ? params.toPoint || 0 : 0;
        this.isActive = params ? params.isActive : true;
        this.levelUpBenefit = params ? params.levelUpBenefit : null;
    }
}
export class MissionManagementUsersModel {
    stt: number;
    id: string;
    name: string;
    email: string;
    phone: string;
    title: any;
    totalRewardPoints: Number;
    missionParticipated: UserMission[];
    missionCompleted: UserMission[];
    missionInProgress: UserMission[];
    missionFailed: UserMission[];
    rank: Number;
    avatar: string;
    avatarThumbnailUrl: string;


    constructor(params?: any) {
        this.id = params ? params.id || null : null;
        this.name = params ? params.name || null : null;
        this.phone = params ? params.phone || null : null;
        this.email = params ? params.email || null : null;
        this.title = params ? params.title || null : null;
        this.totalRewardPoints = params ? params.totalRewardPoints || 0 : 0;
        this.missionCompleted = params && params.missionCompleted && params.missionCompleted.length ? params.missionCompleted.map(mission => {
            mission.type = MissionTypeEnum.COMPLETED
            return mission; 
        }) || [] : [];
        this.missionInProgress = params && params.missionInProgress && params.missionInProgress.length ? params.missionInProgress.map(mission => {
            mission.type = MissionTypeEnum.INPROGRESS
            return mission; 
        }) || [] : [];
        this.missionFailed = params && params.missionFailed && params.missionFailed.length ? params.missionFailed.map(mission => {
            mission.type = MissionTypeEnum.FAILED
            return mission; 
        }) || [] : [];
        this.rank = params ? params.rank || 0 : 0;
        this.avatar = params ? params.avatar || null : null;
        this.avatarThumbnailUrl = params ? params.avatarThumbnailUrl || null : null;

        this.missionParticipated = this.missionCompleted.concat(this.missionInProgress, this.missionFailed);
    }
}

export class  UserMission {
    id: string;
    name: string;
    point: Number;

    constructor(params?: any) {
        this.id = params ? params.id || null : null;
        this.name = params ? params.name || null : null;
        this.point = params ? params.point || 0 : 0;
    }
}

export class MissionAppEventModel {
    stt: number;
    id: string;
    description: string;
    queue: string;
    name: string;
    createdDate: string;
    modifiedDate: string;

    constructor(params?: any) {
        this.id = params ? params.id || '' : '';
        this.name = params ? params.name || '' : '';
        this.description = params ? params.description || '' : '';
        this.queue = params ? params.queue || '' : '';
        this.createdDate = params && params.createdDate ? params.createdDate : '';
        this.modifiedDate = params && params.modifiedDate ? params.modifiedDate : '';
    }
}
