import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioGroupComponent } from './radio-group.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    RadioGroupComponent,
    RadioButtonComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    RadioGroupComponent,
    RadioButtonComponent
  ]
})
export class RadioGroupModule { }
