import { IDecode } from './i-decode';

export class CAddress implements IDecode<CAddress> {

    public address: string;
    public ward: string;
    public district: number;
    public province: string;

    constructor(params: Partial<CAddress> = {}) {
        this.copy(params);
    }

    public get fullAddress(): string {
        return `${this.address ? this.address : ''} ${this.ward ? this.ward : ''} ${this.district ? this.district : ''} ${this.province ? this.province : ''}`;
    }

    copy(params: Partial<CAddress> = {}): CAddress {
        params = params || {};

        this.address = params.address || null;
        this.ward = params.ward || null;
        this.district = params.district || null;
        this.province = params.province || null;
        return this;
    }

    decode(paramsApi: any): CAddress {
        return new CAddress(paramsApi);
    }

    decodeList(paramsApi: any[]): CAddress[] {
        return paramsApi.map(el => new CAddress().decode(el));
    }
}
