<div class="main-popup-property">
  <div class="main-popup-property__header" fxLayout="row" fxLayoutAlign="end center">
    <span class="close-btn" (click)="onClose(false)">
      <mat-icon>close</mat-icon>
    </span>
  </div>
  <div class="main-content" fxLayout="column" fxLayoutGap="6px">
    <div class="icon" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px">
      <img src="assets/img/icon/notification.svg" width="87px">
      <div class="main-content__info">Cập nhật tính năng mới</div>
    </div>
    <div class="container" fxLayout="column">
      <div> Hệ thống vừa cập nhật chức năng  
        <span class="bold">
          Trung tâm hỗ trợ
        </span>
        để hỗ trợ thông tin cho người dùng. Để xem tính năng mới, thực hiện các bước sau: 
      </div>
      <!-- <div class="bold mt-12">1/ Đưa các nút chức năng vào chi tiết phiếu yêu cầu.</div>
      <div>Để đảm bảo chính xác trong quá trình kiểm duyệt thông tin, việc thực hiện các hành động: Gửi Email, Tải file, Duyệt, Từ chối YCDCH/ YCDCO:</div> -->

      <br>

      <div> <span class="bold"> - Bước 1: </span> Click chọn icon  
        <img style="width: 18px; margin: 0 6px;" src="assets/img/dxres/icon/it-helpdesk-2.svg">  
        trên thanh header của trang. </div>
      <div> <span class="bold"> - Bước 2: </span> Nhấp chọn. Hệ thống sẽ đưa bạn đến trang đích thông qua đường dẫn.</div>
      <div> <span class="bold"> - Bước 3: </span> Sau khi truy cập vào trang Trung Tâm hỗ trợ, chọn và xem thông tin mong muốn bằng cách click chọn vào menu tương ứng.</div>
       
      <!-- <div class="bold">2/ Chức năng kéo canh chỉnh các cột.</div>
      <div>Thực hiện canh chỉnh các cột hiển thị trong bảng hiển thị cho phù hợp với chế độ xem cá nhân bằng cách :</div>
      <div>- Bước 1: Đặt con trỏ tại tiêu đề của cột, xuất hiện mũi tên <span><img src="assets/img/icon/_arrow.svg"></span></div>
      <div>- Bước 2: Giữ chuột và kéo cột qua trái/ phải để canh chỉnh theo mong muốn.</div> -->
    </div>

    <br>

    <div class="check-box">
      <app-checkbox-button [(ngModel)]="updateFeature" (change)="onChangeViewAll()" style="min-height: unset;">
        Không hiển thị lại lần sau
      </app-checkbox-button>
    </div>
    <div class="main-content__action" fxLayoutAlign="center center">
      <div class="action-btn btn-blue" (click)="onClose(true)"> Đã hiểu</div>
    </div>
  </div>
</div>
