import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { environment } from "environments/environment";
import { DialogJoinComponent } from "../dialog-join/dialog-join.component";
import { LandingPageEsalekitModel } from "../landing-page.model";
import { LandingPageService } from "../landing-page.service";
@Component({
  selector: "app-landing-esalekit",
  templateUrl: "./landing-esalekit.component.html",
  styleUrls: ["./landing-esalekit.component.scss"],
})
export class LandingEsalekitComponent implements OnInit {
  model: LandingPageEsalekitModel = new LandingPageEsalekitModel();
  id: string = "";
  checkDataEsalekit: boolean = false;
  srcFolderIcon = '../../../../assets/img/icon/ic_logo_mini.png';
  srcFolderVideoIcon = '../../../../assets/img/icon/ic_video_salekit.png';
  srcFolderImageIcon = '../../../../assets/img/icon/ic_image_project.png';
  bannerUrlDemo = '../../../../assets/img/landing/bannerDemo.png';
  icon360 = '../../../../assets/img/landing/icon360.svg';
  
  viewerOptions: any = {
    navbar: false,
    toolbar: {},
  };
  coordinates: any;
  toggleHoverCss: { 'banner360Hover': any; };

  constructor(
    public route: ActivatedRoute,
    private service: LandingPageService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    // Router subscribe
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get("id");
    });
    const params = this.route.snapshot.params;
    const id = params["id"];
    if (id) {
      this.service.getEsalekitById(id).subscribe({
        next: (res: any) => {
          this.checkDataEsalekit = true
          this.model = new LandingPageEsalekitModel(res);
          this.coordinates = this.model.project && this.model.project.location && this.model.project.location.coordinates ? this.model.project.location.coordinates : null;
          this.toggleHoverCss = {
            'banner360Hover': this.model.project.url360
         }
        },
        error: (err) => {
        }
      });
    } else {
      this.model = new LandingPageEsalekitModel();
    }
  }

  openDialog() {
    const dialog = this.dialog.open(DialogJoinComponent, {
      width: '800px',
      data: {
        shareUrl: this.model.shareUrl,
      }
    });
    dialog.afterClosed().subscribe(result => { });
  }
  url360Clicked() {
    if(this.model.project.url360) {
      window.open(this.model.project.url360, '_blank')
    }
  }
}
