export class ContractReport {

    id: string;
    name: string;
    data: any[];
    total: any;

    constructor(
        param?: any
    ) {
      this.setValue(param);
    }

    setValue(params: any) {
        this.id = params ? params.id || null : null;
        this.name = params ? params.name || null : null;
        this.data = params ? params.data || [] : [];
        let totalCountYcdch = 0;
        let totalCountYcdc = 0;
        this.data.forEach(v => { totalCountYcdch += v.countYcdch; totalCountYcdc += v.countYcdc });
        this.total = { totalCountYcdch, totalCountYcdc };
    }
}
