<div fxLayout="row" fxLayoutAlign="center center" *ngIf="!pos">
    <span>Nhân viên không thuộc trung tâm giao dịch</span>
</div>
<div *ngIf="pos">
    <!-- <custom-table [tableProperty]="tableProperty" [ENTITY]="'EMPLOYEE'"></custom-table> -->
    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxFlex="100">
        <div fxLayout="row" class="m-table m-elevation-z8" [ngClass]="list?.length == 0 && 'hidden'">
            <mat-form-field class="p-1">
                <input matInput placeholder="Tìm kiếm" [(ngModel)]="valueSearch"
                    (ngModelChange)="searchEmployee(valueSearch, list)">
            </mat-form-field>
        </div>
        <div class="m-table m-elevation-z8" [ngClass]="list?.length == 0 && 'hidden'">
            <div class="m-header-row">
                <div class="m-header-cell pointer">
                    Code
                </div>
                <div class="m-header-cell pointer">
                    Tên
                </div>
                <div class="m-header-cell pointer">
                    Email
                </div>
                <div class="m-header-cell m-075 pointer">
                    Điện thoại
                </div>
                <!-- <div class="m-header-cell pointer">
                    Nơi làm việc
                </div> -->
                <div class="m-header-cell m-075 pointer">
                    Chức vụ
                </div>
                <div class="m-header-cell pointer m-05">
                    Hành động
                </div>
            </div>

            <div class="m-row" *ngFor="let item of list">
                <div class="m-cell">
                    <div fxLayout="column" *ngIf="item.code">
                        <span>{{item.code}}</span>
                    </div>
                </div>
                <div class="m-cell">
                    <div fxLayout="column" *ngIf="item.name">
                        <span>{{item.name}}</span>
                    </div>
                </div>

                <div class="m-cell ">
                    <div fxLayout="column">
                        <span>{{item.email}}</span>
                    </div>
                </div>
                <div class="m-cell m-075">
                    <div fxLayout="column" fxLayoutAlign="left center">
                        <span>{{item.phone}}</span>
                    </div>
                </div>
                <!-- <div class="m-cell">
                    <div fxLayout="column">
                        <span>{{item.pos?.name}}</span>
                    </div>
                </div> -->
                <div class="m-cell m-075">
                    <div fxLayout="column">
                        <span>{{employeeCompetence(item)}}</span>
                    </div>
                </div>
                <div class="m-cell m-05 pointer">
                    <div fxLayout="row">
                        <button mat-raised-button class="m-bg button-edit" *userAuthority="['EMPLOYEE','UPDATE', 'INFO']"
                        (click)="goToDetail(item.id)">
                                <span>Sửa</span>
                        </button>
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="left center">
                <paging #paging (itemsPerPageChanged)="changeItemPerPage($event)" (pageChanged)="changePage($event)" [selectedPage]="currentPage"></paging>
            </div>
        </div>
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center"
            [ngClass]="list && list?.length > 0 && 'hidden'">
            <span style="color:rgb(89, 103, 128);">Không có nhân viên trong trung tâm giao dịch</span>
        </div>
    </div>
</div>
<div *ngIf="tableProperty.isLoading" fxLayout="column" fxLayoutAlign="center center" class="user-spinner">
    <mat-spinner color="primary"></mat-spinner>
</div>
