import { Injectable } from '@angular/core';
import { Http, Response, RequestOptionsArgs, RequestMethod, Headers, Request, RequestOptions, ResponseContentType } from '@angular/http';
import { HttpClient } from '@angular/common/http';

import 'rxjs/Rx';
// import { Observable } from 'rxjs/Observable';
import { Observable, throwError } from 'rxjs';
import { retry, map, catchError } from 'rxjs/operators';

import 'rxjs/add/observable/throw';
import { Observer } from 'rxjs/Observer';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { SecurityService } from './security.service';

import { Guid } from '../../../guid';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { ConstantUrl } from '../constant/url';
import { ToastrService } from './common';
import { isNullOrUndefined } from 'util';
import { Constant } from '../constant/constant';
import { FileSaverService } from 'ngx-filesaver';
import { ERROR_KEY_MESSAGE } from '../constant/error.message';
import * as es6printJS from "print-js";
import printJS = require("print-js");
import {environment} from '../../../environments/environment';

// Implementing a Retry-Circuit breaker policy
// is pending to do for the SPA app
@Injectable()
export class DataService {

    singleAPIs: any = {};
    queues: any = [];
    constructor(
        private http: Http,
        private httpClient: HttpClient,
        private securityService: SecurityService,
        private storageService: StorageService,
        private router: Router,
        private storage: StorageService,
        private toastrService: ToastrService,
        private _FileSaverService: FileSaverService) { }


    get(url: string, params?: any, headers?: any, noHeader?): Observable<any> {
        const options: RequestOptionsArgs = {};
        options.headers = new Headers();
        options.headers.append('timezoneclient', 'Asia/Ho_Chi_Minh');
        if (this.securityService) {
            options.headers.append('Authorization', 'Bearer ' + this.securityService.GetToken());
            if (headers && headers.act) {
                options.headers.append('act', headers.act);
            }
        }

        const guid = Guid.newGuid();
        options.headers.append('x-requestid', guid);
        if (noHeader) {
            options.headers = new Headers();
        }
        if (params) {
            options.params = params;
        }
        return this.http.get(url, options)
            .pipe(
                // retry(3), // retry a failed request up to 3 times
                // map((res: Response) => {
                //     return res;
                // }),
                // catchError(this.handleError)
                map((res: Response) => {
                    let data = res['_body'] ? res.json() : null;
                    this.storage.setAPIQuery(url, data);
                    return data;
                })
            )
            // .timeout(15000)
            .catch((error: any) => {
                this.handleError(error);
                throw Observable.throw(error);
            })
    }
    getAgent(url: string, token: any): Observable<any> {
        let options: RequestOptionsArgs = {
            headers: new Headers()
        };
        options.headers.append('X-STRINGEE-AUTH', token);
        return this.http.get(url, options)
            .pipe(
                map((res: Response) => {
                    return res.json();
                })
            );
    }
    getWithHeaders(url: string, headers: any, params?: any,): Observable<any> {
        const options: RequestOptionsArgs = {};
        options.headers = new Headers();
        options.headers.append('timezoneclient', 'Asia/Ho_Chi_Minh');
        for (let property in headers) {
            options.headers.append(property, headers[property]);
        }
        if (params) {
            options.params = params;
        }
        return this.http.get(url, options)
            .pipe(
                // retry(3), // retry a failed request up to 3 times
                // map((res: Response) => {
                //     return res;
                // }),
                // catchError(this.handleError)
                map((res: Response) => {
                    let data = res.json();
                    this.storage.setAPIQuery(url, data);
                    return data;
                })
            )
            // .timeout(15000)
            .catch((error: any) => {
                this.handleError(error);
                throw Observable.throw(error);
            })
    }

    dowloadFile(url, data, fileName?, header?): Observable<any> {
        let headers = new Headers();
        headers.append('timezoneclient', 'Asia/Ho_Chi_Minh');
        headers.append('Content-Type', 'application/json');
        if (this.securityService) {
            headers.append('Authorization', 'Bearer ' + this.securityService.GetToken());
        }
        let requestOptions: any = new RequestOptions({
            method: 'GET',
            url,
            headers: header ? header : headers,
            body: {},
            params: data || {},
        });
        requestOptions.responseType = ResponseContentType.Blob;

        //Call API request
        return this.http.request(new Request(requestOptions))
            /**
             * function when response success
             * @params res : Response
             * return Object
             * */
            .timeout(Constant.const_timeout_dowload_file)
            .map((res: Response) => {
                res.headers.toJSON();
                this._FileSaverService.save((<any>res)._body, fileName || res.headers.get('Filename'));
            })
            /**
             * function handle when response fail
             * @params error : Response
             * */
            .catch((error: any) => {
                let blob = new Blob([(<any>error)._body], { type: 'application/json' });
                let downloadUrl = URL.createObjectURL(blob);
                return Observable.throw({ errorExport: downloadUrl });
            });
    }

    dowloadFile2(url, data, fileName?): Observable<any> {
        let headers = new Headers();
        headers.append('timezoneclient', 'Asia/Ho_Chi_Minh');
        headers.append('Content-Type', 'application/json');
        headers.append('X-Client-Code', environment.xClientCode);
        if (this.securityService) {
            headers.append('Authorization', 'Bearer ' + this.securityService.GetToken());
        }
        let requestOptions: any = new RequestOptions({
            method: 'GET',
            url,
            headers: headers,
            body: {},
            params: data || {},
        });
        requestOptions.responseType = ResponseContentType.Blob;

        // Call API request
        return this.http.request(new Request(requestOptions))
            /**
             * function when response success
             * @params res : Response
             * return Object
             * */
            .timeout(Constant.const_timeout_dowload_file)
            .map((res: Response) => {
                this._FileSaverService.save((<any>res)._body, fileName || res.headers.get('filename'));
            })
            /**
             * function handle when response fail
             * @params error : Response
             * */
            .catch((error: any) => {
                return Observable.throw({ errorExport: error });
            });
    }

    printFilePdf(url, data): Observable<any> {
        let headers = new Headers();
        headers.append('timezoneclient', 'Asia/Ho_Chi_Minh');
        headers.append('Content-Type', 'application/json');
        if (this.securityService) {
            headers.append('Authorization', 'Bearer ' + this.securityService.GetToken());
        }
        let requestOptions: any = new RequestOptions({
            method: 'GET',
            url,
            headers: headers,
            body: {},
            params: data || {},
        });
        requestOptions.responseType = ResponseContentType.Blob;
        //Call API request
        return this.http.request(new Request(requestOptions))
            /**
             * function when response success
             * @params res : Response
             * return Object
             * */
            .timeout(Constant.const_timeout_dowload_file)
            .map((res: Response) => {
                var reader = new FileReader();
                reader.readAsDataURL(res.blob());
                reader.onloadend = function () {
                    var base64String = reader.result.toString().split(',').pop();
                    printJS({ printable: base64String, type: 'pdf', base64: true })
                }
            })
            /**
             * function handle when response fail
             * @params error : Response
             * */
            .catch((error: any) => {
                let blob = new Blob([(<any>error)._body], { type: 'application/json' });
                let downloadUrl = URL.createObjectURL(blob);
                return Observable.throw({ errorExport: downloadUrl });
            });
    }
    getFileFromUrl(url): Observable<any> {
        let headers = new Headers();
        let requestOptions: any = new RequestOptions({
            method: 'GET',
            url,
            headers: headers,
            body: {},
        });
        requestOptions.responseType = ResponseContentType.Blob;
        //Call API request
        return this.http.request(new Request(requestOptions))
            /**
             * function when response success
             * @params res : Response
             * return Object
             * */
            .timeout(Constant.const_timeout_dowload_file)
            .map((res: any) => {
                let blob = new Blob([(<any>res)._body], { type: 'application/json' });
                return new File([blob], "image.xlsx");
            })
            /**
             * function handle when response fail
             * @params error : Response
             * */
            .catch((error: any) => {
                let blob = new Blob([(<any>error)._body], { type: 'application/json' });
                let downloadUrl = URL.createObjectURL(blob);
                return Observable.throw({ errorExport: downloadUrl });
            });
    }
    getText(url): Observable<any> {
        let headers = new Headers();
        let requestOptions: any = new RequestOptions({
            method: 'GET',
            url,
            headers: headers,
            body: {},
        });
        requestOptions.responseType = ResponseContentType.Text;
        //Call API request
        return this.http.request(new Request(requestOptions))
            /**
             * function when response success
             * @params res : Response
             * return Object
             * */
            .timeout(Constant.const_timeout_dowload_file)
            .map((res: any) => {
                return (<any>res)._body;
            })
            /**
             * function handle when response fail
             * @params error : Response
             * */
            .catch((error: any) => {

                return Observable.throw({ errorExport: 'downloadUrl' });
            });
    }
    postWithId(url: string, data: any, params?: any): Observable<Response> {
        return this.doPost(url, data, true, params);
    }
    trimData(object) {
        for (let property in object) {
            //   if (typeof object[property] === 'object') {
            //     object[property] = this.trimData(object[property])
            //   } else
            if (typeof object[property] === 'string' && object[property]) {
                object[property] = object[property].trim();
            }
        }
        return object;
    }
    post(url: string, data: any, headers?: any, timeout?: number, error: boolean = false, isThrowError: boolean = false): Observable<Response> {
        // const options: RequestOptionsArgs = {};
        // options.headers = new Headers();
        // options.headers.append('timezoneclient', 'Asia/Ho_Chi_Minh');
        data = this.trimData(data);
        let getQuery = url.replace('domain', 'query');
        let api = this.storage.getAPIQuery(getQuery);
        if (api) {
            this.storage.setAPIQuery(getQuery, null);
        }
        if (url.includes('setUserPermission')) {
            let api3 = this.storage.getAPIQuery(ConstantUrl.url_user_query + '/' + data.userId);
            if (api3) {
                this.storage.setAPIQuery(ConstantUrl.url_user_query + '/' + data.userId, null);
            }
        }

        // if (headers && headers.act) {
        //     options.headers.append('act', headers.act);
        // }

        return this.doPost(url, data, headers, false, timeout, error, isThrowError);
    }
    import(url: string, data: any): Observable<Response> {
        return this.post(url, data, null, 10000, true);
    }

    put(url: string, data: any, headers?: any): Observable<Response> {
        let getQuery = url.replace('domain', 'query');
        let getIdQuery = getQuery + '/' + data.id;
        data = this.trimData(data);
        let api1 = this.storage.getAPIQuery(getQuery);
        let api2 = this.storage.getAPIQuery(getIdQuery);
        if (api1) {
            this.storage.setAPIQuery(getQuery, null);
        }
        if (api2) {
            this.storage.setAPIQuery(getIdQuery, null);
        }
        return this.doPut(url, data, headers);
    }

    private doPost(url: string, data: any, headers?: any, needId?: boolean, timeout?: number, getError: boolean = false, isThrowError: boolean = false): Observable<Response> {

        const options: RequestOptionsArgs = {};
        options.headers = new Headers();
        options.headers.append('timezoneclient', 'Asia/Ho_Chi_Minh');
        if (this.securityService && this.securityService.GetToken()) {
            options.headers.append('Authorization', 'Bearer ' + this.securityService.GetToken());
        }
        if (headers) {
            for (let property in headers) {
                if (property != 'act') {
                    options.headers.append(property, headers[property]);
                }
            }
        }
        let date = Date.now() + '';
        this.storageService.store('timestamp', date);
        this.storageService.store('url', url);
        options.headers.append('act', url + date);
        if (needId) {
            const guid = Guid.newGuid();
            options.headers.append('x-requestid', guid);
        }
        return this.http.post(url, data, options)
            .map((res: Response) => {
                return res;
            })
            .timeout(timeout ? timeout : 15000)
            .catch((error: any) => {
                this.handleError(error);
                if (getError) {
                    return Observable.of(error);
                }
                return throwError(error);

            });
    }

    private doPut(url: string, data: any, headers?: any, needId?: boolean): Observable<Response> {
        const options: RequestOptionsArgs = {};
        options.headers = new Headers();
        options.headers.append('timezoneclient', 'Asia/Ho_Chi_Minh');
        if (this.securityService && this.securityService.GetToken()) {
            options.headers.append('Authorization', 'Bearer ' + this.securityService.GetToken());

        }
        if (needId) {
            const guid = Guid.newGuid();
            options.headers.append('x-requestid', guid);
        }

        // if (headers && headers.act) {
        //     options.headers.append('act', headers.act);
        // }
        // if (headers && headers.stage) {
        //     options.headers.append('stage', headers.stage);
        // }
        if (headers) {
            for (let property in headers) {
                if (property != 'act') {
                    options.headers.append(property, headers[property]);
                }
            }
        }
        let date = Date.now() + '';
        this.storageService.store('timestamp', date);
        this.storageService.store('url', url);
        options.headers.append('act', url + date);
        return this.http.put(url, data, options).map(
            (res: Response) => {
                return res;
            })
            .timeout(15000)
            .catch((error: any) => {
                this.handleError(error);
                return throwError(error);
            });
    }

    delete(url: string, params?: any, headers?: any): Observable<Response> {
        const options: RequestOptionsArgs = {};

        if (this.securityService) {
            options.headers = new Headers();
            options.headers.append('Authorization', 'Bearer ' + this.securityService.GetToken());
            if (headers && headers.act) {
                options.headers.append('act', headers.act);
            }
        }
        if (params) {
            options.body = params.body;  
        }
        return this.http.delete(url, options).map((res) => {
            return res;
        });
    }

    private handleError(error: any) {
        if (error instanceof Response) {
            switch (error.status) {
                case 400:
                    this.handleMessageError(error);
                    break;
                case 401:
                    this.handleMessageError(error);
                    if (this.router.url.length > 1 && this.router.url !== '/login') {
                        this.storageService.store('callbackUrl', this.router.url);
                    }
                    this.router.navigate(['login']);
                    break;
                case 403:
                    const errorForbidden: any = error;
                    if (errorForbidden._body && typeof errorForbidden._body === 'string') {
                        errorForbidden.body = JSON.parse(errorForbidden._body);
                        if (errorForbidden.body.errorCode && errorForbidden.body.errorCode === 4030001) {
                            this.toastrService.error('Lỗi!', 'Nội dung chứa ký tự $ không được sử dụng, vui lòng kiểm tra lại');
                        } else {
                            this.toastrService.error('Lỗi!', 'Không có quyền truy cập');
                        }
                    } else {
                        this.toastrService.error('Lỗi!', 'Không có quyền truy cập');
                    }
                    break;
                case 404:
                    this.catchMessageError(error);
                    break;
                case 500:
                    this.catchMessageError(error);
                    break;
            }
        }

    }
    catchMessageError(error) {
        let errMessage: any = error.json();
        if (errMessage && errMessage.errors) {
            for (let prop in errMessage.errors) {
                if (errMessage.errors[prop]) {
                    let errorFind = ERROR_KEY_MESSAGE.find(er => er.key === errMessage.errors[prop]);
                    if (prop === 'employee.not.found') {
                        return;
                    }
                    let text = errorFind ? errorFind.value : errMessage.errors[prop];
                    this.toastrService.error('Lỗi!', text);
                }
            }
        }
    }
    handleMessageError(error) {
        try {
            // has.been.made.already.error
            // return error;
            let errMessage: any = '';
            errMessage = error.json().message;
            // errMessage = error.json();
            if (errMessage) {
                if (typeof errMessage !== 'string' && errMessage.message) {
                    errMessage = errMessage.message;
                    if (errMessage.message && errMessage.message.length > 0) {
                        return Observable.throw(error);
                    } else {
                        errMessage = errMessage.replace(/_/g, ' ');
                    }
                    this.toastrService.error('Lỗi!', errMessage);
                } else if (typeof errMessage === 'string' && errMessage.length > 0) {
                    this.toastrService.error('Lỗi!', errMessage);
                } else if (errMessage.err && errMessage.err.length > 0) {
                    errMessage = errMessage.err[0].message;
                    this.toastrService.error('Lỗi!', errMessage);
                }
            } else {
                errMessage = error.json();
                if (errMessage && errMessage.errors) {
                    if (typeof errMessage.errors === 'object') {
                        {
                            for (let prop in errMessage.errors) {
                                if (errMessage.errors[prop]) {
                                    let errorFind = ERROR_KEY_MESSAGE.find(er => er.key === errMessage.errors[prop]);
                                    let text = errorFind ? errorFind.value : errMessage.errors[prop];
                                    this.toastrService.error('Lỗi!', text);
                                }
                            }
                        }
                    } else {
                        this.toastrService.error('Lỗi!', errMessage.errors.toString());
                    }
                }
            }

            return Observable.throw(error);
        }
        catch (exp) {
            let errMessage: any = '';
            errMessage = error.json();
        }
    }

    postV2(url: string, data: any, headers?: any, timeout?: number, error: boolean = false): Observable<Response> {
        // const options: RequestOptionsArgs = {};
        // options.headers = new Headers();
        // options.headers.append('timezoneclient', 'Asia/Ho_Chi_Minh');
        data = this.trimData(data);
        let getQuery = url.replace('domain', 'query');
        let api = this.storage.getAPIQuery(getQuery);
        if (api) {
            this.storage.setAPIQuery(getQuery, null);
        }
        if (url.includes('setUserPermission')) {
            let api3 = this.storage.getAPIQuery(ConstantUrl.url_user_query + '/' + data.userId);
            if (api3) {
                this.storage.setAPIQuery(ConstantUrl.url_user_query + '/' + data.userId, null);
            }
        }

        // if (headers && headers.act) {
        //     options.headers.append('act', headers.act);
        // }

        return this.doPostV2(url, data, headers, false, timeout, error);
    }

    private doPostV2(url: string, data: any, headers?: any, needId?: boolean, timeout?: number, getError: boolean = false): Observable<Response> {

        const options: RequestOptionsArgs = {};
        options.headers = new Headers();
        options.headers.append('timezoneclient', 'Asia/Ho_Chi_Minh');
        if (this.securityService && this.securityService.GetToken()) {
            options.headers.append('Authorization', 'Bearer ' + this.securityService.GetToken());
        }
        if (headers) {
            for (let property in headers) {
                if (property != 'act') {
                    options.headers.append(property, headers[property]);
                }
            }
        }
        let date = Date.now() + '';
        this.storageService.store('timestamp', date);
        this.storageService.store('url', url);
        options.headers.append('act', url + date);
        if (needId) {
            const guid = Guid.newGuid();
            options.headers.append('x-requestid', guid);
        }
        return this.http.post(url, data, options)
            .map((res: Response) => {
                return res.json();
            })
            .timeout(timeout ? timeout : 15000)
            .catch((error: any) => {
                this.handleError(error);
                //return throwError(error);
                if (getError) {
                    return Observable.of(error);
                }

            });
    }

    postCustomToken(url: string, data: any, token: string): Observable<Response> {

        const options: RequestOptionsArgs = {};
        options.headers = new Headers();
        options.headers.append('timezoneclient', 'Asia/Ho_Chi_Minh');
        options.headers.append('Authorization', 'Bearer ' + token);
        let date = Date.now() + '';
        this.storageService.store('timestamp', date);
        this.storageService.store('url', url);
        options.headers.append('act', url + date);
        data = this.trimData(data);
        return this.http.post(url, data, options)
            .map((res: Response) => {
                return res;
            })
            .catch((error: any) => {
                this.handleError(error);
                //return throwError(error);
                return Observable.of(error);

            });
    }
    
    putCustomToken(url: string, data: any, token: string): Observable<Response> {
        const options: RequestOptionsArgs = {};
        options.headers = new Headers();
        options.headers.append('timezoneclient', 'Asia/Ho_Chi_Minh');
        options.headers.append('Authorization', 'Bearer ' + token);

        data = this.trimData(data);
        let date = Date.now() + '';
        this.storageService.store('timestamp', date);
        this.storageService.store('url', url);
        options.headers.append('act', url + date);
        return this.http.put(url, data, options).map(
            (res: Response) => {
                return res;
            })
            .timeout(15000)
            .catch((error: any) => {
                this.handleError(error);
                return throwError(error);
            });
    }
    // private handleError(error: any) {
    //     if (error.error instanceof ErrorEvent) {
    //         // A client-side or network error occurred. Handle it accordingly.
    //         console.error('Client side network error occurred:', error.error.message);
    //     } else {
    //         // The backend returned an unsuccessful response code.
    //         // The response body may contain clues as to what went wrong,
    //         console.error('Backend - ' +
    //             `status: ${error.status}, ` +
    //             `statusText: ${error.statusText}, ` +
    //             `message: ${error.error.message}`);
    //     }

    //     // return an observable with a user-facing error message
    //     return throwError(error || 'server error');
    // }
}
