<div fxLayout="row" fxLayoutAlign="end" style="margin-bottom: 10px;">
  <mat-icon (click)="onNoClick()" style="font-size: 2rem; color: black; cursor: pointer;">clear</mat-icon>
</div>
<div fxLayout="row" fxLayoutAlign="center" style="margin-bottom: 50px;">
    <mat-icon style="    font-size: 100px;
    color: green;
    margin-right: 70px;
    margin-bottom: 30px;">done</mat-icon>
</div>
<div mat-dialog-content style="margin-bottom: 30px;">
  <div fxLayout="row" fxLayoutAlign="space-around center">
      <span style="color: green; font-weight: bold; text-align: center;">{{data.text}}</span>
  </div>
</div>