<div class="landing_page">
    <app-landing-header (openDialog)="openDialog()"></app-landing-header>
    <div class="landing_page-body" [ngSwitch]="checkDataEvent">
        <img src="../../../../assets/img/landing/abstract.svg" alt="landingAbstract">
        <div class="landing_page-wrapper" *ngSwitchCase="true">
            <div class="landing_page-content">
                <div class="header">
                    <h1>{{model.title}}</h1>
                    <div class="live" *ngIf="model.liveStream.liveStreamStatus === 'STARTED'">
                        <span class="icon"></span>
                        <span>Live</span>
                    </div>
                </div>
                <div class="infoGroup">
                    <div class="info">
                        <img src="../../../../assets/img/landing/calendal.svg" alt="info" class="vertical-bot">
                        <span>Diễn ra từ: </span>
                        {{model.startDateTime}} <span>đến</span>{{model.endDateTime}}
                    </div>
                    <div class="info">
                        <img src="../../../../assets/img/landing/address.svg" alt="info" class="vertical-bot">
                        <span>Địa điểm: </span>
                        {{model.address}}
                    </div>
                </div>
                <!-- Image-event -->
                <img [src]="model.imageUrl" onError="this.src = './assets/img/landing/Image-event.png'" alt="content" class="img-content-cover" ngxViewer [viewerOptions]="viewerOptions">
                <div class="btn-wrap btn-landing">
                    <button mat-raised-button (click)="openDialog()">
                        <img class="img-btn" src="../../../../assets/img/landing/login.svg" alt="action">
                        <span class="txt-btn">Đăng ký</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="landing_page-wrapper-empty" *ngSwitchCase="false" >
            <div class="landing_page-content">
                <div fxLayout="column"
                    fxLayoutAlign="start center"
                    fxLayoutGap="12px"
                >                
                    <img src="../../../../assets/img/landing/Group404.svg" alt="landingAbstract"> 
                    <div class="infoGroup" fxLayoutAlign="center center">
                        <div fxLayoutAlign="center center" fxLayoutGap="5px">
                            <span class="text-er">Xin lỗi! Bạn không thể xem được sự kiện này</span>
                        </div>                 
                    </div>   
            </div>
        </div>
    </div>
    <div class="landing_page-intro-wrapper">
        <div class="landing_page-intro" fxLayout="row" fxLayoutGap="60px">
            <div fxLayout="column" fxFlex="50" class="landing_page-intro-img">
                <img src="../../../../assets/img/landing/Mockup.png" alt="Mockup">
            </div>
            <div fxLayout="column" fxFlex="50" class="landing_page-intro-content">
                <div class="intro-title">
                    <h1>QUẢNG BÁ SẢN PHẨM</h1>
                    <h1 class="text-primary">THAM GIA TALKSHOW VỚI TÍNH NĂNG SỰ KIỆN</h1>
                </div>
                <div class="intro-content">
                    <p>Dễ dàng tham gia sự kiện quảng bá sản phẩm, talkshow trao đổi kinh nghiệm với 2 hình thức linh hoạt trực tuyến hoặc ngoại tuyến.</p>
                    <p>Nâng cao trải nghiệm các sự kiện qua toàn bộ giải pháp công nghệ về môi giới bất động sản trên ứng dụng Propcom của chúng tôi — dù đó là sự kiện miễn phí hoặc có phí.</p>
                </div>
                <div class="intro-button btn-landing">
                    <button mat-raised-button (click)="openDialog()">Tải ứng dụng</button>
                </div>
            </div>
        </div>
        <img src="../../../../assets/img/landing/abstract.png" alt="abstractFooter">
    </div>
    <app-landing-footer [shareUrl]="model.shareUrl"></app-landing-footer>
</div>