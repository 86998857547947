import { Pipe, PipeTransform } from '@angular/core';
import { Constant } from '../constant/constant';
@Pipe({ name: 'type' })
export class TransformTypeTransaction implements PipeTransform {
  static transform(value: string) {
    return this.parse(value);
  }
  private static parse(typeInput) {
    if(!typeInput) return '';
    let text = null;
    let type = typeInput.toUpperCase();
    text = Constant.LEAD_SOURCE[type];
    if (text) { return text; }
    switch (type) {
      case 'BUY':
        text = 'Mua';
        break;
      case 'SELL':
        text = 'Bán';
        break;
      case 'RENT':
        text = 'Thuê';
        break;
      case 'LEASE':
        text = 'Cho thuê';
        break;
      case 'INITIAL':
        text = 'Chờ duyệt';
        break;
      case 'PROCESS':
        text = 'Đang khảo sát';
        break;
      case 'ONTRADING':
        text = 'Đang giao dịch';
        break;
      case 'DONE':
        text = 'Đã xong';
        break;
      case 'PENDING':
      case 'WAITING':
        text = 'Chờ duyệt';
        break;
      case 'APPROVE':
      case 'APPROVED':
        text = 'Đã duyệt';
        break;
      case 'MODIFY':
        text = 'Đang khảo sát';
        break;
      case 'EDIT':
        text = 'Đã chỉnh sửa';
        break;
      case 'EXTEND':
        text = 'Đã gia hạn';
        break;
      case 'LIQUIDATE':
        text = 'Đang thanh lý';
        break;
      case 'SIGNED':
        text = 'Đã ký';
        break;
      case 'REJECT':
      case 'REJECTED':
        text = 'Đã từ chối';
        break;
      case 'NOT_MINING':
        text = 'N/A';
        break;
      case 'WORKING':
        text = 'Đang hoạt động';
        break;
      case 'WAITING.WORKING':
        text = 'Chờ duyệt hoạt động';
        break;
      case 'WAITING.STOP':
        text = 'Chờ duyệt ngừng hoạt động';
        break;
      case 'WAITING.APPROVE':
        text = 'Chờ duyệt';
        break;
      case 'INIT':
        text = 'Khởi tạo';
        break;
      case 'STOP':
        text = 'Ngừng hoạt động';
        break;
      case 'REQUEST':
        text = 'Gửi yêu cầu';
        break;
      case 'RECEIVED':
        text = 'Đã nhận';
        break;
      case 'RECEIPT':
        text = 'Có biên lai';
        break;
      case 'RESERV':
        text = 'Đã đặt chỗ';
        break;
      case 'DEPOSIT':
        text = 'Đã đặt cọc';
        break;
      case 'LOCK':
        text = 'Đã khóa';
        break;
      case 'PRICE':
        text = 'Giá';
        break;
      case 'AREA':
        text = 'Diện tích';
        break;
      case 'DIRECTION':
        text = 'Hướng';
        break;
      case 'LOCATION':
        text = 'Địa chỉ';
        break;
      case 'SURVEY':
        text = 'Khảo sát';
        break;
      case 'TRADING':
        text = 'Đang giao dịch';
        break;
      case 'DEALING':
        text = 'Đã đặt chỗ';
        break;
      case 'TRADED':
        text = 'Đã giao dịch';
        break;
      case 'EMPLOYEE':
        text = 'Nhân viên';
        break;
      case 'WEBBROKER':
        text = 'Khách hàng';
        break;
      case 'CASH':
        text = 'Tiền mặt';
        break;
      case 'TRANSFER':
        text = 'Chuyển khoản';
        break;
      case 'CONTRACT':
        text = 'Hợp đồng';
        break;
      case 'ALL':
        text = 'Tất cả';
        break;
      case 'COMPLETED':
        text = 'Hoàn thành';
        break;
      case 'REMOVED':
        text = 'Bị xoá';
        break;
      case 'DELETE':
        text = 'Đã xoá';
        break;
      case 'TẤT CẢ':
        text = 'Tất cả';
        break;
      case 'WAITING_TRANSFER':
        text = 'Chờ duyệt';
        break;
      case 'TRANSFERED':
        text = 'Đã duyệt';
        break;
      case 'PROCESSING':
        text = 'Bị từ chối';
        break;
      case 'ASSIGNED':
        text = 'Đã phân bổ';
        break;
      case 'INPOOL':
        text = 'Chưa phân bổ';
        break;
      case 'ADVISING':
        text = 'Dịch vụ khác';
        break;
      case 'BROKERYEUCAU':
        text = 'Web Broker';
        break;
      case 'MISSING':
        text = 'Cuộc gọi nhỡ';
        break;
      case 'CALLOUT':
        text = 'Cuộc gọi đi';
        break;
      case 'INCOMINGCALL':
        text = 'Cuộc gọi đến';
        break;
      case 'PRIMARY':
        text = 'Tiềm năng';
        break;
      case 'CANCELED':
        text = 'Đã hủy';
        break;
      case 'PAID':
        text = 'Đã thanh toán';
        break;
      case 'REFUNDED':
        text = 'Đã hoàn tiền';
        break;
      case 'REFUND':
        text = 'Hoàn tiền';
        break;
      case 'REFUND_IN_PROGRESS':
        text = 'Đang hoàn tiền';
        break;
      case 'CONSIDERING':
        text = 'Bị duyệt lại';
        break;
      case 'CANCELLED':
        text = 'Đã hủy';
        break;
      case 'WITHDRAWING':
        text = 'Đang rút';
        break;
      case 'HAS_WITHDRAWN':
        text = 'Đã rút';
        break;
      case 'PAID_WAITING_APPROVAL':
        text = 'Đã thanh toán trả góp';
        break;
      case 'UNPAID':
        text = 'Chưa thanh toán';
        break;
      case 'SIGNED_CONTRACT':
        text = 'Đã ký hợp đồng';
        break;
      case 'IDENTIFIED':
        text = 'Đã xác thực';
        break;
      default:
        text = type;
        break;
    }
    return text;
  }

  transform(value: string) {
    return TransformTypeTransaction.parse(value);
  }
}
