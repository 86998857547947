export class CompareConstant {
    public static StatusEnum = 
    {   
        INIT : "init",   
        WAITING : "waiting",   
        APPROVED : "approved", 
        REJECTED : "rejected", 
        CANCELLED : "cancelled" 
    }
    public static StatusNameEnum = 
    {   
        INIT : "Khởi tạo",   
        WAITING : "Chờ duyệt",   
        APPROVED : "Đã duyệt", 
        REJECTED : "Đã từ chối", 
        CANCELLED : "Đã hủy" 
    }
    public static StatusNameConstant = 
    {   
        init : "Khởi tạo",   
        waiting : "Chờ duyệt",   
        approved: "Đã duyệt", 
        rejected : "Đã từ chối", 
        cancelled : "Đã hủy" 
    }

    public static StatusVideoEnum= 
    {
        INIT :"INIT",
        WAITING :"WAITING",
        APPROVED:"APPROVED", 
        REJECTED :"REJECTED", 
        CANCELLED :"CANCELLED" 
    }

    public static VideoStatusEnum = 
    {   
        INIT : "Khởi tạo",   
        WAITING : "Chờ duyệt",   
        APPROVED : "Đã duyệt", 
        REJECTED : "Đã từ chối", 
        CANCELLED : "Đã hủy" 
    }

    public static VideoOwnerEnum = 
    {
        GROUP: "Nhóm",
        LIVESTREAM: "Livestream",
        OTHER: "Khác"
    }
}
