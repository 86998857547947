import { Injectable } from '@angular/core';

import { DataService } from '../../shared/services/data.service';
import { StorageService } from '../../shared/services/storage.service';
import {Subject, Observable} from 'rxjs';

import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import { ConstantUrl } from '../../shared/constant/url';
import { BaseService } from '../../shared/components/base.service';
import {HttpService} from '../../shared/services/http.service';
import { CPagination } from 'app/api-models';
import { ContractReport } from '../../shared/models/contract-report.model';
import {map} from 'rxjs/operators';

@Injectable()
export class ContractService extends BaseService {
    ConstantUrl = ConstantUrl;
    public emitChangeSource = new Subject<any>();
    public emitTable = new Subject<any>();
    constructor(
        public service: DataService,
        private httpService: HttpService,
        private storageService: StorageService
    ) {
        super(service);
        this.domainUrl = ConstantUrl.url_contract_domain + 'contract';
        this.queryUrl = ConstantUrl.url_contract_query + 'contract';
    }
    get(params?) {
        const url = this.queryUrl;
        return this.httpService.get(url, params ? params : null);
    }
    update(contract, headers) {
        return this.service.post(this.domainUrl, contract, headers);
    }
    executeAmendment(amendment, headers) {
        return this.service.post(this.domainUrl, amendment, headers);
    }
    insertCustomer(customer) {
        const url = ConstantUrl.url_property_domain + 'propertyCustomer';
        return this.service.post(url, customer);
    }
    insertSurveyPropertyUnit(property) {
        const url = ConstantUrl.url_property_domain + 'surveyPropertyUnit';
        return this.service.post(url, property);
    }
    getSurveyPropertyUnitById(id) {
        const url = ConstantUrl.url_property_query + 'surveyPropertyUnit/' + id;
        return this.service.get(url);
    }
    getTicketById(id) {
        const url = ConstantUrl.url_ticket_query + 'lead/' + id;
        return this.service.get(url);
    }
    getConsignmentById(id) {
        const url = ConstantUrl.url_property_query + 'consignment/' + id;
        return this.service.get(url);
    }
    getCategories() {
        const url = ConstantUrl.url_category_query + 'category/pub/all';
        return this.service.get(url);
    }
    checkIdentity(request) {
        let url =  ConstantUrl.url_property_query + 'propertyCustomer/checkCustomerExist';
        return this.service.post(url, request);
    }
    extend(request) {
        const url = ConstantUrl.url_contract_domain + 'contract/extend';
        return this.service.put(url, request);
    }
    sendApprove(request) {
        const url = ConstantUrl.url_contract_domain + 'contract/sendApprove';
        return this.service.put(url, request);
    }
    approve(request, header?: any) {
        const url = ConstantUrl.url_contract_domain + 'contract/approve';
        return this.service.put(url, request, header);
    }
    reject(request, header?: any) {
        const url = ConstantUrl.url_contract_domain + 'contract/reject';
        return this.service.put(url, request, header);
    }
    liquidate(id, headers?, model?) {
        const url = ConstantUrl.url_contract_domain + 'contract/changeState';
        let request = model;
        request.id = id;
        request.state = "LIQUIDATE";
        return this.service.put(url, request, headers || {});
    }
    approveLiquidate(request, header?: any) {
        const url = ConstantUrl.url_contract_domain + 'contract/changeState/approve';
        return this.service.put(url, request, header);
    }
    rejectLiquidate(request) {
        const url = ConstantUrl.url_contract_domain + 'contract/changeState/reject';
        return this.service.put(url, request);
    }
    downloadFile(code) {
        // code = 'DXS_O2O_00004';
        const url = ConstantUrl.url_contract_query + 'contract/download/' + code;
        return this.service.dowloadFile(url, null, code);
    }
    getLiquidates() {
        const url = this.queryUrl + '/getLiquidateContracts';
        return this.service.get(url);
    }
    changeState(contract: any) {
        const url = ConstantUrl.url_contract_domain + 'contract/liquidate';
        return this.service.put(url, contract);
    }
    getEmployee() {
        const url = ConstantUrl.url_contract_query + 'employee';
        return this.service.get(url);
    }
    upload(body) {
        const url = ConstantUrl.url_contract_query + 'contract/upload';
        return this.service.put(url, body);
    }
    confirmRevenue(params: any) {
        const url = ConstantUrl.url_contract_domain + 'contract/confirmRevenue';
        return this.service.put(url, params);
    }
    getManager(userId) {
        const url = ConstantUrl.url_contract_query + 'employee/findManager/' + userId;
        return this.service.get(url);
    }
    actionActive(contract, headers) {
        let url = ConstantUrl.url_contract_domain + 'contract/active';
        return this.service.put(url, contract, headers);
    }
    getFilterList() {
      let url = ConstantUrl.url_contract_query + 'contract/filterList';
      return this.service.get(url);
    }
    getAllFilesByContractId(contractId, fileName) {
      let url = ConstantUrl.url_contract_query + 'contract/download/' + contractId + '/liquidate-documents';
      return this.service.dowloadFile(url, {}, fileName);
    }
    // Hợp đồng cần duyệt
    getWaiting(params?) {
        const url = this.queryUrl + '/waiting';
        return this.httpService.get(url, params ? params : null);
    }

    getProjects(params?) {
        let url = ConstantUrl.url_property_query + 'project/admin';
        return this.service.get(url, params || {});
    }

    // Report
    public getReportContracts(params, id?): Observable<any> {
        const url = ConstantUrl.url_property_query + 'project/admin/report' + (id ? `/${id}` : '');
        return this.service.get(url, params, { timezoneclient: 'Asia/Ho_Chi_Minh' }).pipe(
            map((res: any) => {
                if (params.page && params.pageSize) {
                    const pagination = new CPagination<ContractReport>().decode(res);
                    pagination.items = pagination.items.map((item: any) => new ContractReport(item));
                    return pagination;
                } else {
                    return res;
                }
            })
        );
    }

    // Report
    public getReportContractsV2(params, id?): Observable<any> {
        const url = ConstantUrl.url_property_query + 'project/admin/report' + (id ? `/${id}` : '');
        return this.service.get(url, params, { timezoneclient: 'Asia/Ho_Chi_Minh' }).pipe(
            map((res: any) => {
                if (params.page && params.pageSize) {
                    const pagination = new CPagination<ContractReport>().decode(res);
                    pagination.items = pagination.items.map((item: any) => new ContractReport(item));
                    return pagination;
                } else {
                    return res.rows.map((item: any) => new ContractReport(item));;
                }
            })
        );
    }

    public getReportContractExport(params) {
        params.pageSize = '';
        params.page = '';
        const url = ConstantUrl.url_dealt_query + 'dealt/reportSales?export=grouping';
        return this.service.get(url, params);
    }
}
