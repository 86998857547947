import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { NumberToMoneyVN } from "app/shared/parse/number-to-money-vn";
import { environment } from "environments/environment";
import moment = require("moment");
import { DialogJoinComponent } from "../dialog-join/dialog-join.component";
import { LandingPageEventModel, LandingSocialPostModel } from "../landing-page.model";
import { LandingPageService } from "../landing-page.service";
@Component({
  selector: "app-landing-social-post",
  templateUrl: "./landing-social-post.component.html",
  styleUrls: ["./landing-social-post.component.scss"],
})
export class LandingSocialPostComponent implements OnInit {
  model: LandingSocialPostModel = new LandingSocialPostModel();
  id: string = "";
  checkDataEvent: boolean = false;
  viewerOptions: any = {
    navbar: false,
    toolbar: {},
  };
  iscommissionType: boolean = false;

  constructor(
    public route: ActivatedRoute,
    private service: LandingPageService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    // Router subscribe
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get("id");
    });
    const params = this.route.snapshot.params;
    const id = params["id"];
    if (id) {
      this.service.getSocialPostById(id).subscribe({
        next: (res: any) => {
          this.checkDataEvent = true
          this.model = new LandingSocialPostModel(res);
          this.model.auction.startingPrice = this.model.auction.startingPrice ? NumberToMoneyVN.parseToMoney(this.model.auction.startingPrice) : 0;
          this.model.auction.endDate = this.model.auction.endDate ? moment(this.model.auction.endDate).format("hh:mm - DD-MM-YYYY") : '';
          if(this.model.commissionType === 'CASH') {
            this.iscommissionType = true
          }
        },
        error: (err) => {
        }
      });
    } else {
      this.model = new LandingSocialPostModel();
    }
  }

  openDialog() {
    const dialog = this.dialog.open(DialogJoinComponent, {
      width: '800px',
      data: {
        shareUrl: this.model.shareUrl,
      }
    });
    dialog.afterClosed().subscribe(result => { });
  }
}
