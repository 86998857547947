import { Component, Injector, EventEmitter, Input, Output, forwardRef, OnInit, OnChanges, SimpleChange, Inject } from '@angular/core';
import { Page } from './paging.model';
import { DOCUMENT } from '@angular/common';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'paging',
  templateUrl: './paging.component.html',
  styleUrls: [
    './paging.scss'
  ]
})
export class PagingComponent implements OnInit {
  @Input('tableItems') tableItems: any;
  @Input('selectedPage') selectedPage: number = 1;
  pageModel: any;
  pages: any;
  @Input('total') total: number = 0;
  @Input('totalPages') totalPages: number = 0;
  @Input('itemsPerPage') itemsPerPage: number = 10;
  @Output() result: EventEmitter<any> = new EventEmitter();
  @Output() itemsPerPageChanged: EventEmitter<any> = new EventEmitter();
  @Output() pageChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject(DOCUMENT) private document
  ) { }

  ngOnInit() {
    this.setDefault();
  }
  setDefault(items?) {
    this.pages = [];
    !this.tableItems && (this.tableItems = []);
    if (items) {
      this.tableItems = items;
    }
    let tableItems = this.tableItems;
    if (!this.pageModel) {
      this.pageModel = new Page();
      this.pageModel.showPage = 0;
    }
    let count = 0;
    let list: any = [];
    let page = 0;
    tableItems.forEach((item) => {
      if (count < this.pageModel.itemsPerPage) {
        item.page = [page, count];
        list.push(item);
        count++;
      }
      else {
        this.pages.push(list);
        page++;
        item.page = [page, 0];
        list = [item];
        count = 1;
      }
    })
    if (list && list.length > 0) {
      this.pages.push(list);
    }
    this.result.emit(this.pages[this.pageModel.selectedPage]);
  }
  setDefaultPaging(total, totalPages) {
      this.total = total;
      this.totalPages = totalPages;
  }
  changePage(index) {
    this.selectedPage = index;
    this.pageChanged.emit(index);
    this.scrollToTop();
  }
  changeItemsPerPage() {
    this.selectedPage = 1;
    this.itemsPerPageChanged.emit(this.itemsPerPage);
    this.scrollToTop();
  }
  fowardPage() {
    this.pageModel.showPage += 5;
  }
  disabledForwadPage() {
    if (!this.tableItems) {
      return false;
    }
    if ((this.pageModel.showPage + 5) * this.pageModel.itemsPerPage > this.tableItems.length) {
      return true;
    }
  }
  disabledPreviousPage() {
    if (!this.tableItems) {
      return false;
    }
    if ((this.pageModel.showPage - 5) * this.pageModel.itemsPerPage < this.tableItems.length) {
      return true;
    }
  }

  private scrollToTop() {
    setTimeout(() => {
      this.document.getElementsByTagName('app-breadcrumb')[0].scrollIntoView(true);
    });
  }
}
