import { IDecode } from './i-decode';

export class CPagination<T> implements IDecode<CPagination<T>> {
  public items: T[] = [];
  public page: number;
  public pageSize: number;
  public pageSizeMax: number = 100;
  public total: number;
  public totalPage: number;
  public get itemsSize(): number {
    return this.items ? this.items.length : null;
  }

  constructor(params: Partial<CPagination<T>> = {}) {
    this.copy(params);
  }

  isLoadMore(): boolean {
    return this.page < this.totalPage;
  }

  copy(params: Partial<CPagination<T>> = {}): CPagination<T> {
    params = params || {};
    this.items = params.items || [];
    this.page = params.page || 1;
    this.pageSize = params.pageSize || 20;
    this.total = params.total || 0;
    this.totalPage = params.totalPage || 0;
    return this;
  }

  decode(paramsApi: any): CPagination<T> {
    const { totalPages, rows } = paramsApi;
    return new CPagination<T>(Object.assign(paramsApi, { items: rows, totalPage: totalPages }));
  }

  decodeList(paramsApi: any[]): CPagination<T>[] {
    return paramsApi.map(el => new CPagination<T>().decode(el));
  }

}
