import { IDecode } from './i-decode';

export class CPermission implements IDecode<CPermission> {
    public featureName: string;
    public msxName: string;

    constructor(params: Partial<CPermission> = {}) {
        this.copy(params);
    }

    static copyList(params: Partial<CPermission>[] = []): CPermission[] {
        return params.map(el => new CPermission().copy(el));
    }


    copy(params: Partial<CPermission> = {}): CPermission {
        params = params || {};

        this.featureName = params.featureName || null;
        this.msxName = params.msxName || null;

        return this;
    }

    decode(paramsApi: any): CPermission {
        return new CPermission(paramsApi);
    }

    decodeList(paramsApi: any[]): CPermission[] {
        return paramsApi.map(el => new CPermission().decode(el));
    }
}
