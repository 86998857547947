import { BaseModel } from "app/shared/models/base.model";
import { DefineAttribute } from "../define-attributes/define-attributes.model";
import { POS } from "../orgchart-pos/model/pos.model";
import { Images } from "app/shared/components/upload-images/image.model";
import { Address } from "app/shared/models/address.model";
import { SocialUrl } from "app/shared/models/social-url.model";

export class Employee extends BaseModel {
    id: string;
    slugId: string;
    identityCode: string;
    name: string;
    code: string;
    avatar: string;
    password: string;
    email: string;
    phone: string;
    extPhone: string;
    status: string;
    attributes: any;
    group: any;
    permissions: any;
    images: Images;
    system: string = "SPX";
    // Override this property to work with current logic.
    description: string = "";
    roleId: string = "";
    posId: string = "";
    level: number;
    workingAt: string;
    managerAt: string;
    pos: any;
    interestedArea: Address;
    socialUrls: SocialUrl[];
    relationIinviter: string = "";
    createdAt: Date;
    inviter: object;
    identityFrontImage: string = null;
    portraitImage: string = null;
    identityBackImage: string = null;
    gender: string = "";
    job: any = {};
    dob: string = "";
    identityDate: string = "";
    identityAddress: string = "";
    taxId: string = "";
    address: string;
    registerStatus: string;
    secondEmail: string;
    user: any;
    isManage: boolean;
    referralPerson: any;
    statusContract: string;
    contractContent: string;
    erpCode: string;
    notes: any;
    supporter: any;
    title: string = "";
    contractCode: string = "";
    contractSigningDate: string = "";
    isStaff: boolean = false;
    isProbation: boolean = false;
    settingKYC: any;
    businessProfile: {
        introducer: string;
        quote: string;
        projectPaticipated: any[];
    };
    onlyYear: boolean = false;
    bankInfo: any;
    groupLead: any = [];

    static createObjectReference(employee: any) {
        let object: any = {};
        employee.id && (object.id = employee.id || null);
        object.identityCode = employee.object;
        object.email = employee.email;
        object.code = employee.code;
        object.name = employee.name;
        object.phone = employee.phone;
        object.extPhone = employee.extPhone;
        object.roleId = employee.roleId || "";
        object.images = employee.images.createObjectRequestAPI();
        // object.identityFrontImage = employee.identityFrontImage;
        // object.identityBackImage = employee.identityBackImage;
        // object.gender = employee.gender;
        // object.dob = employee.dob;
        // object.identityDate = employee.identityDate;
        // object.identityAddress = employee.identityAddress;
        // object.taxId = employee.taxId;
        // object.address = employee.address;
        return object;
    }

    static getType() {
        return "employee";
    }

    constructor(object?: any) {
        super();
        if (object) {
            this.id = object.id || "";
            this.slugId = object.slugId || "";
            this.identityCode = object.identityCode;
            this.name = object.name;
            this.code = object.code;
            this.password = object.password;
            this.avatar = object.avatar || "";
            this.status = object.status;
            this.phone = object.phone;
            this.extPhone = object.extPhone;
            this.email = object.email;
            this.attributes = object.attributes || [];
            this.permissions = object.permissions || [];
            // this.pos = object.pos ? new POS(object.pos) :  new POS();
            this.images = object.images
                ? new Images(object.images)
                : new Images();
            this.description = object.description || "";
            this.system = object.system;
            this.roleId = object.roleId || "";
            this.level = object.level;
            this.workingAt = object.workingAt;
            this.managerAt = object.managerAt;
            this.pos = object.pos;
            this.posId = object.posId;
            this.isManage = object.isManage;
            this.interestedArea = object.interestedArea
                ? new Address(object.interestedArea)
                : new Address();
            this.socialUrls = object.socialUrls
                ? object.socialUrls.map((item: any) => new SocialUrl(item))
                : [];
            this.relationIinviter = object.relationIinviter;
            this.createdAt = object.createdAt;
            this.inviter = object.inviter;
            this.portraitImage = object.portraitImage;
            this.identityFrontImage = object.identityFrontImage;
            this.identityBackImage = object.identityBackImage;
            this.gender = object.gender;
            this.job = object.job;
            this.dob = object.dob;
            this.identityDate = object.identityDate;
            this.identityAddress = object.identityAddress;
            this.taxId = object.taxId;
            // this.address = object.address;
            this.registerStatus = object.registerStatus;
            this.secondEmail = object.secondEmail;
            this.referralPerson = object.referralPerson;
            this.statusContract = object.statusContract;
            this.contractContent = object.contractContent;
            this.erpCode = object.erpCode;
            this.notes = object.notes;
            this.supporter = object.supporter;
            this.title = object.title;
            this.contractCode = object.contractCode;
            this.contractSigningDate = object.contractSigningDate;
            this.isStaff = object.isStaff || false;
            this.isProbation = object.isProbation || false;
            this.settingKYC = object.settingKYC || null;
            this.businessProfile = object.businessProfile || null;
            this.onlyYear = object.onlyYear || false;
            this.bankInfo = object.bankInfo || [];
            this.groupLead = object.groupLead;
        } else {
            this.setAllNull();
        }
        super.setValue(object);
    }

    setValue(params: any) {
        if (params) {
            this.id = params.id || "";
            this.slugId = params.slugId || "";
            this.identityCode = params.identityCode;
            this.name = params.name;
            this.code = params.code;
            this.password = params.password;
            this.avatar = params.avatar || "";
            this.status = params.status;
            this.phone = params.phone;
            this.extPhone = params.extPhone;
            this.email = params.email;
            this.attributes = params.attributes || [];
            this.permissions = params.permissions || [];
            this.images = params.images
                ? new Images(params.images)
                : new Images();
            this.description = params.description || "";
            this.system = params.system;
            this.roleId = params.roleId || "";
            this.level = params.level;
            this.workingAt = params.workingAt;
            this.managerAt = params.managerAt;
            this.isManage = params.managerAt ? true : false;
            this.pos = params.pos;
            this.posId = params.pos ? (params.pos.id ? params.pos.id : "") : "";
            this.interestedArea = params.interestedArea
                ? new Address(params.interestedArea)
                : new Address();
            this.socialUrls = params.socialUrls
                ? params.socialUrls.map((item: any) => new SocialUrl(item))
                : [];
            this.relationIinviter = params.relationIinviter;
            this.createdAt = params.createdAt;
            this.inviter = params.inviter;
            this.portraitImage = params.portraitImage;
            this.identityFrontImage = params.identityFrontImage;
            this.identityBackImage = params.identityBackImage;
            this.gender = params.gender;
            this.job = params.job;
            this.dob = params.dob;
            this.identityDate = params.identityDate;
            this.identityAddress = params.identityAddress;
            this.taxId = params.taxId;
            this.secondEmail = params.secondEmail;
            this.referralPerson = params.referralPerson || {};
            this.statusContract = params.statusContract || "";
            this.contractContent = params.contractContent || "";
            this.notes = params.notes || [];
            this.supporter = params.supporter || {};
            this.title = params.title || "";
            this.contractCode = params.contractCode || "";
            this.erpCode = params.erpCode || '';
            this.contractSigningDate = params.contractSigningDate || "";
            this.isStaff = params.isStaff || false;
            this.isProbation = params.isProbation || false;
            this.settingKYC = params.settingKYC || null;
            this.businessProfile = params.businessProfile || null;
            this.onlyYear = params.onlyYear || false;
            this.bankInfo = params.bankInfo || [];

            // this.address = params.address;
            this.groupLead = params.groupLead || [];
        } else {
            this.setAllNull();
        }
        super.setValue(params);
    }

    setAllNull() {
        this.id = null;
        this.identityCode = null;
        this.name = null;
        this.avatar = "";
        this.code = "";
        this.email = "";
        this.password = "";
        this.phone = "";
        this.status = "waiting";
        this.attributes = [];
        this.images = new Images();
        this.description = "";
        this.extPhone = "";
        this.system = "O2O";
        this.roleId = "";
        this.level = null;
        this.workingAt = null;
        this.managerAt = null;
        this.posId = null;
        this.isManage = false;
        this.pos = {};
        this.interestedArea = new Address();
        this.socialUrls = [];
        this.referralPerson = {};
        this.statusContract = null;
        this.contractContent = null;
        this.notes = [];
        this.supporter = {};
        this.title = null;
        this.contractCode = null;
        this.contractSigningDate = null;
        this.erpCode = '';
        this.isStaff = false;
        this.isProbation = false;
    }

    createObjectRequestAPI() {
        let object: any = {};
        this.id && (object.id = this.id || "");
        object.slugId = this.slugId;
        object.identityCode = this.identityCode;
        object.name = this.name;
        object.code = this.code;
        object.email = this.email;
        object.password = this.password;
        object.status = this.status;
        object.permissions = this.permissions;
        object.images = this.images;
        object.avatar = this.avatar;
        object.phone = this.phone;
        object.extPhone = this.extPhone;
        object.description = this.description;
        object.roleId = this.roleId;
        object.images = this.images.createObjectRequestAPI();
        object.attributes = this.attributes.map((attribute: any) =>
            new DefineAttribute(attribute).createObjectRequestAPI()
        );
        object.system = this.system;
        object.level = this.level;
        object.workingAt = this.workingAt;
        object.managerAt = this.managerAt;
        object.pos = this.pos;
        object.interestedArea = this.interestedArea.createObjectRequestAPI();
        object.socialUrls = this.socialUrls.map((item) =>
            item.createObjectRequestAPI()
        );
        object.relationIinviter = this.relationIinviter;
        object.createdAt = this.createdAt;
        object.inviter = this.inviter;
        object.portraitImage = this.portraitImage;
        object.identityFrontImage = this.identityFrontImage;
        object.identityBackImage = this.identityBackImage;
        object.gender = this.gender;
        object.job = this.job;
        object.dob = this.dob;
        object.onlyYear = this.onlyYear;
        object.identityDate = this.identityDate;
        object.identityAddress = this.identityAddress;
        object.taxId = this.taxId;
        object.secondEmail = this.secondEmail;
        // object.address = this.address;
        object.posAssign = {
            id: this.posId,
            is_manager_checked: this.posId ? this.isManage : false,
        };
        object.referralPerson = this.referralPerson;
        object.statusContract = this.statusContract;
        object.contractContent = this.contractContent;
        object.notes = this.notes;
        object.supporter = this.supporter;
        object.title = this.title;
        object.contractCode = this.contractCode;
        object.contractSigningDate = this.contractSigningDate;
        object.erpCode = this.erpCode;
        object.isStaff = this.isStaff || false;
        object.isProbation = this.isProbation || false;

        return object;
    }

    createObjectReference() {
        let object: any = {};
        this.id && (object.id = this.id || null);
        object.slugId = this.slugId;
        object.identityCode = this.identityCode;
        object.email = this.email;
        object.code = this.code;
        object.name = this.name;
        object.phone = this.phone;
        object.extPhone = this.extPhone;
        object.images = this.images;
        object.avatar = this.avatar;
        object.description = this.description;
        object.roleId = this.roleId;
        object.level = this.level;
        object.workingAt = this.workingAt;
        object.managerAt = this.managerAt;
        object.posId = this.posId;
        object.isManage = this.isManage;
        object.pos = this.pos;
        object.interestedArea = this.interestedArea;
        object.relationIinviter = this.relationIinviter;
        object.createdAt = this.createdAt;
        object.inviter = this.inviter;
        object.portraitImage = this.portraitImage;
        object.identityFrontImage = this.identityFrontImage;
        object.identityBackImage = this.identityBackImage;
        object.gender = this.gender;
        object.job = this.job;
        object.dob = this.dob;
        object.onlyYear = this.onlyYear;
        object.identityDate = this.identityDate;
        object.identityAddress = this.identityAddress;
        object.taxId = this.taxId;
        object.secondEmail = this.secondEmail;
        object.isStaff = this.isStaff;
        object.isProbation = this.isProbation;
        // object.address = this.address;
        return object;
    }
}

export class EmployeeMLMDiagram {
    stt: string;
    id: string;
    status: string;
    code: string;
    name: string;
    phone: string;
    email: string;
    createdDate: string;
    userLevel: UserLevelModel;
    isHasAction: boolean;
    isParent: boolean;
    childLevel: number;

    constructor(params?: any) {
        this.id = params && params.id ? params.id : "";
        this.status = params && params.status ? params.status : "";
        this.code = params && params.code ? params.code : "";
        this.name = params && params.name ? params.name : "";
        this.phone = params && params.phone ? params.phone : "";
        this.email = params && params.email ? params.email : "";
        this.childLevel = params && params.childLevel ? params.childLevel : 0;
        this.createdDate =
            params && params.createdDate ? params.createdDate : "";
        this.userLevel =
            params && params.userLevel
                ? new UserLevelModel(params.userLevel)
                : new UserLevelModel();
    }
}

export class UserLevelModel {
    totalChild: number;
    level: number;
    levelCode: string;
    parents: any;
    totalChildPerLevel: any;
    maxChildLevel: number;
    group: any;
    parentCode: string;

    constructor(params?: any) {
        this.totalChild = params && params.totalChild ? params.totalChild : 0;
        this.level = params && params.level ? params.level : 0;
        this.levelCode = params && params.levelCode ? params.levelCode : "";
        this.parents = params && params.parents ? params.parents : [];
        this.totalChildPerLevel =
            params && params.totalChildPerLevel
                ? params.totalChildPerLevel
                : [];
        this.maxChildLevel =
            params && params.maxChildLevel ? params.maxChildLevel : 0;
        this.parentCode = params && params.parentCode ? params.parentCode : "";
    }
}

export class EmployeeSupportModel {
    stt: number;
    id: string;
    status: string;
    code: string;
    name: string;
    phone: string;
    email: string;
    createdDate: string;
    countSupporter: number;
    pos: any;

    constructor(params?: any) {
        this.id = params && params.id ? params.id : "";
        this.status = params && params.status ? params.status : "";
        this.code = params && params.code ? params.code : "";
        this.name = params && params.name ? params.name : "";
        this.phone = params && params.phone ? params.phone : "";
        this.email = params && params.email ? params.email : "";
        this.createdDate =
            params && params.createdDate ? params.createdDate : "";
        this.countSupporter =
            params && params.countSupporter ? params.countSupporter : 0;
        this.pos = params && params.pos ? params.pos : {};
    }
}

export class ChangeSupporterModel {
    stt: number;
    id: string;
    status: string;
    user: any;
    supporterOld: any;
    supporterNew: any;
    phoneSupporterNew: string;
    note: string;
    reason: string;
    createdDate: string;

    constructor(params?: any) {
        this.id = params && params.id ? params.id : "";
        this.status = params && params.status ? params.status : "";
        this.user = params && params.user ? params.user : {};
        this.supporterOld =
            params && params.supporterOld ? params.supporterOld : {};
        this.supporterNew =
            params && params.supporterNew ? params.supporterNew : {};
        this.phoneSupporterNew =
            params && params.phoneSupporterNew ? params.phoneSupporterNew : "";
        this.note = params && params.note ? params.note : "";
        this.reason = params && params.reason ? params.reason : "";
        this.createdDate =
            params && params.createdDate ? params.createdDate : "";
    }
}
