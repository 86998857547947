export const UpgradeTrialConstant = [
    { value: 'Starter' },
    { value: 'Growth' },
    { value: 'Business' },
    { value: 'Enterprise' }
];

export enum UserTrialStatusEnum {
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    WAITING = 'WAITING',
}

export enum UserTrialStatusTrial {
    TRIAL = "TRIAL",
    WAITNG_UPGRADE = "WAITING_UPGRADE",
    EXPIRED =  "EXPIRED",
    NOT_ACTIVATED = "NOT_ACTIVATED",
    ACTIVE = "ACTIVE"
}

export enum StatusCustomerTrial {
    TRYING = "Đang dùng thử",
    EXPIRED = "Hết hạn",
    NOT_ACTIVATED = "Chưa kích hoạt",
    WAITING_FOR_UPGRADE = "Chờ nâng cấp",
    UPGRADED = "Đã nâng cấp"
}