import { DateToFormat } from './../parse/date-to-format';
import { ICallHistory, ICallHistoryEmployeeInfo, IAgentInfo } from './../interfaces/employee-callHistory.interface';

export class CallHistoryModel implements ICallHistory {
    stt: number;
    startTime: number;
    answerTime: number;
    stopTime: number;
    duration: number;
    answerDuration: number;
    clientId: string;
    callId: string;
    projectId: number;
    fromUserId: string;
    id: string;
    created: Date;
    from: CallHistoryEmployeeInfoModel;
    to: CallHistoryEmployeeInfoModel;

    constructor(params?: any) {
        params = params || {};

        this.startTime = params.startTime ? DateToFormat.parseDate(params.startTime, 'dd/MM/yyyy - hh:mm:ss') : 0;
        this.answerTime = params.answerTime || 0;
        this.stopTime = params.stopTime ? DateToFormat.parseDate(params.stopTime, 'dd/MM/yyyy - hh:mm:ss') : 0;
        this.duration = params.duration || 0;
        this.answerDuration = params.answerDuration || 0;
        this.clientId = params.clientId || '';
        this.callId = params.callId || '';
        this.projectId = params.projectId || 0;
        this.fromUserId = params.fromUserId || '';
        this.id = params.id || '';
        this.created = params.created ? DateToFormat.parseDate(params.created, 'dd/MM/yyyy - hh:mm:ss') : '';
        this.from = params.from || null;
        this.to = params.to || null;
    }
}

export class CallHistoryEmployeeInfoModel implements ICallHistoryEmployeeInfo {
    number: string;
    alias: string;
    agentInfo: AgentModel;
    callType: string;

    constructor(params?: any) {
        params = params || {};

        this.number = params.number || '';
        this.alias = params.alias || '';
        this.agentInfo = params.agentInfo || null;
        this.callType = params.callType || '';
    }
}

export class AgentModel implements IAgentInfo {
    name: string;
    code: string;
    email: string;
    system: string;
    stringeeId: string;
    phone: string;
    avatarUrl: string;
    id: string;

    constructor(params?: any) {
        params = params || {};

        this.name = params.name || '';
        this.code = params.code || '';
        this.email = params.email || '';
        this.system = params.system || '';
        this.stringeeId = params.stringeeId || '';
        this.phone = params.phone || '';
        this.avatarUrl = params.avatarUrl || '';
        this.id = params.id || '';
    }
}