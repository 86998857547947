<h2 mat-dialog-title>{{data.title}}</h2>

<div mat-dialog-content style="margin-bottom: 30px;">

  <span style="color: red; ">{{data.text}}</span>

  <div *ngFor="let error of data.errors" fxLayout="row" fxLayoutAlign="space-around center">
    <mat-icon style="color: red;">clear</mat-icon>
    <span style="color: red; ">
      {{error.message}}
    </span>
  </div>      
</div>

<mat-dialog-actions align="end">
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button [mat-dialog-close]="true">Đóng</button>
</mat-dialog-actions>