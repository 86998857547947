import { POSService } from './pages/orgchart-pos/pos.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';

import { AgmCoreModule } from '@agm/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};
import { SharedModule } from './shared/shared.module';
import { PipesModule } from './theme/pipes/pipes.module';
import { routing } from './app.routing';

import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
// import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { AppSettings } from './app.settings';

import { SidenavComponent } from './theme/components/sidenav/sidenav.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { FileSaverModule } from "ngx-filesaver";
import { ToastrModule } from 'ngx-toastr';
import { UserRouteAccessDeactivate } from './shared/services/common/user.route.access.service';
import { UserAuthorityDirective } from './shared/services/common/user-authority.directive';
import { AuthorizeService } from './shared/services/common/authorize.service';
import { EventChangeService } from './shared/services/event.change.service';
import { SearchComponent } from './pages/search/search.component';
import { ReasonDialog } from './pages/ticket/popup-dialog';
import { AppCustomPreloader } from './AppCustomPreloader';
import { NotificationService } from './shared/services/notification.service';
import { CommingSoonComponent } from './pages/errors/coming-soon/coming-soon.component';
import { HandleErrorRequestDialog } from './shared/components/dialog-handle-error/popup-dialog';
import { InfoDialogComponent } from './shared/components/info-dialog/popup-dialog';
import { ConfirmDialog } from './pages/money-order/confirm-dialog/confirm-dialog';
import { ConfirmPopup } from './shared/components/confirm-popup/confirm-popup';
import { NoteDialog } from './pages/project/investor/note-dialog/note-dialog';
import { WebsocketService } from './shared/components/websocket.service';
import { WsNotifyService } from './shared/components/ws-notify.service';
import { ErrorPopup } from './shared/components/error-popup/error-popup';
import { SuccessPopup } from './shared/components/success-popup/success-popup';
import { ResetPasswordComponent } from './pages/password/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './pages/password/forgot-password/forgot-password.component';
import { PasswordService } from './pages/password/password.service';
import { TokenInterceptor } from './shared/services/interceptors/token.interceptor';
import { ImagePopup } from './shared/components/image-popup/image-popup';
import { EmployeePOSComponent } from './pages/employee/employee-pos/employee-pos.component';
import { ChoosePopup } from './shared/components/choose-popup/choose-popup';
import { GroupPopup } from './pages/message/group-popup/group-popup';
import { ToastModule } from './theme/components/toast';
import { environment } from 'environments/environment';
import { SecurityService } from './shared/services/security.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ErpService } from './shared/services/common/erp.service';
import { ConfirmErpPopup } from './shared/components/confirm-erp-popup/confirm-erp-popup';
import { ConfirmChangedEmailComponent } from './pages/employee/confirm-changed-email/confirm-changed-email.component';
import { NewsPublicComponent } from './pages/news-public/news-public.component';
import { NewsPublicService } from './pages/news-public/news-public.service';
import { EditorPopup } from './shared/components/editor-popup/editor-popup';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { LandingPromotionComponent } from './pages/landing-page/landing-promotion/landing-promotion.component';
import { LandingEventComponent } from './pages/landing-page/landing-event/landing-event.component';
import { LandingSocialPostComponent } from './pages/landing-page/landing-social-post/landing-social-post.component';
import { LandingEsalekitComponent } from './pages/landing-page/landing-esalekit/landing-esalekit.component';
import { LandingGroupPostComponent } from './pages/landing-page/landing-group-post/landing-group-post.component';
import { LandingGroupComponent } from './pages/landing-page/landing-group/landing-group.component';
import { LandingPageService } from './pages/landing-page/landing-page.service';
import { DialogJoinComponent } from './pages/landing-page/dialog-join/dialog-join.component';
import { LayoutPublicComponent } from './layout-public/layout-public.component';
import { LayoutPublicHeaderComponent } from './layout-public/header/layout-public-header.component';
import { LayoutPublicBreadcrumbComponent } from './layout-public/breadcrumb/layout-public-breadcrumb.component';
import { LayoutPublicFooterComponent } from './layout-public/footer/layout-public-footer.component';
import { InjectionToken, FactoryProvider } from '@angular/core';
import { LandingHeaderComponent } from './pages/landing-page/landing-header/landing-header.component';
import { LandingFooterComponent } from './pages/landing-page/landing-footer/landing-footer.component';
import { LandingVideoComponent } from './pages/landing-page/landing-video/landing-video.component';
import { ConfirmPopupV3 } from './shared/components/v3/confirm-popup-v3/confirm-popup-v3.component';
import { MatCarouselModule } from '@ngmodule/material-carousel';

export const WINDOW = new InjectionToken<Window>('window');

const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: () => window
};

export const WINDOW_PROVIDERS = [
    windowProvider
]
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMap.apiKey
    }),
    PerfectScrollbarModule,
    // CalendarModule.forRoot(),
    SharedModule,
    PipesModule,
    routing,
    FileSaverModule,
    ToastrModule.forRoot(
      {
        timeOut: 2000,
        positionClass: 'toast-top-right',
        // progressBar: true,
        progressAnimation: 'increasing',
        closeButton: true,
        maxOpened: 3
      }
    ), // ToastrModule added
    ToastModule.forRoot({
      position: {
        top: 60,
        right: 16,
      },
      animation: {
        fadeOut: 100,
        fadeIn: 50,
      },
    }),
    OAuthModule.forRoot(),
    AngularEditorModule,
    MatCarouselModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    SearchComponent,
    NotFoundComponent,
    CommingSoonComponent,
    ErrorComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SidenavComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    FlagsMenuComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    ReasonDialog,
    ConfirmDialog,
    ConfirmPopup,
    NoteDialog,
    HandleErrorRequestDialog,
    InfoDialogComponent,
    ErrorPopup,
    SuccessPopup,
    ImagePopup,
    EmployeePOSComponent,
    ChoosePopup,
    GroupPopup,
    ConfirmErpPopup,
    ConfirmChangedEmailComponent,
    NewsPublicComponent,
    EditorPopup,
    LandingGroupComponent,
    LandingHeaderComponent,
    LandingFooterComponent,
    LandingGroupPostComponent,
    LandingEventComponent,
    LandingSocialPostComponent,
    LandingEsalekitComponent,
    LandingPromotionComponent,
    DialogJoinComponent,
    LandingVideoComponent,
    // Public layout
    LayoutPublicComponent,
    LayoutPublicHeaderComponent,
    LayoutPublicBreadcrumbComponent,
    LayoutPublicFooterComponent,
    LayoutPublicFooterComponent,
    ConfirmPopupV3,
  ],
  entryComponents: [
    VerticalMenuComponent,
    ReasonDialog,
    ConfirmDialog,
    ConfirmPopup,
    NoteDialog,
    HandleErrorRequestDialog,
    InfoDialogComponent,
    ErrorPopup,
    SuccessPopup,
    ImagePopup,
    ChoosePopup,
    GroupPopup,
    ConfirmErpPopup,
    DialogJoinComponent,
    EditorPopup,
    ConfirmPopupV3,
  ],
  providers: [
    WINDOW_PROVIDERS,
    UserRouteAccessDeactivate,
    SecurityService,
    AuthorizeService,
    UserAuthorityDirective,
    EventChangeService,
    NotificationService,
    WebsocketService,
    WsNotifyService,
    PasswordService,
    AppSettings,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    AppCustomPreloader,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    ErpService,
    NewsPublicService,
    LandingPageService,
    POSService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
