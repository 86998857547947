<div class="landing_page-header">
    <div class="landing_page-header-wrap">
        <img class="bg-front" src="../../../../assets/img/landing/bg-header.png" alt="landingHeader">
        <div class="landing_page-subHeader">
            <div class="landing_page-logo">
                <img src="../../../../assets/img/landing/Logo.svg" alt="logo">
            </div>
            <div class="landing_page-download">
                <button mat-raised-button (click)="showDialog()">Tải ứng dụng</button>
            </div>
        </div>
    </div>
</div>