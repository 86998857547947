import { Component, OnInit, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from 'app/shared/components/info-dialog/popup-dialog';

@Component({
  selector: 'app-input-otp',
  templateUrl: './input-otp.component.html',
  styleUrls: ['./input-otp.component.scss']
})
export class InputOtpComponent implements OnInit {
  @ViewChild('otpInput') ngOtpInputRef: any;
  @Output() changeDone: EventEmitter<any> = new EventEmitter();
  @Output() reSendOtp: EventEmitter<any> = new EventEmitter();
  public otpValue: string = '';
  public isOtpInvalid: boolean = false;
  validateOtp = false;
  isSendOtp = false;
  public otpConfig = {
    length: 6,
    allowNumbersOnly: true,
    inputClass: 'otp-input-custom'
  };
  constructor() { }

  ngOnInit() {
  }


  onOtpChange(event: any) {
    if(event && event.length === 6) {
      this.otpValue = event;
      this.validateOtp = true;
    }
  }

  reGenerateOtp() {
    this.reSendOtp.emit();
    this.isSendOtp = true;
    setInterval(() => {
      this.isSendOtp = false;
    }, 2 * 60 * 1000); // Gửi lại mã sau 2p
  }

  accept() {
    this.changeDone.emit(this.otpValue)
  }
}
