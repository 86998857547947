export class CustomerConstant {
    public static listIdentity = [
        { value: 'CMND', label: 'CMND' },
        { value: 'IC', label: 'Thẻ Căn cước' },
        { value: 'PASSPORT', label: 'Hộ chiếu' },
        { value: 'GPKD', label: 'Giấy phép kinh doanh' }
    ];
    public static listIncome = [
        { value: 1, label: '< 5 triệu' },
        { value: 2, label: '5 triệu - 10 triệu' },
        { value: 3, label: '10 triệu - 18 triệu' },
        { value: 4, label: '18 triệu - 32 triệu' },
        { value: 5, label: '32 triệu - 52 triệu' },
        { value: 6, label: '52 triệu - 80 triệu' },
        { value: 7, label: '> 80 triệu' }
    ];
    public static listRelationshipStatus = [
        { value: 'single', label: 'Độc thân' },
        { value: 'married', label: 'Đã kết hôn' },
        { value: 'separated', label: 'Ly thân' },
        { value: 'divorce', label: 'Ly hôn' },
        { value: 'widowed', label: 'Góa bụa' },
        { value: 'other', label: 'Khác' }
    ]
}
