import { Injectable } from '@angular/core';
import { Http, Response, RequestOptionsArgs, RequestMethod, Headers, RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { retry, map, catchError } from 'rxjs/operators';

import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { Guid } from '../../../guid';
import { Router } from '@angular/router';
import { ConstantUrl } from '../constant/url';
import { SecurityService, StorageService } from '../services';
import { ToastrService } from 'ngx-toastr';

// Implementing a Retry-Circuit breaker policy
// is pending to do for the SPA app
@Injectable()
export class ComponentService {

    singleAPIs: any = {};
    queues: any = [];
    constructor(
        private http: Http, 
        private httpClient: HttpClient, 
        private securityService: SecurityService, 
        private router: Router,
        private storage: StorageService,
        private toastrService: ToastrService) 
        { }
    
        
    getMasterDataProvince() {
        let url = ConstantUrl.url_property_query+ 'propertyUnit' + '/pub/hanhchinhvn';
        return this.http.get(url)
    }
}
