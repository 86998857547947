import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Constant } from 'app/shared/constant/constant';
import {Customer, IdentityInfo, PersonalInfo, InfoCustomer, BankInfo} from 'app/pages/customer/customer.model';
import { Address } from 'app/shared/models/address.model';
import { ConsignmentService } from 'app/pages/consignment/consignment.service';
import { StorageService } from 'app/shared/services';
import { HANH_CHINH_VN } from 'app/shared/constant/hanhchinhvn';
import * as moment from 'moment';
import {BankService, IBankDropdown} from '../../../services/bank.service';
const VIET_NAM = 'Việt Nam';
import { TXN_PLACE } from 'app/shared/constant/transaction.constant';
import { CustomerConstant } from 'app/shared/constant/customer.constant';

@Component({
  selector: 'app-form-input-customer',
  templateUrl: './form-input-customer.component.html',
  styleUrls: ['./form-input-customer.component.scss'],
  providers: [ConsignmentService]
})
export class FormInputCustomerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() disabled: boolean = false;
  @Input() isContractValidate: boolean = false;
  @Input() data: Customer = new Customer();
  @Output() dataChange: EventEmitter<Customer> = new EventEmitter();
  @Output() validChange: EventEmitter<boolean> = new EventEmitter();
  private unsubscribe$: Subject<any> = new Subject();
  private identityChanged$: Subject<any> = new Subject<any>();
  private validationChanged$: Subject<boolean> = new Subject<boolean>();

  public Constant = Constant;
  public mainForm: FormGroup = null;
  public listIdentity: SelectItemModel[] = CustomerConstant.listIdentity;
  public listIncome: SelectItemModel[] = CustomerConstant.listIncome;
  public listBank = [];
  public listRelationshipStatus: SelectItemModel[] = CustomerConstant.listRelationshipStatus;
  public listProvince: Province[] = JSON.parse(HANH_CHINH_VN);
  public listProvinceRootAddress: Province[] = JSON.parse(HANH_CHINH_VN);
  public listDistrictRootAddress: District[] = [];
  public listWardRootAddress: string[] = [];
  public listProvinceAddress: Province[] = JSON.parse(HANH_CHINH_VN);
  public listDistrictAddress: District[] = [];
  public listWardAddress: string[] = [];
  public get fullRootAddress(): string {
    if (!this.mainForm) {
      return '';
    }
    let result = '';
    const dashSpace = ', ';
    this.mainForm.get('rootAddressText').value ? result += `${this.mainForm.get('rootAddressText').value}` : result += '';
    this.mainForm.get('rootAddressWard').value ? result += `${dashSpace + this.mainForm.get('rootAddressWard').value}` : result += '';
    this.mainForm.get('rootAddressDistrict').value ? result += `${dashSpace + this.mainForm.get('rootAddressDistrict').value}` : result += '';
    this.mainForm.get('rootAddressProvince').value ? result += `${dashSpace + this.mainForm.get('rootAddressProvince').value}` : result += '';
    this.mainForm.get('rootAddressCountry').value ? result += `${dashSpace + this.mainForm.get('rootAddressCountry').value}` : result += '';
    return result.indexOf(',') === 0 ? result.substring(1) : result;
  }
  public get fullAddress(): string {
    if (!this.mainForm) {
      return '';
    }
    let result = '';
    const dashSpace = ', ';
    this.mainForm.get('addressText').value ? result += `${this.mainForm.get('addressText').value}` : result += '';
    this.mainForm.get('addressWard').value ? result += `${dashSpace + this.mainForm.get('addressWard').value}` : result += '';
    this.mainForm.get('addressDistrict').value ? result += `${dashSpace + this.mainForm.get('addressDistrict').value}` : result += '';
    this.mainForm.get('addressProvince').value ? result += `${dashSpace + this.mainForm.get('addressProvince').value}` : result += '';
    this.mainForm.get('addressCountry').value ? result += `${dashSpace + this.mainForm.get('addressCountry').value}` : result += '';
    return result.indexOf(',') === 0 ? result.substring(1) : result;
  }
  public readonly dropdowPlace = Object.keys(TXN_PLACE).map(txn => {
    return { id: txn, name: TXN_PLACE[txn] };
  });
  constructor(
    public formBuilder: FormBuilder,
    public consignmentService: ConsignmentService,
    public storageService: StorageService,
    public bankService: BankService) {
    this.identityChanged$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$))
      .subscribe((input?: any) => this.searchIdentity(input));
    this.validationChanged$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$))
      .subscribe((input?: boolean) => this.updateFormValidation(input));
  }

  ngOnInit() {
    this.initForm(this.data, this.isContractValidate);
    this.bankService.banksDropdown().subscribe((banks: IBankDropdown[]) => {
      this.listBank = banks || [];
    });
  }

  ngOnChanges() {
    this.validationChanged$.next(this.isContractValidate);
    this.initForm(this.data, this.isContractValidate);

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initForm(customer: Customer, isContractValidate: boolean) {
    if (!customer) {
      return;
    }
    let selectedIdentity: IdentityInfo = new IdentityInfo();
    if (customer
      && customer.personalInfo
      && customer.personalInfo.identities
      && customer.personalInfo.identities.length > 0) {
      selectedIdentity = customer.personalInfo.identities[0];
    }
    let selectedIncome: any = null;
    if (customer
      && customer.personalInfo
      && customer.personalInfo.incomeFrom !== undefined
      && customer.personalInfo.incomeTo !== undefined) {
      // Tính toán selected income
      selectedIncome = this.getIncomeFromRange(customer.personalInfo.incomeFrom, customer.personalInfo.incomeTo);
    }
    const info: InfoCustomer = (customer && customer.info) ? customer.info : new InfoCustomer();
    const personalInfo: PersonalInfo = (customer && customer.personalInfo) ? customer.personalInfo : new PersonalInfo();
    const rootAddress = (customer && customer.info && customer.info.rootAddress) ? customer.info.rootAddress : new Address();
    const bankInfo = (customer && customer.bankInfo) ? customer.bankInfo : new BankInfo();
    if (!rootAddress.country) { rootAddress.country = VIET_NAM; }
    this.loadRootAddressOptions(rootAddress);
    const address = (customer && customer.info && customer.info.address) ? customer.info.address : new Address();
    if (!address.country) { address.country = VIET_NAM; }
    this.loadAddressOptions(address);
    let takeNote = '';
    if (customer && customer.takeNote){
      takeNote = customer.takeNote;
    }
    let taxCode = '';
    if (customer && customer.taxCode){
      taxCode = customer.taxCode;
    }
    if (this.mainForm) {
      // Patch existed form
      this.mainForm.setValue({
        // Thông tin cá nhân
        name: personalInfo.name,
        gender: info.gender,
        birthday: info.birthday,
        onlyYear: info.onlyYear,
        birthdayYear: info.birthdayYear,
        phone: personalInfo.phone,
        email: personalInfo.email,
        identityType: selectedIdentity.type,
        identityNumber: selectedIdentity.value,
        identityIssuedDate: selectedIdentity.date,
        identityIssuedBy: selectedIdentity.place,
        // Thông tin khác
        job: personalInfo.job,
        taxCode: taxCode,
        bankName: bankInfo.code,
        bankNumber: bankInfo.accountNumber,
        income: selectedIncome, // Patch to incomeFrom and incomeTo
        incomeSource: personalInfo.incomeSource,
        relationshipStatus: personalInfo.relationshipStatus,
        // Địa chỉ thường trú
        rootAddressCountry: rootAddress.country,
        rootAddressProvince: rootAddress.province,
        rootAddressDistrict: rootAddress.district,
        rootAddressWard: rootAddress.ward,
        rootAddressText: rootAddress.address,
        // Địa chỉ liên lạc
        isCopyRootAddress: false,
        addressCountry: address.country,
        addressProvince: address.province,
        addressDistrict: address.district,
        addressWard: address.ward,
        addressText: address.address,
        takeNote: takeNote,
      });
      // Update input disable/enable state
      if (this.disabled) {
        this.mainForm.disable();
      } else {
        this.mainForm.enable();
        this.mainForm.get('rootAddressCountry').disable();
        this.mainForm.get('addressCountry').disable();
      }
    } else {
      // Init form
      if (this.isContractValidate) {
        this.mainForm = this.formBuilder.group({
          // Thông tin cá nhân
          name: [{ value: personalInfo.name, disabled: this.disabled }, Validators.required],
          gender: [{ value: info.gender, disabled: this.disabled }],
          birthday: [{ value: info.birthday, disabled: this.disabled }],


          onlyYear: [{value: info.onlyYear, disabled: this.disabled}],
          birthdayYear: [{value: info.birthdayYear, disabled: this.disabled}],

          phone: [{ value: personalInfo.phone, disabled: this.disabled }, Validators.required],
          email: [{ value: personalInfo.email, disabled: this.disabled }, Validators.compose([Validators.required, Validators.email])],
          identityType: [{ value: selectedIdentity.type, disabled: this.disabled }, Validators.required],
          identityNumber: [{ value: selectedIdentity.value, disabled: this.disabled }, Validators.required],
          identityIssuedDate: [{ value: selectedIdentity.date, disabled: this.disabled }, Validators.required],
          identityIssuedBy: [{ value: selectedIdentity.place, disabled: this.disabled }],
          // Thông tin khác
          job: [{ value: personalInfo.job, disabled: this.disabled }],
          taxCode: [{ value: taxCode, disabled: this.disabled }],
          bankName: [{ value: bankInfo.code, disabled: this.disabled }],
          bankNumber: [{ value: bankInfo.accountNumber, disabled: this.disabled }],
          income: [{ value: selectedIncome, disabled: this.disabled }], // Patch to incomeFrom and incomeTo
          incomeSource: [{ value: personalInfo.incomeSource, disabled: this.disabled }],
          relationshipStatus: [{ value: personalInfo.relationshipStatus, disabled: this.disabled }],
          // Địa chỉ thường trú
          rootAddressCountry: [{ value: rootAddress.country, disabled: true }],
          rootAddressProvince: [{ value: rootAddress.province, disabled: this.disabled }, Validators.required],
          rootAddressDistrict: [{ value: rootAddress.district, disabled: this.disabled }, Validators.required],
          rootAddressWard: [{ value: rootAddress.ward, disabled: this.disabled }, Validators.required],
          rootAddressText: [{ value: rootAddress.address, disabled: this.disabled }, Validators.required],
          // Địa chỉ liên lạc
          isCopyRootAddress: [{ value: false, disabled: this.disabled }],
          addressCountry: [{ value: address.country, disabled: true }],
          addressProvince: [{ value: address.province, disabled: this.disabled }, Validators.required],
          addressDistrict: [{ value: address.district, disabled: this.disabled }, Validators.required],
          addressWard: [{ value: address.ward, disabled: this.disabled }, Validators.required],
          addressText: [{ value: address.address, disabled: this.disabled }, Validators.required],
          takeNote : [{ value: takeNote , disabled: this.disabled }],
        });
      } else {
        this.mainForm = this.formBuilder.group({
          // Thông tin cá nhân
          name: [{ value: personalInfo.name, disabled: this.disabled }, Validators.required],
          gender: [{ value: info.gender, disabled: this.disabled }],
          birthday: [{ value: info.birthday, disabled: this.disabled }],
          onlyYear: [{value: info.onlyYear, disabled: this.disabled}],
          birthdayYear: [{value: info.birthdayYear, disabled: this.disabled}],
          phone: [{ value: personalInfo.phone, disabled: this.disabled }, Validators.required],
          email: [{ value: personalInfo.email, disabled: this.disabled }, Validators.email],
          identityType: [{ value: selectedIdentity.type, disabled: this.disabled }],
          identityNumber: [{ value: selectedIdentity.value, disabled: this.disabled }],
          identityIssuedDate: [{ value: selectedIdentity.date, disabled: this.disabled }],
          identityIssuedBy: [{ value: selectedIdentity.place, disabled: this.disabled }],
          // Thông tin khác
          job: [{ value: personalInfo.job, disabled: this.disabled }],
          taxCode: [{ value: taxCode, disabled: this.disabled }],
          bankName: [{ value: bankInfo.code, disabled: this.disabled }],
          bankNumber: [{ value: bankInfo.accountNumber, disabled: this.disabled }],
          income: [{ value: selectedIncome, disabled: this.disabled }], // Patch to incomeFrom and incomeTo
          incomeSource: [{ value: personalInfo.incomeSource, disabled: this.disabled }],
          relationshipStatus: [{ value: personalInfo.relationshipStatus, disabled: this.disabled }],
          // Địa chỉ thường trú
          rootAddressCountry: [{ value: rootAddress.country, disabled: true }],
          rootAddressProvince: [{ value: rootAddress.province, disabled: this.disabled }],
          rootAddressDistrict: [{ value: rootAddress.district, disabled: this.disabled }],
          rootAddressWard: [{ value: rootAddress.ward, disabled: this.disabled }],
          rootAddressText: [{ value: rootAddress.address, disabled: this.disabled }],
          // Địa chỉ liên lạc
          isCopyRootAddress: [{ value: false, disabled: this.disabled }],
          addressCountry: [{ value: address.country, disabled: true }],
          addressProvince: [{ value: address.province, disabled: this.disabled }],
          addressDistrict: [{ value: address.district, disabled: this.disabled }],
          addressWard: [{ value: address.ward, disabled: this.disabled }],
          addressText: [{ value: address.address, disabled: this.disabled }],
          takeNote: [{ value: takeNote, disabled: this.disabled }]
        });
      }

      this.mainForm.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {

          const name: string = this.mainForm.get('name').value || '';
          this.mainForm.get('name').setValue(name.toUpperCase(), { emitEvent: false });

          // Patch data
          this.data = this.patchFormValue(this.data, this.mainForm.getRawValue());
          // Emit new data
          this.dataChange.emit(this.data);
          // Emit form valid state
          this.validChange.emit(this.mainForm.valid);
        });
      // Subscribe rootAddress change
      Observable.merge(
        this.mainForm.get('rootAddressCountry').valueChanges,
        this.mainForm.get('rootAddressProvince').valueChanges,
        this.mainForm.get('rootAddressDistrict').valueChanges,
        this.mainForm.get('rootAddressWard').valueChanges,
        this.mainForm.get('rootAddressText').valueChanges)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => this.copyRootAddress());
      // Subscribe isCopyRootAddress change
      this.mainForm.get('isCopyRootAddress').valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: boolean) => this.onChangeAddress(res));
    }

  }

  // PUBLIC
  public validateForm(): boolean {
    if (!this.mainForm) {
      return false;
    }
    this.markAsTouched(this.mainForm);
    return this.mainForm.disabled || this.mainForm.valid;
  }

  updateFormValidation(isContractValidate: boolean) {
    if (!this.mainForm) {
      return;
    }
    // Update validate
    if (isContractValidate) {
      this.mainForm.get('email').setValidators(Validators.compose([Validators.required, Validators.email]));
      this.mainForm.get('identityType').setValidators(Validators.required);
      this.mainForm.get('identityNumber').setValidators(Validators.required);
      this.mainForm.get('identityIssuedDate').setValidators(Validators.required);
      this.mainForm.get('rootAddressProvince').setValidators(Validators.required);
      this.mainForm.get('rootAddressDistrict').setValidators(Validators.required);
      this.mainForm.get('rootAddressWard').setValidators(Validators.required);
      this.mainForm.get('rootAddressText').setValidators(Validators.required);
      this.mainForm.get('addressProvince').setValidators(Validators.required);
      this.mainForm.get('addressDistrict').setValidators(Validators.required);
      this.mainForm.get('addressWard').setValidators(Validators.required);
      this.mainForm.get('addressText').setValidators(Validators.required);
    } else {
      this.mainForm.get('email').setValidators(Validators.email);
      this.mainForm.get('identityType').setValidators(null);
      this.mainForm.get('identityNumber').setValidators(null);
      this.mainForm.get('identityIssuedDate').setValidators(null);
      this.mainForm.get('rootAddressProvince').setValidators(null);
      this.mainForm.get('rootAddressDistrict').setValidators(null);
      this.mainForm.get('rootAddressWard').setValidators(null);
      this.mainForm.get('rootAddressText').setValidators(null);
      this.mainForm.get('addressProvince').setValidators(null);
      this.mainForm.get('addressDistrict').setValidators(null);
      this.mainForm.get('addressWard').setValidators(null);
      this.mainForm.get('addressText').setValidators(null);
    }
    this.markAsTouched(this.mainForm);
  }

  copyRootAddress() {
    if (this.mainForm.get('isCopyRootAddress').value) {
      const data = {
        addressCountry: this.mainForm.get('rootAddressCountry').value,
        addressProvince: this.mainForm.get('rootAddressProvince').value,
        addressDistrict: this.mainForm.get('rootAddressDistrict').value,
        addressWard: this.mainForm.get('rootAddressWard').value,
        addressText: this.mainForm.get('rootAddressText').value
      };
      this.mainForm.patchValue(data); // Prevent emit change event => infinity loop
    }
  }

  onChangeAddress(value: boolean) {
    // If form is disabled, no control status change allowed
    if (this.disabled) {
      return;
    }
    if (value) {
      this.copyRootAddress();
      this.mainForm.get('addressProvince').disable();
      this.mainForm.get('addressDistrict').disable();
      this.mainForm.get('addressWard').disable();
      this.mainForm.get('addressText').disable();
    } else {
      this.mainForm.get('addressProvince').enable();
      this.mainForm.get('addressDistrict').enable();
      this.mainForm.get('addressWard').enable();
      this.mainForm.get('addressText').enable();
    }
  }

  changeIdentity() {
    if (!this.mainForm || !this.mainForm.get('identityType').value || !this.mainForm.get('identityNumber').value) {
      return;
    }
    const request: any = {};
    let posId = '';
    if (this.data.pos && this.data.pos.id) {
      posId = this.data.pos.id || '';
    }
    if (posId.length === 0) {
      const pos = this.storageService.retrieve('pos');
      posId = pos && pos.id ? pos.id : '';
    }
    if (posId) {
      request.posId = posId;
    }
    request.identities = JSON.stringify([{ type: this.mainForm.get('identityType').value, value: this.mainForm.get('identityNumber').value }]);
    this.identityChanged$.next(request);
  }

  searchIdentity(request: any) {
    this.consignmentService.checkIdentity(request)
      .subscribe((res: any) => {
        let customerFind = res.customer || res;
        if (customerFind && customerFind.id) {
          const newIdentities = customerFind.identities.map((identity: any) => {
            let object = new IdentityInfo(identity);
            let findIdentity = customerFind.personalInfo.identities.find(item => item.type === identity.type && item.value === identity.value);
            if (findIdentity) {
              object.date = findIdentity.date;
              object.place = findIdentity.place;
            }
            return object;
          });
          this.data.personalInfo.identities = newIdentities;
          this.data.info = new InfoCustomer(customerFind.info);
          this.initForm(this.data, this.isContractValidate);
        }
      });
  }

  /** BEGIN - Xử lý Select Địa chỉ thường trú */
  onChangeProvinceRootAddress(provinceName: string) {
    const currentProvince: Province = this.listProvince.find((province: Province) => province.n === provinceName);
    if (!currentProvince) {
      return;
    }
    this.mainForm.patchValue({
      rootAddressDistrict: null,
      rootAddressWard: null
    });
    this.listDistrictRootAddress = currentProvince.d;
  }

  onChangeDistrictRootAddress(districtName: string) {
    const provinceName = this.mainForm.get('rootAddressProvince').value;
    const currentProvince: Province = this.listProvince.find((province: Province) => province.n === provinceName);
    if (!currentProvince) {
      return;
    }
    const currentDistrict: District = currentProvince.d.find((district: District) => district.n === districtName);
    if (!currentDistrict) {
      return;
    }
    this.mainForm.patchValue({
      rootAddressWard: null
    });
    this.listWardRootAddress = currentDistrict.w;
  }
  /** END - Xử lý Select Địa chỉ thường trú */

  /** BEGIN - Xử lý Select Địa chỉ liên lạc */
  onChangeProvinceAddress(provinceName: string) {
    const currentProvince: Province = this.listProvince.find((province: Province) => province.n === provinceName);
    if (!currentProvince) {
      return;
    }
    this.mainForm.patchValue({
      addressDistrict: null,
      addressWard: null
    });
    this.listDistrictAddress = currentProvince.d;
  }

  onChangeDistrictAddress(districtName: string) {
    const provinceName = this.mainForm.get('addressProvince').value;
    const currentProvince: Province = this.listProvince.find((province: Province) => province.n === provinceName);
    if (!currentProvince) {
      return;
    }
    const currentDistrict: District = currentProvince.d.find((district: District) => district.n === districtName);
    if (!currentDistrict) {
      return;
    }
    this.mainForm.patchValue({
      addressWard: null
    });
    this.listWardAddress = currentDistrict.w;
  }
  /** END - Xử lý Select Địa chỉ liên lạc */

  loadRootAddressOptions(rootAddress: Address) {
    const currentProvince: Province = this.listProvince.find((province: Province) => province.n === rootAddress.province);
    if (!currentProvince) {
      return;
    }
    this.listDistrictRootAddress = currentProvince.d;
    const currentDistrict: District = currentProvince.d.find((district: District) => district.n === rootAddress.district);
    if (!currentDistrict) {
      return;
    }
    this.listWardRootAddress = currentDistrict.w;
  }

  loadAddressOptions(address: Address) {
    const currentProvince: Province = this.listProvince.find((province: Province) => province.n === address.province);
    if (!currentProvince) {
      return;
    }
    this.listDistrictAddress = currentProvince.d;
    const currentDistrict: District = currentProvince.d.find((district: District) => district.n === address.district);
    if (!currentDistrict) {
      return;
    }
    this.listWardAddress = currentDistrict.w;
  }

  getIncomeFromRange(incomeFrom: number, incomeTo: number): number {
    let id = 0;
    switch (incomeTo) {
      case 0:
        id = incomeFrom === 80000000 ? 7 : 0;
        break;
      case 5000000:
        id = 1;
        break;
      case 10000000:
        id = 2;
        break;
      case 18000000:
        id = 3;
        break;
      case 32000000:
        id = 4;
        break;
      case 52000000:
        id = 5;
        break;
      case 80000000:
        id = 6;
        break;
      default:
        id = 0;
        break;
    }
    return id;
  }

  getImcomeRange(incomeId: number): any {
    let range = { incomeFrom: 0, incomeTo: 0 };
    switch (incomeId) {
      case 0:
        range.incomeFrom = 0;
        range.incomeTo = 0;
        break;
      case 1:
        range.incomeFrom = 0;
        range.incomeTo = 5000000;
        break;
      case 2:
        range.incomeFrom = 5000000;
        range.incomeTo = 10000000;
        break;
      case 3:
        range.incomeFrom = 10000000;
        range.incomeTo = 18000000;
        break;
      case 4:
        range.incomeFrom = 18000000;
        range.incomeTo = 32000000;
        break;
      case 5:
        range.incomeFrom = 32000000;
        range.incomeTo = 52000000;
        break;
      case 6:
        range.incomeFrom = 52000000;
        range.incomeTo = 80000000;
        break;
      case 7:
        range.incomeFrom = 80000000;
        range.incomeTo = 0;
        break;
      default:
        range.incomeFrom = 0;
        range.incomeTo = 0;
        break;
    }
    return range;
  }

  markAsTouched(group: FormGroup | FormArray) {
    group.markAsTouched({ onlySelf: true });
    Object.keys(group.controls).map((field) => {
      const control = group.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAsTouched(control);
      }
    });
  }

  getFormControl(name: string) {
    return this.mainForm && this.mainForm.get(name);
  }

  patchFormValue(model: Customer, formData: any): Customer {
    // Patch Customer PersonalInfo
    const personalInfo: PersonalInfo = model.personalInfo || new PersonalInfo();
    personalInfo.name = formData.name;
    personalInfo.phone = formData.phone;
    personalInfo.email = formData.email;
    personalInfo.job = formData.job;
    const { incomeFrom, incomeTo } = this.getImcomeRange(formData.income);
    personalInfo.incomeFrom = incomeFrom;
    personalInfo.incomeTo = incomeTo;
    personalInfo.incomeSource = formData.incomeSource;
    personalInfo.relationshipStatus = formData.relationshipStatus;
    // Patch identity
    const selectedIdentity: IdentityInfo = new IdentityInfo({
      type: formData.identityType,
      value: formData.identityNumber,
      date: formData.identityIssuedDate,
      place: formData.identityIssuedBy
    });
    personalInfo.identities = [selectedIdentity]; // Chỉ lưu lại thông tin giấy tờ mới nhất
    // if (personalInfo.identities) {
    //   const currentIdentityIndex: number = personalInfo.identities.findIndex((item: IdentityInfo) => item.type === selectedIdentity.type);
    //   if (currentIdentityIndex === -1) {
    //     personalInfo.identities.push(selectedIdentity);
    //   } else {
    //     personalInfo.identities[currentIdentityIndex] = selectedIdentity;
    //   }
    // } else {
    //   personalInfo.identities = [selectedIdentity];
    // }
    model.personalInfo = personalInfo;
    // Patch Customer InfoCustomer
    const rootAddress: Address = new Address({
      country: formData.rootAddressCountry,
      province: formData.rootAddressProvince,
      district: formData.rootAddressDistrict,
      ward: formData.rootAddressWard,
      address: formData.rootAddressText
    });
    const address: Address = new Address({
      country: formData.addressCountry,
      province: formData.addressProvince,
      district: formData.addressDistrict,
      ward: formData.addressWard,
      address: formData.addressText
    });

    const info: InfoCustomer = new InfoCustomer({
      gender: formData.gender,
      birthday: formData.birthday,
      birthdayYear: formData.birthdayYear,
      onlyYear: formData.onlyYear,
      rootAddress: rootAddress,
      address: address
    });
    model.info = info;
    model.takeNote = formData.takeNote;
    model.taxCode = formData.taxCode;
    model.bankInfo = this.listBank.find(b => b.CODE === formData.bankName);
    model.bankInfo = Object.assign({}, model.bankInfo, { accountNumber: formData.bankNumber});

    return model;
  }

  // capital_letter(str = '') {
  //     let strArr = str.split(' ');
  //     for (let i = 0, x = strArr.length; i < x; i++) {
  //       if (strArr[i][0]) {
  //         strArr[i] = strArr[i][0].toUpperCase() + strArr[i].substr(1).toLowerCase();
  //       }
  //     }

  //     return strArr.join(' ');
  // }

  onChangeViewAll() {
    // const isOnlyyear = this.mainForm.get('onlyYear').value;
    // if(isOnlyyear) {
    //   this.mainForm.get('birthdayYear').setValidators(Validators.required);
    //   this.mainForm.get('birthdayYear').updateValueAndValidity();
    //   this.mainForm.get('birthday').clearValidators();
    //   this.mainForm.get('birthday').updateValueAndValidity();
    // } else {
    //     this.mainForm.get('birthday').setValidators(Validators.required);
    //     this.mainForm.get('birthday').updateValueAndValidity();
    //     this.mainForm.get('birthdayYear').clearValidators();
    //     this.mainForm.get('birthdayYear').updateValueAndValidity();
    // }
  }
  onModalChange($event) {
    if ($event && $event.term && typeof $event.term === 'string') {
      $event.term = $event.term.trim();
      this.mainForm.get('identityIssuedBy').setValue($event.term);
    }
  }

}

interface SelectItemModel {
  value: any;
  label: string;
}
interface Province {
  n: string;  // Name
  d: District[]; // Districts
}
interface District {
  n: string;  // Name
  w: string[];  // Wards
}
