import { Injectable } from '@angular/core';
import { StorageService } from 'app/shared/services';
import { Conversation, Message } from 'app/shared/models/conversation.model';
import { VoiceCallService } from 'app/shared/services/voicecall.service';
import { StringeeClient, StringeeChat } from 'stringee-chat-js-sdk';
import { Subject } from 'rxjs';
import { StringeeObjectTypeEnum, StringeeChangeTypeEnum, StringeeMessageStateEnum, StringeeMessageTypeEnum } from 'app/shared/enum/stringee.enum';
import { ToastService } from 'app/theme/components/toast';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class StringeeService {
  currentUserId: string;
  public isMessage: boolean = true; // from component message
  public stringeeClient: any;
  public stringeeChat: any;
  public isConnectStringeeCallback = new Subject<boolean>();
  isConnectStringeeCallback$ = this.isConnectStringeeCallback.asObservable();

  public updateConversationsCallback = new Subject<boolean>();
  updateConversationsCallback$ = this.updateConversationsCallback.asObservable();

  public updateCurrentConversationCallback = new Subject<boolean>();
  updateCurrentConversationCallback$ = this.updateCurrentConversationCallback.asObservable();

  public updateMessagesCallback = new Subject<boolean>();
  updateMessagesCallback$ = this.updateMessagesCallback.asObservable();

  public static conversations: Conversation[] = [];
  public static extraConvs: Conversation[] = [];
  public static currentConversation: Conversation;
  public static messages: Message[] = [];
  public static lastSequenceMessage: number = 0;
  public static lastMsgTimestamp: number = 0;
  public targetConversationId: string;

  numberConversation: number = 100000;
  numberMessage: number = 20;
  moreNumberMessage: number = 5;
  constructor(
    private storageService: StorageService,
    private voiceCallService: VoiceCallService,
    private toastService: ToastService
  ) {
    if (this.storageService.retrieve('email')) {
      this.currentUserId = this.storageService.retrieve('email').replace('@', '.');
    }
  }

  public connectStringee() {
    this.voiceCallService.getCallToken().then((res) => {
      if (res) {
        this.storageService.store('stringeeToken', res);
        this.stringeeClient = new StringeeClient();
        this.settingClientEvents(this.stringeeClient);
        this.stringeeClient._stringeeServerAddr = 'https://dx-v2.stringee.com';
        this.stringeeClient.connect(res);
      }
    });
  }

  settingClientEvents(client) {
    const _this = this;
    client.on('connect', () => {
    });

    client.on('authen', (res) => {
      if (res.r !== 0) {
      } else {
      }
    });
    client.on('authensuccess', () => {
      _this.stringeeChat = new StringeeChat(client);
      this.isConnectStringeeCallback.next(true);
      _this.getConversationsBefore(false);
      _this.settingChatEvents(_this.stringeeChat);
    });

    client.on('chatmessagestate', (state) => {

      if (state.from !== _this.currentUserId) {
        StringeeService.lastMsgTimestamp = state.lastMsgTimestamp;
        _this.getConversationsBefore(false);
      }
    });

    client.on('otherdeviceauthen', () => {
    });

    client.on('disconnect', () => {
    });
  }

  setCurrentConversation(conversation) {
    StringeeService.currentConversation = conversation;
    this.markConversationAsRead();
    this.updateCurrentConversationCallback.next(true);
  }

  setMessages(messages) {
    StringeeService.messages = messages;
    this.updateMessagesCallback.next(true);
  }


  sendMessage(txtMsg) {
    let _this = this;
    this.stringeeChat.sendMessage(txtMsg, function (status, code, message, msg) {
    });
  }

  getConversationsBefore(isFirst) {
    let _this = this;
    const isAscending = false;
    const currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + 1);
    const datetime = currentDate.getTime();
    this.stringeeChat.getConversationsBefore(datetime, this.numberConversation, isAscending, function (status, code, message, convs) {
      StringeeService.conversations = convs || [];
      StringeeService.extraConvs.forEach(e => {
        if (StringeeService.conversations.filter(x => x.id === e.id).length === 0) {
          StringeeService.conversations.unshift(e);
        }
      });
      if (convs && convs.length > 0) {
        // set default conversation and load history chat
        if (isFirst) {
          let conv = convs[0];
          _this.setCurrentConversation(conv);
          _this.updateCurrentConversationCallback.next(true);
          _this.getLastMessages();
        } else if (_this.targetConversationId) {
          let conv = _.find(convs, function (o) { return o.id === _this.targetConversationId; });
          if (conv) {
            _this.targetConversationId = '';
            _this.setCurrentConversation(conv);
            _this.updateCurrentConversationCallback.next(true);
            _this.getLastMessages();
          } else {
          }
        }
      }
      _this.updateConversationsCallback.next(true);
    });
  }

  public getLastMessages() {
    const _this = this;
    if (StringeeService.currentConversation && StringeeService.currentConversation.id) {
      const convId = StringeeService.currentConversation.id;
      let isAscending = true;
      this.stringeeChat.getLastMessages(convId, this.numberMessage, isAscending, function (status, code, message, msgs) {
        StringeeService.messages = msgs;
        _this.updateMessagesCallback.next(true);

        const countMessages = msgs && msgs.length ? msgs.length : 0;
        if (countMessages > 0 && msgs[countMessages - 1].sender != _this.currentUserId) {
          _this.markConversationAsRead();
        }
      });
    }
  }

  public getMessagesBefore(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (StringeeService.currentConversation && StringeeService.messages && StringeeService.messages.length > 0) {
        const convId = StringeeService.currentConversation.id;
        const count = this.moreNumberMessage;
        const isAscending = true;
        const sequence = StringeeService.messages[0].sequence;
        this.stringeeChat.getMessagesBefore(convId, sequence, count, isAscending, function (status, code, message, msgs) {
          if (msgs && msgs.length > 0) {
            StringeeService.messages = [...msgs, ...StringeeService.messages];
            resolve(msgs && msgs.length > 0 ? msgs : []);
          } else {
            resolve([]);
          }
        });
      } else {
        resolve([]);
      }
    })
  }

  markConversationAsRead() {
    let _this = this;
    if (StringeeService.currentConversation) {
      this.stringeeChat.markConversationAsRead(StringeeService.currentConversation.id, (status, code, message) => {
        _this.getConversationsBefore(false);
      });
    }
  }

  settingChatEvents(stringeeChat) {
    const _this = this;

    stringeeChat.on('onObjectChange', function (info) {
      if (info.objectChanges.length > 0) {
        // 1. message
        // 2. conversation
        if (info.objectType === StringeeObjectTypeEnum.Message) {
          switch (info.changeType) {
            case StringeeChangeTypeEnum.Insert:

              if (info.objectChanges[0] && StringeeService.lastSequenceMessage !== info.objectChanges[0].sequence) {
                switch (info.objectChanges[0].state) {
                  case StringeeMessageStateEnum.Delivered:
                    StringeeService.lastSequenceMessage = info.objectChanges[0].sequence;
                    // 1. current conversation
                    // 2. other conversation
                    if (
                      StringeeService.currentConversation &&
                      StringeeService.currentConversation.id === info.objectChanges[0].conversationId) {
                      StringeeService.messages.push(info.objectChanges[0]);
                      _this.updateMessagesCallback.next(true);

                      // other sender
                      if (info.objectChanges[0].sender !== _this.currentUserId) {
                        _this.markConversationAsRead();
                        _this.getConversationsBefore(false);
                      }
                    } else if (!StringeeService.currentConversation || (StringeeService.currentConversation.id !== info.objectChanges[0].conversationId)) {
                      // create group
                      if (info.objectChanges[0].type === StringeeMessageTypeEnum.CreateGroup && info.objectChanges[0].sender === _this.currentUserId) {
                        _this.getConversationsBefore(true);
                      } else {
                        // update unread
                        _this.getConversationsBefore(false);
                      }

                      // show moti
                      let title = '';
                      let txtNoti = '';
                      if (_.find(StringeeService.conversations, function (o) { return o.id === info.objectChanges[0].conversationId; })) {
                        if (info.objectChanges[0].content.content) {
                          const curConv = StringeeService.conversations.filter(x => x.id === info.objectChanges[0].conversationId);
                          if (curConv && curConv.length > 0) {
                            title = curConv[0].participants.filter(x => x.userId === info.objectChanges[0].sender)[0].name;
                          }
                          txtNoti = info.objectChanges[0].content.content;
                          _this.toastService.show({
                            title: title,
                            text: txtNoti,
                            type: 'success',
                            conversationId: info.objectChanges[0].conversationId,
                            messageData: info.objectChanges[0]
                          });
                        } else {
                          txtNoti += info.objectChanges[0].sender === _this.currentUserId ? 'Đã tạo nhóm ' : 'Đã thêm bạn vào nhóm ';
                          txtNoti += info.objectChanges[0].content.groupName;
                        }
                      }
                      // _this.toastService.show({
                      //   title: info.objectChanges[0].sender,
                      //   text: txtNoti,
                      //   type: 'success',
                      // });
                    }
                    break;
                  case StringeeMessageStateEnum.Read:

                    break;
                  default:
                    break;
                }
              }
              break;
            case StringeeChangeTypeEnum.Update:
              if (
                StringeeService.currentConversation &&
                StringeeService.currentConversation.id === info.objectChanges[0].conversationId &&
                info.objectChanges[0].type === StringeeMessageTypeEnum.Notify) {
                _this.getConversationsBefore(true);
              } else {
                _this.getConversationsBefore(false);
              }
              break;
            case StringeeChangeTypeEnum.Delete:
              break;
          }
        } else {
          // update unread
          switch (info.changeType) {
            case StringeeChangeTypeEnum.Delete:
              _this.getConversationsBefore(true);
              break;
          }
        }
      }
    });

    stringeeChat.on('removeParticipantFromServer', function (info) {
      // Remove conversation
      if (StringeeService.currentConversation && StringeeService.currentConversation.id === info.groupId) {
        _this.setCurrentConversation(null);
      }
      StringeeService.conversations = StringeeService.conversations.filter(x => x.id !== info.groupId);
      _this.updateConversationsCallback.next(true);
    });

    stringeeChat.on('pinMsgFromServer', function (info) {
      if (info.convId === StringeeService.currentConversation.id) {
        const currentConv = StringeeService.currentConversation;
        currentConv.pinMsgId = info.isPin ? info.msgId : '';
        _this.setCurrentConversation(currentConv)
      }
    });
    stringeeChat.on('revokeMsgFromServer', function (info) {
  });
  }

  public createConversation(userIds, options) {
    const _this = this;
    this.stringeeChat.createConversation(userIds, options, (status, code, message, conv) => {
      // auto select conversation
      _this.targetConversationId = conv.id;
      let currentConv = _.find(StringeeService.conversations, function (o) { return o.id === conv.id; });
      if (currentConv) {
        _this.setCurrentConversation(currentConv);
        _this.getLastMessages();
      }
      else {
        StringeeService.conversations.push(conv);
        StringeeService.extraConvs.push(conv);
        _this.setCurrentConversation(conv);
        _this.getLastMessages();
      }
    });
  }

  public deleteConversation(convId) {
    const _this = this;
    const participantIds = [];
    // delete conversation
    // this.stringeeChat.deleteConversation(convId, function (status, code, message) {
    // });
    // Get all params
    const currentConv = StringeeService.conversations.filter(x => x.id === convId);
    if (currentConv.length > 0) {
      currentConv[0].participants.forEach(e => {
        participantIds.push(e.userId);
      });
      // leave before delete
      this.stringeeChat.removeParticipants(convId, participantIds, function (status, code, message, removed) {
        // delete
        StringeeService.conversations = StringeeService.conversations.filter(x => x.id !== convId);

        _this.setCurrentConversation(null);
        _this.updateCurrentConversationCallback.next(true);
        _this.updateConversationsCallback.next(true);
      });
    }

  }

  public removeParticipants(convId, ids) {
    const _this = this;
    this.stringeeChat.removeParticipants(convId, ids, function (status, code, message, removed) {
      _this.getConversationsBefore(true);
    });
  }

  public addParticipants(convId, ids) {
    const _this = this;
    this.stringeeChat.addParticipants(convId, ids, function (status, code, message, added) {
      _this.getConversationsBefore(true);
    });
  }

  public updateConversation(convId, params) {
    const _this = this;
    this.stringeeChat.updateConversation(convId, params, function (status, code, message) {
      _this.getConversationsBefore(true);
    });
  }

  public pinMessage(convId, msgId, isPin) {
    this.stringeeChat.pinMessage(convId, msgId, isPin, function (status, code, message) {
    });
  }

  public revokeMessage(convId, msgId){
    this.stringeeChat.revokeMessage(convId, msgId, function (status, code, message) {
    });
  }
}
