import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../http.service';
import { CUser } from 'app/api-models/c-user';
import { ConstantUrl } from 'app/shared/constant/url';
import { map, tap, mergeMap } from 'rxjs/operators';
import { CommonStaticFunction } from 'app/shared/components/common.function';

@Injectable({
  providedIn: 'root'
})
export class UserV2Service {

  private _user: BehaviorSubject<CUser> = new BehaviorSubject(new CUser());
  public get user(): CUser {
    return this._user.value;
  }
  public user$: Observable<CUser> = this._user.asObservable();

  constructor(protected httpService: HttpService) {
  }

  public updateUserBehaviorSubject(user: CUser) {
    this._user.next(user);
  }

  updateCurrentUser(formUser: Partial<CUser>, user: CUser) {
    const uri = ConstantUrl.url_employee_domain + `employee/updateProfile`;
    const { avatar } = formUser;
    return this.httpService.put(uri, Object.assign(formUser, { id: user.id, images: { avatar } }));
  }

  updatePassword(formPassword: { oldPassword: string, password: string }, user: CUser) {
    const uri = ConstantUrl.url_sts_domain + `user/changePwd`;
    formPassword.password = CommonStaticFunction.encodeBase64(formPassword.password);
    formPassword.oldPassword = CommonStaticFunction.encodeBase64(formPassword.oldPassword);
    return this.httpService.put(uri, Object.assign(formPassword, { id: user.id, isEncodePassword: true }));
  }

  getCurrentUser(): Observable<CUser> {
    const uri = ConstantUrl.url_employee_query + 'employee/current';
    return this.httpService.get(uri)
      .pipe(
        map((res: any) => {
          return new CUser().decode(res);
        }),
        mergeMap((userOb1: CUser) => {
          return this.getPermissions(userOb1.id)
            .pipe(
              map((userOb2: CUser) => {
                const { permissions, roleId , role} = userOb2;
                return Object.assign(userOb1, { permissions, roleId , role});
              })
            );
        }),
        tap((res: CUser) => {
          this.updateUserBehaviorSubject(res)
        })
      );
  }

  getCurrentEmployee(): Observable<any> {
    const uri = ConstantUrl.url_employee_query + 'employee/getProfile';
    return this.httpService.get(uri);
  }

  getPermissions(userId: string): Observable<CUser> {
    const uri = ConstantUrl.url_sts_query + `user/${userId}`;
    return this.httpService.get(uri)
      .pipe(
        map((res: any) => new CUser().decode(res)),
      );
  }

}
