<div fxLayout="row" fxLayoutAlign="end center" class="deal-checker-title">
  <div (click)="onNoClick()" class="button-close">
    <mat-icon>close</mat-icon>
  </div>
</div>
<mat-card class="deal-checker">
  <div class="text-center text-header">{{unRegister ? 'Trả về': 'Đăng kí'}} sản phẩm </div>
  <div mat-dialog-content class="deal-checker-content">
    <p class="text-center">Bạn xác nhận {{ unRegister ? 'trả về': 'đăng kí'}} sản phẩm {{item?.code}}?</p>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
    <button class="m-bg action-btn" mat-button (click)="execute()">Xác nhận</button>
  </div>
</mat-card>
