<mat-dialog-content>
  <div class="image-container">
    <mat-spinner *ngIf="isLoading" color="primary"></mat-spinner>
    <image-cropper [class.hidden]="isLoading"
      #imageCropper
      [imageFile]="imageFile"
      [maintainAspectRatio]="true"
      [aspectRatio]="1 / 1"
      [resizeToWidth]="200"
      [cropperMinWidth]="200"
      [outputType]="'file'"
      [autoCrop]="false"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"></image-cropper>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="center center">
  <button mat-raised-button color="primary" (click)="cropImage()" [disabled]="isLoading">Xác nhận</button>
  <button mat-raised-button (click)="onNoClick()">Hủy</button>
</mat-dialog-actions>