import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// let chatSource = document.createElement("script");
// chatSource.setAttribute('id', 'stringeeXChatWidget');
// chatSource.setAttribute('src', environment.stringChat.liveChatUrl);
// document.body.appendChild(chatSource);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
